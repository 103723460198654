export class QuestionReset {
    constructor(public securityQuestion?: SecurityQuestion,
                public securityAnswer?: string,
                public password?: string) {
    }
}

interface SecurityQuestion {
    id: string;
    question: string;

}

export default SecurityQuestion;
