import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {DatePipe} from '@angular/common';
import {
    AuthServerProvider,
    CSRFService,
    HasAnyAuthorityDirective, httpLoaderFactory,
    JhiLoginModalComponent,
    Principal,
    StateStorageService,
    VdrSharedCommonModule,
    VdrSharedLibsModule,
} from './';
import {CustomMaterialModule} from '../material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FileSizePipe} from './pipe/file-size.pipe';
import {NgxSpinnerModule} from 'ngx-spinner';
import {FindLanguageFromKeyPipe, JhiLanguageHelper} from './index';
import {BrowserModule, Title} from '@angular/platform-browser';
import {GroupService} from './group/group.service';
import {ThermsModalService} from './therms/therms-modal.service';
import {VerificationCodeComponent} from './therms/verification-code.component';
import {ThermsModalComponent} from './therms/therms.component';
import {UtilsService} from './utils/utils.service';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DragAndDropModule} from 'angular-draggable-droppable';
import {ShowErrorsComponent} from './utils/show-errors.component';
import {ConfirmationDialogComponent} from '../entities/confirmation-dialog/confirmation-dialog.component';
import {ConfirmationDialogService} from '../entities/confirmation-dialog/confirmation-dialog.service';
import {ListDocumentNamePipe} from '../entities/directory-document/pipe/list-document-name-pipe';
import {GitVersionComponent} from './git-version/git-version.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {SafeHtmlPipe} from './pipe/safe-html.pipe';
import {ListDocumentPathPipe} from '../entities/directory-document/pipe/list-document-path-pipe';
import {HighlightSearch} from './pipe/highlight.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import {RoundUpPipe} from './pipe/round-up.pipe';
import {VerificationCodeModalService} from './therms/verification-code-modal.service';

@NgModule({
    imports: [
        VdrSharedLibsModule,
        VdrSharedCommonModule,
        CustomMaterialModule,
        FormsModule,
        NgSelectModule,
        ReactiveFormsModule,
        BrowserModule,
        FlexLayoutModule,
        NgxSpinnerModule,
        MatDatepickerModule,
        DragAndDropModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        })

    ],
    declarations: [
        JhiLoginModalComponent,
        HasAnyAuthorityDirective,
        FileSizePipe,
        HighlightSearch,
        FindLanguageFromKeyPipe,
        ThermsModalComponent,
        VerificationCodeComponent,
        ShowErrorsComponent,
        ConfirmationDialogComponent,
        ListDocumentNamePipe,
        ListDocumentPathPipe,
        GitVersionComponent,
        SafeHtmlPipe,
        RoundUpPipe
    ],
    providers: [
        ThermsModalService,
        GroupService,
        DatePipe,
        Title,
        {
            provide: LOCALE_ID,
            useValue: 'pl'
        },
        JhiLanguageHelper,
        UtilsService,
        ConfirmationDialogService,
        VerificationCodeModalService
    ],
    entryComponents: [JhiLoginModalComponent, ThermsModalComponent, VerificationCodeComponent, ConfirmationDialogComponent],
    exports: [
        VdrSharedCommonModule,
        JhiLoginModalComponent,
        HasAnyAuthorityDirective,
        DatePipe,
        ListDocumentNamePipe,
        ListDocumentPathPipe,
        CustomMaterialModule,
        FlexLayoutModule,
        FileSizePipe,
        HighlightSearch,
        NgxSpinnerModule,
        NgxSpinnerModule,
        NgSelectModule,
        FindLanguageFromKeyPipe,
        DragAndDropModule,
        ShowErrorsComponent,
        ConfirmationDialogComponent,
        GitVersionComponent,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        SafeHtmlPipe,
        RoundUpPipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class VdrSharedModule {
}
