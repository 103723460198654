
export class Pageable {
    public sorts?: PageableSort[];

    constructor(
        public page: number,
        public size: number
    ) {
    }

    public copy(): Pageable {
        return Object.assign({}, this);
    }
}

export class PageableSort {
    constructor(
        public field?: string,
        public sortDirection?: SortDirection
    ) {
    }
}

export enum SortDirection {
    ASC, DESC
}
