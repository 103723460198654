import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChange, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DocumentRowVdr} from '../document-vdr/document-row-vdr.model';
import {SelectionModel} from '@angular/cdk/collections';
import {DocumentVdr, DocumentVdrService} from '../document-vdr';
import {JhiAlertService} from '@upside-cloud/ng-jhipster';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {UploadDialogComponent} from './upload.component';
import {DirectoryCreateDialog} from './directory-utils/directory-create-dialog';
import {DownloadType, getFileNameFromResponseContentDispositionBase64, saveFile, UploadDownloadService} from './upload-download.service';
import {DirectoryDocumentRowDto} from './dto/directory-document-row-dto.model';
import {DirectoryVdr, DirectoryVdrService} from '../directory-vdr';
import {DownloadObjectDto} from './dto/download-object-dto.model';
import {NgxSpinnerService} from 'ngx-spinner';
import getIconForExtension from 'font-awesome-filetypes';
import {PdfDocumentViewerComponent} from '../../account';
import {DirectoryDocumentViewSettingsDto} from './dto/directory-document-view-settings-dto.model';
import {DirectoryDocumentService} from './directory-document.service';
import {DocumentCriteriaDto} from './dto/document-criteria-dto.model';
import {DocumentType} from './dto/document-type.enum';
import {enumToKeyValue, Principal, UtilsService} from '../../shared';
import {SearchIn} from './dto/search-in.enum';
import {DocumentSearchComponent} from './document-search.component';
import {PdfPasswordProtectionDialog} from './pdf-password-protection-dialog/pdf-password-protection-dialog';
import {ChangeNameDialog} from './utils/change-name-dialog';
import {DropEvent} from 'angular-draggable-droppable';
import {ConfirmationDialogService} from '../confirmation-dialog/confirmation-dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationDialogData} from '../confirmation-dialog/confirmation-dialog.component';
import {ReportService} from '../../report';
import {moment} from 'fullcalendar';
import {ReportUploadedCriteriaDto} from '../../report/report-uploaded/dto/report-uploaded-criteria-dto.model';
import {DirectoryDocumentPermissionService} from './directory-document-permission/directory-document-permission.service';
import {PermissionDto} from './directory-document-permission/permission-dto.model';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ReportArchive, ReportArchiveOption} from '../../report/report-archvie/report-archive.model';
import {RequestZipStatusDialog} from './zip-request/request-zip-status-dialog.component';
import {Socket} from 'ngx-socket-io';
import {DocumentPermissionType} from './directory-document-permission/document-permission-type.enum';
import {result} from 'lodash';

export class SelectedItem {
    constructor(
        public id?: number,
        public type?: string,
        public qaAttachment?: boolean
    ) {
    }
}

export class DirectoryChange {
    constructor(
        public id?: number,
        public refreshId?: number,
    ) {
    }
}

@Component({
    selector: 'jhi-document-list',
    templateUrl: 'document-list.component.html',
    styleUrls: ['directory-document.scss'],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'pl-PL'}
    ]
})
export class DocumentListComponent implements OnChanges, AfterViewInit, AfterViewChecked, OnDestroy {
    _directoryId: number;
    @Input() directoryName: string;
    @Input() nodeType: string;
    @Input() indexCumulate: string;
    @Input() ddvSettings: DirectoryDocumentViewSettingsDto;
    private _refresh: number;
    canModifyCurrentDirectory: boolean;

    @Output() selectedDocuments = new EventEmitter<SelectedItem[]>();
    @Output() directoryAdded = new EventEmitter<DirectoryChange>();
    @Output() directorySelected = new EventEmitter<number>();

    displayedColumns: string[];
    dataSource = new MatTableDataSourceWithCustomSort<DirectoryDocumentRowDto>();
    selection = new SelectionModel<DirectoryDocumentRowDto>(true, []);
    originDirectoryId: number;
    copy = false;
    copyCutInProgress = false;
    copyCutSelected: DownloadObjectDto[];
    copyCutSelectionType: string;
    isViewerOpened = false;
    documentMainVersionId: number;
    previousVersionMode = false;
    documentCriteriaDto: DocumentCriteriaDto;
    documentTypesSelect: any;
    downloadTypes: string[] = ['DOWNLOAD_PDF', 'DOWNLOAD_ORIGINAL', 'DOWNLOAD_ENCRYPTED'];
    canDeleteFilesFromMenu = false;

    documentCriteriaDtoTmp: DocumentCriteriaDto;
    public SearchIn = SearchIn;

    directoryPermission: PermissionDto;

    @ViewChild('documentSearchComponent', {static: true}) documentSearchComponent: DocumentSearchComponent;
    @ViewChild(MatSort) sort: MatSort;

    documentId: number;

    confirmationSettings: ConfirmationDialogData;

    @ViewChild('menuBtn1', {read: MatMenuTrigger}) menu: MatMenuTrigger;

    contextMenuPosition = {x: '0px', y: '0px'};

    @Input() set refresh(value: number) {
        this._refresh = value;
        this.loadAll();
    }

    constructor(
        private documentService: DocumentVdrService,
        private jhiAlertService: JhiAlertService,
        public dialog: MatDialog,
        private uploadDownloadService: UploadDownloadService,
        private directoryService: DirectoryVdrService,
        private spinner: NgxSpinnerService,
        private directoryDocumentService: DirectoryDocumentService,
        private utilsService: UtilsService,
        private confirmationDialogService: ConfirmationDialogService,
        private translateService: TranslateService,
        private reportService: ReportService,
        private principal: Principal,
        private directoryDocumentPermissionService: DirectoryDocumentPermissionService,
        private ref: ChangeDetectorRef,
        private socket: Socket) {

        const onEntryChanged = (directoryId: string, document: DocumentRowVdr) => {
            // it's about directory we're no longer in, ignore.
            if (this.directoryId !== Number(directoryId)) {
                return;
            }

            const matchingDocuments = this.dataSource.data
                .filter((v) => v.id === document.id);

            if (!matchingDocuments.length) {
                const documentInstance = Object.assign(new DirectoryDocumentRowDto(), document as DirectoryDocumentRowDto);
                documentInstance.new = true;
                this.dataSource.data.push(documentInstance);
                this.dataSource._updateChangeSubscription();
                console.log('Added new document', document);
                return;
            }

            matchingDocuments.forEach((entry) => {
                entry.name = document.name;
                entry.status = document.status;
                entry.number = document.number;
                this.dataSource._updateChangeSubscription();
                console.log('Updated document', document);
            });
        };

        this.socket.on('directory-in-directory-changed', onEntryChanged);
        this.socket.on('document-in-directory-changed', onEntryChanged);

        this.displayedColumns = ['select', 'favourite', 'type', 'number', 'name', 'actions', 'newDocument', 'createdDate'];
        this.initDocumentCriteria();
        this.utilsService.initSvgIcons();
    }

    ngOnDestroy(): void {
        this.socket.removeAllListeners('directory-in-directory-changed');
        this.socket.removeAllListeners('document-in-directory-changed');
    }

    getTooltipText(document: DirectoryDocumentRowDto) {
        let ret = document.name;

        if (document.originalName) {
            ret +=  '\n' + this.translateService.instant('details.file.originalName') + document.originalName;
        }

        if (document.pdfCreatedTimestamp) {
            ret += '\nPDF: ' + moment(document.pdfCreatedTimestamp).format('YYYY-MM-DD kk:mm');
        }
        if (document.fileIndexedTimestamp) {
            ret += '\nINDEX :' + moment(document.fileIndexedTimestamp).format('YYYY-MM-DD kk:mm');
        }
        if (document.originalName && document.name !== document.originalName) {
            ret += '\n' + document.originalName;
        }
        return ret;
    }

    onContextMenu(event: MouseEvent, item?) {
        if (item && (item.status != 'NORMAL' || this.copyCutInProgress)) {
            return;
        }
        event.preventDefault();
        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        if (item) {
            this.masterToggle();
            setTimeout(() => {
                this.masterToggle();
                setTimeout(() => {
                    this.selection.select(item);
                    this.canDeleteFile();
                    this.menu.openMenu();
                }, 25);

            }, 25);
        } else {
            this.canDeleteFile();
            this.menu.openMenu();
        }
    }

    toggleAll(event) {
        return event ? this.masterToggle() : null;
    }

    selectRow(event, row) {
        if (!this.copyCutInProgress || row.status != 'NORMAL') {
            this.selection.toggle(row);
            this.onSelection(row);
        }
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
    }

    ngAfterViewChecked(): void {
        this.confirmationSettings = {
            'title': this.translateService.instant('deleteConfirmation.title'),
            'text': this.translateService.instant('deleteConfirmation.text')
        };
    }

    initDocumentCriteria() {
        this.documentCriteriaDto = new DocumentCriteriaDto();
        this.documentCriteriaDto.read = null;
        this.documentTypesSelect = enumToKeyValue(DocumentType);
    }

    areFilesSelected() {
        const numSelected = this.selection.selected.length;
        return numSelected > 0;
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        // const numRows = this.dataSource.data.length;
        let numRows = 0;
        this.dataSource.data.forEach((x) => {
            if (x.status === 'NORMAL') {
                numRows++;
            }
        });
        return numSelected === numRows;
    }

    isItemSelected(id: number): boolean {
        let res = false;
        this.selection.selected.forEach((value) => {
            if (value.id === id) {
                res = true;
                return;
            }
        });
        return res;
    }

    isOneDocumentSelected() {
        let res = false;
        const numSelected = this.selection.selected.length;
        if (numSelected === 1) {
            const selectedType = this.selection.selected[0].type;
            if (selectedType !== 'directory') {
                res = true;
            }
        }
        return res;
    }

    isOneSelected() {
        let res = false;
        const numSelected = this.selection.selected.length;
        if (numSelected === 1) {
            res = true;
        }
        return res;
    }

    isRoot() {
        let res = false;
        if (!this.selection.selected.length) {
            return;
        }
        const selected = this.selection.selected[0];
        if (selected.type === 'directory' && !selected.parentDirectoryId) {
            res = true;
        }

        return res;
    }

    isPreviousVersionEnable() {
        let res = false;
        const numSelected = this.selection.selected.length;
        if (numSelected === 1) {
            const selectedType = this.selection.selected[0].type;
            if (selectedType !== 'directory') {
                if (this.selection.selected[0].documentVersion > 1) {
                    res = true;
                }
            }
        }
        return res;
    }

    getParentElement(): DirectoryDocumentRowDto {
        let result = null;
        this.dataSource.data.forEach((value) => {
            if (value.id === this.directoryId) {
                result = value;

            }
        });
        return result;
    }

    onSelection(row: DirectoryDocumentRowDto) {
        if (row.id !== this.directoryId) {
            if (!this.isAllSelected()) {
                if (this.isItemSelected(this.directoryId)) {
                    this.selection.deselect(this.getParentElement());
                }
            }
        }
        this.selectedDocuments.emit(this.getItemssFromSelection());
    }

    masterToggle2() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach((row) => {
                if (row.status === 'NORMAL') {
                    this.selection.select(row);
                }
            });
        this.selectedDocuments.emit(this.getItemssFromSelection());
    }

    masterToggle(select?: boolean) {
        if (select === undefined) {
            select = !this.isAllSelected();
        }

        if (select) {
            this.dataSource.data.forEach((row) => {
                if (row.status === 'NORMAL') {
                    this.selection.select(row);
                }
            });
        } else {
            this.selection.clear();
        }
        this.selectedDocuments.emit(this.getItemssFromSelection());
    }

    mergePDFs() {
        this.documentService.mergePdfs(this.selection.selected[0].id, this.selection.selected[1].id)
            .subscribe((result) => {
                this.selection.clear();
                this.loadAll();
            });
    }

    mergePDFsAvailable(): boolean {
        const directoryDocumentRowDtos = this.selection.selected;
        return directoryDocumentRowDtos.length === 2 && directoryDocumentRowDtos
            .every((row) => row.pdfSupport);
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        console.warn('on ngOnChanges ', this.directoryId);

        if (this.ddvSettings.indexVisible) {
            this.displayedColumns = ['select', 'favourite', 'number', 'type', 'name', 'actions', 'newDocument', 'createdDate'];
        } else {
            this.displayedColumns = ['select', 'favourite', 'type', 'name', 'actions', 'newDocument', 'createdDate'];
        }
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'name':
                    return this.sortPrefix(item) + item.name.toLowerCase();
                case 'number':
                    return this.sortPrefix(item) + item.number;
                default:
                    return this.sortPrefix(item) + item[property];
            }
        };
        for (const propName of Object.keys(changes)) {
            if (propName === 'directoryId') {
                if (this.directoryId && this.directoryId !== changes['directoryId'].previousValue) {
                    this.previousVersionMode = false;
                    if (this.documentCriteriaDto) {
                        this.documentCriteriaDto.directoryId = this.directoryId;
                    }
                    this.loadAll();
                    this.selection.clear();
                }
            }
        }

        console.warn('on ngOnChanges end', this.directoryId);
    }

    sortPrefix(item: DirectoryDocumentRowDto) {
        let ret;
        if (item.type === 'directory') {
            if (item.id == this.directoryId) {
                ret = 'A';
            } else if (item.type === 'directory') {
                ret = 'B';
            }
        } else {
            ret = 'C';
        }

        return ret;
    }

    getDownloadObjectFromSelection(): DownloadObjectDto[] {
        const ids: DownloadObjectDto[] = [];
        this.selection.selected.forEach((value) => {
            const obj = new DownloadObjectDto(value.id, (value.type === 'directory') ? 'directory' : 'document', (value.id === this.directoryId), value.name);
            ids.push(obj);
        });
        return (ids.length > 0) ? ids : null;
    }

    getItemssFromSelection(): SelectedItem[] {
        const ids: SelectedItem[] = [];
        this.selection.selected.forEach((value) => {
            ids.push(new SelectedItem(value.id
                , (value.type === 'directory') ? 'directory' : 'document'
                , (this.nodeType === 'QA') ? true : false));
        });
        return (ids.length > 0) ? ids : null;
    }

    loadAll() {
        if (!this.directoryId) {
            console.error('Trying to load directory structure without directorId set');
            return; // do nothing
        }

        this.spinner.show();
        this.documentSearchClear();

        if (this.previousVersionMode) {
            this.documentService.getRowsDocumentVersionsList(this.documentMainVersionId).subscribe(
                (res: HttpResponse<DocumentRowVdr[]>) => {
                    // this.dataSource.data = res.body;
                    this.dataSource.data = this.prepareData(res.body);
                    this.spinner.hide();
                },
                (res: HttpErrorResponse) => this.onError(res.message)
            );

            return;
        }

        const documentsObservable = this.documentService.getRowsWithCriteria(this.documentCriteriaDto).toPromise();
        const directoriesObservable = this.directoryService.find(this.directoryId).toPromise();
        const directoriesByParentObservable = this.directoryService.directoriesByParent(this.directoryId).toPromise();
        const userDocumentPermissionObservable = this.directoryDocumentPermissionService.userDocumentPermission(this._directoryId, true).toPromise();

        Promise.all([documentsObservable, directoriesObservable, directoriesByParentObservable, userDocumentPermissionObservable]).then((value) => {
            const documents: HttpResponse<DocumentRowVdr[]> = value[0];
            const directories = value[1];
            const directoriesByParent: DirectoryVdr[] = value[2];
            const userDocumentPermission = value[3];

            const data: DirectoryDocumentRowDto[] = [];


            if (this.directoryId && this.nodeType !== 'QA') {
                if (directoriesByParent.length > 0) {
                    directoriesByParent.forEach((subdir) => {
                        data.unshift(new DirectoryDocumentRowDto(null, subdir));
                    });
                }

                if (this.directoryId !== -1) {
                    data.unshift(new DirectoryDocumentRowDto(null, directories.body));

                    this.directoryPermission = userDocumentPermission;
                    const uploadPermissionType = DocumentPermissionType[DocumentPermissionType.UPLOAD];
                    this.canModifyCurrentDirectory = this.directoryPermission.permissionType === uploadPermissionType;
                }
            }
            data.push(...this.prepareData(documents.body));

            this.dataSource.data = data;
            this.spinner.hide();
        }).catch((reason) => this.onError(reason));
    }

    prepareData(data: DocumentRowVdr[]): DirectoryDocumentRowDto[] {
        const result: DirectoryDocumentRowDto[] = [];
        data.forEach((value) => {
            result.push(new DirectoryDocumentRowDto(value));
        });
        return result;
    }

    favouriteOn(id) {
        this.documentService.favouriteOn(id)
            .subscribe(() => {
                for (const doc of this.dataSource.data) {
                    if (doc.id === id) {
                        doc.favourite = true;
                        break;
                    }
                }
            });

    }

    favouriteOff(id) {
        this.documentService.favouriteOff(id)
            .subscribe(() => {
                for (const doc of this.dataSource.data) {
                    if (doc.id === id) {
                        doc.favourite = false;
                        break;
                    }
                }
            });
    }

    private onError(error) {
        this.spinner.hide();
        this.jhiAlertService.error(error.message, null, null);
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(UploadDialogComponent, {
            width: '580px',
            data: {directoryId: this.directoryId},
            disableClose: true,
            hasBackdrop: true
        });

        dialogRef.afterClosed().subscribe(() => {
            this.selection.clear();
            this.synchronizeList();
            this.directoryAdded.emit(new DirectoryChange(this.directoryId, new Date().getTime()));
        });
    }

    openUploadDocumentVersion(): void {
        const documentId = this.selection.selected[0].id;
        const dialogRef = this.dialog.open(UploadDialogComponent, {
            width: '580px',
            data: {
                directoryId: this.directoryId,
                documentId,
                disableClose: true,
                hasBackdrop: true
            }
        });

        dialogRef.afterClosed().subscribe(() => {
            this.loadAll();
            this.directoryAdded.emit(new DirectoryChange(new Date().getTime(), new Date().getTime()));
            this.selection.clear();
        });
    }

    createDirectoryDialog(): void {
        const dialogRef = this.dialog.open(DirectoryCreateDialog, {
            width: '480px',
            data: {directoryId: this.directoryId}
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.loadAll();
                this.directoryAdded.emit(new DirectoryChange(this.directoryId, new Date().getTime()));
            }
        });
    }

    changeNameDialog(): void {
        const selectedId = this.selection.selected[0].id;
        const currentFilename = this.selection.selected[0].name;
        const elementType = this.selection.selected[0].type;
        const dialogRef = this.dialog.open(ChangeNameDialog, {
            width: '480px',
            data: {id: selectedId, type: elementType, currentFilename}
        });

        dialogRef.afterClosed().subscribe(() => {
            if (dialogRef.componentInstance.result === true) {
                this.selection.selected[0].name = dialogRef.componentInstance.newName;
                this.onSelection(this.selection.selected[0]);
            }
            // this.directoryAdded.emit(new DirectoryChange(this.directoryId, new Date().getTime()));
        });
    }

    copyCutAction(copy: boolean) {
        this.copyCutSelected = this.getDownloadObjectFromSelection();
        const type = this.getItemssFromSelection()[0].type;
        if (this.copyCutSelected.length > 0) {
            this.copy = copy;
            this.copyCutInProgress = true;
            this.originDirectoryId = this.directoryId;
            this.copyCutSelectionType = type;
        }

    }

    isCurrentDirectoryOrigin(): boolean {
        if (this.originDirectoryId) {
            return this.originDirectoryId === this.directoryId;
        }
        return false;
    }

    finalizeCopyCut() {
        if (!this.isCurrentDirectoryOrigin()) {
            this.spinner.show();
            this.directoryDocumentService.copyCutDocuments(this.copyCutSelected, this.copy, this.directoryId)
                .subscribe(() => {
                    this.copyCutInProgress = false;
                    this.copyCutSelected = [];
                    this.originDirectoryId = null;
                    this.copyCutSelectionType = null;
                    this.selection.clear();
                    this.loadAll();
                    this.directoryAdded.emit(new DirectoryChange(this.directoryId, new Date().getTime()));
                    this.spinner.hide();
                }, (error1) => {
                    this.spinner.hide();
                    this.onError(error1);
                });
        }
    }

    clearCopyCut() {
        this.copyCutInProgress = false;
        this.copyCutSelected = [];
        this.originDirectoryId = null;
        this.copyCutSelectionType = null;
    }

    downloadFiles(downloadType: string) {
        const downloadObjectFromSelection = this.getDownloadObjectFromSelection();
        const refreshSubscription = this.principal.refresh();
        const containsDocuments = downloadObjectFromSelection.filter((o) => o.fileType === 'document').length > 0;

        this.spinner.show();
        this.jhiAlertService.info('document.download.preparing', null, null);
        const filesToDownload = this.getDownloadObjectFromSelection();

        if (!filesToDownload) {
            console.error('cannot download files, selection is empty');
            return;
        }

        console.log('Files to download', filesToDownload);

        if (filesToDownload.length > 1 || filesToDownload[0].fileType === 'directory') {
            console.log('Downloading multiple files as zip');
            const ids = filesToDownload.map((v) => v.id);
            this.requestFilesAsZip(ids, downloadType)
                .then((result) => refreshSubscription.unsubscribe())
                .catch((reason) => {
                    console.error('Unable to download zip', reason);
                    refreshSubscription.unsubscribe();
                });

            return;
        }

        if (filesToDownload.length === 1) {
            this.uploadDownloadService.downloadZip(filesToDownload, downloadType).subscribe((res) => {
                console.log('file download');
                const fileName = getFileNameFromResponseContentDispositionBase64(res);
                console.log('name after decode:' + fileName);
                saveFile(res.body, fileName);
                this.spinner.hide();
                this.handlePdfPassword(res, filesToDownload);
                refreshSubscription.unsubscribe();
            }, (error1) => {
                refreshSubscription.unsubscribe();
                this.spinner.hide();
                console.log('error:' + error1);
                this.onError(error1);
            });
        }
    }

    downloadFile(id) {
        this.jhiAlertService.success('document.download.preparing', null, null);
        const downloadObjectFromSelection: DownloadObjectDto[] = [];
        const obj = new DownloadObjectDto(id, 'document', false);
        downloadObjectFromSelection.push(obj);
        const refreshSubscription = this.principal.refresh();

        this.spinner.show();
        this.uploadDownloadService.downloadZip(downloadObjectFromSelection, 'ORIGINAL').subscribe((res) => {
            const fileName = getFileNameFromResponseContentDispositionBase64(res);
            saveFile(res.body, fileName);
            this.spinner.hide();
            refreshSubscription.unsubscribe();
            this.handlePdfPassword(res, downloadObjectFromSelection);
        }, (error1) => {
            refreshSubscription.unsubscribe();
            this.onError(error1);
        });
    }

    downloadFileError() {
        this.jhiAlertService.warning('document.download-file-error', null, null);
    }

    private handlePdfPassword(res, downloadObjectFromSelection) {
        if (res.headers.keys().includes('x-pdf-protection')) {
            this.dialog.open(PdfPasswordProtectionDialog, {
                width: '350px',
                data: {
                    pdfPassword: res.headers.get('x-pdf-protection'),
                    archiveMode: downloadObjectFromSelection.length > 1
                }
            });
        }
    }

    sameTypeSelected(): boolean {
        const selected = this.getItemssFromSelection();
        if (selected.length > 1) {
            const type = selected[0].type;
            return selected.filter((value) => value.type === type).length === selected.length;
        }
        return true;
    }

    allSelectedPDFSCanBeDownloaded(): boolean {
        return this.selection.selected
            .filter((row) => row.type !== 'directory')
            .every((row) => row.effectivePermissions.includes('DOWNLOAD_PDF'));
    }

    allSelectedPDFSCanBeDownloadedEncrypted(): boolean {
        return this.selection.selected
            .filter((row) => row.type !== 'directory')
            .every((row) => row.effectivePermissions.includes('DOWNLOAD_ENCRYPTED'));
    }

    allSelectedOriginalsCanBeDownloaded(): boolean {
        return this.selection.selected
            .filter((row) => row.type !== 'directory')
            .every((row) => row.effectivePermissions.includes('DOWNLOAD_ORIGINAL'));
    }

    allSelectedIsDirectory(): boolean {
        return this.selection.selected
            .every((row) => row.type === 'directory');
    }

    getColor(row) {
        return (row.id === this.directoryId) ? 'var(--prime-color)' : 'black';
    }

    getFolderMargin(row) {
        return (row.id === this.directoryId) ? '-25px' : '0';
    }

    getPermissionIconColor(effectivePermissions, iconPermission) {
        let color = '#d9d9d9';
        if (effectivePermissions.includes(iconPermission)) {
            color = '#000000';
        }
        return color;
    }

    deleteFiles() {
        this.confirmationDialogService.confirm(this.confirmationSettings).subscribe((res) => {
            if (!res) {
                return;
            }

            this.spinner.show();
            const selectedFiles: DownloadObjectDto[] = this.getDownloadObjectFromSelection();
            this.uploadDownloadService.deleteFiles(selectedFiles).subscribe(() => {
                this.loadAll();
                const dir = selectedFiles.filter((value) => {
                    return value.fileType === 'directory';
                }).length > 0;
                if (dir) {
                    this.directoryAdded.emit(new DirectoryChange(this.directoryId, new Date().getTime()));
                }
            });
        });
    }

    pdfViewer(id, documents) {
        if (!this.isViewerOpened) {
            this.spinner.show();
            this.isViewerOpened = true;
            const dialogRef = this.dialog.open(PdfDocumentViewerComponent, {
                width: '100%',
                height: '100%',
                minHeight: '100%',
                maxWidth: '100vw',
                panelClass: 'pdf-viewer-dialog',
                autoFocus: false,
                data: {documentId: id, documents}
            });
            dialogRef.afterClosed().subscribe(() => {
                this.isViewerOpened = false;
            });
            this.spinner.hide();
        }
    }

    getIcon(type: string): string {
        // return 'fa ' + getIconForExtension(type) + '-o';
        return 'fa ' + getIconForExtension(type);
    }

    showDocumentVersionOn() {
        this.documentMainVersionId = this.selection.selected[0].id;
        this.previousVersionMode = true;
        this.displayedColumns = ['select', 'favourite', 'version', 'type', 'name', 'newDocument', 'createdDate'];
        this.selection.clear();
        this.loadAll();
    }

    showDocumentVersionOff() {
        this.previousVersionMode = false;
        if (this.ddvSettings.indexVisible) {
            this.displayedColumns = ['select', 'favourite', 'number', 'type', 'name', 'newDocument', 'createdDate'];
        } else {
            this.displayedColumns = ['select', 'favourite', 'type', 'name', 'newDocument', 'createdDate'];
        }
        this.selection.clear();
        this.loadAll();
    }

    folderNavigation(newDirectoryId: number, currentDirectoryId: number, index: number, parentDirectoryId: number) {
        if (parentDirectoryId === null) {
            return;
        }

        if (newDirectoryId !== currentDirectoryId) {
            this.directoryId = newDirectoryId;
            if (this.indexCumulate !== '') {
                this.indexCumulate = this.indexCumulate + '.';
            }
            this.indexCumulate = this.indexCumulate + index;
            this.selection.clear();
            this.loadAll();
            this.directorySelected.emit(newDirectoryId);

            return;
        }

        const lastDotIndex = this.indexCumulate.lastIndexOf('.');
        if (lastDotIndex > -1) {
            this.indexCumulate = this.indexCumulate.substring(0, lastDotIndex);
        }

        this.directoryId = parentDirectoryId;
        this.selection.clear();
        this.loadAll();
        this.directorySelected.emit(parentDirectoryId);
    }

    filterOpen() {
        this.documentCriteriaDtoTmp = Object.assign(new DocumentCriteriaDto(), this.documentCriteriaDto);
    }

    isDirty() {
        return this.documentCriteriaDto.createdFrom || this.documentCriteriaDto.createdTo || (this.documentCriteriaDto.documentTypes && this.documentCriteriaDto.documentTypes.length) || this.documentCriteriaDto.favourite || this.documentCriteriaDto.read !== null || this.documentSearchComponent.phrase;
    }

    search() {
        let changed = false;
        if (this.documentCriteriaDtoTmp != null) {
            this.documentCriteriaDto = Object.assign(new DocumentCriteriaDto(), this.documentCriteriaDtoTmp);
            if (this.isDirty()) {
                changed = true;
            }

            this.documentCriteriaDtoTmp = null;
        }
        if (changed) {
            this.documentSearchComponent.documentCriteriaDto = this.documentCriteriaDto;
            this.documentSearchComponent.search();
        } else {
            this.documentSearchComponent.hasSearched = false;
            this.loadAll();
        }
    }

    favouriteChange() {
        this.documentCriteriaDto.favourite = !this.documentCriteriaDto.favourite;
        this.loadAll();
    }

    getDocumentTypesFilerText(): string {
        if (this.documentCriteriaDto.documentTypes == null || this.documentCriteriaDto.documentTypes.length < 1) {
            return '';
        }

        const ret = [];
        for (let i = 0; i < this.documentCriteriaDto.documentTypes.length; i++) {
            ret.push(DocumentType[this.documentCriteriaDto.documentTypes[i]]);
        }

        return ret.join(', ');
    }

    @Input()
    set directoryId(directoryId: number) {
        this._directoryId = directoryId;
        this.documentCriteriaDto.directoryId = directoryId;
        this.directoryService.setLastVisited(directoryId);
    }

    get directoryId(): number {
        return this._directoryId;
    }

    chipsRemoveRead() {
        this.documentCriteriaDto.read = null;
        this.reloadAll();
    }

    chipsRemoveCreatedFrom() {
        this.documentCriteriaDto.createdFrom = null;
        this.reloadAll();
    }

    chipsRemoveCreatedTo() {
        this.documentCriteriaDto.createdTo = null;
        this.reloadAll();
    }

    chipsRemoveDocumentTypes() {
        this.documentCriteriaDto.documentTypes = null;
        this.reloadAll();
    }

    chipsRemoveAll() {
        const favouriteTmp = this.documentCriteriaDto.favourite;
        this.initDocumentCriteria();
        this.documentCriteriaDto.favourite = favouriteTmp;
        this.documentCriteriaDto.directoryId = this._directoryId;
        this.reloadAll();
    }

    hasFilters() {
        return this.documentCriteriaDto != null
            && (this.documentCriteriaDto.read != null
                || this.documentCriteriaDto.readFrom != null
                || this.documentCriteriaDto.readTo != null
                || this.documentCriteriaDto.createdFrom != null
                || this.documentCriteriaDto.createdTo != null
                || (this.documentCriteriaDto.documentTypes != null && this.documentCriteriaDto.documentTypes.length > 0));
    }

    documentSearch() {
        if (this.documentSearchComponent.phrase) {
            this.documentSearchComponent.search();
        }
    }

    documentSearchClear() {
        this.documentSearchComponent.clear();
    }

    dropped({dropData}: DropEvent<any>, row: DirectoryDocumentRowDto) {
        if (row.type === 'directory' && dropData.parentDirectoryId !== row.id) { // && dropData.type !== 'directory'
            const obj = new DownloadObjectDto(dropData.id, (dropData.type === 'directory') ? 'directory' : 'document', (dropData.id === row.id));
            this.spinner.show();
            this.directoryDocumentService.copyCutDocuments([obj], false, row.id)
                .subscribe(() => {
                    this.loadAll();
                    if (dropData.type === 'directory') {
                        this.directoryAdded.emit(new DirectoryChange(new Date().getTime(), new Date().getTime()));
                    }
                    this.spinner.hide();
                }, () => {
                    this.spinner.hide();
                });
        }
    }

    checkDownloadType(object: DirectoryDocumentRowDto, downloadType: string): boolean {
        console.log('checkDownloadType ' + JSON.stringify(object));
        if (object.type !== 'directory') {
            console.log('effectivePermissions ' + object.effectivePermissions + ' downloadtye ' + downloadType);
            return object.effectivePermissions.includes(downloadType);
        } else {
            return true;
        }
    }

    download(downloadType: string, object: DirectoryDocumentRowDto) {
        this.spinner.show();
        const refreshSubscription = this.principal.refresh();

        if (object.type === 'directory') {
            console.log('Downloading directories as zip');
            this.requestFilesAsZip([object.id], downloadType).then((result) => refreshSubscription.unsubscribe())
                .catch((reason) => {
                    console.error('Unable to download zip', reason);
                    refreshSubscription.unsubscribe();
                });
            return;
        }
        const obj = new DownloadObjectDto(object.id, (object.type === 'directory') ? 'directory' : 'document', (object.id === this.directoryId));
        this.jhiAlertService.info('document.download.preparing', null, null);

        // hotfix: if downloading single directory use archive creation as it's working waaay better
        if (obj.fileType === 'directory') {
            this.downloadZipViaArchive([object]);
            refreshSubscription.unsubscribe();
            return;
        }

        this.uploadDownloadService.downloadZip([obj], downloadType).subscribe((res) => {
            const fileName = getFileNameFromResponseContentDispositionBase64(res);
            saveFile(res.body, fileName);
            this.spinner.hide();
            this.handlePdfPassword(res, obj);
            refreshSubscription.unsubscribe();
        }, (error1) => {
            this.onError(error1);
            refreshSubscription.unsubscribe();
        });
    }

    requestFilesAsZip(ids: number[], downloadType: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.uploadDownloadService.requestFilesAsZip(ids, DownloadType[downloadType as keyof typeof DownloadType])
                .subscribe((r) => {
                    this.spinner.hide();
                    this.dialog.open(RequestZipStatusDialog, {
                        width: '450px',
                        disableClose: true,
                        hasBackdrop: true,
                        data: r
                    }).afterClosed().subscribe((value) => resolve(value));
                }, (e) => {
                    console.error('Error while requesting zip', e);
                    this.jhiAlertService.error('Nie udao się stworzyć archiwum z zaznaczenia');
                    this.spinner.hide();
                    reject();
                });
        });

    }

    private downloadZipViaArchive(directoriesToDownload: DirectoryDocumentRowDto[]) {
        let fileName = directoriesToDownload[0].name;
        if (!fileName || directoriesToDownload.length > 1) {
            fileName = this.principal.getCurrentProject().name;
        }

        const reportArchive = new ReportArchive();
        reportArchive.selectedOptions = [ReportArchiveOption.DOCUMENTS];
        reportArchive.directories = directoriesToDownload.map((d) => {
            const directoryVdr = new DirectoryVdr();
            directoryVdr.id = d.id;
            return directoryVdr;
        });

        this.reportService.createAndWaitForReportArchive(reportArchive).subscribe((response) => {
            saveFile(response.body, fileName + '.zip');
            this.spinner.hide();
        }, (e) => {
            console.error(e);
            this.spinner.hide();
            this.jhiAlertService.warning('document.download-file-error', null, null);
        });
    }

    createArchive(): void {
        this.spinner.show();
        this.getItemssFromSelection().forEach((item) => console.log('select ' + JSON.stringify(item)));
        const reportArchive = new ReportArchive();
        reportArchive.selectedOptions = [ReportArchiveOption.DOCUMENTS];
        reportArchive.directories = [];
        this.getItemssFromSelection().forEach((item) => {
            const d = new DocumentVdr();
            d.id = item.id;
            reportArchive.directories.push(d);
        });

        this.reportService.addReportArchiveWithDownload(reportArchive).subscribe(() => this.spinner.hide());
    }

    delete(object: DirectoryDocumentRowDto) {
        this.confirmationDialogService.confirm(this.confirmationSettings).subscribe((res) => {
            if (res) {
                const obj = new DownloadObjectDto(object.id, (object.type === 'directory') ? 'directory' : 'document', (object.id === this.directoryId));
                this.uploadDownloadService.deleteFiles([obj]).subscribe(() => {
                    this.loadAll();
                    if (object.type === 'directory') {
                        this.directoryAdded.emit(new DirectoryChange(this.directoryId, new Date().getTime()));
                    }
                });
            }
        });
    }

    synchronizeList() {
        this.loadAll();
    }

    reloadAll() {
        if (this.isDirty()) {
            this.documentSearchComponent.documentCriteriaDto = this.documentCriteriaDto;
            this.documentSearchComponent.search();
        } else {
            this.loadAll();
        }
    }

    exportFileReport() {
        const reportDto = new ReportUploadedCriteriaDto();
        reportDto.dateFrom = moment().utc();
        reportDto.dateFrom = reportDto.dateFrom.year(reportDto.dateFrom.get('year') - 30);
        reportDto.dateTo = moment().utc();
        reportDto.deleted = false;
        this.reportService.exportUploaded(reportDto);
    }

    canDeleteFile() {
        if (!this.canModifyCurrentDirectory) {
            this.canDeleteFilesFromMenu = false;
        }

        if (this.nodeType === 'QA') {
            this.canDeleteFilesFromMenu = false;
        }
        const directoryDocumentRowDtos = this.selection.selected;
        this.canDeleteFilesFromMenu = directoryDocumentRowDtos.length > 0 && directoryDocumentRowDtos
            .every((row) => !(row.type === 'directory' && row.parentDirectoryId === null));
    }

    reindexNumber(): void {
        this.directoryService.reindexNumber(this.directoryId).subscribe(() => {
            this.loadAll();
            this.directoryAdded.emit(new DirectoryChange(this.directoryId, new Date().getTime()));
        });
    }

}

export class MatTableDataSourceWithCustomSort<T> extends MatTableDataSource<T> {

    _compareFn = new Intl.Collator('pl', {sensitivity: 'base', numeric: true}).compare;

    sortData: ((data: T[], sort: MatSort) => T[]) = (data: T[], sort: MatSort): T[] => {
        const active = sort.active;
        const direction = sort.direction;
        if (!active || direction == '') {
            return data;
        }

        return data.sort((a, b) => {
            const valueA: any = this.sortingDataAccessor(a, active);
            const valueB: any = this.sortingDataAccessor(b, active);

            const comparatorResult = this._compareFn(<string>valueA, <string>valueB);
            console.log('valueA:' + valueA + ' valueB:' + valueB + ' ret:' + comparatorResult);
            let ret = comparatorResult * ((direction == 'asc') ? 1 : -1);

            if (valueA.startsWith('A')) {
                ret = -1;
            } else if (valueB.startsWith('A')) {
                ret = 1;
            }

            console.log('valueA:' + valueA + ' valueB:' + valueB + ' ret:' + ret);

            return ret;
        });
    };
}
