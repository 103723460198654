<div fxLayout="row">
    <span mat-dialog-title fxFlex="90%" jhiTranslate="changeNameAction.title">Change name</span>
    <button mat-icon-button fxFlex (click)="onCancelClick()">
        <mat-icon>clear</mat-icon>
    </button>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content fxLayout="column" fxLayoutAlign="center stretch" style="min-height: 117px">
    <jhi-alert-error></jhi-alert-error>
    <div fxLayout="column" fxLayoutAlign="center">
        <label for="directory_name" class="label-style" jhiTranslate="changeNameAction.newName">NEW NAME</label>
        <input #directoryNameInput cdkFocusInitial id="directory_name" [disabled]="disabledSubmit" name="directory" type="text" [(ngModel)] = "newName"
               class="input-style" required minlength="3" maxlength="100">
    </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button (click)="onCancelClick()" [disabled]="disabledSubmit">
        <mat-icon>clear</mat-icon>
        <span jhiTranslate="changeNameAction.cancel">Cancel</span>
    </button>
    <button mat-raised-button color="warn" (click)="onConfirmClick()" [disabled]="(!newName || newName.length < minLength) || disabledSubmit">
        <mat-icon style="font-size: 22px">check</mat-icon>
        <span jhiTranslate="changeNameAction.ok">Ok</span>
    </button>
</div>
