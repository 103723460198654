<div>
    <div class="row justify-content-center">
        <div class="col-md-8">
            <h1 jhiTranslate="info.title">User Info</h1>
            <div class="alert alert-success" *ngIf="success" jhiTranslate="info.messages.success">
                <strong>Personal information has been successfully updated.</strong>
            </div>

            <div class="alert alert-danger" *ngIf="error" jhiTranslate="info.messages.error.fail">
                <strong>Failed to update personal information.</strong>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-8">
            <form name="form" role="form" (ngSubmit)="save()" #registerForm="ngForm" *ngIf="!success">
                <div class="form-group">
                    <label class="form-control-label" for="email" jhiTranslate="global.form.email">E-MAIL</label>
                    <input type="text" class="form-control" [(ngModel)]="account.email" id="email" name="email"
                           #emailInput="ngModel" placeholder="{{'global.form.email.placeholder' | translate}}"
                           required minlength="4" maxlength="50" pattern="^[_'.@A-Za-z0-9-]*$">
                    <div *ngIf="emailInput.dirty && emailInput.invalid">
                        <small class="form-text text-danger" *ngIf="emailInput.errors.pattern"
                               jhiTranslate="info.messages.validate.email.pattern">
                            Incorrect E-MAIL
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="firstName" jhiTranslate="global.form.firstName">
                        first Name</label>
                    <input type="text" class="form-control" id="firstName" name="firstName"
                           #firstNameInput="ngModel"
                           placeholder="{{'global.form.firstName.placeholder' | translate}}"
                           [(ngModel)]="account.firstName" maxlength=50>
                    <div *ngIf="firstNameInput.dirty && firstNameInput.invalid">
                        <small class="form-text text-danger" *ngIf="firstNameInput.errors.maxlength"
                               jhiTranslate="global.messages.validate.firstName.maxlength">
                            Invalid first Name.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="lastName" jhiTranslate="global.form.lastName">
                        New lastName</label>
                    <input type="text" class="form-control" id="lastName" name="lastName"
                           #lastNameInput="ngModel"
                           placeholder="{{'global.form.lastName.placeholder' | translate}}"
                           [(ngModel)]="account.lastName" maxlength=50>
                    <div *ngIf="lastNameInput.dirty && lastNameInput.invalid">
                        <small class="form-text text-danger" *ngIf="lastNameInput.errors.maxlength"
                               jhiTranslate="global.messages.validate.lastName.maxlength">
                            Invalid last Name.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="company" jhiTranslate="global.form.company">
                        Company</label>
                    <input type="text" class="form-control" id="company" name="company"
                           #companyInput="ngModel"
                           placeholder="{{'global.form.company.placeholder' | translate}}"
                           [(ngModel)]="account.company" maxlength=100>
                    <div *ngIf="companyInput.dirty && companyInput.invalid">
                        <small class="form-text text-danger" *ngIf="companyInput.errors.maxlength"
                               jhiTranslate="global.messages.validate.company.maxlength">
                            Invalid company name
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="title" jhiTranslate="global.form.title">
                        Title</label>
                    <input type="text" class="form-control" id="title" name="title"
                           #titleInput="ngModel"
                           placeholder="{{'global.form.title.placeholder' | translate}}"
                           [(ngModel)]="account.title" maxlength=50>
                    <div *ngIf="titleInput.dirty && titleInput.invalid">
                        <small class="form-text text-danger" *ngIf="titleInput.errors.maxlength"
                               jhiTranslate="global.messages.validate.title.maxlength">
                            Invalid last Name.
                        </small>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-control-label" for="phoneNumber" jhiTranslate="global.form.phoneNumber">Phone
                        Number</label>
                    <input type="text" class="form-control" id="phoneNumber" name="phoneNumber"
                           #phoneNumberInput="ngModel"
                           placeholder="{{'global.form.phoneNumber.placeholder' | translate}}"
                           [(ngModel)]="account.phoneNumber" maxlength=12 minlength=4>
                    <div *ngIf="phoneNumberInput.dirty && phoneNumberInput.invalid">
                        <small class="form-text text-danger" *ngIf="phoneNumberInput.errors.maxlength"
                               jhiTranslate="global.messages.validate.phoneNumber.maxlength">
                            Your phone number cannot be longer than 12 characters.
                        </small>
                        <small class="form-text text-danger" *ngIf="phoneNumberInput.errors.minlength"
                               jhiTranslate="global.messages.validate.phoneNumber.minlength">
                            Your phone number is required to be at least 4 characters.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="language" jhiTranslate="global.form.language">Phone
                        Number</label>
                    <input type="text" class="form-control" id="language" name="language"
                           #languageInput="ngModel"
                           placeholder="{{'global.form.language.placeholder' | translate}}"
                           [(ngModel)]="account.language">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="timeZone" jhiTranslate="global.form.timeZone">Phone
                        Number</label>
                    <input type="text" class="form-control" id="timeZone" name="timeZone"
                           #timeZoneInput="ngModel"
                           placeholder="{{'global.form.timeZone.placeholder' | translate}}"
                           [(ngModel)]="account.timeZone">
                </div>
                <button type="submit" [disabled]="registerForm.form.invalid" class="btn btn-primary"
                        jhiTranslate="entity.action.save">Save
                </button>
            </form>
            <p></p>
        </div>
    </div>
</div>
