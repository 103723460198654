import { Route } from '@angular/router';

import { UserRouteAccessService } from '../../shared';
import { Project2Component } from './project2.component';

export const project2Route: Route = {
    path: 'project-management',
    component: Project2Component,
    data: {
        authorities: ['PROJECT_PROPERTIES'],
        pageTitle: 'global.menu.account.settings'
    },
    canActivate: [UserRouteAccessService]
};
