import './vendor.ts';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, ErrorHandler, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {JhiEventManager} from '@upside-cloud/ng-jhipster';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthExpiredInterceptor} from './blocks/interceptor/auth-expired.interceptor';
import {ErrorHandlerInterceptor} from './blocks/interceptor/errorhandler.interceptor';
import {NotificationInterceptor} from './blocks/interceptor/notification.interceptor';
import {AccountService, httpLoaderFactory, Principal, StateStorageService, UserRouteAccessService, VdrSharedModule} from './shared';
import {VdrAppRoutingModule} from './app-routing.module';
import {VdrHomeModule} from './home';
import {VdrAdminModule} from './admin/admin.module';
import {VdrAccountModule} from './account/account.module';
import {VdrReportModule} from './report/report.module';
import {VdrEntityModule} from './entities/entity.module';
import {PaginationConfig} from './blocks/config/uib-pagination.config';
import {ActiveMenuDirective, ErrorComponent, FooterComponent, HelpComponent, JhiMainComponent, NavbarComponent, PageRibbonComponent, ProfileService} from './layouts';
import {NgxSpinnerModule} from 'ngx-spinner';

import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {TimeZoneInterceptor} from './blocks/interceptor/timezone.interceptor';
import {BlockUIModule} from 'primeng/blockui';
import {BackendUnavailableInterceptor} from './blocks/interceptor/backend-unavailable.interceptor';
import {NoCachingInterceptor} from './blocks/interceptor/no-caching-interceptor';
import {CardModule} from 'primeng/card';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {SocketIoConfig, SocketIoModule} from 'ngx-socket-io';
import {SafePipeModule} from 'safe-pipe';
import {
    CompositePropagatorModule,
    OpenTelemetryConfig,
    OpenTelemetryInterceptorModule,
    OTEL_CUSTOM_SPAN,
    OTEL_INSTRUMENTATION_PLUGINS,
    OtelColExporterModule,
    OtelWebTracerModule
} from '@jufab/opentelemetry-angular-interceptor';
import {DiagLogLevel} from '@opentelemetry/api';
import {XMLHttpRequestInstrumentation} from '@opentelemetry/instrumentation-xml-http-request';
import {DocumentLoadInstrumentation} from '@opentelemetry/instrumentation-document-load';
import {UserInteractionInstrumentation} from '@opentelemetry/instrumentation-user-interaction';
import {LongTaskInstrumentation} from '@opentelemetry/instrumentation-long-task';
import {FetchInstrumentation} from '@opentelemetry/instrumentation-fetch';
import {UserSpan} from './shared/telemetry/user-span';
import {faroInitializer} from './faro-initializer';
import {GlobalErrorHandler} from '../global-error-handler';


// tslint:disable-next-line
export function socketIoConfigGenerator(): SocketIoConfig {
    return {url: ':7312', options: {autoConnect: false, withCredentials: true, transports: ['websocket'], reconnectionDelay: 100, reconnectionDelayMax: 500}};
}

const socketIoConfig: SocketIoConfig = socketIoConfigGenerator();

export function otelConfigGenerator(): OpenTelemetryConfig {
    return {
        commonConfig: {
            console: false, // Display trace on console (only in DEV env)
            production: false, // Send Trace with BatchSpanProcessor (true) or SimpleSpanProcessor (false)
            logBody: true,
            logLevel: DiagLogLevel.ALL,

            serviceName: 'VDR-Angular', // Service name send in trace
            probabilitySampler: '1',
        },
        batchSpanProcessorConfig: {
            scheduledDelayMillis: '60000',
            exportTimeoutMillis: '320000'
        },
        otelcolConfig: {
            url: 'https://secudo.solutions/otel/v1/traces', // URL of opentelemetry collector
        },
    };

}

const otelConfig = otelConfigGenerator();

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        VdrAppRoutingModule,
        NgxWebstorageModule.forRoot({prefix: 'jhi', separator: '-'}),
        VdrSharedModule,
        VdrHomeModule,
        VdrAdminModule,
        VdrReportModule,
        VdrAccountModule,
        VdrEntityModule,
        MatMomentDateModule,
        NgxSpinnerModule,
        BlockUIModule,
        CardModule,
        SafePipeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SocketIoModule.forRoot(socketIoConfig),
        OpenTelemetryInterceptorModule.forRoot(otelConfig),
        OtelWebTracerModule.forRoot(otelConfig),
        // // Insert OtelCol exporter module
        OtelColExporterModule,
        // // Insert propagator module
        CompositePropagatorModule,
    ],
    declarations: [
        JhiMainComponent,
        NavbarComponent,
        ErrorComponent,
        HelpComponent,
        PageRibbonComponent,
        ActiveMenuDirective,
        FooterComponent
    ],
    providers: [
        NgbActiveModal,
        ProfileService,
        PaginationConfig,
        UserRouteAccessService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthExpiredInterceptor,
            multi: true,
            deps: [
                StateStorageService,
                Injector
            ]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true,
            deps: [
                JhiEventManager
            ]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NotificationInterceptor,
            multi: true,
            deps: [
                Injector
            ]
        },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: {
                useUtc: true
            }
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TimeZoneInterceptor,
            multi: true,
            deps: [
                Injector
            ]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NoCachingInterceptor,
            multi: true,
            deps: [
                Injector
            ]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BackendUnavailableInterceptor,
            multi: true,
            deps: [
                JhiEventManager,
                AccountService
            ]
        },
        {
            provide: OTEL_INSTRUMENTATION_PLUGINS,
            useValue: [
                new XMLHttpRequestInstrumentation(),
                new DocumentLoadInstrumentation(),
                new UserInteractionInstrumentation(),
                new LongTaskInstrumentation(),
                new FetchInstrumentation({
                    ignoreNetworkEvents: false
                })]
        }, {provide: OTEL_CUSTOM_SPAN, useClass: UserSpan},
        {
            provide: APP_INITIALIZER,
            useFactory: faroInitializer,
            deps: [Principal], // <-- Add your dependencies here
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
    ],
    bootstrap: [JhiMainComponent]
})
export class VdrAppModule {
}

