<div>
    <div class="modal-header">
        <h4 class="modal-title" jhiTranslate="invitation.title">Invitation</h4>

        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                (click)="dismiss()">&times;
        </button>
    </div>
    <div class="modal-body">
        <jhi-alert-error></jhi-alert-error>
        <jhi-alert></jhi-alert>
        <div>
            <form name="form" role="form" (ngSubmit)="invitation()" novalidate #invitationForm="ngForm">
                <mat-tab-group>

                    <mat-tab label="{{'invitation.individual' | translate}}" class="custom-mat-tab ">
                        <div class="form-group mt-3">
                            <label class="form-control-label" for="emails"
                                   jhiTranslate="invitation.emails">Email</label>
                            <input type="emails" class="form-control" id="emails" name="emails" #email="ngModel"
                                   placeholder="{{'invitation.email-placeholder' | translate}}" [required]="true"
                                   [(ngModel)]="emails">

                            <div class="d-flex flex-column pt-2 pb-2 form-group">

                                <label>{{'invitation.group.label' | translate}}</label>
                                <ng-select [(ngModel)]="groupId" name="group" required>

                                    <ng-option [ngValue]="null">
                                        {{'invitation.group.placeholder' | translate}}
                                    </ng-option>
                                    <ng-option *ngFor="let group of groups" [value]="group.id">
                                        {{group.name}}
                                    </ng-option>
                                </ng-select>


                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="dismiss()">
                                        <span class="fa fa-ban"></span>&nbsp;<span jhiTranslate="entity.action.cancel">Cancel</span>
                                    </button>
                                    <button type="submit" [disabled]="invitationForm.form.invalid"
                                            class="btn btn-primary float-right">
                                        <span class="fa fa-save"></span>&nbsp;<span jhiTranslate="invitation.form.button">Invitation</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </mat-tab>
                    <mat-tab label="{{'invitation.mass' | translate}}" class="custom-mat-tab">
                        <div fxLayout="column" style="width: 100%">
                            <div fxLayout="row">
                                <span class="step-number" fxFlex><label style="max-width: 60px">{{'invitation.step1' | translate}}</label></span>
                                <div fxLayout="row" fxLayoutAlign="end center" style="width: 100%; padding: 1rem" fxFlex="80">
                                    <button class="btn btn-primary" (click)="downloadInvitationTemplate()">
                                        <span class="fa fa-dowload"></span> {{'invitation.download.template' | translate}}
                                    </button>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                            <div fxLayout="row">
                                <span class="step-number"><label style="max-width: 60px">{{'invitation.step2' | translate}}</label></span>
                                <div fxLayout="row" fxLayoutAlign="end center" style="width: 100%; padding: 1rem">
                                    <div fxLayout="column" fxLayoutAlign="center end">
                                        <button (click)="file.click()" class="btn btn-primary"
                                                type="button"><span
                                            class="fa fa-dowload"></span>{{'invitation.upload' | translate}}
                                        </button>
                                        <input type="file" id="file" #file style="display: none"
                                               (change)="myUploader($event?.currentTarget)" accept=".csv"/>
                                        <small *ngIf="uploadedFile">{{uploadedFile.name}}</small>
                                    </div>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                            <div fxLayout="row">
                                <span class="step-number" fxFlex><label style="max-width: 60px" jhiTranslate="invitation.step3">Krok 3</label></span>
                                <div fxLayout="row" fxLayoutAlign="end center" style="padding: 1rem" fxFlex="50">
                                    <div fxLayout="column" style="width: 100%">
                                        <label style="max-width: 60px">{{'invitation.group.label' | translate}}</label>z
                                        <ng-select [(ngModel)]="groupId">
                                            <ng-option [value]="null">
                                                {{'invitation.group.placeholder' | translate}}
                                            </ng-option>
                                            <ng-option *ngFor="let group of groups" [value]="group.id">
                                                {{group.name}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                            <div fxLayout="row">
                                <span class="step-number"><label style="max-width: 60px" jhiTranslate="invitation.step4">Krok 4</label></span>
                                <div  class="modal-footer" fxLayoutAlign="end center" style="width: 100%">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="dismiss()">
                                        <span class="fa fa-ban"></span>&nbsp;<span jhiTranslate="entity.action.cancel">Cancel</span>
                                    </button>
                                    <button type="submit" [disabled]="invitationForm.form.invalid && !uploadedFile"
                                            class="btn btn-primary float-right">
                                        <span class="fa fa-save"></span>&nbsp;<span jhiTranslate="invitation.form.button">Invitation</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </form>
            <p></p>
        </div>
    </div>
</div>
