import {Component, OnDestroy, OnInit} from '@angular/core';
import {DashboardService} from './dashboard.service'
import {Dashboard} from './dashboard.model'
import {JhiEventManager, JhiAlertService}from '@upside-cloud/ng-jhipster';
import {moment} from 'fullcalendar';

@Component({
    templateUrl: './dashboard.component.html',
        selector: 'jhi-dashboard',
    styleUrls: ['./dashboard.scss']
})
export class DashboardComponent {

    dashboards: Dashboard[];

    constructor(private dashboardService: DashboardService, private alertService: JhiAlertService){
        this.loadAll();
    }

    cancel(dashboard: Dashboard) {
         this.loadAll();
    }

    save(dashboard: Dashboard) {
        this.dashboardService.save(dashboard).subscribe(()=> {
            this.alertService.info('global.messages.info.success-update');
            this.loadAll();
        });
    }

    createEmpty() {
        this.dashboardService.save(new Dashboard()).subscribe(()=> {
                    this.alertService.info('Nowy wpis został dodany');
                    this.loadAll();
                });
    }

    loadAll() {
        this.dashboardService.get().subscribe((dashboards: Dashboard[]) => {
               dashboards.forEach(d=> d.date = moment(d.date).add('hours', 5));
               this.dashboards = dashboards;
            });
        }

}
