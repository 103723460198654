import {Component, OnDestroy, OnInit} from '@angular/core';
import {JhiAlertService}from '@upside-cloud/ng-jhipster';
import {trigger, sequence, state, animate, transition, style} from '@angular/animations';

@Component({
    selector: 'jhi-alert',
    template: `
        <div class="alerts" role="alert">
            <div @items *ngFor="let alert of alerts" [ngClass]="{\'alert.position\': true, \'toast\': alert.toast}">
                <ngb-alert *ngIf="alert && alert.type && alert.msg" [type]="alert.type" (close)="alert.close(alerts)">
                    <div style="display: flex !important; max-height: 100%">
                        <!--
                                                <mat-icon style="color: green; line-height: 24px;">check_circle</mat-icon>
                        -->
                        <div>
                            <pre [innerHTML]="alert.msg" style="line-height: 24px;font-weight: bold"></pre>
                        </div>
                    </div>
                </ngb-alert>
            </div>
        </div>`,
    animations: [
        trigger('items', [
            transition(':enter', [
                style({transform: 'scale(0.3)'}),  // initial
                animate('250ms cubic-bezier(.8, -0.6, 0.2, 1.5)',
                    style({transform: 'scale(1)', opacity: 1,

}))  // final
            ])
        ])
    ]
})
export class JhiAlertComponent implements OnInit, OnDestroy {
    alerts: any[];

    constructor(private alertService: JhiAlertService) {
    }

    ngOnInit() {
        this.alerts = this.alertService.get();
    }

    ngOnDestroy() {
        this.alerts = [];
    }

}
