import {AfterViewInit, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DirectoryVdrService} from '../../directory-vdr';
import {JhiAlertService}from '@upside-cloud/ng-jhipster';
import {DocumentVdrService} from '../../document-vdr';
import {FocusMonitor} from '@angular/cdk/a11y';

export interface PermanentDeleteDialog {
    count: number;
}

@Component({
    selector: 'permanent-delete-dialog',
    templateUrl: 'permanent-delete-dialog.html',
    styleUrls: ['./../directory-document.scss']
})
export class PermanentDeleteDialog implements AfterViewInit {

    result: boolean;

    constructor(
        public dialogRef: MatDialogRef<PermanentDeleteDialog>,
        @Inject(MAT_DIALOG_DATA) public data: PermanentDeleteDialog,
        private _focusMonitor: FocusMonitor
    ) {
        this.result = false;
    }

    ngAfterViewInit(): void {
        this._focusMonitor.stopMonitoring(document.getElementById('clear'));
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onYesClick(): void {
        this.result = true;
        this.onNoClick();
    }
}
