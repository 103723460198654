import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponseBase} from '@angular/common/http';
import {interval, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, takeWhile} from 'rxjs/operators';
import {JhiEventManager} from '@upside-cloud/ng-jhipster';
import {AccountService} from '../../shared';

@Injectable()
export class BackendUnavailableInterceptor implements HttpInterceptor {
    public static readonly eventNameBackendUnavailable = 'backendUnavailable';
    public static readonly eventNameBackendAvailable = 'backendAvailable';
    private backendUnavailableOnLastRequest = false;

    constructor(private eventManger: JhiEventManager, private accountService: AccountService) {
    }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError((err: HttpErrorResponse) => {
            if (!this.isBackendUnavailable(err)) {
                console.log('Lost connection to backend');
                this.backendUnavailableOnLastRequest = false;
                return next.handle(req);
            }

            interval(1000)
                .pipe(takeWhile(() => this.backendUnavailableOnLastRequest))
                .subscribe(() => {
                    this.accountService.get().subscribe((value) => {
                        this.backendUnavailableOnLastRequest = this.isBackendUnavailable((<HttpResponseBase>value));
                        if (this.backendUnavailableOnLastRequest) {
                            console.log('Backend is available again');
                            this.eventManger.broadcast(BackendUnavailableInterceptor.eventNameBackendUnavailable);
                        }
                    });
                });

            return next.handle(req);
        }));
    }

    private isBackendUnavailable(err: HttpResponseBase) {
        return err && (err.status === 502 || err.status === 0);
    }
}
