import {getWebInstrumentations, initializeFaro} from '@grafana/faro-web-sdk';
import {Principal} from './shared';

export function faroInitializer(principal: Principal): Function {
    return async() => {
        initializeFaro({
            url: 'https://secudo.solutions/alloy/collect',
            apiKey: 'kylajmvvkrrfdqtajrzvtrug',
            app: {
                name: 'VDR-Angular',
                version: '1.0.0',
            },
            beforeSend: (item) => {
                item.meta.user = {
                    username: principal.getUserIdentity()?.login,
                    attributes: {
                        project: principal.getUserIdentity()?.project?.name
                    }
                };

                return item;
            },
            batching: {
                enabled: true,
                itemLimit: 25,
                sendTimeout: 60_000
            },
            instrumentations: [...getWebInstrumentations({captureConsole: true, captureConsoleDisabledLevels: []})],
        });
    };
}
