<div>
    <div class="row justify-content-center">
        <div class="col-md-4">
            <h1 jhiTranslate="reset.finish.title">Reset password</h1>

            <div class="alert alert-danger" jhiTranslate="reset.finish.messages.keymissing" *ngIf="keyMissing">
                <strong>The password reset key is missing.</strong>
            </div>

            <div class="alert alert-warning" *ngIf="!success && !keyMissing">
                <p jhiTranslate="reset.finish.messages.info">Choose a new password</p>
            </div>

            <jhi-alert-error></jhi-alert-error>

            <div class="alert alert-success" *ngIf="success">
                <span jhiTranslate="reset.finish.messages.success"><strong>Your password has been reset.</strong> Please </span>
                <a class="alert-link" (click)="login()" jhiTranslate="global.messages.info.authenticated.link">sign
                    in</a>.
                <div class="login-panel">
                    <jhi-login-modal></jhi-login-modal>
                </div>
            </div>


            <div *ngIf="!keyMissing">
                <form *ngIf="!success" name="form" role="form" (ngSubmit)="finishReset()" #passwordForm="ngForm">
                    <div class="form-group">
                        <div class="form-group">
                            <label class="form-control-label" for="password" jhiTranslate="global.form.newpassword">New
                                password</label>
                            <div class="input-group">
                                <input [type]="password1Show ? 'text' : 'password'" class="form-control" id="password"
                                       name="password" #password="ngModel"
                                       placeholder="{{'global.form.newpassword.placeholder' | translate}}"
                                       [(ngModel)]="resetAccount.password" minlength=8 maxlength=40
                                       pattern="^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,40}$"
                                       required #passwordInput="ngModel">

                                <div class="input-group-append">
                                    <span class="input-group-text">
                                      <i class="fa"
                                         [ngClass]="{'fa-eye-slash': !password1Show,'fa-eye': password1Show}"
                                         (click)="password1Show = !password1Show"
                                      ></i>
                                    </span>
                                </div>
                            </div>

                            <div *ngIf="passwordInput.dirty && passwordInput.invalid">
                                <small class="form-text text-danger"
                                       jhiTranslate="global.messages.validate.newpassword.pattern">
                                    Your password is required.
                                </small>
                            </div>
                            <jhi-password-strength-bar
                                [passwordToCheck]="resetAccount.password"></jhi-password-strength-bar>
                        </div>

                        <div class="form-group">
                            <label class="form-control-label" for="confirmPassword"
                                   jhiTranslate="global.form.confirmpassword">New password confirmation</label>

                            <div class="input-group">
                                <input [type]="password2Show ? 'text' : 'password'" class="form-control"
                                       id="confirmPassword"
                                       name="confirmPassword" #confirmPasswordInput="ngModel"
                                       placeholder="{{'global.form.confirmpassword.placeholder' | translate}}"
                                       [(ngModel)]="confirmPassword" minlength=8 maxlength=40
                                       required>

                                <div class="input-group-append">
                                    <span class="input-group-text">
                                      <i class="fa"
                                         [ngClass]="{'fa-eye-slash': !password2Show,'fa-eye': password2Show}"
                                         (click)="password2Show = !password2Show"
                                      ></i>
                                    </span>
                                </div>
                            </div>
                            <div *ngIf="confirmPasswordInput.dirty && confirmPasswordInput.value!=passwordInput.value">
                                <small class="form-text text-danger"
                                       jhiTranslate="global.messages.validate.confirmpassword.doNotMatch">
                                    Your password confirmation is required.
                                </small>

                            </div>
                        </div>
                        <button type="submit" [disabled]="passwordForm.form.invalid" class="btn btn-primary"
                                jhiTranslate="reset.finish.form.button">Reset Password
                        </button>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>
