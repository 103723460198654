/* after changing this file run 'yarn run webpack:build' */
/* tslint:disable */
import '../content/scss/vendor.scss';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/observable/of';
// import 'rxjs/add/operator/do';
// import 'rxjs/add/observable/throw';
// import 'rxjs/add/observable/interval';
// import 'rxjs/add/operator/take';

import 'quill/dist/quill.js';
import '../../../../node_modules/fullcalendar/dist/fullcalendar.js';
// import '../../../../node_modules/chart.js/dist/Chart.js';


// jhipster-needle-add-element-to-vendor - JHipster will add new menu items here
