import { Routes } from '@angular/router';

import { HelpComponent } from './help.component';

export const helpRoute: Routes = [
    {
        path: 'help',
        component: HelpComponent,
        data: {
            authorities: [],
            pageTitle: 'help.title'
        },
    },

];
