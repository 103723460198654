<div class="d-flex flex-row button-row" fxLayout="row" fxFlexAlign="start center">
    <div fxLayout="row" fxFlexAlign="start" fxFlex="100%">
        <button mat-raised-button style="min-width: 70px" [matMenuTriggerFor]="filterMenu" (click)="filterOpen()">
            <mat-icon>filter_list</mat-icon>
            <span jhiTranslate="document.filter.search">Filtr</span>
        </button>
        <button mat-raised-button style="min-width: 70px; margin-left: 0.5rem;" (click)="export()" color="warn"  matTooltip="{{'report.excel.button.tooltip' | translate}}">
            <mat-icon>get_app</mat-icon>
        </button>
        <mat-menu #filterMenu="matMenu" class="filter-menu" style="width: 300px">
            <div fxFlex fxLayout="column" fxLayoutAlign="center" *ngIf="reportUploadedCriteriaDtoTmp" (click)="$event.stopPropagation();">
                <div class="filter-item" fxLayout="column">
                    <mat-form-field>
                        <input
                            name="dateFrom"
                            matInput
                            [matDatepicker]="dateFromPicker"
                            [(ngModel)]="reportUploadedCriteriaDtoTmp.dateFrom"
                            placeholder="{{'report.date-from' | translate}}"
                            (click)="$event.stopPropagation();">
                        <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dateFromPicker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field>
                        <input
                            name="maxReport"
                            matInput
                            [matDatepicker]="dateToPicker"
                            [(ngModel)]="reportUploadedCriteriaDtoTmp.dateTo"
                            placeholder="{{'report.date-to' | translate}}"
                            (click)="$event.stopPropagation();">
                        <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dateToPicker></mat-datepicker>
                    </mat-form-field>
                    <div class="flex-d">
                        <mat-checkbox [(ngModel)]="reportUploadedCriteriaDtoTmp.maxReport"></mat-checkbox> {{'showSpecialReport' | translate}}
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="filter-item mt-2" fxLayout="column">
                    <div align="right">
                        <button
                            mat-raised-button
                            color="warn"
                            (click)="search()">
                            <span class="fa fa-search"></span>
                        </button>
                    </div>
                </div>
            </div>
        </mat-menu>
    </div>
</div>
<mat-divider></mat-divider>
<div fxLayout="row" class="d-flex flex-row button-row" *ngIf="hasFilters()">
    <mat-form-field #chipList fxFlex="98%">
        <mat-chip-list>
            <mat-chip *ngIf="reportUploadedCriteriaDto.dateFrom" (removed)="chipsRemoveDateFrom()">
                <span jhiTranslate="report.date-from">Date from</span>
                <span>&nbsp;{{reportUploadedCriteriaDto.dateFrom | date: 'yyyy-MM-dd'}}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip *ngIf="reportUploadedCriteriaDto.dateTo" (removed)="chipsRemoveDateTo()">
                <span jhiTranslate="report.date-to">Date to</span>
                <span>&nbsp;{{reportUploadedCriteriaDto.dateTo | date: 'yyyy-MM-dd'}}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip color="warn" (removed)="chipsRemoveAll()">
                <span jhiTranslate="report.clear-filters">Clear filters</span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </mat-form-field>
</div>
<div class="p-3 overview-content" >
    <div style="background-color: white;" fxLayout="column">
        <mat-table [dataSource]="dataSource" fxFlex="30%">
            <ng-container matColumnDef="data">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font"><b jhiTranslate="report.uploaded.data">Data</b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font"><span>{{element.name}}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="filtered">
                <mat-header-cell *matHeaderCellDef style="justify-content:center !important; color: black;"  class="header-font">
                    <b>{{reportUploadedCriteriaDto.dateFrom | date: 'yyyy-MM-dd'}} - {{reportUploadedCriteriaDto.dateTo | date: 'yyyy-MM-dd'}}</b>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" style="justify-content:center !important;" class="content-font"><span>{{element.filtered}}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="sum">
                <mat-header-cell *matHeaderCellDef style="justify-content:center !important; color: black;"  class="header-font">
                    <b jhiTranslate="report.uploaded.sum">Sum</b>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" style="justify-content:center !important;" class="content-font"><span>{{element.sum}}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="max">
                <mat-header-cell *matHeaderCellDef style="justify-content:center !important; color: black;"  class="header-font">
                    <b jhiTranslate="report.uploaded.max">Max</b>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" style="justify-content:center !important;" class="content-font"><span>{{element.max}}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="total">
                <mat-header-cell *matHeaderCellDef style="justify-content:center !important; color: black;"  class="header-font">
                    <b jhiTranslate="report.uploaded.total">Total</b>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" style="justify-content:center !important; color: black;" class="content-font"><span>{{element.total}}</span></mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="getDisplayColumns()"></mat-header-row>
            <mat-row *matRowDef="let row; columns: getDisplayColumns();"></mat-row>
        </mat-table>
    </div>

</div>
