import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {SERVER_API_URL} from '../../app.constants';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs/index';
import {DirectoryDocumentViewSettingsDto} from './dto/directory-document-view-settings-dto.model';
import {DownloadObjectDto} from './dto/download-object-dto.model';
import {DocumentSpecialRowDto} from './dto/document-special-row-dto.model';

export type EntityResponseType = HttpResponse<DirectoryDocumentViewSettingsDto>;

@Injectable()
export class DirectoryDocumentService {
    private resourceSettingsUrl = SERVER_API_URL + 'api/ddview/settings';
    private resourceCopyUrl = SERVER_API_URL + 'api/ddview/copy';
    private resourceMoveUrl = SERVER_API_URL + 'api/ddview/move';
    private resourceDeletedDocumentDirectoryRowUrl = SERVER_API_URL + 'api/ddview/deletedDocumentDirectoryRows';
    private resourceRestoreUrl = SERVER_API_URL + 'api/ddview/restore';
    private resourceDeletePermanentlyUrl = SERVER_API_URL + 'api/ddview/deletePermanently';

    constructor(private http: HttpClient) {
    }

    getSettings(): Observable<EntityResponseType> {
        return this.http.get<DirectoryDocumentViewSettingsDto>(`${this.resourceSettingsUrl}`, {observe: 'response'}).pipe(
            map((res: EntityResponseType) => this.convertResponse(res)));
    }

    copyCutDocuments(documents: DownloadObjectDto[], copy: boolean, directoryId: number): Observable<HttpResponse<void>> {
        const params = new HttpParams().set('directoryId', directoryId.toString());
        if (copy) {
            return this.http.post<HttpResponse<void>>(this.resourceCopyUrl, documents, {params});
        } else{
            return this.http.post<HttpResponse<void>>(this.resourceMoveUrl, documents, {params});
        }
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: DirectoryDocumentViewSettingsDto = this.convertItemFromServer(res.body);
        return res.clone({body});
    }

    private convertItemFromServer(settings: DirectoryDocumentViewSettingsDto): DirectoryDocumentViewSettingsDto {
        const copy: DirectoryDocumentViewSettingsDto = Object.assign({}, settings);
        return copy;
    }

    getRowsDeletedDocumentDirectoryList(): Observable<HttpResponse<DocumentSpecialRowDto[]>> {
        const apiURL = this.resourceDeletedDocumentDirectoryRowUrl;
        return this.http.get<DocumentSpecialRowDto[]>(apiURL, {params: null, observe: 'response'}).pipe(
            map((res: HttpResponse<DocumentSpecialRowDto[]>) => this.convertArrayResponse(res)));

    }

    private convertArrayResponse(res: HttpResponse<DocumentSpecialRowDto[]>): HttpResponse<DocumentSpecialRowDto[]> {
        const jsonResponse: DocumentSpecialRowDto[] = res.body;
        const body: DocumentSpecialRowDto[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer2(jsonResponse[i]));
        }
        return res.clone({body});
    }

    /**
     * Convert a returned JSON object to DocumentVdr.
     */
    private convertItemFromServer2(document: DocumentSpecialRowDto): DocumentSpecialRowDto {
        const copy: DocumentSpecialRowDto = Object.assign({}, document);
        return copy;
    }

    restore(rows: DocumentSpecialRowDto[]): Observable<Object> {
        return this.http.post(this.resourceRestoreUrl, rows);
    }

    deletePermanently(rows: DocumentSpecialRowDto[]): Observable<Object> {
        return this.http.post(this.resourceDeletePermanentlyUrl, rows);
    }
}
