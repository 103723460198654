<ng-container *ngFor="let userGroup of userGroups">
    <mat-checkbox (click)="$event.stopPropagation()"
                  (change)="selectGroup(userGroup)"
                  [checked]="isElementSelected(userGroup)"
                  class="d-block"
                  color="warn">
        {{userGroup.name}}
    </mat-checkbox>
</ng-container>

