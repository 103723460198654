import {DocumentPermissionType} from './document-permission-type.enum';

export class ChangePermissionRequest {
    constructor(
        public holderId: number,
        public permission: DocumentPermissionType,
        public groupId: number,
    ) {
    }
}
