import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'treeIndex'})
export class TreeIndexPipe implements PipeTransform {

    transform(name: string, index: string, indexVisible: boolean, toolTip: boolean): string {
        let result = name;
        const resultLength = 30;
        if (indexVisible){
            result = index + ' ' + name;
        }
        if (!toolTip && result.length > resultLength) {
            result = result.substr(0, (resultLength - '...'.length));
            result = result + '...';
        }
        return result;
    }
}
