import {Component, OnInit, OnDestroy, Inject} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JhiEventManager }from '@upside-cloud/ng-jhipster';

import { ProjectVdr } from './project-vdr.model';
import { ProjectVdrPopupService } from './project-vdr-popup.service';
import { ProjectVdrService } from './project-vdr.service';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'jhi-project-vdr-delete-dialog',
    templateUrl: './project-vdr-delete-dialog.component.html'
})
export class ProjectVdrDeleteDialogComponent {

    project: ProjectVdr;
    info: string;

    constructor(
        private projectService: ProjectVdrService,
        public activeModal: NgbActiveModal,
        private eventManager: JhiEventManager,
        private translateService: TranslateService,
        public dialogRef: MatDialogRef<ProjectVdrDeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ProjectVdr
    ) {
        this.info = this.translateService.instant('vdrApp.project.delete.question', {'id': this.data.name});
    }

    close() {
        this.dialogRef.close();
    }

    confirmDelete() {
        this.projectService.delete(this.data.id).subscribe((response) => {
            this.eventManager.broadcast({
                name: 'projectListModification',
                content: 'Deleted an project'
            });
            this.activeModal.dismiss(true);
            this.close();
        });
    }
}

// @Component({
//     selector: 'jhi-project-vdr-delete-popup',
//     template: ''
// })
// export class ProjectVdrDeletePopupComponent implements OnInit, OnDestroy {
//
//     routeSub: any;
//
//     constructor(
//         private route: ActivatedRoute,
//         private projectPopupService: ProjectVdrPopupService
//     ) {}
//
//     ngOnInit() {
//         this.routeSub = this.route.params.subscribe((params) => {
//             this.projectPopupService
//                 .open(ProjectVdrDeleteDialogComponent as Component, params['id']);
//         });
//     }
//
//     ngOnDestroy() {
//         this.routeSub.unsubscribe();
//     }
// }
