import {Group} from "../../shared";
import {Category} from "./category/category.model";

export class QuestionSearch {
    constructor(
        public priority?: null,
        public status?: null,
        public author?: string,
        public content?: string,
        public phrase?: string,
        public sort?: any,
        public direction?: string,
        public page?: number,
        public size: number = 5,
        public isCriteria = false,
        public groups?: Group[],
        public priorities?: string[],
        public categories?: Category[],
        public fromDay?: Date,
        public untilDay?: Date,
        public canceled?: boolean
    ) {
    }

    clear() {
        this.isCriteria = false;
        this.priority = null;
        this.status = null;
        this.author = null;
        this.content = null;
        this.phrase = null;
        this.sort = 'ASC';
        this.page = 0;
        this.size = 10;
        this.groups = [];
        this.priorities = [];
        this.categories = [];
        this.fromDay = null;
        this.untilDay = null;
        this.canceled = false;
    }
}
