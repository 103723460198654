<div fxLayout="column">
    <div>
        <div  fxLayout="row">
            <h4 class="p-2" jhiTranslate="permission.title" style="min-height: 60px; font-size: 20px !important; margin-left: 8px">Uprawnienia</h4>

            <div>
                <button mat-raised-button
                        style="min-width: 70px; margin-left: 0.5rem; margin-top: 5px"
                        (click)="exportEntitlementReport()"
                        color="warn"
                        *jhiHasAnyAuthority="['ADMIN', 'ADMIN_LIMITED']"
                        [disabled]="loading">
                    <mat-icon  *ngIf="!loading">get_app</mat-icon>
                    <ng-container *ngIf="loading">
                        <mat-icon><mat-spinner diameter="20"></mat-spinner></mat-icon>
                    </ng-container>
                </button>
            </div>
        </div>
        <mat-divider></mat-divider>
    </div>
    <jhi-alert></jhi-alert>
    <jhi-alert-error></jhi-alert-error>
    <div fxLayout="row">
        <directory-document-tree (selectedItem)="onSelectedItem($event)"></directory-document-tree>
        <mat-divider [vertical]="true"></mat-divider>
        <group-list fxFlex="55" (groupId)="onGroupIdChange($event)" [selectedObject]="selectedItem"></group-list>
        <mat-divider [vertical]="true"></mat-divider>
        <permission-detail fxFlex [selectedObject]="selectedItem" [selectedGroup]="groupObject" ></permission-detail>
    </div>
</div>
