<ng-container [ngTemplateOutlet]="customSecondaryToolbar ? customSecondaryToolbar : defaultSecondaryToolbar"> </ng-container>

<ng-template #defaultSecondaryToolbar>
  <div
    id="secondaryToolbar"
    class="secondaryToolbar hidden doorHangerRight"
    [style.top]="secondaryToolbarTop"
    [style.transform]="'scale(' + mobileFriendlyZoomScale + ')'"
    [style.transformOrigin]="'right top'"
  >
    <div id="secondaryToolbarButtonContainer">
      <button
        *ngFor="let button of pdfShyButtonService.buttons"
        type="button"
        [id]="button.id"
        [ngClass]="button.cssClass | invertForSecondaryToolbar"
        [class.toggled]="button.toggled"
        class="secondaryToolbarButton"
        [title]="button.title"
        [attr.data-l10n-id]="button.l10nId"
        (click)="onClick($event, button.action, button.eventBusName, button.closeOnClick)"
        [attr.aria-label]="button.title"
        role="button"
      >
        <span class="icon" role="img" aria-hidden="true" [attr.aria-label]="button.title" *ngIf="button.image" [innerHTML]="button.image"></span>
        <span class="toolbar-caption" [attr.data-l10n-id]="button.l10nLabel">{{ button.title }}</span>
      </button>
    </div>
  </div>
</ng-template>
