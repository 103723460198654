import {DirectoryVdr} from "../../entities/directory-vdr";

export class ReportArchive {
    id: number;
    additionBy: User;
    additionDate: Date;
    selectedOptions: ReportArchiveOption[];
    directories: DirectoryVdr[];
    fromDate: Date;
    untilDate: Date;
    reportArchiveState: string;
    lastReportArchiveStateDate: Date;
    downloading = false;
}

export class User {
    id: number;
    firstName: string;
    lastName: string;
}

export enum ReportArchiveOption {
    ENTITLEMENTS = 'ENTITLEMENTS',
    DOCUMENTS = 'DOCUMENTS',
    USERS = 'USERS',
    QA = 'QA',
    HISTORY = 'HISTORY',
    UPLOADED_DATA = 'UPLOADED_DATA',
    OVERVIEW = 'OVERVIEW'
}
