<div style="overflow-y:scroll;">
    <div class="modal-header">
        <h4 class="modal-title"><span jhiTranslate="report.archive.dialog.title">Choose data to download</span></h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                (click)="close()">&times;
        </button>
    </div>

    <div class="container">

        <div class="row">
            <div class="col-12 col-sm-6">
                <mat-form-field fxFlex>
                    <input
                        name="searchDateFrom"
                        matInput
                        [matDatepicker]="searchDateFromPicker"
                        [(ngModel)]="data.fromDate"
                        placeholder="{{'report.date-from' | translate}}"
                        (click)="$event.stopPropagation();">
                    <mat-datepicker-toggle matSuffix [for]="searchDateFromPicker"></mat-datepicker-toggle>
                    <mat-datepicker #searchDateFromPicker></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-12 col-sm-6">
                <mat-form-field fxFlex>
                    <input
                        name="searchDateTo"
                        matInput
                        [matDatepicker]="searchDateToPicker"
                        [(ngModel)]="data.untilDate"
                        placeholder="{{'report.date-to' | translate}}"
                        (click)="$event.stopPropagation();">
                    <mat-datepicker-toggle matSuffix [for]="searchDateToPicker"></mat-datepicker-toggle>
                    <mat-datepicker #searchDateToPicker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-6 mt-2">
                <mat-card fxFlex>
                    <mat-card-content>
                        <b><span jhiTranslate="report.archive.entitlements">Entitlements</span></b>
                        <span jhiTranslate="report.archive.entitlements.info">Entitlements info</span>
                    </mat-card-content>
                    <mat-card-actions>
                        <div fxFlex></div>
                        <div  class="slide-toggles">{{ (entitlements ? 'report.archive.your.choice' : 'report.archive.choose') | translate }}
                            <mat-slide-toggle
                                [checked]="entitlements === true" (change)="entitlements = !entitlements">
                            </mat-slide-toggle>
                        </div>
                    </mat-card-actions>
                </mat-card>
            </div>

            <div class="col-12 col-sm-6 mt-2">
                <mat-card fxFlex>
                    <mat-card-content>
                        <b><span jhiTranslate="report.archive.documents">Documents</span></b>
                        <span jhiTranslate="report.archive.documents.info">Documents info</span>
                    </mat-card-content>
                    <mat-card-actions>
                        <div fxFlex></div>
                        <div  class="slide-toggles">{{ (documents ? 'report.archive.your.choice' : 'report.archive.choose') | translate }}
                            <mat-slide-toggle
                                [checked]="documents === true" (change)="documents = !documents">
                            </mat-slide-toggle>
                        </div>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-6 mt-2">
                <mat-card fxFlex>
                    <mat-card-content>
                        <b><span jhiTranslate="report.archive.users">Users</span></b>
                        <span jhiTranslate="report.archive.users.info">Users info</span>
                    </mat-card-content>
                    <mat-card-actions>
                        <div fxFlex></div>
                        <div  class="slide-toggles">{{ (users ? 'report.archive.your.choice' : 'report.archive.choose') | translate }}
                            <mat-slide-toggle
                                [checked]="users === true" (change)="users = !users">
                            </mat-slide-toggle>
                        </div>
                    </mat-card-actions>
                </mat-card>
            </div>

            <div class="col-12 col-sm-6 mt-2">
                <mat-card fxFlex>
                    <mat-card-content>
                        <b><span jhiTranslate="report.archive.qa">Q&A</span></b>
                        <span jhiTranslate="report.archive.qa.info">Q&A info</span>
                    </mat-card-content>
                    <mat-card-actions>
                        <div fxFlex></div>
                        <div  class="slide-toggles">{{ (qa ? 'report.archive.your.choice' : 'report.archive.choose') | translate }}
                            <mat-slide-toggle
                                [checked]="qa === true" (change)="qa = !qa">
                            </mat-slide-toggle>
                        </div>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-6 mt-2">
                <mat-card fxFlex>
                    <mat-card-content>
                        <b><span jhiTranslate="report.archive.history">History</span></b>
                        <span jhiTranslate="report.archive.history.info">History info</span>
                    </mat-card-content>
                    <mat-card-actions>
                        <div fxFlex></div>
                        <div  class="slide-toggles">{{ (history ? 'report.archive.your.choice' : 'report.archive.choose') | translate }}
                            <mat-slide-toggle
                                [checked]="history === true" (change)="history = !history">
                            </mat-slide-toggle>
                        </div>
                    </mat-card-actions>
                </mat-card>
            </div>

            <div class="col-12 col-sm-6 mt-2">
                <mat-card fxFlex>
                    <mat-card-content>
                        <b><span jhiTranslate="report.archive.uploaded.data">Uploaded data</span></b>
                        <span jhiTranslate="report.archive.uploaded.data.info">Uploaded data info</span>
                    </mat-card-content>
                    <mat-card-actions>
                        <div fxFlex></div>
                        <div  class="slide-toggles">{{ (uploadedData ? 'report.archive.your.choice' : 'report.archive.choose') | translate }}
                            <mat-slide-toggle
                                [checked]="uploadedData === true" (change)="uploadedData = !uploadedData">
                            </mat-slide-toggle>
                        </div>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-6 mt-2">
                <mat-card fxFlex>
                    <mat-card-content>
                        <b><span jhiTranslate="report.archive.overview">Overview</span></b>
                        <span jhiTranslate="report.archive.overview.info">Overview info</span>
                    </mat-card-content>
                    <mat-card-actions>
                        <div fxFlex></div>
                        <div  class="slide-toggles">{{ (overview ? 'report.archive.your.choice' : 'report.archive.choose') | translate }}
                            <mat-slide-toggle
                                [checked]="overview === true" (change)="overview = !overview">
                            </mat-slide-toggle>
                        </div>
                    </mat-card-actions>
                </mat-card>
            </div>
            <div class="col-12 col-sm-6 mt-2 align-items-end">
                <div fxFlex></div>
                <button mat-raised-button
                        class="btn-primary"
                        (click)="generate()"
                        [disabled]="!overview && !uploadedData && !history && !qa && !users && !documents && !entitlements">
                    {{ 'report.archive.generate.archive' | translate }}
                </button>
            </div>
        </div>

    </div>

</div>
