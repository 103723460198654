<div fxLayout="column" fxLayoutAlign="start" style="background-color: #efeff2;">
    <div fxLayout="row" fxFlex fxLayoutAlign="start" class="p-3">
        <button mat-raised-button style="min-width: 70px" [matMenuTriggerFor]="filterMenu" (click)="filterOpen()">
            <mat-icon>filter_list</mat-icon>
            <span jhiTranslate="document.filter.search">Filtr</span>
        </button>
        <br/>
        <!--        <button mat-raised-button style="min-width: 70px; margin-left: 0.5rem;" (click)="export()" color="warn"  matTooltip="{{'report.excel.button.tooltip' | translate}}">-->
        <!--            <mat-icon>get_app</mat-icon>-->
        <!--        </button>-->
        <mat-menu #filterMenu="matMenu" class="filter-menu" style="width: 300px">
            <div fxFlex fxLayout="column" fxLayoutAlign="center" *ngIf="reportSmsCriteriaDtoTmp" (click)="$event.stopPropagation();">
                <div class="filter-item" fxLayout="column">
                    <mat-form-field>
                        <input
                            name="dateFrom"
                            matInput
                            [matDatepicker]="dateFromPicker"
                            [(ngModel)]="reportSmsCriteriaDtoTmp.dateFrom"
                            placeholder="{{'report.date-from' | translate}}"
                            (click)="$event.stopPropagation();">
                        <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dateFromPicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field>
                        <input
                            name="dateTo"
                            matInput
                            [matDatepicker]="dateToPicker"
                            [(ngModel)]="reportSmsCriteriaDtoTmp.dateTo"
                            placeholder="{{'report.date-to' | translate}}"
                            (click)="$event.stopPropagation();">
                        <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dateToPicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <div class="filter-item mt-2" fxLayout="column">
                    <div align="right">
                        <button
                            mat-raised-button
                            color="warn"
                            (click)="search()">
                            <span class="fa fa-search"></span>
                        </button>
                    </div>
                </div>
            </div>
        </mat-menu>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxFlex fxLayoutAlign="center" class="pr-3 pl-3 pt-3 pb-1" *ngIf="hasFilters()">
        <mat-form-field #chipList fxFlex="98%">
            <mat-chip-list>
                <mat-chip *ngIf="reportSmsCriteriaDto.dateFrom" (removed)="chipsRemoveDateFrom()">
                    <span jhiTranslate="report.date-from">Date from</span>
                    <span>&nbsp;{{reportSmsCriteriaDto.dateFrom | date: 'yyyy-MM-dd'}}</span>
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip *ngIf="reportSmsCriteriaDto.dateTo" (removed)="chipsRemoveDateTo()">
                    <span jhiTranslate="report.date-to">Date to</span>
                    <span>&nbsp;{{reportSmsCriteriaDto.dateTo | date: 'yyyy-MM-dd'}}</span>
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip color="warn" (removed)="chipsRemoveAll()">
                    <span jhiTranslate="report.clear-filters">Clear filters</span>
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxFlex class="report-sms-table p-3">
        <mat-table [dataSource]="dataSource" fxFlex>
            <ng-container matColumnDef="projectName">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font"><b jhiTranslate="report.sms.project-name"></b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font"><span>{{element.projectName}}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="lastMonth">
                <mat-header-cell *matHeaderCellDef style="justify-content:center !important; color: black;"  class="header-font"><b jhiTranslate="report.sms.last-month">Last month</b></mat-header-cell>
                <mat-cell *matCellDef="let element" style="justify-content:center !important; color: black;" class="content-font"><span>{{element.lastMonth}}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="filtered">
                <mat-header-cell *matHeaderCellDef style="justify-content:center !important; color: black;"  class="header-font">
                    <b>{{reportSmsCriteriaDto.dateFrom | date: 'yyyy-MM-dd'}} - {{reportSmsCriteriaDto.dateTo | date: 'yyyy-MM-dd'}}</b>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" style="justify-content:center !important;" class="content-font"><span>{{element.filtered}}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="total">
                <mat-header-cell *matHeaderCellDef style="justify-content:center !important; color: black;"  class="header-font"><b jhiTranslate="report.sms.total">Total</b></mat-header-cell>
                <mat-cell *matCellDef="let element" style="justify-content:center !important; color: black;" class="content-font"><span>{{element.total}}</span></mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
    <div fxLayout="row" fxLayoutAlign="end" class="p-3 pr-4">
        <button type="button" class="btn btn-secondary" (click)="close()">
            <span class="fa fa-times"></span>
            &nbsp;
            <span jhiTranslate="entity.action.cancel">Cancel</span>
        </button>
    </div>
</div>
