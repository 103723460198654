<ng-container *ngFor="let user of projectUsers">
    <mat-checkbox (click)="$event.stopPropagation()"
                  (change)="selectUser(user)"
                  [checked]="isElementSelected(user)"
                  class="d-block"
                  color="warn">
        {{user.userLogin}}
    </mat-checkbox>
</ng-container>

