<!--
  qa-permission-info works!
    {{permissionFunction('QA')}}
    {{permissionFunction('QA_DELETE')}}
    {{permission}}
-->

<div class="form-group  d-flex flex-column qa-permissions">
    <ul class="fa-ul">
            <li *ngIf="permissionFunction('QA')">
                <span class="fa-li"><i class="fa fa-check"></i></span> {{'userManagement.qa.'+getMesageSuffix()+'.read' | translate }}
            </li>
            <li *ngIf="permissionFunction('QA_WRITE')">
                <span class="fa-li"><i class="fa fa-check"></i></span> {{'userManagement.qa.'+getMesageSuffix()+'.add' | translate }}
            </li>
            <li *ngIf="permissionFunction('QA_WRITE')">
                <span class="fa-li"><i class="fa fa-check"></i></span> {{'userManagement.qa.'+getMesageSuffix()+'.reply' | translate }}
            </li>

            <li *ngIf="permissionFunction('QA_WRITE')&& !(permissionFunction('QA_DELETE') && isModeratorOrAdministrator())">
                <span class="fa-li"><i class="fa fa-check"></i></span> {{'userManagement.qa.'+getMesageSuffix()+'.mark' | translate }}
            </li>
            <li *ngIf="(permissionFunction('QA_DELETE') && isModeratorOrAdministrator())">
                <span class="fa-li"><i class="fa fa-check"></i></span> {{'userManagement.qa.'+getMesageSuffix()+'.markDelete' | translate }}
            </li>
            <li *ngIf="permissionFunction('QA_SHARE')">
                <span class="fa-li"><i class="fa fa-check"></i></span> {{'userManagement.qa.'+getMesageSuffix()+'.forward' | translate }}
            </li>
            <li *ngIf="permissionFunction('QA_DELETE')">
                <span class="fa-li"><i class="fa fa-check"></i></span> {{'userManagement.qa.'+getMesageSuffix()+'.delete' | translate }}
            </li>



    </ul>
</div>
