import {Component, ElementRef, OnInit} from '@angular/core';
import {JhiAlertService, JhiDataUtils, JhiDateUtils, JhiEventManager, JhiLanguageService}from '@upside-cloud/ng-jhipster';
import {ActivatedRoute} from '@angular/router';


@Component({
    selector: 'jhi-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.scss']
})
export class SettingsComponent implements OnInit {
    error: string;
    success: string;
    settingsAccount: any;
    languages: any[];
    timeZones: any[];
    securityPanel: boolean;

    constructor(
        private route: ActivatedRoute
    ) {

        this.securityPanel =  this.route.snapshot.data['security'];

    }

    ngOnInit() {
    }

}
