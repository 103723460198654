import {Component, OnInit, ViewChild} from '@angular/core';
import {UIChart} from 'primeng/chart';
import {ReportOverviewCriteriaDto} from '../report-overview/report-overview-criteria-dto.model';
import {JhiAlertService}from '@upside-cloud/ng-jhipster';
import {ReportService} from '../report.service';
import {TranslateService} from '@ngx-translate/core';
import {moment} from 'fullcalendar';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Group, Principal, ProjectUser, UserService} from "../../shared";

@Component({
    selector: 'jhi-report-document-views',
    templateUrl: './report-document-views.component.html',
    styleUrls: ['../report.scss']
})
export class ReportDocumentViewsComponent implements OnInit {
    mostViewedDocumentsData: any;
    groups: Group[];
    selectedGroups: Group[];
    projectUsers: ProjectUser[];
    selectedProjectUser: ProjectUser[];
    reportOverviewCriteriaDto: ReportOverviewCriteriaDto;
    reportOverviewCriteriaDtoTmp: ReportOverviewCriteriaDto;
    mostViewedDocumentsOptions: any;
    mostViewedDocumentsItem: any[];
    visit = true;
    stepSize = 1;

    @ViewChild('mostViewedDocumentsChart') mostViewedDocumentsChart: UIChart;

    constructor(
        private alertService: JhiAlertService,
        private reportService: ReportService,
        private translateService: TranslateService,
        private userService: UserService,
        private principal: Principal
    ) {
    }

    ngOnInit(): void {
        this.reportOverviewCriteriaDto = new ReportOverviewCriteriaDto();
        this.reportOverviewCriteriaDto.dateFrom = moment().utc();
        this.reportOverviewCriteriaDto.dateFrom = this.reportOverviewCriteriaDto.dateFrom
            .year(this.reportOverviewCriteriaDto.dateFrom.get('year') - 1);
        this.reportOverviewCriteriaDto.dateTo = moment().utc();
        this.mostViewedDocumentsOptions = {
            // scales: {
            //     xAxes: [{
            //         ticks: {
            //             stepSize: this.stepSize,
            //             beginAtZero: true,
            //         }
            //     }],
            // },
            indexAxis: 'y',
            legend: {
                display: false
            }
        };
        this.mostViewedDocumentsData = {
            datasets: [
                {
                    backgroundColor: [
                        'rgba(213, 0, 0, 0.5)',
                        'rgba(197, 17, 98, 0.5)',
                        'rgba(98, 0, 234, 0.5)',
                        'rgba(41, 98, 255, 0.5)',
                        'rgba(0, 184, 212, 0.5)',
                        'rgba(0, 200, 83, 0.5)',
                        'rgba(174, 234, 0, 0.5)',
                        'rgba(255, 171, 0, 0.5)',
                        'rgba(255, 61, 0, 0.5)',
                        'rgba(46, 39, 35, 0.5)'
                    ],
                    borderColor: [
                        'rgba(213, 0, 0)',
                        'rgba(197, 17, 98)',
                        'rgba(98, 0, 234)',
                        'rgba(41, 98, 255)',
                        'rgba(0, 184, 212)',
                        'rgba(0, 200, 83)',
                        'rgba(174, 234, 0)',
                        'rgba(255, 171, 0)',
                        'rgba(255, 61, 0)',
                        'rgba(46, 39, 35)'
                    ],
                },
            ]
        };
        this.loadAll();
    }

    changeGroup(event) {
        this.visit = true;
        this.selectedGroups = event;
        this.selectedProjectUser = null;
        this.loadAll();
    }

    changeUser(event) {
        this.visit = true;
        this.selectedProjectUser = event;
        this.selectedGroups = null;
        this.loadAll();
    }

    loadAll() {
        if (this.selectedProjectUser != null && this.selectedProjectUser.length > 0) {
            this.reportOverviewCriteriaDto.userIds = [];
            this.reportOverviewCriteriaDto.groupIds = null;
            this.selectedProjectUser.forEach((user) => this.reportOverviewCriteriaDto.userIds.push(user.userId));
        } else if (this.selectedGroups != null && this.selectedGroups.length > 0) {
            this.reportOverviewCriteriaDto.groupIds = [];
            this.reportOverviewCriteriaDto.userIds = null;
            this.selectedGroups.forEach((group) => this.reportOverviewCriteriaDto.groupIds.push(group.id));
        }
        this.reportService.findOverviewDocumentViews(this.reportOverviewCriteriaDto, this.visit).subscribe(
            (res: HttpResponse<Object[]>) => {
                this.mostViewedDocumentsItem = res.body;
                this.loadMostViewedDocuments();
                this.visit = false;
                if (this.mostViewedDocumentsItem && this.mostViewedDocumentsItem.length > 0) {
                    const maxCount = this.mostViewedDocumentsItem.sort((a, b) => (b.count - a.count))[0].count;
                    this.stepSize = Math.ceil(maxCount / 10);
                } else {
                    this.stepSize = 1;
                }
            },
            (res: HttpErrorResponse) => this.onError(res.message)
        );
    }

    loadMostViewedDocuments(){
        this.mostViewedDocumentsData.labels = this.mostViewedDocumentsItem.map((reportItem) => reportItem.name);
        this.mostViewedDocumentsData.datasets[0].data = this.mostViewedDocumentsItem.map((reportItem) => reportItem.count);

        this.mostViewedDocumentsChart.refresh();
    }

    private onError(error) {
        this.alertService.error(error.message, null, null);
    }

    search() {
        this.reportOverviewCriteriaDto = Object.assign(new ReportOverviewCriteriaDto(), this.reportOverviewCriteriaDtoTmp);
        this.reportOverviewCriteriaDtoTmp = null;
        this.loadAll();
    }

    filterOpen() {
        this.reportOverviewCriteriaDtoTmp = Object.assign(new ReportOverviewCriteriaDto(), this.reportOverviewCriteriaDto);
    }

    hasFilters() {
        return this.reportOverviewCriteriaDto.dateFrom != null
            || this.reportOverviewCriteriaDto.dateTo != null;
    }

    chipsRemoveDateFrom() {
        this.reportOverviewCriteriaDto.dateFrom = null;
        this.loadAll();
    }

    chipsRemoveDateTo() {
        this.reportOverviewCriteriaDto.dateTo = null;
        this.loadAll();
    }

    chipsRemoveAll() {
        this.reportOverviewCriteriaDto = new ReportOverviewCriteriaDto();
        this.loadAll();
    }
}
