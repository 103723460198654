import {AfterViewInit, Component, ElementRef, HostListener, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DirectoryVdrService} from '../../directory-vdr';
import {JhiAlertService} from '@upside-cloud/ng-jhipster';
import {DocumentVdrService} from '../../document-vdr';
import {NgxSpinnerService} from 'ngx-spinner';

export interface ChangeNameDialogData {
    id: number;
    type: string;
    currentFilename: string;
}

@Component({
    selector: 'change-name-dialog',
    templateUrl: 'change-name-dialog.html',
    styleUrls: ['./../directory-document.scss']
})
export class ChangeNameDialog implements AfterViewInit {

    newName: string;
    result: boolean;
    disabledSubmit = false;
    minLength = 1;

    @ViewChild('directoryNameInput')
    directoryNameInput: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<ChangeNameDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ChangeNameDialogData,
        private directoryService: DirectoryVdrService,
        private documentService: DocumentVdrService,
        private jhiAlertService: JhiAlertService,
        private spinner: NgxSpinnerService
    ) {
        this.result = false;
        this.newName = data.currentFilename;
    }


    ngAfterViewInit(): void {
        this.directoryNameInput.nativeElement.selectionStart = 0;
        this.directoryNameInput.nativeElement.selectionEnd = this.data.currentFilename.lastIndexOf('.');
        this.minLength = this.data.type === 'directory' ? 1 : 3;
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    @HostListener('window:keyup.Enter')
    onConfirmClick(): void {
        if (this.disabledSubmit) {
            return;
        }

        if (this.data.currentFilename === this.newName) {
            this.jhiAlertService.info('changeNameAction.actionNoChange');
            return;
        }

        this.rename();
    }

    private rename() {
        this.disabledSubmit = true;
        this.spinner.show();

        const request = this.data.type === 'directory' ?
            this.directoryService.changeName(this.data.id, encodeURIComponent(this.newName)) :
            this.documentService.changeName(this.data.id, encodeURIComponent(this.newName));

        request.subscribe((value) => {
                this.result = true;
                this.disabledSubmit = false;
                this.spinner.hide();
                this.onCancelClick();
            }, (error) => {
                this.jhiAlertService.error(error.error.errorKey === 'fileNameExists'
                        ? 'changeNameAction.existDirectoryOrFile' : 'changeNameAction.actionError',
                    error.error.errorKey === 'fileNameExists' ? {'name': this.newName} : null);
                this.disabledSubmit = false;
                this.spinner.hide();

            }
        );
    }
}
