import { Component } from '@angular/core';

@Component({
    selector: 'jhi-docs',
    templateUrl: './docs.component.html'
})
export class JhiDocsComponent {
    constructor(
    ) {
    }
}
