import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import {zxcvbn} from 'zxcvbn-typescript';

@Component({
    selector: 'jhi-password-strength-bar',
    template: `
        <div id="strength">
            <small style="display: none" jhiTranslate="global.messages.validate.newpassword.strength">Password strength:</small>
            <ul id="strengthBar">
                <li class="point"></li>
                <li class="point"></li>
                <li class="point"></li>
                <li class="point"></li>
                <li class="point"></li>
            </ul>
        </div>`,
    styleUrls: [
        'password-reset-strength-bar.scss'
    ]
})
export class PasswordStrengthBarComponent {
    colors = ['#F00', '#F90', '#FF0', '#9F0', '#0F0'];

    constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

    measureStrength(p: string): number {
        const passwordInfo = zxcvbn(p);
        return passwordInfo.score;
    }

    getColor(s: number): any {
        return {idx: s + 1, col: this.colors[s]};
    }

    @Input()
    set passwordToCheck(password: string) {
        if (password) {
            const c = this.getColor(this.measureStrength(password));
            const element = this.elementRef.nativeElement;
            if ( element.className ) {
                // this.renderer.setElementClass(element, element.className , false);
                this.renderer.removeClass(element, element.className);
            }
            const lis = element.getElementsByTagName('li');
            for (let i = 0; i < lis.length; i++) {
                if (i < c.idx) {
                    // this.renderer.setElementStyle(lis[i], 'backgroundColor', c.col);
                    this.renderer.setStyle(lis[i], 'backgroundColor', c.col);
                } else {
                    this.renderer.setStyle(lis[i], 'backgroundColor', '#DDD');
                    // this.renderer.setElementStyle(lis[i], 'backgroundColor', '#DDD');
                }
            }
        }
    }
}
