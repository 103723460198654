import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import 'intl-tel-input';
import * as jQuery from 'jquery';

/**
 * @deprecated
 */
@Directive({
    selector: '[jhiAppIntlTelInput]'
})
export class IntlTelInputDirective implements OnInit {

    @Input('jhiAppIntlTelInput') jhiAppIntlTelInput: any;

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        jQuery.fn.intlTelInput.loadUtils('https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.12/js/utils.js');
        jQuery(this.el.nativeElement).intlTelInput(this.jhiAppIntlTelInput, {
            autoPlaceholder: false,
        });
    }
}
