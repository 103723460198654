import {Injectable} from '@angular/core';
import {Observable, of, Subject, Subscription, switchMap, timer} from 'rxjs';
import {AccountService} from './account.service';
import {ProjectVdr} from '@admin/project-vdr';
import {Socket} from 'ngx-socket-io';
import {catchError} from 'rxjs/operators';
import {faro} from '@grafana/faro-web-sdk';
import {Account} from '../user/account.model';

@Injectable({providedIn: 'root'})
export class Principal {
    private userIdentity: Account;
    private authenticated = false;
    private authenticationState = new Subject<any>();
    private isConnectedToSocketIo = false;

    constructor(private account: AccountService, private socket: Socket) {
        socket.on('disconnect', () => {
            this.isConnectedToSocketIo = false;
           this.connectToSocket();
        });

    }

    authenticate(identity: Account) {
        this.userIdentity = identity;
        this.authenticated = identity !== null;
        this.authenticationState.next(this.userIdentity);
    }

    hasAnyAuthority(authorities: string[]): Promise<boolean> {
        return Promise.resolve(this.hasAnyAuthorityDirect(authorities));
    }

    hasAnyAuthorityDirect(authorities: string[]): boolean {
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
            return false;
        }

        for (let i = 0; i < authorities.length; i++) {
            if (this.userIdentity.authorities.includes(authorities[i])) {
                return true;
            }
        }

        return false;
    }

    hasAuthority(authority: string): Promise<boolean> {
        if (!this.authenticated) {
            return Promise.resolve(false);
        }

        return this.identity().then((id) => {
            return Promise.resolve(id.authorities && id.authorities.includes(authority));
        }, () => {
            return Promise.resolve(false);
        });
    }

    identity(force?: boolean): Promise<any> {
        if (force === true) {
            this.userIdentity = undefined;
        }

        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        if (this.userIdentity) {
            return Promise.resolve(this.userIdentity);
        }

        // retrieve the userIdentity data from the server, update the identity object, and then resolve.
        return this.account.get().toPromise().then((response) => {
            const account = response.body;
            if (account) {
                this.userIdentity = account;
                this.authenticated = true;
                this.connectToSocket();
            } else {
                this.userIdentity = null;
                this.authenticated = false;
                this.onLoggedOut();
            }
            this.authenticationState.next(this.userIdentity);
            return this.userIdentity;
        }).catch((err) => {
            this.userIdentity = null;
            this.authenticated = false;
            this.authenticationState.next(this.userIdentity);
            return null;
        });
    }

    private onLoggedOut() {
        this.socket.disconnect();
        console.log('Disconnected from socket.io');
    }

    private connectToSocket() {
        if (this.isConnectedToSocketIo) {
            console.warn('Attempt to reconnect when connected');
            return;
        }

        try {
            this.socket.connect();
            this.socket.on('ping', (data) => {
                this.socket.emit('pong', data);
                console.log('ping', data);
            });
            console.log('Connected to socket.io', this.socket);
            this.isConnectedToSocketIo = true;
        } catch (e) {
            console.error('Error while attempting to connect to socket.io', e);
        }
        this.socket.on('user-permissions-changed', (userId, data) => {
            console.log('Received user-permissions-changed, forcing user data to reload', userId, data);
            this.identity(true).then((value) => console.log('New user data', value));
        });
    }

    identity2fa(force?: boolean): Promise<any> {
        if (force === true) {
            this.userIdentity = undefined;
        }

        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        if (this.userIdentity) {
            return Promise.resolve(this.userIdentity);
        }

        // retrieve the userIdentity data from the server, update the identity object, and then resolve.
        return this.account.get().toPromise().then((response) => {
            this.userIdentity = null;
            this.authenticated = false;
            this.authenticationState.next(this.userIdentity);
            return this.userIdentity;
        }).catch((err) => {
            this.userIdentity = null;
            this.authenticated = false;
            this.authenticationState.next(this.userIdentity);
            return null;
        });
    }

    isAuthenticated(): boolean {
        return this.authenticated;
    }

    isIdentityResolved(): boolean {
        return this.userIdentity !== undefined;
    }

    getAuthenticationState(): Observable<any> {
        return this.authenticationState.asObservable();
    }

    getUserIdentity() {
        return this.userIdentity;
    }

    getCurrentProject(): ProjectVdr {
        return this.getUserIdentity().project;
    }

    getMainColor(): string {
        return this.getCurrentProject().mainColor;
    }

    getImageUrl(): String {
        return this.isIdentityResolved() ? this.userIdentity.imageUrl : null;
    }

    refresh(): Subscription {
        return timer(0, 10_000)
            .pipe(switchMap(() => this.account.get().pipe(catchError((x) => of({})))))
            .subscribe();
    }
}
