import {Route} from '@angular/router';

import {UserRouteAccessService} from '../../../shared/index';
import {QuestionResetComponent} from './question-reset.component';

export const questionResetRoute: Route = {
    path: 'question-reset',
    component: QuestionResetComponent,
    data: {
        pageTitle: 'question.title'
    },
};
