import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiAlertService, JhiDataUtils, JhiEventManager} from '@upside-cloud/ng-jhipster';

import {ProjectVdr} from './project-vdr.model';
import {ProjectVdrPopupService} from './project-vdr-popup.service';
import {ProjectVdrService} from './project-vdr.service';
import {SearchCountryField} from 'ngx-intl-tel-input-gg';
import {CountryISO} from 'ngx-intl-tel-input-gg';
import {Client} from '@entities/client/client.model';
import {ClientService} from '@entities/client/client.service';

@Component({
    selector: 'jhi-project-vdr-dialog',
    templateUrl: './project-vdr-dialog.component.html',
    styleUrls: ['./project.scss'],

})
export class ProjectVdrDialogComponent implements OnInit {
    readonly SearchCountryField = SearchCountryField;
    readonly CountryISO = CountryISO;

    project: ProjectVdr;
    isSaving: boolean;
    clients: Client[];
    clientName = '';

    constructor(
        public activeModal: NgbActiveModal,
        private jhiAlertService: JhiAlertService,
        private projectService: ProjectVdrService,
        private eventManager: JhiEventManager,
        private dataUtils: JhiDataUtils,
        private elementRef: ElementRef,
        private clientService: ClientService
    ) {
    }

    byteSize(field) {
        return this.dataUtils.byteSize(field);
    }

    openFile(contentType, field) {
        return this.dataUtils.openFile(contentType, field);
    }

    setFileData(event, entity, field, isImage) {
        this.dataUtils.setFileData(event, entity, field, isImage);
    }

    clearInputImage(field: string, fieldContentType: string, idInput: string) {
        this.dataUtils.clearInputImage(this.project, this.elementRef, field, fieldContentType, idInput);
    }

    ngOnInit() {
        this.isSaving = false;
        this.getClients();
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    calculateWww() {
        if (this.project.name) {
            return slugify(this.project.name.toLowerCase()).replace(/[^a-zA-Z0-9\-]+/g, '');
        }
        return null;
    }

    save() {
        this.project.phone = this.project.phone['e164Number'];
        // this.project.www = this.calculateWww();
        this.project.client = this.getOrCreateClient();

        this.isSaving = true;
        if (this.project.id !== undefined) {
            this.subscribeToSaveResponse(
                this.projectService.update(this.project));
        } else {
            this.subscribeToSaveResponse(
                this.projectService.create(this.project));
        }
    }

    getOrCreateClient(): Client {
        if (this.project.client !== undefined) {
            return this.project.client;
        }

        if (this.clientName !== '' && this.clientName !== undefined) {
            const client = new Client();
            client.name = this.clientName;
            return client;
        }
        return null;
    }

    inputChange(data) {
        data.control.setValue(data.$event.target.value);
    }


    private subscribeToSaveResponse(result: Observable<HttpResponse<ProjectVdr>>) {
        result.subscribe((res: HttpResponse<ProjectVdr>) =>
            this.onSaveSuccess(res.body), (res: HttpErrorResponse) => this.onSaveError());
    }

    private onSaveSuccess(result: ProjectVdr) {
        this.eventManager.broadcast({name: 'projectListModification', content: 'OK'});
        this.isSaving = false;
        this.activeModal.dismiss(result);
    }

    private onSaveError() {
        this.isSaving = false;
    }

    private onError(error: any) {
        this.jhiAlertService.error(error.message, null, null);
    }

    private getClients(): void {
        this.clientService.findAll().subscribe((response) => {
            this.clients = response.body;
        });
    }
}

@Component({
    selector: 'jhi-project-vdr-popup',
    template: ''
})
export class ProjectVdrPopupComponent implements OnInit, OnDestroy {

    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private projectPopupService: ProjectVdrPopupService
    ) {
    }

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            if (params['id']) {
                this.projectPopupService
                    .open(ProjectVdrDialogComponent as Component, params['id']);
            } else {
                this.projectPopupService
                    .open(ProjectVdrDialogComponent as Component);
            }
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }


}

function slugify(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = 'ąęśćńół·/_,:;';
    const to = 'aescnol------';
    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}


