import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {VdrSharedModule} from '../../shared';
import {
    DirectoryVdrService,
} from './';

@NgModule({
    imports: [
        VdrSharedModule
    ],
    providers: [
        DirectoryVdrService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VdrDirectoryVdrModule {}
