import {Injectable} from '@angular/core';
import {JhiLanguageService}from '@upside-cloud/ng-jhipster';

import {Principal} from '../auth/principal.service';
import {AuthServerProvider} from '../auth/auth-session.service';
import {AccountService} from '../auth/account.service';
import {faro} from '@grafana/faro-web-sdk';

@Injectable({providedIn: 'root'})
export class LoginService {

    constructor(private languageService: JhiLanguageService,
                private accountService: AccountService,
                private principal: Principal,
                private authServerProvider: AuthServerProvider) {
    }

    login(credentials, callback?) {
        const cb = callback || function() {
        };

        return new Promise((resolve, reject) => {
            this.authServerProvider.login(credentials).subscribe((data) => {
                this.principal.identity(true).then((account) => {
                    faro.api.setUser({
                        username: account?.login,
                    });

                    // zgodnie z DEAL DEAL-257 ZMIENIAMY język w account //na podstawie ustawień konta
                    if (account !== null) {
                        // this.languageService.changeLanguage(account.langKey);
                        this.accountService.saveLanguageToAccount(credentials.langKey).subscribe((res) => {
                                //  res.body
                            }, (error) => {
                                console.log(error);
                            }
                        );
                    }
                    resolve(data);
                });
                return cb();
            }, (err) => {
                this.logout();
                reject(err);
                return cb(err);
            });
        });
    }

    confirmation(credentials, callback?) {
        const cb = callback || function() {
        };

        return new Promise((resolve, reject) => {
            this.authServerProvider.confirmation(credentials).subscribe((data) => {
                this.principal.identity2fa(true).then((account) => {
                    // After the login the language will be changed to
                    // the language selected by the user during his registration
                    if (account !== null) {
                        this.languageService.changeLanguage(account.langKey);
                    }
                    resolve(data);
                });
                return cb();
            }, (err) => {
                // this.logout();
                reject(err);
                return cb(err);
            });
        });
    }

    logout() {
        this.authServerProvider.logout().subscribe();
        this.principal.authenticate(null);
        faro.api.resetUser();
    }
}
