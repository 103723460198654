import { Route } from '@angular/router';

import { UserSettingsComponent } from './user-settings.component';

export const userSettingsRoute: Route = {

    path: 'user-settings',
    component: UserSettingsComponent,
    data: {
        pageTitle: 'global.menu.account.settings'
    },
};
