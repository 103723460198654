import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ReportArchive, ReportArchiveOption} from "./report-archive.model";
import {ReportService} from "../report.service";
import {HttpHeaders} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import * as moment from "moment";
import {SERVER_API_URL} from "../../app.constants";


@Component({
    selector: 'jhi-report-archive-download',
    templateUrl: './report-archive-download.component.html',
    styleUrls: ['./report-archive.scss']
})
export class ReportArchiveDownloadComponent implements OnInit {

    reportArchiveOption = ReportArchiveOption;
    reportArchive: ReportArchive;
    error = false;
    message = '';
    title = '';

    constructor(private route: ActivatedRoute,
                private reportService: ReportService,
                private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            if (params['token']) {
               this.reportService.findReportArchiveByToken(params['token']).subscribe((response) => {

                  this.reportArchive = response.body;

                  if (this.reportArchive) {
                      this.title = this.translateService.instant('report.archive.download.title',
                          {'ADDITION_DATE': moment(this.reportArchive.additionDate).format('YYYY-MM-DD HH:mm') });
                      this.reportService.downloadURI('api/archive/download/' + this.reportArchive.id, 'archive' + this.reportArchive.id);
                  } else {
                      this.error = true;
                      this.message = this.translateService.instant('report.archive.download.error.token');
                  }
               });
            } else {
                this.error = true;
                this.message = this.translateService.instant('report.archive.download.error.url');
            }
        });
    }

    isReportArchive(): boolean {
        if (this.reportArchive) {
            return true;
        }
        return false;
    }

    hasSelectedOptions(selectedOption: ReportArchiveOption): boolean {
        return this.reportArchive.selectedOptions.includes(selectedOption);
    }

    private getFileNameFromHeaders(headers: HttpHeaders)  {
        const contentDisposition = headers.get('content-disposition') || '';
        const matches = /filename=([^;]+)/ig.exec(contentDisposition);
        const fileName = (matches[1] || 'untitled').trim();
        return fileName.replace(/['"]+/g, '');
    }
}
