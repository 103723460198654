<div class="settings">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <mat-tab-group [selectedIndex]="securityPanel? 1: 0">

                <mat-tab label="{{ 'settings.userSettingsTab' | translate }}" class="custom-mat-tab">
                    <jhi-user-settings></jhi-user-settings>
                </mat-tab>

                <mat-tab [routerLink]="['/security']" label="{{ 'settings.securitySettingsTab' | translate }}"
                         class="custom-mat-tab">
                    <jhi-security-settings></jhi-security-settings>
                </mat-tab>
            </mat-tab-group>

        </div>
    </div>

</div>
