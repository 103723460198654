import {Route} from '@angular/router';

import {UserRouteAccessService} from '../../shared';
import {SettingsComponent} from './settings.component';

export const settingsRoute: Route[] = [{

        path: 'settings',
        component: SettingsComponent,
        data: {
            pageTitle: 'global.menu.account.settings'
        },

    },
        {

            path: 'security',
            component: SettingsComponent,
            data: {
                pageTitle: 'global.menu.account.settings',
                security: true,
            }

        }]
;
