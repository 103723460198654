<div>
    <div class="row justify-content-center">
        <div class="col-md-8">
            <h2 jhiTranslate="password.title" *ngIf="account">Password
                for [<b>{{account.email}}</b>]</h2>

            <jhi-alert-error></jhi-alert-error>
            <jhi-alert></jhi-alert>


            <div class="alert alert-success" *ngIf="success" jhiTranslate="password.messages.success">
                <strong>Password changed!</strong>
            </div>


            <form name="form" role="form" (ngSubmit)="changePassword()" #passwordForm="ngForm">

                <div class="form-group">
                    <label class="form-control-label" for="oldPassword" jhiTranslate="password.oldPassword.label">Old
                        password</label>
                    <div class="input-group">
                        <input [type]="passwordCurrentShow ? 'text' : 'password'" class="form-control" id="oldPassword"
                               name="oldPassword" #password="ngModel"
                               placeholder="{{'password.oldPassword.placeholder' | translate}}"
                               [(ngModel)]="passwordPhoneReset.oldPassword"
                               required>

                        <div class="input-group-append">
                                    <span class="input-group-text">
                                      <i class="fa"
                                         [ngClass]="{'fa-eye-slash': !passwordCurrentShow,'fa-eye': passwordCurrentShow}"
                                         (click)="passwordCurrentShow = !passwordCurrentShow"
                                      ></i>
                                    </span>
                        </div>
                    </div>
                </div>


                <div *ngIf="!isPhone" class="question-info">
                    <span jhiTranslate="password.question-info"></span>
                    <span>{{account.securityQuestion}}</span>

                </div>


                <div *ngIf="isPhone">
                    <div class="form-group">
                        <label class="form-control-label" for="code" jhiTranslate="password.code.label">Kod</label>

                        <input type="text" class="form-control" id="code" name="code"
                               #passwordInput="ngModel"
                               placeholder="{{'password.code.placeholder' | translate}}"
                               [(ngModel)]="passwordPhoneReset.verificationCode"
                               required autocomplete="foo">
                    </div>

                    <div class="d-flex justify-content-end">
                        <button type="button" class="btn" (click)="sendVerificationCode()"
                                jhiTranslate="password.form.send-verification-code-again">Wyślij kod ponownie
                        </button>
                    </div>

                </div>

                <div class="form-group">
                    <label class="form-control-label" for="newPassword" jhiTranslate="global.form.newpassword">New
                        password</label>
                    <div class="input-group">
                        <input [type]="password1Show ? 'text' : 'password'" class="form-control" id="newPassword"
                               name="newPassword" #passwordInput="ngModel"
                               placeholder="{{'global.form.newpassword.placeholder' | translate}}"
                               [(ngModel)]="passwordPhoneReset.newPassword" minlength=8 maxlength=40
                               pattern="^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,40}$"
                               required>

                        <div class="input-group-append">
                                    <span class="input-group-text">
                                      <i class="fa"
                                         [ngClass]="{'fa-eye-slash': !password1Show,'fa-eye': password1Show}"
                                         (click)="password1Show = !password1Show"
                                      ></i>
                                    </span>
                        </div>
                    </div>
                    <div *ngIf="passwordInput.dirty && passwordInput.invalid">
                        <small class="form-text text-danger"
                               jhiTranslate="global.messages.validate.newpassword.pattern">
                            Your password is required.
                        </small>
                    </div>
                    <jhi-password-strength-bar
                        [passwordToCheck]="passwordPhoneReset.newPassword"></jhi-password-strength-bar>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="confirmPassword" jhiTranslate="global.form.confirmpassword">New
                        password confirmation</label>

                    <div class="input-group">
                        <input [type]="password2Show ? 'text' : 'password'" class="form-control" id="confirmPassword"
                               name="confirmPassword" #confirmPasswordInput="ngModel"
                               placeholder="{{'global.form.confirmpassword.placeholder' | translate}}"
                               [(ngModel)]="passwordPhoneReset.confirmPassword" minlength=8 maxlength=40
                               pattern="^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,40}$"
                               required>

                        <div class="input-group-append">
                                    <span class="input-group-text">
                                      <i class="fa"
                                         [ngClass]="{'fa-eye-slash': !password2Show,'fa-eye': password2Show}"
                                         (click)="password2Show = !password2Show"
                                      ></i>
                                    </span>
                        </div>
                    </div>

                    <small class="form-text text-danger"
                           *ngIf="doNotMatch"
                           jhiTranslate="global.messages.validate.confirmpassword.doNotMatch">
                        Your confirmation don't match the first password
                    </small>
                    <div *ngIf="confirmPasswordInput.dirty && confirmPasswordInput.invalid">


                        <small class="form-text text-danger"
                               *ngIf="confirmPasswordInput.errors.required"
                               jhiTranslate="global.messages.validate.confirmpassword.required">
                            Your confirmation password is required.
                        </small>
                        <small class="form-text text-danger"
                               *ngIf="confirmPasswordInput.errors.minlength"
                               jhiTranslate="global.messages.validate.confirmpassword.minlength">
                            Your confirmation password is required to be at least 8 characters.
                        </small>
                        <small class="form-text text-danger"
                               *ngIf="confirmPasswordInput.errors.maxlength"
                               jhiTranslate="global.messages.validate.confirmpassword.maxlength">
                            Your confirmation password cannot be longer than 40 characters.
                        </small>
                        <small class="form-text text-danger" *ngIf="confirmPasswordInput.errors.pattern"
                               jhiTranslate="global.messages.validate.newpassword.pattern">
                            Your password must containt minimum eight characters, at least one uppercase letter, one
                            lowercase letter, one number and one special character:.
                        </small>
                    </div>
                </div>
                <div class="button-panel">
                    <button type="button" class="btn btn-no-border" [routerLink]="cancel()"
                            jhiTranslate="entity.action.cancel">Cancel
                    </button>
                    <button type="submit" [disabled]="passwordForm.form.invalid" class="btn btn-primary"
                            jhiTranslate="password.form.button">Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
