import {Component, OnInit} from '@angular/core';
import {SelectedItemDto} from './directory-document-tree.component';
import {GroupObject} from './group-list.component';
import {getFileNameFromResponseContentDisposition, saveFile, ReportService} from "../../../report";
import {HttpResponse} from "@angular/common/http";

@Component({
    selector: 'permission-view',
    templateUrl: 'permission-view.component.html',
    styleUrls: ['../directory-document.scss']
})
export class PermissionViewComponent implements OnInit{

    selectedItem: SelectedItemDto;
    groupObject: GroupObject;
    loading = false;

    constructor(private reportService: ReportService) {
    }


    ngOnInit(): void {

    }


    onSelectedItem(item: SelectedItemDto) {
        this.selectedItem = item;
    }

    onGroupIdChange(object: GroupObject) {
        this.groupObject = object;
    }

    exportEntitlementReport(): void {
        this.loading = true;
        this.reportService.downloadEntitlementReport().subscribe((response: HttpResponse<Blob>) => {
            const fileName = getFileNameFromResponseContentDisposition(response);
            saveFile(response.body, fileName);
            this.loading = false;
        });
    }
}
