<div id="sidebarContent" [style.top]="top">
  <div #thumbnailViewTemplate>
    <ng-content *ngTemplateOutlet="customThumbnail ? customThumbnail : defaultThumbnail"></ng-content>
  </div>

  <div id="thumbnailView" (keydown)="onKeyDown($event)"></div>
  <div id="outlineView" class="hidden"></div>
  <div id="attachmentsView" class="hidden"></div>
  <div id="layersView" class="hidden"></div>
</div>

<ng-template #defaultThumbnail>
  <a class="pdf-viewer-template">
    <div class="thumbnail" data-page-number="PAGE_NUMBER">
      <img class="thumbnailImage" alt="miniature of the page" />
    </div>
  </a>
</ng-template>
