import {Component, OnInit} from '@angular/core';
import {Principal} from '../shared';

@Component({
    selector: 'jhi-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./report.scss']
})
export class ReportsComponent implements OnInit {

    selectedReportType: ReportType;
    ReportType = ReportType;
    isAdmin: boolean;
    isQA: boolean;

    constructor(
        private principal: Principal
    ) {
    }

    ngOnInit() {
        this.principal.hasAnyAuthority(['REPORT']).then((isAdmin) => {
            this.isAdmin = isAdmin;

            if (!isAdmin) {
                this.principal.hasAnyAuthority(['REPORT_QA']).then((isQA) => {
                    this.isQA = isQA;

                    if (isQA) {
                        this.selectedReportType = ReportType.QA;
                    }
                });
            } else {
                this.isQA = true;
                this.selectedReportType = ReportType.OVEVIEW;
            }
        });
    }

    chooseReportType(idx) {
        if (this.isAdmin) {
            switch (idx) {
                case 0:
                    this.selectedReportType = ReportType.OVEVIEW;
                    break;
                case 1:
                    this.selectedReportType = ReportType.DOCUMENTS;
                    break;
                case 2:
                    this.selectedReportType = ReportType.QA;
                    break;
                case 3:
                    this.selectedReportType = ReportType.HISTORY;
                    break;
                case 4:
                    this.selectedReportType = ReportType.UPLOADED;
                    break;
                case 5:
                    this.selectedReportType = ReportType.PROCESSING;
                    break;
                case 6:
                    this.selectedReportType = ReportType.ARCHIVE;
                    break;
            }
        } else {
            switch (idx) {
                case 0:
                    this.selectedReportType = ReportType.QA;
                    break;
                case 1:
                    this.selectedReportType = ReportType.HISTORY;
                    break;
            }
        }
    }
}

export enum ReportType {
    'HISTORY',
    'QA',
    'OVEVIEW',
    'UPLOADED',
    'DOCUMENTS',
    'PROCESSING',
    'ARCHIVE'
}


