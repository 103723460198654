export enum DocumentPermissionType {
    UPLOAD = 700,
    DOWNLOAD_ORIGINAL = 600,
    DOWNLOAD_PDF = 500,
    PRINT = 400,
    DOWNLOAD_ENCRYPTED = 300,
    VIEW = 200,
    FENCE_VIEW = 100,
    NONE = 0
}
