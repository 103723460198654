<div>
    <div class="row justify-content-center">
        <div class="col-md-8">

            <h2 jhiTranslate="settings.form.changeQuestion">Zmień pytanie pomocnicze</h2>

            <jhi-alert-error></jhi-alert-error>
            <jhi-alert></jhi-alert>
            <form name="form" role="form" (ngSubmit)="save()" #settingsForm="ngForm" novalidate autocomplete="off">

                <div class="form-group">
                    <label class="form-control-label" jhiTranslate="settings.form.question">Pytanie pomocnicze</label>
                    <ng-select class="form-control" id="question" name="question" [(ngModel)]="questionReset.securityQuestion">
                        <ng-option *ngFor="let securityQuestion of securityQuestions" [ngValue]="securityQuestion">{{securityQuestion.question | translate}}</ng-option>
                    </ng-select>
                </div>


                <div class="form-group">
                    <label for="question-answer2" jhiTranslate="settings.form.questionAnswer">Odpowiedź na pytanie</label>
                    <input class="form-control" name="question-answer2" id="question-answer2" readonly onfocus ="this.removeAttribute('readonly')"
                           placeholder="" autocomplete="off"
                           [(ngModel)]="questionReset.securityAnswer">
                </div>

                <div class="change-password-info">
                    {{'settings.changeQuestionInfo' | translate}}
                </div>

                <div class="form-group">
                    <label for="password" jhiTranslate="login.form.password">Password</label>
                    <input type="password" autocomplete="off" class="form-control" name="password" id="password" readonly onfocus ="this.removeAttribute('readonly')"
                           placeholder="{{'login.form.password.placeholder' | translate}}"
                           [(ngModel)]="questionReset.password">
                </div>

                <div style="text-align:right">
                    <button type="button" class="btn btn-no-border" routerLink="../security"
                            jhiTranslate="entity.action.cancel">Cancel
                    </button>
                    <button class="ml-10" type="submit" [disabled]="settingsForm.form.invalid" class="btn btn-primary" jhiTranslate="settings.form.button">Save</button>
                </div>
            </form>
        </div>
    </div>

</div>
