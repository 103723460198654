<pdf-shy-button
  [cssClass]="'xs' | responsiveCSSClass"
  title="Previous Page"
  primaryToolbarId="primaryPrevious"
  secondaryMenuId="secondaryPreviousPage"
  l10nId="previous"
  l10nLabel="previous_label"
  [disabled]="disablePreviousPage"
  [order]="600"
  eventBusName="previouspage"
  [closeOnClick]="false"
  image="<svg style='width: 24px; height: 24px' viewBox='0 0 24 24'><path fill='currentColor' d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z' /></svg>"
>
</pdf-shy-button>
