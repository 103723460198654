import {BaseEntity} from './../../shared';
import {Recipient} from '../question/recipient.model';

export class Answer implements BaseEntity {
    constructor(
        public id?: number,
        public content?: string,
        public questionId?: number,
        public authorName?: string,
        public authorId?: number,
        public createdDate?: Date,
        public recipients: Recipient[] = [],
        public lastModifiedDate?: Date,
        public lastModifiedBy?: String,
        public forward?: boolean,
        public avatarBytes?: any
    ) {
    }
}
