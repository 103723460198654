<div class="d-flex flex-row button-row" fxLayout="row" fxFlexAlign="start center">
    <div fxLayout="row" fxFlexAlign="start" fxFlex="30%">
        <button mat-raised-button style="min-width: 70px" [matMenuTriggerFor]="filterMenu" (click)="filterOpen()">
            <mat-icon>filter_list</mat-icon>
            <span jhiTranslate="document.filter.search">Filtr</span>
        </button>

        <button mat-raised-button style="min-width: 70px; margin-left: 0.5rem;" (click)="export()" color="warn" type="button">
            <mat-icon>get_app</mat-icon>
        </button>

        <mat-menu #filterMenu="matMenu" class="filter-menu">
            <div fxFlex fxLayout="column" fxLayoutAlign="center" *ngIf="reportOverviewCriteriaDtoTmp" (click)="$event.stopPropagation();">
                <div class="filter-item" fxLayout="column">
                    <mat-form-field>
                        <input
                            name="searchDateFrom"
                            matInput
                            [matDatepicker]="searchDateFromPicker"
                            [(ngModel)]="reportOverviewCriteriaDtoTmp.dateFrom"
                            placeholder="{{'report.date-from' | translate}}"
                            (click)="$event.stopPropagation();">
                        <mat-datepicker-toggle matSuffix [for]="searchDateFromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #searchDateFromPicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field>
                        <input
                            name="searchDateTo"
                            matInput
                            [matDatepicker]="searchDateToPicker"
                            [(ngModel)]="reportOverviewCriteriaDtoTmp.dateTo"
                            placeholder="{{'report.date-to' | translate}}"
                            (click)="$event.stopPropagation();">
                        <mat-datepicker-toggle matSuffix [for]="searchDateToPicker"></mat-datepicker-toggle>
                        <mat-datepicker #searchDateToPicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <div class="filter-item mt-2" fxLayout="column">
                    <div align="right">
                        <button
                            mat-raised-button
                            color="warn"
                            (click)="search()">
                            <span class="fa fa-search"></span>
                        </button>
                    </div>
                </div>
            </div>
        </mat-menu>
    </div>
    <div fxLayout="row" fxLayoutAlign="center start" fxFlex="40%" *ngIf="overviewReportProjectDto">
        <div fxLayout="column">
            <div><h5 style="text-align: right; margin-bottom: 0.2rem;">{{overviewReportProjectDto.activeFrom | date: 'yyyy-MM-dd'}}</h5></div>
            <h6 class="hint"><span jhiTranslate="report.project-start">Project started date</span></h6>
        </div>
        <div fxLayout="column" class="ml-2 mr-2"><h5>&nbsp;-&nbsp;</h5></div>
        <div fxLayout="column">
            <div><h5 style="margin-bottom: 0.2rem;">{{overviewReportProjectDto.activeTo | date: 'yyyy-MM-dd'}}</h5></div>
            <h6 class="hint"><span jhiTranslate="report.project-end">Project end date</span></h6>
        </div>
    </div>
    <div fxLayout="row" fxFlexAlign="start" fxFlex="30%">
    </div>
</div>
<mat-divider></mat-divider>
<div fxLayout="row" class="d-flex flex-row button-row" *ngIf="hasFilters()">
    <mat-form-field #chipList fxFlex="98%">
        <mat-chip-list>
            <mat-chip *ngIf="reportOverviewCriteriaDto.dateFrom" (removed)="chipsRemoveDateFrom()">
                <span jhiTranslate="report.date-from">Date from</span>
                <span>&nbsp;{{reportOverviewCriteriaDto.dateFrom | date: 'yyyy-MM-dd'}}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip *ngIf="reportOverviewCriteriaDto.dateTo" (removed)="chipsRemoveDateTo()">
                <span jhiTranslate="report.date-to">Date to</span>
                <span>&nbsp;{{reportOverviewCriteriaDto.dateTo | date: 'yyyy-MM-dd'}}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip color="warn" (removed)="chipsRemoveAll()">
                <span jhiTranslate="report.clear-filters">Clear filters</span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </mat-form-field>
</div>
<div class="p-3 overview-content">
    <div class="d-flex flex-row" style="margin-bottom: 1%;">
        <mat-card style="width: 33%; margin-right: 1%;">
            <mat-card-title align="center">
                <span jhiTranslate="report.overview.file-type-chart.title" class="header-font">File formats</span>
            </mat-card-title>
            <mat-card-content>
                <p-chart #documentTypeChart type="pie" [data]="fileTypeChartData" class="content-font"></p-chart>
            </mat-card-content>
        </mat-card>
        <mat-card style="width: 32%;margin-right: 1%;">
            <mat-card-title align="center">
                <span jhiTranslate="report.overview.users-chart.title" class="header-font">Users</span>
            </mat-card-title>
            <mat-card-content>
                <p-chart #usersChart type="bar" [data]="usersData" [options]="usersChartOptions" class="content-font"></p-chart>
            </mat-card-content>
        </mat-card>
        <mat-card style="width: 33%;">
            <mat-card-title align="center">
                <span jhiTranslate="report.overview.most-searched-tags-chart.title" class="header-font">Most searched documents</span>
            </mat-card-title>
            <mat-card-content>
                <p-chart #mostSearchedDocumentsChart type="bar" [data]="mostSearchedDocumentsData" [options]="mostSearchedDocumentsOptions" class="content-font"></p-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="d-flex flex-row" style="margin-bottom: 1%;">
        <mat-card style="width: 49.5%; margin-right: 1%">
            <mat-card-title align="center">
                <span jhiTranslate="report.overview.most-viewed-questions-chart.title" class="header-font">Most viewed questions</span>
            </mat-card-title>
            <mat-card-content>
                <p-chart #mostViewedQuestionsChart type="bar" [data]="mostViewedQuestionsData" [options]="mostViewedQuestionsOptions" class="content-font"></p-chart>
            </mat-card-content>
        </mat-card>

        <mat-card style="width: 49.5%;">
            <mat-card-title align="center">
                <span jhiTranslate="report.overview.most-viewed-documents-chart.title" class="header-font">Most viewed documents</span>
            </mat-card-title>
            <mat-card-content>
                <p-chart #mostViewedDocumentsChart type="bar" [data]="mostViewedDocumentsData" [options]="mostViewedDocumentsOptions" class="content-font"></p-chart>
            </mat-card-content>
        </mat-card>
    </div>
</div>
