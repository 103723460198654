import {AfterViewInit, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {QuestionResetService} from './question-reset.service';
import {AccountService} from '../../../shared/index';
import {QuestionReset, default as SecurityQuestion} from './question-reset.model';
import {INVALID_PASSWORD_TYPE, Principal} from '../../../shared';
import {SecuritySettings} from '../../security-settings/security-settings.model';

@Component({
    selector: 'jhi-question',
    templateUrl: './question-reset.component.html',
    styleUrls: ['question-reset.scss']
})
export class QuestionResetComponent implements OnInit, AfterViewInit {

    // securityQuestion: SecurityQuestion;
    securityAnswer: string;
    password: string;
    invalidPassword: string;
    error: string;
    success: string;
    questionReset: QuestionReset;
    securityQuestions: SecurityQuestion[];

    constructor(private accountService: AccountService,
                private questionResetService: QuestionResetService, private principal: Principal) {
        this.questionReset = new QuestionReset();
        this.questionResetService.getQuestions().then((questions) => {
            this.securityQuestions = questions;
            this.principal.identity().then((account) => {
                const questionText = account.securityQuestion;
                this.questionReset.securityQuestion = questions.filter((question) => question.question === questionText)[0];
                this.questionReset.securityAnswer = account.securityAnswer;
            });

        });

    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        // this.renderer.selectRootElement(this.elementRef.nativeElement.querySelector('#firstNameInput')).focus();
    }

    save() {
        this.questionResetService.save(this.questionReset).subscribe(() => {
            this.error = null;
            this.invalidPassword = null;
            this.success = 'OK';

        }, (response) => {
            this.success = null;
            this.processError(response);
        });
    }

    private processError(response) {
        this.success = null;
        if (response.status === 400 && response.error.type === INVALID_PASSWORD_TYPE) {
            this.invalidPassword = 'ERROR';
        } else {
            this.error = 'ERROR';
        }
    }
}
