import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {JhiAlertService, JhiEventManager, JhiParseLinks} from '@upside-cloud/ng-jhipster';

import {ITEMS_PER_PAGE, Principal, ProjectUser, User, UserService} from '../../shared';
import {GroupService} from '../../shared/group/group.service';
import {Group} from '../../shared/group/group.model';
import {UserCriteriaDto} from './user-criteria-dto.model';
import {ConfirmationDialogService} from '../../entities/confirmation-dialog/confirmation-dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {ReportService} from '../../report';
import {ProjectVdr} from '@admin/project-vdr';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ClipboardService} from 'ngx-clipboard';
import {InvitationDialogComponent} from '../../account';
import {GroupDialogComponent} from './group/group-dialog.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

declare var navigator: any;

@Component({
    selector: 'jhi-user-mgmt',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user.scss']

})
export class UserMgmtComponent implements OnInit, OnDestroy {

    currentAccount: any;
    usersAll: ProjectUser[];
    users: ProjectUser[];
    error: any;
    success: any;
    routeData: any;
    links: any;
    totalItems: any;
    queryCount: any;
    itemsPerPage: any;
    page: any;
    predicate: any;
    previousPage: any;
    reverse: any;
    group: Group;
    testId: any;
    currentSearch: string;
    groups: Group[];
    filteredGroup: Group[];
    selectedUser: ProjectUser;
    isSaving: boolean;
    userCriteriaDto: UserCriteriaDto;
    questionPermissionArray = [null, 'QA', 'QA_WRITE', 'QA_SHARE', 'QA_DELETE'];
    currentProject: ProjectVdr;
    selectedTab: number;
    allUsersCount: number;


    constructor(
        public clipboardService: ClipboardService,
        private userService: UserService,
        private groupService: GroupService,
        private alertService: JhiAlertService,
        private principal: Principal,
        private parseLinks: JhiParseLinks,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private eventManager: JhiEventManager,
        private confirmationDialogService: ConfirmationDialogService,
        private translateService: TranslateService,
        private reportService: ReportService,
        private snackBar: MatSnackBar,
        private dialog: NgbModal
    ) {
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.routeData = this.activatedRoute.data.subscribe((data) => {
            this.page = data['pagingParams'].page;
            this.previousPage = data['pagingParams'].page;
            this.reverse = data['pagingParams'].ascending;
            this.predicate = data['pagingParams'].predicate;

        });
    }

    ngOnInit() {
        this.currentProject = this.principal.getUserIdentity().project;
        this.selectedUser = null;
        this.principal.identity(true).then((account) => {
            this.userCriteriaDto = new UserCriteriaDto();
            this.userCriteriaDto.status = 'a';
            this.currentAccount = account;
            this.loadAll();

            this.registerChangeInUsers();
            this.registerGroupSaveModification();
        });
    }

    findFilterdGroups(isadmin: boolean) {
        if (isadmin) {
            return this.groups.filter((group) => (group.role.toString() === 'ADMIN' || group.role.toString() === 'ADMIN_LIMITED'));
        } else {
            return this.groups.filter((group) => (group.role.toString() !== 'ADMIN' && group.role.toString() !== 'ADMIN_LIMITED'));
        }
    }

    /* save() {
         this.isSaving = true;
         if (this.selectedUser.group && this.selectedUser.group.users) {
             this.selectedUser.group.users = null;
         }
         if (this.selectedUser.id !== null) {
             this.userService.update(this.selectedUser).subscribe((response) => this.onSaveSuccess(response), () => this.onSaveError());
         } else {
             this.userService.create(this.selectedUser).subscribe((response) => this.onSaveSuccess(response), () => this.onSaveError());
         }
     }*/

    isUserRowSelected(user: ProjectUser) {
        if (this.selectedUser) {
            return user.id === this.selectedUser.id;
        }
        return false;
    }

    selectGroup(group: Group) {
        this.userCriteriaDto.selectedGroupId = group ? group.id : null;
        this.criteriaChanged();
    }

    changeGroupTypeAdmin(projectUser: ProjectUser, admin: boolean) {
        projectUser.groupAdmin = admin;
    }

    selectUser(user: ProjectUser) {
        this.selectedUser = user;
        this.selectedTab = 0;
    }

    loadProjectGroups() {

        this.groupService.getProjectGroups().subscribe(
            (res: HttpResponse<Group[]>) => {
                this.groups = res.body.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
                this.currentSearch = '';
            },
            (res: HttpErrorResponse) => this.onError(res.message)
        );
    }

    clear() {
        this.currentSearch = '';
        this.loadAll();
    }

    search(query) {
        this.currentSearch = query;
        this.loadAll();
    }

    ngOnDestroy() {
        this.routeData.unsubscribe();
    }

    registerChangeInUsers() {
        this.eventManager.subscribe('userListModification', (response) => this.loadAll());
    }

    registerGroupSaveModification() {
        this.eventManager.subscribe('groupListModification', (response) => this.loadProjectGroups());

    }

    setActive(projectUser: ProjectUser, isActivated) {

        this.userService.switchActive(projectUser).subscribe(
            (response) => {
                if (response.status === 200) {
                    this.error = null;
                    this.success = 'OK';
                    projectUser.active = !projectUser.active;
                    // this.loadAll();
                } else {
                    this.success = null;
                    this.error = 'ERROR';
                }
            });
    }

    changeGroup(projectUser: ProjectUser) {
        this.userService.changeGroup(projectUser).subscribe(
            (response) => {
                if (response.status === 200) {
                    this.error = null;
                    this.success = 'OK';
                    this.selectUser(response.body);
                    this.loadAll();
                } else {
                    this.success = null;
                    this.error = 'ERROR';
                }
            });
    }

    changeSecuritySettings(projectUser: ProjectUser) {
        this.userService.changeSecuritySettings(projectUser).subscribe(
            (response) => {
                if (response.status === 200) {
                    this.error = null;
                    this.success = 'OK';

                    this.loadAll();

                } else {
                    this.success = null;
                    this.error = 'ERROR';
                }
            });
    }

    loadAll() {
        this.loadProjectGroups();
        this.loadProjectUsers();
    }

    loadProjectUsers(): void {
        this.userService.findProjectUsers().subscribe(
            (res: HttpResponse<User[]>) => this.onSuccess(res.body, res.headers),
            (res: HttpResponse<any>) => this.onError(res.body)
        );
    }

    trackIdentity(index, item: User) {
        return item.id;
    }

    getGroupCount(group: Group) {
        if (!this.usersAll || !this.groups) {
            return null;
        }
        return this.usersAll.filter((user) => {
            return user.groupId === group.id;
        }).length;
    }

    sort() {
        const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }

    loadPage(page: number) {
        if (page !== this.previousPage) {
            this.previousPage = page;
            this.transition();
        }
    }

    transition() {
        const moveSmaller = this.reverse ? 1 : -1;
        const moveLarger = this.reverse ? -1 : 1;
        if (this.users) {
            this.users.sort((a, b) => {
                if (a[this.predicate] <= b[this.predicate]) {
                    return moveSmaller;
                } else {
                    return moveLarger;
                }
            });
        }
    }

    chooseNotificationDocuments(notificationType) {
        this.selectedUser.notificationDocumentFrequency = this.selectedUser.notificationDocumentFrequency === notificationType ? null : notificationType;
        this.changeSecuritySettings(this.selectedUser);
    }

    chooseNotificationQA(notificationType) {
        this.selectedUser.notificationQa = notificationType;
        this.changeSecuritySettings(this.selectedUser);

    }

    chooseAccountStatus(accountStatus) {
        this.userCriteriaDto.status = accountStatus;
        this.criteriaChanged();
    }

    private onSuccess(data, headers) {

        this.usersAll = data.map((projectUser) => new ProjectUser(projectUser));
        this.allUsersCount = this.usersAll.length;
        this.criteriaChanged();
    }

    private delete(data, headers) {
        this.groupService.delete(this.testId).subscribe((response) => {
            this.success = 'OK';
        }, () => this.success = null);
    }

    private onError(error) {
        this.success = null;
    }

    getQAPermissionLevel(qa: string): number {
        return this.questionPermissionArray.indexOf(qa);

    }

    chooseIpRestriction(choose: boolean) {
        this.selectedUser.ipRestriction = choose;
        if (!choose) {
            this.changeSecuritySettings(this.selectedUser);
        }
    }

    chooseIp6Type(choose: boolean) {
        this.selectedUser.ip6Type = choose;
        // this.changeSecuritySettings(this.selectedUser);
    }

    isQAPermissionSelected(qa: string) {
        if (!qa) {
            return !this.selectedUser.qaPermission;
        }
        return this.getQAPermissionLevel(qa) <= this.getQAPermissionLevel(this.selectedUser.qaPermission);
    }

    isQAPermissionFunction = function isQAPermissionFunction(qa: string) {
        return this.isQAPermissionFunction(qa);
    };

    switchQAPermission(qa: string) {
        if (!qa) {
            this.selectedUser.qaPermission = null;
        }
        if (this.getQAPermissionLevel(qa) > this.getQAPermissionLevel(this.selectedUser.qaPermission)) {
            this.selectedUser.qaPermission = qa;
        } else {
            this.selectedUser.qaPermission = this.questionPermissionArray[this.getQAPermissionLevel(qa) - 1];
        }

        this.userService.changeQAPermission(this.selectedUser).subscribe(
            (response) => {
                if (response.status === 200) {
                    this.error = null;
                    this.success = 'OK';
                    // this.loadAll();
                    this.principal.identity(true);
                } else {
                    this.success = null;
                    this.error = 'ERROR';
                }
            });

    }

    criteriaChanged() {
        let users = this.usersAll;

        if (this.userCriteriaDto.selectedGroupId != null) {
            users = users.filter((user) => {
                return user.groupId === this.userCriteriaDto.selectedGroupId;
            });
        }

        if (this.userCriteriaDto.name != null) {
            users = users.filter((user) => {
                return user.userEmail.indexOf(this.userCriteriaDto.name) !== -1 || user.userFullName.toLowerCase().indexOf(this.userCriteriaDto.name.toLowerCase()) !== -1;
            });
        }

        if (this.userCriteriaDto.status != null) {
            users = users.filter((user) => {
                return (user.active && this.userCriteriaDto.status === 'a')
                    || (!user.active && this.userCriteriaDto.status === 'i');
            });
        }

        this.users = users;
    }

    criteriaNameRemove() {
        this.userCriteriaDto.name = null;
        this.criteriaChanged();
    }

    criteriaNameChanged() {
        if (!this.userCriteriaDto.name) {
            this.criteriaChanged();
        }
    }

    deleteGroup(group: Group) {
        this.confirmationDialogService.confirm({
            'title': this.translateService.instant('entity.delete.title'),
            'text': this.translateService.instant('vdrApp.group.delete.question', {name: group.name}),
            'closeLabel': this.translateService.instant('entity.action.cancel'),
            'confirmLabel': this.translateService.instant('entity.action.delete')
        }).subscribe((res) => {
            if (res) {
                this.groupService.delete(group.id.toString()).subscribe((response) => {
                    this.eventManager.broadcast({
                        name: 'userListModification',
                        content: 'Deleted a group'
                    });
                });
            }
        });
    }

    deleteUser(user: ProjectUser) {
        this.confirmationDialogService.confirm({
            'title': this.translateService.instant('entity.delete.title'),
            'text': this.translateService.instant('userManagement.delete.question', {login: user.userLogin}),
            'closeLabel': this.translateService.instant('entity.action.cancel'),
            'confirmLabel': this.translateService.instant('entity.action.delete')
        }).subscribe((res) => {
            if (res) {
                this.userService.switchActive(user).subscribe((response) => {
                    this.eventManager.broadcast({
                        name: 'userListModification',
                        content: 'Deleted a user'
                    });
                });
            }
        });
    }

    sendRegistrationEmail(email: String): void {
        this.userService.resendRegistrationEmail(email).subscribe(
            (response: HttpResponse<void>) => {
                this.alertService.success('userManagement.link.registration.send', {'email': email});
            },
            (response: HttpErrorResponse) => this.alertService.error(response.message, null, null)
        );
    }

    copyRegistrationLink(email: string): void {
        this.userService.copyRegistrationLink(email).subscribe((response: HttpResponse<any>) => {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(response.body.value)
                    .then(() =>
                            this.alertService.success('userManagement.link.registration.copy', {'email': email})
                        , () =>
                            this.alertService.error('userManagement.link.registration.error.copy')
                    ).catch;
            } else {
                this.clipboardService.copyFromContent(response.body.value);
                this.alertService.success('userManagement.link.registration.copy', {'email': email});
            }
        });

    }

    sendActivationEmail(email: String): void {
        this.userService.resendActivationEmail(email).subscribe(
            (response: HttpResponse<void>) => {
                this.alertService.success('userManagement.link.activation.send', {'email': email});
            },
            (response: HttpErrorResponse) => this.alertService.error(response.message, null, null)
        );
    }

    export() {
        this.reportService.exportUser(this.userCriteriaDto);
    }

    showInvitationDialog(): void {
        this.dialog.open(InvitationDialogComponent, {
            size: 'lg'
        }).result.then((value) => {
            this.alertService.success('invitation.messages.success');
            this.loadProjectUsers();
        });
    }

    showGroupDialog(group: Group): void {
        const ngbModalRef = this.dialog.open(GroupDialogComponent, {
        });
        ngbModalRef.componentInstance.group = group != null ? group : new Group();
        ngbModalRef.result.then(() => this.loadProjectGroups());
    }
}




