import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {JhiLanguageHelper} from '../../shared';
import {VERSION} from '../../app.constants';
import {JhiLanguageService} from '@upside-cloud/ng-jhipster';

@Component({
    selector: 'jhi-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.scss']
})
export class HelpComponent implements OnInit {

    version: string;
    contactEmail = 'support@secudo.solutions';
    private currentLanguage: string;

    constructor(
        private route: ActivatedRoute,
        private languageHelper: JhiLanguageHelper,
        private languageService: JhiLanguageService
    ) {

        this.languageHelper.getAll().then((languages) => {
            this.languageService.getCurrent().then((value) => {
                this.currentLanguage = value;
            });

        });

    }

    ngOnInit() {
        this.version = VERSION;
    }

    openContactEmail() {
        window.open('mailto:' + this.contactEmail);
    }
}
