import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from '../../app.constants';

import {Question, QuestionListElement, QuestionRequest} from './question.model';
import {createRequestOption} from '../../shared';
import {QuestionSearch} from './question-search.model';

export type EntityResponseType = HttpResponse<Question>;

@Injectable()
export class QuestionService {

    private resourceUrl = SERVER_API_URL + 'api/questions';
    private resourceSearchUrl = SERVER_API_URL + 'api/_search/questions';

    constructor(private http: HttpClient) {
    }

    create(question: QuestionRequest, attachments: any[]): Observable<EntityResponseType> {
        const formData: FormData = new FormData();

        formData.append('questionRequest', new Blob(
            [JSON.stringify(question)], {
            type: 'application/json'
        }));

        for (const attachment of attachments) {
            formData.append('attachments', attachment);
        }

        return this.http.post(this.resourceUrl, formData, {
            observe: 'response'
        }).pipe(
            map((res: EntityResponseType) => this.convertResponse(res)));
    }

    update(question: QuestionRequest): Observable<EntityResponseType> {
        const copy = this.convert(question);
        return this.http.put<Question>(this.resourceUrl, copy, {observe: 'response'}).pipe(
            map((res: EntityResponseType) => this.convertResponse(res)));
    }

    markAsResolved(questionId: number): Observable<EntityResponseType> {
        return this.http.post<Question>(`${this.resourceUrl}/${questionId}/resolve`, null, {observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    changeQuestionPriority(questionId: number, newPriority: string): Observable<EntityResponseType> {
        return this.http.post<Question>(`${this.resourceUrl}/${questionId}/priority/${newPriority}`, null, {observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    changeQuestionCategories(questionId: number, categories: number[]): Observable<EntityResponseType> {
        return this.http.post<Question>(`${this.resourceUrl}/${questionId}/categories`, categories, {observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get<Question>(`${this.resourceUrl}/${id}`, {observe: 'response'}).pipe(
            map((res: EntityResponseType) => this.convertResponse(res)));
    }

    query(req?: any): Observable<HttpResponse<QuestionListElement[]>> {
        const options = createRequestOption(req);
        return this.http.get<QuestionListElement[]>(this.resourceUrl, {params: options, observe: 'response'}).pipe(
            map((res: HttpResponse<QuestionListElement[]>) => this.convertArrayResponse(res)));
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, {observe: 'response'});
    }

    restore(id: number): Observable<HttpResponse<any>> {
        return this.http.post<any>(`${this.resourceUrl}/restore/${id}`, {observe: 'response'});
    }

    search(req: QuestionSearch): Observable<HttpResponse<QuestionListElement[]>> {
        return this.http.post<QuestionListElement[]>(this.resourceSearchUrl, req, { observe: 'response'})
            .pipe(map((res: HttpResponse<QuestionListElement[]>) => this.convertArrayResponse(res)));
    }

     getLastQuestions(): Observable<QuestionListElement[]> {
            return this.http.get<QuestionListElement[]>(SERVER_API_URL + 'api/home/questions', { observe: 'response'})
                .pipe(map((res: HttpResponse<QuestionListElement[]>) => this.convertArrayResponse(res).body));
        }


    findAllByDocument(documentId: number): Observable<QuestionListElement[]> {
        const params = new HttpParams().set('documentId', documentId.toString());
        return this.http.get<QuestionListElement[]>(this.resourceUrl + '/document', {params});
    }

    getQuestionLimit(): Observable<any> {
        return this.http.get(this.resourceUrl + '/limit', {observe: 'response'});
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: Question = this.convertItemFromServer(res.body);
        return res.clone({body});
    }

    private convertArrayResponse(res: HttpResponse<QuestionListElement[]>): HttpResponse<QuestionListElement[]> {
        const jsonResponse: QuestionListElement[] = res.body;
        const body: QuestionListElement[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertListItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

    /**
     * Convert a returned JSON object to Question.
     */
    private convertItemFromServer(question: Question): Question {
        return new Question(
            question.number,
            question.id,
            question.title,
            question.content,
            question.status,
            question.priority,
            question.authorName,
            question.authorId,
            question.recipients,
            question.document,
            question.document !== null ? question.document.name : '',
        question.document !== null ? question.document.id : null,
            question.createdDate,
            question.answersCount,
            question.answers,
            question.attachments,
            question.avatarBytes,
            question.categories,
            question.active
        );
    }

    private convertListItemFromServer(question: QuestionListElement): QuestionListElement {
        return new QuestionListElement(
            question.number,
            question.id,
            question.title,
            question.status,
            question.priority,
            question.authorId,
            question.authorName,
            question.documentName,
            question.createdDate,
            question.answersCount,
            question.avatarBytes,
            question.active
        );
    }

    /**
     * Convert a Question to a JSON which can be sent to the server.
     */
    private convert(question: QuestionRequest): QuestionRequest {
        return new QuestionRequest(
            question.title,
            question.priority,
            question.content,
            question.documentId,
            question.id,
            question.recipients
        );
    }
}
