import {CustomSpan} from '@jufab/opentelemetry-angular-interceptor';
import {Span} from '@opentelemetry/api';
import {HttpErrorResponse, HttpRequest, HttpResponse} from '@angular/common/http';
import {Principal} from '../auth/principal.service';
import {Account} from '../user/account.model';
import {Injectable} from '@angular/core';

@Injectable()
export class UserSpan implements CustomSpan {
    private user: Account;


    constructor(private principal: Principal) {
        principal.getAuthenticationState().subscribe((user) => {
            this.user = user;
        });
    }

    add(span: Span, request: HttpRequest<unknown>, response: HttpResponse<unknown> | HttpErrorResponse): Span {
        if (this.user) {
            span.setAttribute('user_login', this.user.login);
            // span.setAttribute('project', this.principal.getCurrentProject()?.id);
        }

        return span;
    }
}
