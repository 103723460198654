<div class="directory-tree-permission" fxLayout="column">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="permissions-tree">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle [ngStyle]="{'margin-left': getMarginFlat(node.level), 'margin-right': '5px'}"   fxLayout="column" fxLayoutAlign="start center">
            <div >
                <button mat-button (click)="action(node)" style="text-align: start" [ngClass]="{ 'background-highlight': activeNode?.id === node.id }"><i class="fa fa-folder "  style="font-size: 18px"></i><span class="directory-tree-text">{{node.name}}</span> </button>
            </div>
            <div *ngIf="node.documents?.length > 0 && activeDirectory === node">
                <div *ngFor="let document of node.documents" [ngStyle]="{'margin-left': getMarginFile(), 'margin-right': '5px'}" [ngClass]="{ 'background-highlight': activeNode?.id === document.id }">
                    <button mat-button (click)="actionFile(document)" matTooltip="{{document.name}}" matTooltipPosition="below"><i  class="{{getIcon(document.name)}}" style="font-size: 18px"></i>{{document.name | listDocumentName: false: 39}}</button>
                </div>
            </div>

        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node;when: hasChild"   [ngStyle]="{'margin-left': getMargin(node.level), 'margin-right': '5px'}"  fxLayout="column" fxLayoutAlign="start start">
            <div>
                <button mat-icon-button matTreeNodeToggle
                        [attr.aria-label]="'toggle ' + node.name">
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                </button>
                <button mat-button (click)="action(node)" style="text-align: start" [ngClass]="{ 'background-highlight': activeNode?.id === node.id }"><i [class]="treeControl.isExpanded(node) ? 'fa fa-folder-open' :  'fa fa-folder' "  style="font-size: 18px"></i><span class="directory-tree-text">{{node.name}}</span> </button>

            </div>
            <div *ngIf="node.documents?.length > 0 && treeControl.isExpanded(node)">
                <div *ngFor="let document of node.documents" [ngStyle]="{'margin-left': getMarginFileFl(), 'margin-right': '5px'}" [ngClass]="{ 'background-highlight': activeNode?.id === document.id }">
                    <button  mat-button (click)="actionFile(document)" matTooltip="{{document.name}}" matTooltipPosition="below"> <i  class="{{getIcon(document.name)}}" style="font-size: 18px"></i> {{document.name | listDocumentName: false: 39}}</button>
                </div>
            </div>
        </mat-tree-node>
    </mat-tree>
</div>


