import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {JhiAlertService} from '@upside-cloud/ng-jhipster';
import {Group, GroupService, Role} from '../../../shared';
import {HttpResponse} from '@angular/common/http';
import {SelectedItemDto} from './directory-document-tree.component';
import {SelectionModel} from '@angular/cdk/collections';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'group-list',
    templateUrl: 'group-list.component.html',
    styleUrls: ['../directory-document.scss'],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'pl-PL'}
    ]
})
export class GroupListComponent implements OnInit {

    @Input() selectedObject: SelectedItemDto;
    groups: Group[] = [];
    displayedColumns: string[];
    dataSource = new MatTableDataSource<Group>();
    selection = new SelectionModel<Group>(false, []);

    @Output() groupId = new EventEmitter<GroupObject>();

    constructor(
        private jhiAlertService: JhiAlertService,
        private groupService: GroupService,
        private spinnerService: NgxSpinnerService
    ) {
        this.displayedColumns = ['select', 'name', 'role'];
    }

    ngOnInit(): void {
        this.loadProjectGroups();
    }

    loadProjectGroups() {
        this.spinnerService.show();
        this.groupService.getProjectGroups().subscribe(
            (res: HttpResponse<Group[]>) => {
                this.groups = res.body;
                this.dataSource.data = this.groups;
                this.selection.toggle(this.groups[0]);
                this.onSelectionLogic(true, this.groups[0]);
                this.spinnerService.hide();
            }
        );

    }

    onSelection(event, row: Group) {
        this.onSelectionLogic(event.checked, row);
    }

    private onSelectionLogic(check: boolean, row: Group) {
        if (check) {
            this.groupId.emit(new GroupObject(row.id, row.name, row.role));
        } else {
            this.groupId.emit(null);
        }


    }
}

export class GroupObject {
    constructor(
        public groupId?: number,
        public groupName?: string,
        public role?: Role
    ) {
    }
}
