import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {SERVER_API_URL} from '../../app.constants';
import {User} from './user.model';
import {createRequestOption} from '../model/request-util';
import {ProjectUser} from './projectUser.model';
import {getFileNameFromResponseContentDisposition, saveFile} from '../../report';
import {UserCriteriaDto} from '../../admin';

@Injectable({providedIn: 'root'})
export class UserService {
    private resourceUrl = SERVER_API_URL + 'api/users';
    private resourceSearchUrl = SERVER_API_URL + 'api/_search/users';
    private projectUsersResourceUrl = SERVER_API_URL + 'api/project-users';

    constructor(private http: HttpClient) {
    }

    create(user: User): Observable<HttpResponse<User>> {
        return this.http.post<User>(this.resourceUrl, user, {observe: 'response'});
    }

    update(user: User): Observable<HttpResponse<User>> {
        return this.http.put<User>(this.resourceUrl, user, {observe: 'response'});
    }

    find(id: number): Observable<HttpResponse<ProjectUser>> {
        return this.http.get<ProjectUser>(`${this.projectUsersResourceUrl}/${id}`, {observe: 'response'});
    }

        findAdminProjects(): Observable<NameDto[]> {
            return this.http.get<NameDto[]>(`${SERVER_API_URL}/api/admin/projects`, {observe: 'body'});
        }


    findProjectUsers(): Observable<HttpResponse<ProjectUser[]>> {
        return this.http.get<ProjectUser[]>(this.projectUsersResourceUrl + '/current-project', {
            observe: 'response'
        }).pipe(map((res: HttpResponse<ProjectUser[]>) => this.convertArrayResponse(res)));
    }

        findAdminProjectUsers(name: string, projectId: number): Observable<HttpResponse<ProjectUser[]>> {
            return this.http.get<ProjectUser[]>(SERVER_API_URL + `api/admin/users?name=${name??''}&projectId=${projectId??''}` , {
                observe: 'response'
            }).pipe(map((res: HttpResponse<ProjectUser[]>) => this.convertArrayResponse(res)));
        }

    switchActive(projectUser: ProjectUser, skipAlert = false): Observable<HttpResponse<ProjectUser>> {
        return this.http.put<ProjectUser>(this.projectUsersResourceUrl + '/active?skipAlert='+skipAlert, projectUser, {
            observe: 'response'
        });
    }


    changeGroup(projectUser: ProjectUser): Observable<HttpResponse<ProjectUser>> {
        return this.http.put<ProjectUser>(this.projectUsersResourceUrl + '/changeGroup', projectUser, {
            observe: 'response'
        });
    }


    changeSecuritySettings(projectUser: ProjectUser): Observable<HttpResponse<ProjectUser>> {
        return this.http.put<ProjectUser>(this.projectUsersResourceUrl + '/changeSecuritySettings', projectUser, {
            observe: 'response'
        });
    }


    query(req?: any): Observable<HttpResponse<ProjectUser[]>> {
        const options = createRequestOption(req);
        return this.http.get<ProjectUser[]>(this.resourceUrl, {
            params: options,
            observe: 'response'
        }).pipe(map((res: HttpResponse<ProjectUser[]>) => this.convertArrayResponse(res)));
    }


    authorities(): Observable<string[]> {
        return this.http.get<string[]>(SERVER_API_URL + 'api/users/authorities');
    }

    private convertArrayResponse(res: HttpResponse<ProjectUser[]>): HttpResponse<ProjectUser[]> {
        const jsonResponse: ProjectUser[] = res.body;
        const body: ProjectUser[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

    private convertItemFromServer(user: ProjectUser): ProjectUser {
        user.activated = true;
        //        user.fullName = user.lastName + ' ' + user.firstName;
        return Object.assign({}, user);
    }

    changeQAPermission(projectUser: ProjectUser) {
        return this.http.put<ProjectUser>(this.projectUsersResourceUrl + '/changeQAPermission', projectUser, {
            observe: 'response'
        });

    }

    changeTaskPermission(projectUser: ProjectUser) {
        return this.http.put<ProjectUser>(this.projectUsersResourceUrl + '/changeTaskPermission', projectUser, {
            observe: 'response'
        });
    }

    exportUserByUserCriteria(userCriteria: UserCriteriaDto) {
        this.http.post<Blob>(
            SERVER_API_URL + 'api/_export/users',
            userCriteria,
            {responseType: 'blob' as 'json', observe: 'response'}
        ).subscribe((res) => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
        });
    }

    resendRegistrationEmail(email: String): Observable<HttpResponse<void>> {
        return this.http.post<void>(SERVER_API_URL + '/api/invitation/resend', email, {observe: 'response'});
    }

    copyRegistrationLink(email: string): Observable<HttpResponse<String>> {
        return this.http.post<String>(SERVER_API_URL + '/api/invitation/link/', email, {observe: 'response'});
    }

    resendActivationEmail(email: String): Observable<HttpResponse<void>> {
        return this.http.post<void>(SERVER_API_URL + '/api/activation/resend', email, {observe: 'response'});
    }

}


export class NameDto {
constructor (public id: number, public name: string) {}

}
