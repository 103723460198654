import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {ClientService} from './client.service';
import {ClientChoiceDialog} from './client-choice-dialog';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgJhipsterModule} from '@upside-cloud/ng-jhipster';
import {VdrSharedModule} from '../../shared';
import {ClientsDialog} from './clients-dialog';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgJhipsterModule,
        VdrSharedModule
    ],
    declarations: [
        ClientChoiceDialog,
        ClientsDialog
    ],
    providers: [
        ClientService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ClientModule {
}
