import {DocumentRowVdr} from '../../document-vdr/document-row-vdr.model';
import {DirectoryVdr} from '../../directory-vdr';
import {Moment} from 'moment';

export class DirectoryDocumentRowDto {
    public id?: number;
    public name?: string;
    public type?: string;
    public number?: number;
    public createdDate?: string;
    public favourite?: boolean;
    public new?: boolean;
    public status?: string;
    public pdfSupport?: boolean;
    public documentVersion?: number;
    public effectivePermissions = [];
    public parentDirectoryId?: number;
    public qaIndex?: string;
    public pdfCreatedTimestamp?: Moment;
    public fileIndexedTimestamp: Moment;
    public originalName?: string;
    public annotated?: boolean;

    constructor(document?: DocumentRowVdr, directory?: DirectoryVdr) {
        if (document) {
            this.id = document.id;
            this.name = document.name;
            this.type = document.type;
            this.number = document.number;
            this.createdDate = document.createdDate;
            this.favourite = document.favourite;
            this.new = document.newDocument;
            this.status = document.status;
            this.pdfSupport = document.pdfSupport;
            this.documentVersion = document.documentVersion;
            this.effectivePermissions = document.effectivePermissions;
            this.qaIndex = document.qaIndex;
            this.fileIndexedTimestamp = document.fileIndexedTimestamp;
            this.pdfCreatedTimestamp = document.pdfCreatedTimestamp;
            this.originalName = document.originalName;
            this.annotated = document.annotated;

        } else if (directory) {
            this.id = directory.id;
            this.name = directory.name;
            this.type = 'directory';
            this.number = directory.number;
            this.new = false;
            this.favourite = false;
            this.createdDate = directory.createdDate;
            this.status = 'NORMAL';
            this.pdfSupport = false;
            this.documentVersion = 1;
            this.parentDirectoryId = directory.parentDirectoryId;
            this.qaIndex = '';
            this.annotated = false;
        }

    }

    public toString = (): string => {
        return JSON.stringify(this);
    }


}
