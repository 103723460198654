<div>
  <ng-content *ngTemplateOutlet="customSidebar ? customSidebar : defaultSidebar"></ng-content>
</div>

<ng-template #defaultSidebar>
  <div id="sidebarContainer" [style.top]="sidebarPositionTop">
    <div id="additionalSidebarContainer" [style.display]="hideSidebarToolbar ? 'none' : ''">
      <pdf-sidebar-toolbar [mobileFriendlyZoomScale]="mobileFriendlyZoomScale"></pdf-sidebar-toolbar>
    </div>
    <pdf-sidebar-content
      [customThumbnail]="customThumbnail"
      (thumbnailDrawn)="thumbnailDrawn.emit($event)"
      [hideSidebarToolbar]="hideSidebarToolbar"
      [mobileFriendlyZoomScale]="mobileFriendlyZoomScale"
    ></pdf-sidebar-content>
    <div id="sidebarResizer" class="hidden"></div>
  </div>
</ng-template>
