import {Route} from '@angular/router';

import {UserRouteAccessService} from '../../shared/index';
import {SecuritySettingsComponent} from './security-settings.component';

export const securitySettingsRoute: Route = {
    path: 'security-settings',
    component: SecuritySettingsComponent,
    data: {
        pageTitle: 'security.title'
    },
};
