<h4 class="text-center mt-4" *ngIf="!list.length">Brak plików w projekcie</h4>
<div class="table-responsive user-table" *ngIf="list.length">
    <div class="d-flex justify-content-between mt-4 pl-3 mr-3">
        <div>
            <div class="btn-group flex-btn-group-container">
                <button type="button"
                        class="btn btn-light btn-user-status"
                        mat-raised-button
                        (click)="criteria.index=false; filter();"
                        [ngClass]="{'btn-primary': !criteria.index}">
                            <span class="d-none d-md-inline"
                            >PDF</span>
                </button>
                <button type="button"
                        class="btn btn-light btn-user-status"
                        mat-raised-button
                        (click)="criteria.index=true; filter();"
                        [ngClass]="{'btn-primary': criteria.index}">
                            <span class="d-none d-md-inline"
                                  >Index</span>
                </button>

            </div>
            <div class="btn-group flex-btn-group-container ml-4">
                <button type="button"
                        class="btn btn-light btn-user-status"
                        mat-raised-button
                        (click)="criteria.active=false; filter();"
                        [ngClass]="{'btn-primary': !criteria.active}">
                            <span class="d-none d-md-inline"
                            >Wszystkie</span>
                </button>
                <button type="button"
                        class="btn btn-light btn-user-status"
                        mat-raised-button
                        (click)="criteria.active=true; filter();"
                        [ngClass]="{'btn-primary': criteria.active}">
                            <span class="d-none d-md-inline"
                                 >Aktywne</span>
                </button>

            </div>
        </div>
        <mat-progress-spinner class="float-right" [value]="progressbarValue" [diameter]="70"></mat-progress-spinner>

    </div>

    <table class="table">
        <thead>
        <tr>
            <th>
                <span>Status</span>
            </th>

            <th>
                <span>Start</span>

            </th>
            <th>
                <span>Czas</span>
            </th>
            <th>
                <span>Użytkownik</span>
            </th>
            <th>
                <span>Postęp</span>
            </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of filteredList" class="group-item">
            <td class="report-status">
                <div class="login-status"><span class="logged-out"
                                                [ngClass]="{'logged-in': item.countCompleted===item.countAll}">●</span>
                </div>
            </td>
            <td style="width:15%">{{item.startDate | date:'dd.MM.yyyy HH:mm'}}</td>
            <td style="width:15%">{{item.durationString}}</td>
            <td style="width:15%">{{item.createdBy}}</td>
            <td style="min-width:30%">
                <div class="d-flex flex-column">
                    <div>{{item.countCompleted}}/{{item.countAll}}</div>
                    <mat-progress-bar style="min-width: 300px; min-height: 12px"
                                      [value]="100*item.countCompleted/item.countAll"></mat-progress-bar>
                </div>
            </td>
        </tr>
        </tbody>
    </table>
</div>
