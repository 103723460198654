<pdf-shy-button
  [cssClass]="show | responsiveCSSClass : 'always-in-secondary-menu'"
  title="Use Page Scrolling"
  primaryToolbarId="scrollPage"
  [toggled]="scrollMode == 3"
  l10nId="scroll_page"
  [action]="onClick"
  l10nLabel="scroll_page_label"
  [order]="3000"
  [closeOnClick]="false"
  image="<svg style='width: 24px; height: 24px' viewBox='0 0 24 24'><path fill='currentColor' d='M10,7V9H12V17H14V7H10Z' /></svg>"
>
</pdf-shy-button>
