import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Group} from '../shared';
import {GroupService} from '../shared';

@Component({
    selector: 'jhi-group-filter',
    templateUrl: './group-filter.html',
    styleUrls: ['./report.scss']
})
export class GroupFilter implements OnInit {

    @Input()
    userGroups: Group[];

    @Input()
    reportType: string;


    selectedUserGroup: any[];

    @Output() select = new EventEmitter();

    constructor(
        private userGroupService: GroupService,
    ) {
    }

    loadAllGroups() {
        this.userGroupService.getProjectGroups(false, true).subscribe(
            (res: HttpResponse<Group[]>) => {
                this.userGroups = res.body;
                this.selectedUserGroup = Object.assign([], this.userGroups);
                this.changeGroup();
            },
            (res: HttpErrorResponse) => this.onError(res.message)
        );
    }

    selectGroup(group) {
        if (this.selectedUserGroup.includes(group)) {
            this.selectedUserGroup.splice(this.selectedUserGroup.indexOf(group), 1);
        } else {
            this.selectedUserGroup.push(group);
        }
        this.changeGroup();
    }

    changeGroup() {
        this.select.emit(this.selectedUserGroup);
    }

    isElementSelected(group) {
        return this.selectedUserGroup && this.selectedUserGroup.includes(group);
    }

    private onError(error) {
    }

    ngOnInit() {

        this.loadAllGroups();

    }

}
