<div class="project-card pl-2 pr-2">

    <jhi-alert-error></jhi-alert-error>
    <jhi-alert></jhi-alert>
    <div class="pt-2 pl-3" fxLayout="row" fxLayoutGap="0.5rem" fxLayoutAlign="start">
        <div class="search-input" fxLayout="row">
            <div fxLayout="column">
                <input name="search-name"
                       type="text"
                       placeholder="Nazwa projektu"
                       [(ngModel)]="searchText"
                       (ngModelChange)="searchProject()"/>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div fxLayout="column">
                <button
                    mat-icon-button
                    type="submit"
                    style="margin: 0;"
                    [disabled]="!searchText">
                    <mat-icon>search</mat-icon>
                </button>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div fxLayout="column">
                <button
                    mat-icon-button
                    type="button"
                    (click)="clearSearchText()"
                    style="margin: 0"
                    [disabled]="!searchText">
                    <mat-icon>clear</mat-icon>
                </button>
            </div>
        </div>
        <div fxLayout="row" fxLayoutGap="0.5rem" class="button-panel" *ngIf="currentAccount?.admin===true">
            <button class="btn btn-primary"
                    [routerLink]="['/', { outlets: { popup: ['project-vdr-new'] } }]">
                <span class="fa fa-plus"></span>
                <span class="hidden-sm-down ml-2" jhiTranslate="vdrApp.project.home.createLabel">
            Create new Project
            </span>
            </button>
            <button class="btn btn-primary"
                    [routerLink]="['/', { outlets: { popup: ['report-sms'] } }]">
                <span class="fa fa-eye"></span>
                <span class="hidden-sm-down ml-2" jhiTranslate="vdrApp.project.home.report-sms">
            Report of sent SMS
            </span>
            </button>
            <button class="btn btn-primary"
                    (click)="openUploadedReport()">
                <span class="fa fa-eye"></span>
                <span class="hidden-sm-down ml-2">
                    Wgrane dane
                </span>
            </button>

            <button class="btn btn-primary"
                    (click)="openClientsDialog()">
                <span class="fa fa-edit"></span>
                <span class="hidden-sm-down ml-2">
                    Klienci
                </span>
            </button>

        </div>

    </div>
    <br/>
    <div class="table-responsive mt-2 projects-table" *ngIf="filterProjects">
        <table class="table" aria-rowspan="">
            <thead>
            <tr jhiSort [(predicate)]="predicate" [(ascending)]="reverse" [callback]="transition.bind(this)">
                <th><span jhiTranslate="vdrApp.project.logo">Logo</span> <span
                ></span></th>
                <th jhiSortBy="name"><span jhiTranslate="vdrApp.project.name">Name</span> <span
                    class="fa fa-sort"></span></th>
                <th jhiSortBy="admin"><span jhiTranslate="vdrApp.project.admin">Admin</span> <span
                    class="fa fa-sort"></span></th>
                <th jhiSortBy="dealNo"><span jhiTranslate="vdrApp.project.dealNo">Numer umowy</span> <span
                    class="fa fa-sort"></span></th>
                <th jhiSortBy="dealNo"><span jhiTranslate="vdrApp.project.client">Klient</span> <span
                    class="fa fa-sort"></span></th>
                <th></th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let project of filterProjects ;trackBy: trackId"
                class="selection-available">
                <td (click)="changeProject(project)">
                    <img [src]="project?.getProjectImageUrl() | safe: 'resourceUrl'"
                            style="max-width: 120px;max-height:30px;" *ngIf="project?.bytesContentType != null"/>

                    <img src="favicon2.png"
                         style="max-width: 120px;max-height:30px;" *ngIf="project?.bytesContentType == null" />
                </td>
                <td (click)="changeProject(project)"><div [innerHTML]="project.name | highlight : searchText"></div></td>
                <td (click)="changeProject(project)"><div [innerHTML]="project.email | highlight : searchText"></div></td>
                <td (click)="changeProject(project)">
                    <div [innerHTML]="project.dealNo | highlight : searchText"></div>
                </td>
                <td (click)="changeProject(project)">
                    <div [innerHTML]="project.client.name | highlight : searchText"></div>
                </td>
                <td>
                    <button type="submit" *ngIf="currentAccount?.admin===true"
                            class="btn btn-primary btn-sm float-right"
                            (click)="openClientChoice(project)">
                        <span class="fa fa-edit"></span>

                    </button>
                </td>
                <td>
                    <!-- [routerLink]="['/', { outlets: { popup: 'project-vdr/'+ project.id + '/delete'} }]"-->
                    <button type="submit" *ngIf="currentAccount?.admin===true"
                            (click)="openDeleteProjectDialog(project)"
                            replaceUrl="true"
                            queryParamsHandling="merge"
                            class="btn btn-primary btn-sm float-right">
                        <span class="fa fa-trash"></span>

                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <!--
        <div *ngIf="projects && projects.length">
            <div class="row justify-content-center">
                <jhi-item-count [page]="page" [total]="queryCount" [maxSize]="5" [itemsPerPage]="itemsPerPage"></jhi-item-count>
            </div>
            <div class="row justify-content-center">
                <ngb-pagination [collectionSize]="totalItems" [(page)]="page" [pageSize]="itemsPerPage" [maxSize]="5" [rotate]="true" [boundaryLinks]="true" (pageChange)="loadPage(page)"></ngb-pagination>
            </div>
        </div>
    -->
</div>
