import {AfterViewInit, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FocusMonitor} from '@angular/cdk/a11y';

export interface UploadDialogData {
    directoryId: number;
    documentId?: number;
}

@Component({
    selector: 'upload-dialog',
    templateUrl: 'upload.component.html',
    styleUrls: ['directory-document.scss']
})
export class UploadDialogComponent implements  AfterViewInit{

    id: number;
    documentId: number;

    constructor(
        public dialogRef: MatDialogRef<UploadDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UploadDialogData,
        private _focusMonitor: FocusMonitor,
    ) {
        this.id = this.data.directoryId;
        this.documentId = this.data.documentId;
    }

    ngAfterViewInit(): void {
        this._focusMonitor.stopMonitoring(document.getElementById('clear'));
    }



    close(): void {
        this.dialogRef.close();
    }

    onUploadCompleted(event) {
        if (event) {
            this.dialogRef.close(event);
        }
    }



}

