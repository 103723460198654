import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {VerificationCodeComponent} from './verification-code.component';
import {ProjectVdr} from '@admin/project-vdr';

@Injectable()
export class VerificationCodeModalService {
    private isOpen = false;

    constructor(private modalService: NgbModal) {
    }

    open(project: ProjectVdr, messageType: string): NgbModalRef {
        if (this.isOpen) {
            return;
        }
        this.isOpen = true;
        const modalRef = this.modalService.open(VerificationCodeComponent, {
            backdrop: 'static',
            keyboard: false,
            backdropClass: 'login-backdrop',
            windowClass: 'login-modal',
            centered: true
        });

        modalRef.componentInstance.messageType = messageType;
        modalRef.componentInstance.project = project;

        modalRef.result.then((result) => {
            this.isOpen = false;
        }, (reason) => {
            this.isOpen = false;
        });
        return modalRef;
    }
}
