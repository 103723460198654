<div>
    <div fxLayout="column" fxLayoutAlign="center start"  style="margin-left: 1rem; margin-right: 1rem">
        <div style="min-width: 100%">
            <div fxLayout="row" style="padding-top: 9px">
                <span class="dir-name" >{{'permission.details.to' | translate}} {{selectedGroup?.groupName}}</span>
            </div>
            <mat-divider></mat-divider>
        </div>
        <div  style="min-width: 100%" class="slide-toggles">
            <div  fxLayout="row" style="padding-bottom: 6px !important; padding-top: 6px !important;">
                <div fxFlex="80"><span jhiTranslate="permission.details.permission"></span></div>
                <div fxFlex><span jhiTranslate="permission.details.state"></span></div>
            </div>
            <mat-divider></mat-divider>
            <div  fxLayout="row" style="padding-bottom: 6px !important; padding-top: 6px !important;">
                <div fxFlex="80"><span jhiTranslate="permission.enum.700"></span></div>
                <div fxFlex>
                    <mat-slide-toggle
                        [checked]="isPermissionActive(700)" (change)="onToggle($event ,700)" [disabled]="disabled()">
                    </mat-slide-toggle>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div  fxLayout="row" style="padding-bottom: 6px !important; padding-top: 6px !important;">
                <div fxFlex="80"><span jhiTranslate="permission.enum.600">DOWNLOAD_ORIGINAL</span></div>
                <div fxFlex>
                    <mat-slide-toggle
                        [checked]="isPermissionActive(600)" (change)="onToggle($event ,600)"  [disabled]="disabled()">
                    </mat-slide-toggle>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div  fxLayout="row" style="padding-bottom: 6px !important; padding-top: 6px !important;">
                <div fxFlex="80"><span jhiTranslate="permission.enum.500">DOWNLOAD_PDF</span></div>
                <div fxFlex>
                    <mat-slide-toggle
                        [checked]="isPermissionActive(500)" (change)="onToggle($event ,500)"  [disabled]="disabled()">
                    </mat-slide-toggle>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div  fxLayout="row" style="padding-bottom: 6px !important; padding-top: 6px !important;">
                <div fxFlex="80"><span jhiTranslate="permission.enum.400">PRINT</span></div>
                <div fxFlex>
                    <mat-slide-toggle
                        [checked]="isPermissionActive(400)" (change)="onToggle($event ,400)"  [disabled]="disabled()">
                    </mat-slide-toggle>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div  fxLayout="row" style="padding-bottom: 6px !important; padding-top: 6px !important;">
                <div fxFlex="80"><span jhiTranslate="permission.enum.300">DOWNLOAD_ENCRYPTED</span></div>
                <div fxFlex>
                    <mat-slide-toggle
                        [checked]="isPermissionActive(300)" (change)="onToggle($event ,300)"  [disabled]="disabled()">
                    </mat-slide-toggle>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div  fxLayout="row" style="padding-bottom: 6px !important; padding-top: 6px !important;">
                <div fxFlex="80"><span jhiTranslate="permission.enum.200">VIEW</span></div>
                <div fxFlex>
                    <mat-slide-toggle
                        [checked]="isPermissionActive(200)" (change)="onToggle($event ,200)"  [disabled]="disabled()">
                    </mat-slide-toggle>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div  fxLayout="row" style="padding-bottom: 6px !important; padding-top: 6px !important;">
                <div fxFlex="80"><span jhiTranslate="permission.enum.100">FENCE_VIEW</span></div>
                <div fxFlex>
                    <mat-slide-toggle
                        [checked]="isPermissionActive(100)" (change)="onToggle($event ,100)"  [disabled]="disabled()">
                    </mat-slide-toggle>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div *ngIf="false">
                <div  fxLayout="row" style="padding-bottom: 6px !important; padding-top: 6px !important;">
                    <div fxFlex="80"><span jhiTranslate="permission.enum.0">NONE</span></div>
                    <div fxFlex>
                        <mat-slide-toggle
                            [checked]="isPermissionActive(0)" (change)="onToggle($event ,0)"   [disabled]="disabled()">
                        </mat-slide-toggle>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
</div>
