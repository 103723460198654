<div id="errorWrapper" hidden="true">
  <div id="errorMessageLeft">
    <span id="errorMessage"></span>
    <button type="button" id="errorShowMore">More Information</button>
    <button type="button" id="errorShowLess" hidden="true">Less Information</button>
  </div>
  <div id="errorMessageRight">
    <button type="button" id="errorClose">Close</button>
  </div>
  <div class="clearBoth"></div>
  <textarea id="errorMoreInfo" hidden="true" readonly="readonly"></textarea>
</div>
