<pdf-shy-button
  title="Document Properties…"
  primaryToolbarId="documentProperties"
  [cssClass]="show | responsiveCSSClass : 'always-in-secondary-menu'"
  l10nId="document_properties"
  l10nLabel="document_properties_label"
  [order]="5000"
  eventBusName="documentproperties"
  [closeOnClick]="true"
  image="<svg style='width: 16px; height: 16px; margin-top: 3px' viewBox='0 0 16 16'><path fill='currentColor' d='M8 16a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8zM8 2a6 6 0 1 0 6 6 6.006 6.006 0 0 0-6-6z' /><path fill='currentColor' d='M8 7a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0V8a1 1 0 0 0-1-1z' /><circle fill='currentColor' cx='8' cy='5' r='1.188' /></svg>"
>
</pdf-shy-button>
