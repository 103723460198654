import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'listIndex'})
export class ListIndexPipe implements PipeTransform {

    transform(index: string, parentIndex: string, parentDir: boolean, toolTip: boolean): string {
        let result = '';
        const resultLength = 10;
        // nie wyswietlamy indesku do momentu wyliczenia jego wartosci
        if (<number><any>index !== 0) {
            if (parentDir) {
                result = parentIndex;
            } else {
                if (parentIndex !== '') {
                    result = result + parentIndex + '.';
                }
                result = result + index;
            }
            if (!toolTip && result.length > resultLength) {
                result = result.substr(0, (resultLength - '...'.length));
                result = result + '...';
            }
        }

        return result;
    }
}
