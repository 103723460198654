import { BaseEntity } from './../../shared';

export const enum DocumentNoteTypeEnum {
    'PRIVATE',
    'PUBLIC'
}

export class DocumentNoteVdr implements BaseEntity {
    constructor(
        public id?: number,
        public noteType?: DocumentNoteTypeEnum,
        public note?: string,
        public documentId?: number,
        public userId?: number,
        public mainVersionId?: number,
        public active?: string,
        public createdDate?: string,
        public lastModifiedDate?: string,
        public activeFrom?: string,
        public activeTo?: string,
    ) {
    }
}
