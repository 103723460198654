import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface PdfPasswordProtectionDialogData {
    pdfPassword: string;
    archiveMode: boolean;
    onPasswordCopy?: () => void;
}

@Component({
    templateUrl: 'pdf-password-protection-dialog.html',
    styleUrls: ['pdf-password-protection-dialog.scss']
})
export class PdfPasswordProtectionDialog {

    public copiedToClipboard: boolean;

    constructor(
        public dialogRef: MatDialogRef<PdfPasswordProtectionDialog>,
        @Inject(MAT_DIALOG_DATA) public data: PdfPasswordProtectionDialogData) {
    }

    close(): void {
        this.dialogRef.close();
    }

    copyPasswordToClipboard(): void {

        if (!this.copiedToClipboard && this.data.onPasswordCopy) {
            this.data.onPasswordCopy();
        }

        this.copiedToClipboard = true;
    }
}
