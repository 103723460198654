import { BaseEntity } from './../../shared';
import {Moment} from 'moment';

export class DocumentVdr implements BaseEntity {
    constructor(
        public effectivePermissions = [],
        public id?: number,
        public name?: string,
        public description?: string,
        public type?: string,
        public ocrContent?: string,
        public documentPath?: string,
        public pdfPath?: string,
        public fileSize?: number,
        public number?: number,
        public directoryId?: number,
        public versions?: BaseEntity[],
        public mainVersionId?: number,
        public version?: number,
        public status?: string,
        public favourite?: boolean,
        public pdfCreatedTimestamp?: Moment,
        public fileIndexedTimestamp?: Moment,
        public originalName?: string
    ) {
    }
}
