import {Routes} from '@angular/router';
import {UserRouteAccessService} from '../shared';
import {ReportsComponent} from './reports.component';
import {ReportSmsPopupComponent} from './report-sms/report-sms.component';
import {ReportArchiveDownloadComponent} from "./report-archvie/report-archive-download.component";

export const reportRoute: Routes = [
    {
        path: 'reports',
        component: ReportsComponent,
        data: {
            pageTitle: 'logs.title'
        },
        canActivate: [UserRouteAccessService],
    },
    {
        path: 'report-sms',
        component: ReportSmsPopupComponent,
        data: {
            pageTitle: 'logs.title'
        },
        outlet: 'popup',
        canActivate: [UserRouteAccessService],
    },
    {
        path: 'report/archive/:token',
        component: ReportArchiveDownloadComponent
    }
];
