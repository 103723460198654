import '@angular/compiler';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ProdConfig } from './blocks/config/prod.config';
import { VdrAppModule } from './app.module';

// require('ngx-extended-pdf-viewer/assets/pdf.min.js');
// require('ngx-extended-pdf-viewer/assets/pdf.worker.min.js');
// require('ngx-extended-pdf-viewer/assets/web/viewer.min.js');

ProdConfig();

if (module['hot']) {
    module['hot'].accept();
}

platformBrowserDynamic().bootstrapModule(VdrAppModule)
.then((success) => console.log(`Application started`))
.catch((err) => console.error(err));
