export enum DocumentType {
    PDF = <any>'pdf',
    XLS = <any>'xls',
    XLSX = <any>'xlsx',
    DOC = <any>'doc',
    DOCX = <any>'docx',
    RTF = <any>'rtf',
    PPT = <any>'ppt',
    PPTX = <any>'pptx',
    GIF = <any>'gif',
    JPEG = <any>'jpeg',
    JPG = <any>'jpg',
    PNG = <any>'png',
    TIFF = <any>'tiff',
    BMP = <any>'bmp',
}
