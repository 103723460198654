import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SERVER_API_URL} from '../../app.constants';
import {ProjectVdr} from '@admin/project-vdr';
import {Dashboard} from './dashboard.model';
import { of, tap } from 'rxjs';

@Injectable({providedIn: 'root'})
export class DashboardService {
    constructor(private http: HttpClient) {
    }

    get(): Observable<Dashboard[]> {
        return this.http.get<Dashboard[]>(SERVER_API_URL + 'api/dashboardNews', {observe: 'body'});
    }

    getActive(): Observable<Dashboard[]> {
        return this.http.get<Dashboard[]>(SERVER_API_URL + 'api/activeDashboardNews', {observe: 'body'})
        .pipe(tap(dashboards => dashboards.forEach(d=>this.initLead(d))
        ));
    }

    save(dashboard: Dashboard): Observable<Dashboard> {
        return this.http.post<Dashboard>(SERVER_API_URL + 'api/dashboardNews', dashboard, {observe: 'body'});
    }

    toggleActive(dashboard: Dashboard) {
        dashboard.active = !dashboard.active;
        this.save(dashboard);
    }

    private initLead(d: Dashboard) {
        d.isShowMore = false;
        if(!d.content) {
            return;
        }
        const more = d.content.split('$more');
        d.lead = more[0] + '<a id="showMoreAnchor" class="showMoreAnchor" onclick="int a=2;"> <b>Pokaż więcej...</b></a>';
        if(more.length > 1 ){
            d.moreInfo = more[1];
        }
    }
}
