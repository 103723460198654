import {AfterViewInit, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {JhiLanguageService}from '@upside-cloud/ng-jhipster';

import {InfoService} from './info.service';
import {LoginModalService} from '../../shared';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'jhi-info',
    templateUrl: './info.component.html'
})
export class InfoComponent implements OnInit, AfterViewInit {

    error: string;
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    title: string;
    phoneNumber: string;
    success: boolean;
    modalRef: NgbModalRef;
    account: any;

    constructor(private languageService: JhiLanguageService,
                private infoService: InfoService,
                private loginModalService: LoginModalService,
                private route: ActivatedRoute
    ) {
    }

      ngOnInit() {
        this.success = false;
        this.account = {};
    }

    ngAfterViewInit() {
        // this.renderer.selectRootElement(this.elementRef.nativeElement.querySelector('#firstNameInput')).focus();
    }
    save() {
        this.infoService.save(this.account).subscribe(() => {
            this.success = true;
        }, (response) => this.processError(response));
    }

    private processError(response) {
        this.success = null;
        // if (response.status === 400 && response.error.type === '') {

        this.error = 'ERROR';
    }
}
