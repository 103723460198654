import {Component, OnInit, Inject} from '@angular/core';
import {HttpResponse, HttpErrorResponse} from '@angular/common/http';

import {Observable} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiEventManager, JhiAlertService}from '@upside-cloud/ng-jhipster';

import {Group} from '../../../shared/group/group.model';
import {GroupService} from '../../../shared/group/group.service';
import {Role} from '../../../shared';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'jhi-user-group-dialog',
    templateUrl: './group-dialog.component.html',
    styleUrls: ['./group-dialog-component.scss']
})
export class GroupDialogComponent implements OnInit {

    group: Group;
    projectGroups: Group[];
    isSaving: boolean;
    adminType: boolean;
    Role = Role;
    groupPermissionsDescriptionVisible = false;

    constructor(
        public activeModal: NgbActiveModal,
        private jhiAlertService: JhiAlertService,
        private groupService: GroupService,
        private eventManager: JhiEventManager) {
    }

    ngOnInit() {
        this.loadProjectGroups();
        if (this.group.id) {
            this.groupService.find(String(this.group.id))
                .subscribe((userGroupResponse: HttpResponse<Group>) => {
                    this.group = userGroupResponse.body;
                    // TODO zastanowic sie nad mechanizmem serializacji do enumeracji w angular
                    this.group.role = Role[this.group.role.toString()];
                    if (this.group.role === Role.ADMIN || this.group.role === Role.ADMIN_LIMITED) {
                        this.adminType = true;
                    } else {
                        this.adminType = false;
                    }
                    this.toggleGroupPermissionsDescription();
                });
        }
        this.isSaving = false;
    }

    loadProjectGroups() {
        this.groupService.getProjectGroups().subscribe(
            (res: HttpResponse<Group[]>) => {
                this.projectGroups = res.body
                    .filter((group) => group.id !== this.group.id)
                    .filter((group) => group.role.toString() === 'USER');
            },
            (res: HttpErrorResponse) => this.onError(res.message)
        );
    }

    clear() {
        this.activeModal.dismiss();
    }

    chooseAdminType() {
        if (!this.adminType) {
            this.toggleGroupPermissionsDescription();
        }
        this.adminType = true;
    }

    chooseRole(role: Role) {
        this.group.role = role;
        this.groupPermissionsDescriptionVisible = true;
    }

    chooseUserType() {
        if (this.adminType) {
            this.toggleGroupPermissionsDescription();
        }
        this.adminType = false;
    }

    toggleGroupPermissionsDescription() {
        this.groupPermissionsDescriptionVisible = !this.groupPermissionsDescriptionVisible;
    }

    save() {
        this.isSaving = true;

        if (this.group.id !== undefined) {
            this.subscribeToSaveResponse(
                this.groupService.update(this.group));
        } else {
            this.subscribeToSaveResponse(
                this.groupService.create(this.group));
        }
    }

    compareGroups(group1: Group, group2: Group): boolean {
        return group1 && group2 && group1.id === group2.id;
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<Group>>) {
        result.subscribe((res: HttpResponse<Group>) =>
            this.onSaveSuccess(res.body), (res: HttpErrorResponse) => this.onSaveError());
    }

    private onSaveSuccess(result: Group) {
        this.eventManager.broadcast({name: 'groupListModification', content: 'OK'});
        this.isSaving = false;
        this.activeModal.close();
    }

    private onSaveError() {
        this.isSaving = false;
    }

    private onError(error: any) {
        this.jhiAlertService.error(error.message, null, null);
    }

    getSelected(selectedVals: Array<any>, option: any) {
        if (selectedVals) {
            for (let i = 0; i < selectedVals.length; i++) {
                if (option.id === selectedVals[i].id) {
                    return selectedVals[i];
                }
            }
        }
        return option;
    }

    isRoleSelected(): boolean {
        return this.group.role === undefined;
    }

    containsCharactor(param: string) {
        return /[a-zA-Z]/g.test(param);
    }
}
