import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from '../../app.constants';

import {DocumentVdr} from './document-vdr.model';
import {createRequestOption, momentEndOfDay} from '../../shared';
import {DocumentRowVdr} from './document-row-vdr.model';
import {DocumentRowInfoDto} from '../directory-document/dto/document-row-info-dto.model';
import {DocumentsRowInfoDto} from '../directory-document/dto/documents-row-indo-dto.model';
import {DocumentCriteriaDto} from '../directory-document/dto/document-criteria-dto.model';
import {DocumentSearchRowCriteriaDTO} from '../directory-document/dto/document-search-row-criteria-dto.model';
import {DocumentSearchRowDTO} from '../directory-document/dto/document-search-row-dto.model';
import {DocumentSpecialRowDto} from '../directory-document/dto/document-special-row-dto.model';

export type EntityResponseType = HttpResponse<DocumentVdr>;

@Injectable()
export class DocumentVdrService {

    private resourceUrl = SERVER_API_URL + 'api/documents';
    private resourceRowUrl = SERVER_API_URL + 'api/documentRows';
    private resourceDocumentVersionsRowUrl = SERVER_API_URL + 'api/documentVersionsRows';
    private resourceDamagedDocumentRowUrl = SERVER_API_URL + 'api/damagedDocumentRows';
    private resourceInfectedDocumentRowUrl = SERVER_API_URL + 'api/infectedDocumentRows';
    private resourceSearchRowUrl = SERVER_API_URL + 'api/documentSearchRows';
    private moveDocumentUrl = SERVER_API_URL + 'api/moveDocument';
    private copyDocumentUrl = SERVER_API_URL + 'api/copyDocument';
    private favouriteOnUrl = SERVER_API_URL + 'api/setFavouriteOn';
    private favouriteOffUrl = SERVER_API_URL + 'api/setFavouriteOff';
    private changeDocumentNameUrl = SERVER_API_URL + 'api/changeDocumentName';
    private mergePDFsUrl = SERVER_API_URL + 'api/merge-pdfs';
    private taskGeneratePdfUrl = SERVER_API_URL + 'api/taskGeneratePdf';
    private deleteInfectedUrl = SERVER_API_URL + 'api/deleteInfected';
    private taskReUploadDocumentUrl = SERVER_API_URL + 'api/taskReUploadDocument';

    constructor(private http: HttpClient) {
    }

    query(req?: any): Observable<HttpResponse<DocumentVdr[]>> {
        const options = createRequestOption(req);
        return this.http.get<DocumentVdr[]>(this.resourceUrl, {params: options, observe: 'response'}).pipe(
            map((res: HttpResponse<DocumentVdr[]>) => this.convertArrayResponse(res)));
    }

    getSearchRow(documentId: number): Observable<DocumentSearchRowDTO> {
        return this.http.get<DocumentSearchRowDTO>(`${this.resourceSearchRowUrl}/${documentId}`, {params: null, observe: 'response'}).pipe(
            map((res: HttpResponse<DocumentSearchRowDTO>) => res.body));
    }

    getRows(directoryId: number): Observable<HttpResponse<DocumentRowVdr[]>> {
        let apiURL = this.resourceRowUrl + '?directoryId=';
        if (directoryId != null) {
            apiURL = apiURL + directoryId;
        }
        return this.http.get<DocumentRowVdr[]>(apiURL, {params: null, observe: 'response'}).pipe(
            map((res: HttpResponse<DocumentRowVdr[]>) => this.convertArrayResponse(res)));
    }

    getRowsDocumentVersionsList(mainVersionId: number): Observable<HttpResponse<DocumentRowVdr[]>> {
        let apiURL = this.resourceDocumentVersionsRowUrl + '?mainVersionId=';
        if (mainVersionId != null) {
            apiURL = apiURL + mainVersionId;
            return this.http.get<DocumentRowVdr[]>(apiURL, {params: null, observe: 'response'}).pipe(
                map((res: HttpResponse<DocumentRowVdr[]>) => this.convertArrayResponse(res)));
        }
        return null;
    }

    getRowsWithCriteria(documentCriteriaDto: DocumentCriteriaDto): Observable<HttpResponse<DocumentRowVdr[]>> {
        const copy = Object.assign(new DocumentCriteriaDto(), documentCriteriaDto);
        if (!copy.favourite) {
            copy.favourite = null;
        }

        let fullSearch  = false;

        if (copy.createdTo) {
            copy.createdTo = momentEndOfDay(copy.createdTo);
            fullSearch = true;
        }

        if (copy.readTo) {
            copy.readTo = momentEndOfDay(copy.createdTo);
            fullSearch = true;
        }

        const apiUrl = `${this.resourceRowUrl}/with-criteria`;
        return this.http.post<DocumentRowVdr[]>(apiUrl, copy, {observe: 'response'}).pipe(
            map((res: HttpResponse<DocumentRowVdr[]>) => this.convertArrayResponse(res)));
    }

    //TODO przepiąć na tą metodę
    getSearchRowsWithCriteria(documentSearchRowCriteriaDTO: DocumentSearchRowCriteriaDTO, documentCriteriaDTO: DocumentCriteriaDto): Observable<DocumentSearchRowDTO[]> {
        return this.http.post<DocumentSearchRowDTO[]>(`${this.resourceSearchRowUrl}/with-criteria`, {documentSearchRowCriteriaDTO, documentCriteriaDTO},
            {observe: 'response'}).pipe(map((res: HttpResponse<DocumentSearchRowDTO[]>) => res.body));
    }

    getDocumentNote(id: number): Observable<DocumentRowInfoDto> {
        return this.http.get(this.resourceUrl + '/detail/' + id);
    }

    getDocumentsNotes(ids: number[]): Observable<DocumentsRowInfoDto> {
        const params = new HttpParams().set('documents', ids.toString());
        return this.http.get(this.resourceUrl + '/details', {params});
    }

    mergePdfs(documentOneId: number, documentTwoId: number): Observable<Object> {
        return this.http.post(this.mergePDFsUrl, {documentOneId, documentTwoId});
    }

    pushFileToStorage(file: File): Observable<HttpEvent<{}>> {
        const formdata: FormData = new FormData();

        formdata.append('file', file);

        const req = new HttpRequest('POST', '/api/upload-file', formdata, {
            reportProgress: true,
            responseType: 'text'
        });

        return this.http.request(req);
    }

    private convertArrayResponse(res: HttpResponse<DocumentRowVdr[]>): HttpResponse<DocumentRowVdr[]> {
        const jsonResponse: DocumentRowVdr[] = res.body;
        const body: DocumentRowVdr[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

    /**
     * Convert a returned JSON object to DocumentVdr.
     */
    private convertItemFromServer(document: DocumentVdr): DocumentVdr {
        const copy: DocumentVdr = Object.assign({}, document);
        return copy;
    }

    /**
     * Convert a DocumentVdr to a JSON which can be sent to the server.
     */
    private convert(document: DocumentVdr): DocumentVdr {
        const copy: DocumentVdr = Object.assign({}, document);
        return copy;
    }

    moveDocument(documentId: string, moveToDirectoryId: string): Observable<Object> {
        const apiURL = this.moveDocumentUrl + '?documentId='
            + documentId + '&moveToDirectoryId=' + moveToDirectoryId;
        return this.http.post(apiURL, {});
    }

    copyDocument(documentId: string, copyToDirectoryId: string): Observable<Object> {
        const apiURL = this.copyDocumentUrl + '?documentId=' + documentId
            + '&copyToDirectoryId=' + copyToDirectoryId;
        return this.http.post(apiURL, {});
    }

    favouriteOn(documentId: string): Observable<Object> {
        const apiURL = this.favouriteOnUrl + '?documentId=' + documentId;
        return this.http.post(apiURL, {});
    }

    favouriteOff(documentId: string): Observable<Object> {
        const apiURL = this.favouriteOffUrl + '?documentId=' + documentId;
        return this.http.post(apiURL, {});
    }

    changeName(documentId: number, newName: string): Observable<Object> {
        const apiURL = this.changeDocumentNameUrl + '?documentId=' + documentId
            + '&newDocumentName=' + newName;
        return this.http.post(apiURL, {});
    }

    getRowsDamagedDocumentList(): Observable<HttpResponse<DocumentRowVdr[]>> {
        const apiURL = this.resourceDamagedDocumentRowUrl;
        return this.http.get<DocumentRowVdr[]>(apiURL, {params: null, observe: 'response'}).pipe(
                map((res: HttpResponse<DocumentRowVdr[]>) => this.convertArrayResponse(res)));

    }

    taskGeneratePdf(documentId: number): Observable<Object> {
        return this.http.post(this.taskGeneratePdfUrl, documentId);
    }

    taskReUploadDocument(documentId: number[]): Observable<Object> {
        return this.http.post(this.taskReUploadDocumentUrl, documentId);
    }

    getRowsInfectedDocumentList(): Observable<HttpResponse<DocumentRowVdr[]>> {
        return this.http.get<DocumentRowVdr[]>(this.resourceInfectedDocumentRowUrl, {params: null, observe: 'response'})
            .pipe(map((res: HttpResponse<DocumentRowVdr[]>) => this.convertArrayResponse(res)));

    }

    deleteInfected(rows: DocumentSpecialRowDto[]): Observable<Object> {
        return this.http.post(this.deleteInfectedUrl, rows);
    }


    getLastUploadedDocuments(): Observable<DocumentSearchRowDTO[]>  {
        return this.http.get<DocumentSearchRowDTO[]>(SERVER_API_URL + 'api/home/lastUploadedDocuments');
    }

    getMostViewedDocuments(): Observable<{name: string, count: number}[]>  {
            return this.http.get<{name: string, count: number}[]>(SERVER_API_URL + 'api/home/mostViewedDocuments');
        }

  getLastRemovedDocuments(): Observable<DocumentSearchRowDTO[]>  {
            return this.http.get<DocumentSearchRowDTO[]>(SERVER_API_URL + 'api/home/lastRemovedDocuments');
        }



}
