import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {PasswordResetComponent} from './password-reset.component';

@Injectable()
export class PasswordResetModalService {
    private isOpen = false;

    constructor(private modalService: NgbModal) {
    }

    open(userId: string): NgbModalRef {
        if (this.isOpen) {
            return;
        }
        this.isOpen = true;
        const modalRef = this.modalService.open(PasswordResetComponent);
        modalRef.result.then((result) => {
            this.isOpen = false;
        }, (reason) => {
            this.isOpen = false;
        });
        modalRef.componentInstance.userId = userId;
        return modalRef;
    }
}
