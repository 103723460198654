<form name="editForm" role="form" novalidate (ngSubmit)="save()" #editForm="ngForm">

    <div class="modal-header">
        <h4 class="modal-title" id="myFileLabel" jhiTranslate="vdrApp.file.home.createOrEditLabel">Create or edit a File</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                (click)="clear()">&times;</button>
    </div>
    <div class="modal-body">
        <jhi-alert-error></jhi-alert-error>
        <div class="form-group" [hidden]="!file.id">
            <label for="id" jhiTranslate="global.field.id">ID</label>
            <input type="text" class="form-control" id="id" name="id"
                   [(ngModel)]="file.id" readonly />
        </div>
        <div class="form-group">
            <label class="form-control-label" jhiTranslate="vdrApp.file.name" for="field_name">Name</label>
            <input type="text" class="form-control" name="name" id="field_name"
                [(ngModel)]="file.name" required/>
            <div [hidden]="!(editForm.controls.name?.dirty && editForm.controls.name?.invalid)">
                <small class="form-text text-danger"
                   [hidden]="!editForm.controls.name?.errors?.required" jhiTranslate="entity.validation.required">
                   This field is required.
                </small>
            </div>
        </div>
        <div class="form-group">
            <label class="form-control-label" jhiTranslate="vdrApp.file.size" for="field_size">Size</label>
            <input type="number" class="form-control" name="size" id="field_size"
                [(ngModel)]="file.size" />
        </div>
        <div class="form-group">
            <label class="form-control-label" jhiTranslate="vdrApp.file.bytes" for="field_bytes">Bytes</label>
            <div>
                <img [src]="'data:' + file.bytesContentType + ';base64,' + file.bytes" style="max-height: 100px;" *ngIf="file.bytes" alt="file image"/>
                <div *ngIf="file.bytes" class="form-text text-danger clearfix">
                    <span class="pull-left">{{file.bytesContentType}}, {{byteSize(file.bytes)}}</span>
                    <button type="button" (click)="clearInputImage('bytes', 'bytesContentType', 'fileImage')" class="btn btn-secondary btn-xs pull-right">
                        <span class="fa fa-times"></span>
                    </button>
                </div>
                <input type="file" id="file_bytes" (change)="setFileData($event, file, 'bytes', true)" accept="image/*" jhiTranslate="entity.action.addimage"/>
            </div>
            <input type="hidden" class="form-control" name="bytes" id="field_bytes"
                [(ngModel)]="file.bytes" />
            <input type="hidden" class="form-control" name="bytesContentType" id="field_bytesContentType"
                [(ngModel)]="file.bytesContentType" />
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="clear()">
            <span class="fa fa-ban"></span>&nbsp;<span jhiTranslate="entity.action.cancel">Cancel</span>
        </button>
        <button type="submit" [disabled]="editForm.form.invalid || isSaving" class="btn btn-primary">
            <span class="fa fa-save"></span>&nbsp;<span jhiTranslate="entity.action.save">Save</span>
        </button>
    </div>
</form>
