import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';

@Injectable()
export class NoCachingInterceptor implements HttpInterceptor {
    private static NO_CACHE_HEADERS = {
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'
    };

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let updatedHeaders = req.headers;
        for (const [key, value] of Object.entries(NoCachingInterceptor.NO_CACHE_HEADERS)) {
            updatedHeaders = updatedHeaders.set(key, value);
        }

        const httpRequest = req.clone({
            headers: updatedHeaders
        });

        return next.handle(httpRequest);
    }
}
