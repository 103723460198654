import {Component, Input, Output, EventEmitter} from '@angular/core';
import {DocumentVdrService} from '../document-vdr/document-vdr.service';
import {saveAs} from 'file-saver/FileSaver';
import {DocumentSearchRowCriteriaDTO} from './dto/document-search-row-criteria-dto.model';
import {DocumentSearchRowDTO} from './dto/document-search-row-dto.model';
import getIconForExtension from 'font-awesome-filetypes';
import {SearchIn} from './dto/search-in.enum';
import {PdfDocumentViewerComponent} from '../../account';
import {MatDialog} from '@angular/material/dialog';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute} from '@angular/router';
import {DocumentCriteriaDto} from './dto/document-criteria-dto.model';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'jhi-document-search',
    templateUrl: 'document-search.component.html',
    styleUrls: ['document-search.scss']
})
export class DocumentSearchComponent {
    private documentSearchRowCriteriaDTO: DocumentSearchRowCriteriaDTO;
    public dataSource = new MatTableDataSource<DocumentSearchRowDTO>();
    private displayedColumns: string[];
    private isViewerOpened = false;


    @Input()
    documentCriteriaDto: DocumentCriteriaDto;

    @Output()
    onDocumentSearch: EventEmitter<any> = new EventEmitter();

    documentId: number;
    hasSearched = false;

    constructor(
        private documentService: DocumentVdrService,
        public dialog: MatDialog,
        private spinner: NgxSpinnerService,
        private route: ActivatedRoute,
    ) {
        this.initDocumentSearchRowCriteria();
        this.documentSearchRowCriteriaDTO.searchIn = SearchIn[SearchIn.TITLES.toString()];
        this.displayedColumns = ['icon', 'fileData', 'creationDate'];
        this.dataSource.data = null;

        this.route.params.subscribe((params) => {
            this.documentId = params['documentId'];

            if (this.documentId) {
                this.documentService.getSearchRow(this.documentId).subscribe((res) => {
                    this.dataSource.data = [res];
                });
            }
        });
    }

    get criteria(): DocumentSearchRowCriteriaDTO {
        return this.documentSearchRowCriteriaDTO;
    }

    get hasSearchResults(): boolean {
        return this.dataSource.data.length > 0;
    }

    get phrase(): string {
        return this.documentSearchRowCriteriaDTO.phrase;
    }

    @Input()
    set phrase(phrase: string) {
        this.documentSearchRowCriteriaDTO.phrase = phrase;
    }

    public search() {
        this.spinner.show();
        this.documentService.getSearchRowsWithCriteria(this.documentSearchRowCriteriaDTO, (this.hasFilters()) ? this.documentCriteriaDto : null)
            .subscribe((res) => {
                this.hasSearched = true;

                this.spinner.hide();
                    return this.dataSource.data = res;
                }
            );

    }

    public clear() {
        this.dataSource.data = null;
        this.documentSearchRowCriteriaDTO.phrase = null;
        this.hasSearched = false;
    }

    initDocumentSearchRowCriteria() {
        this.documentSearchRowCriteriaDTO = new DocumentSearchRowCriteriaDTO();
    }

    getFileIcon(type: string): string {
        return 'fa ' + getIconForExtension(type);
    }

    pdfViewer(id){
        if (!this.isViewerOpened) {
            this.spinner.show();
            this.isViewerOpened = true;
            const dialogRef = this.dialog.open(PdfDocumentViewerComponent, {
                width: '100%',
                height: '100%',
                minHeight: '100%',
                maxWidth: '100vw',
                panelClass: 'pdf-viewer-dialog',
                autoFocus: false,
                data: { documentId: id}
            });
            dialogRef.afterClosed().subscribe(() => {
                this.isViewerOpened = false;
            });
            this.spinner.hide();
        }
    }

    hasFilters() {
        return this.documentCriteriaDto != null
            && (this.documentCriteriaDto.read != null
                || this.documentCriteriaDto.readFrom != null
                || this.documentCriteriaDto.readTo != null
                || this.documentCriteriaDto.createdFrom != null
                || this.documentCriteriaDto.createdTo != null
                || (this.documentCriteriaDto.documentTypes != null && this.documentCriteriaDto.documentTypes.length > 0));
    }
}
