<!--<div class="modal-header">
    <h4 class="modal-title" jhiTranslate="entity.delete.title">Confirm delete operation</h4>
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
            (click)="onNoClick()">&times;</button>
</div>
<div class="modal-body">
    <jhi-alert-error></jhi-alert-error>
    <p jhiTranslate="documentManagement.permanentDelete.question">Are you sure you want to delete permanently</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="onNoClick()">
        <span class="fa fa-ban"></span>&nbsp;<span jhiTranslate="entity.action.cancel">Cancel</span>
    </button>
    <button type="button" class="btn btn-danger" (click)="onYesClick()">
        <span class="fa fa-remove"></span>&nbsp;<span jhiTranslate="entity.action.delete">Delete</span>
    </button>
</div>-->

<div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start" style="min-height: 60px">
        <div fxLayout="column" fxLayoutAlign="end" fxFlex="90">
            <div style="margin-bottom: 10px"><span style="font-size: 18px;">{{'entity.delete.title' | translate}}</span></div>
        </div>
        <div fxLayout="column" fxLayoutAlign="start" fxflex>
            <button mat-icon-button (click)="onNoClick()" style="max-width: 40px; max-height: 40px" [autofocus]="false" id="clear">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
    <br/>
    <div mat-dialog-content style="padding-bottom: 20px">
        <span >{{'documentManagement.permanentDelete.question' | translate}}</span>
    </div>
    <mat-divider></mat-divider>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" color="lightgray">
        <button mat-raised-button (click)="onNoClick()" style="padding: 0 !important; min-width: 80px !important; font-size: 13px">
            <mat-icon style="font-size: 22px">clear</mat-icon>
            <span>{{'entity.action.cancel' | translate}}</span>
        </button>
        <button mat-raised-button (click)="onYesClick()" style="padding: 0 !important; min-width: 80px !important; font-size: 13px"
                [ngStyle]="{'background-color':  'var(--prime-color)', 'color': 'white' }">
            <mat-icon style="font-size: 22px">check</mat-icon>
            <span>{{'entity.action.delete' | translate}}</span>
        </button>
    </div>
</div>


