import {Moment} from 'moment';

export class ReportQuestionsCriteriaDto {
    public createdFrom?: Moment;
    public createdTo?: Moment;
    public groupIds?: number[];
    public categories?: number[];
    public priorities?: string[];
    public author?: string;
    public phrase?: string;
}
