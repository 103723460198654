// show-errors.component.ts
import {Component, Input} from '@angular/core';
import {AbstractControlDirective, AbstractControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'jhi-show-errors',
    template: `
        <div *ngIf="shouldShowErrors()">
            <small class="form-text text-danger"
                   *ngFor="let error of listOfErrors()">
                {{error}}
            </small>
        </div>
    `,
})
export class ShowErrorsComponent {

    private readonly errorMessages = {
        'required': () => this.translateService.instant('entity.validation.required'),
        'email': () => this.translateService.instant('global.messages.validate.email.invalid'),
        'minlength': (params) => this.translateService.instant('entity.validation.minlength', {min: params.requiredLength}),
        'maxlength': (params) => this.translateService.instant('entity.validation.maxlength', {max: params.requiredLength}),
        'pattern': (params) => this.translateService.instant('entity.validation.pattern', {pattern: params.requiredPattern}),
        'number': (params) => this.translateService.instant('global.messages.validate.email.number')
    };

    @Input()
    private control: AbstractControlDirective | AbstractControl;

    constructor(
        private translateService: TranslateService
    ) {
    }

    shouldShowErrors(): boolean {
        return this.control &&
            this.control.errors &&
            (this.control.dirty || this.control.touched);
    }

    listOfErrors(): string[] {
        return Object.keys(this.control.errors)
            .map((field) => this.getMessage(field, this.control.errors[field]));
    }

    private getMessage(type: string, params: any) {
        return this.errorMessages[type](params);
    }

}

