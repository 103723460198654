import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import { VdrSharedModule } from '../../shared';
import {
    DocumentVdrService,
} from './';

@NgModule({
    imports: [
        VdrSharedModule
    ],
    providers: [
        DocumentVdrService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VdrDocumentVdrModule {}
