import {BaseEntity} from './../../shared';
import {Recipient} from './recipient.model';
import {DocumentSearchRowDTO} from '../directory-document/dto/document-search-row-dto.model';
import {Answer} from '../answer';
import {Category} from "./category/category.model";

export const enum QuestionStatus {
    'PENDING',
    'WAITING_FOR_ANSWER',
    'RESOLVED'
}

export const enum QuestionPriority {
    'LOW',
    'MEDIUM',
    'HIGH'
}

export class QuestionListElement {

    constructor(
        public number: number,
        public id: number,
        public title: string,
        public status: string,
        public priority: string,
        public authorId: number,
        public authorName: string,
        public documentName: string,
        public createdDate: Date,
        public answersCount: number,
        public avatarBytes: any,
        public active: boolean,
        public categories: Category[] = []
    ) {
    }

    public isHighPriority(): boolean {
        return this.priority === 'HIGH';
    }

    public isResolved(): boolean {
        return this.status === 'RESOLVED';
    }
}

export type AttachmentMetadata = {
    idempotencyKey: string,
    checksum: string
};

export class QuestionRequest {
    constructor(
        public title?: string,
        public priority = 'LOW',
        public content?: string,
        public documentId?: number,
        public id?: number,
        public recipients?: Recipient[],
        public recipientsTO?: Recipient[],
        public recipientsDW?: Recipient[],
        public document?: DocumentSearchRowDTO,
        public categories: Category[] = [],
        public attachmentMetadata?: Map<string, AttachmentMetadata>
    ) {
        recipientsTO = [];
    }

    public isHighPriority(): boolean {
        return this.priority === 'HIGH';
    }
}

export class Attachment {
    constructor(
        public id: number,
        public name: string,
        public effectivePermissions: string[],
        public pdfSupport: boolean
    ) {
    }
}

export class Question implements BaseEntity {

    constructor(
        public number: number,
        public id: number,
        public title: string,
        public content: string,
        public status = 'PENDING',
        public priority = 'LOW',
        public authorName: string,
        public authorId: number,
        public recipients: Recipient[],
        public document: Attachment,
        public documentName: string,
        public documentId: number,
        public createdDate: Date,
        public answersCount: number,
        public answers: Answer[],
        public attachments: Attachment[],
        public avatarBytes: any,
        public categories: Category[],
        public active: boolean
    ) {
    }

    public toQuestionRequest(): QuestionRequest {
        return new QuestionRequest(
          this.title,
          this.priority,
          this.content,
          this.document.id,
          this.id,
          this.recipients
        );
    }

    public toListElement(): QuestionListElement {
        return new QuestionListElement(
            this.number,
            this.id,
            this.title,
            this.status,
            this.priority,
            this.authorId,
            this.authorName,
            this.document.name,
            this.createdDate,
            this.answersCount,
            this.avatarBytes,
            this.active
        );
    }

    public isHighPriority(): boolean {
        return this.priority === 'HIGH';
    }

    public isResolved(): boolean {
        return this.status === 'RESOLVED';
    }

    public getRecipientsCC(): Recipient[] {
        return (this.recipients || []).filter((recipient) => recipient.recipientType === 'CC');
    }

    public getRecipientsTO(): Recipient[] {
        return (this.recipients || []).filter((recipient) => recipient.recipientType === 'TO');
    }
}
