import {ReportUploadedSingleDto} from './report-uploaded-single-dto.model';

export class MonitoringReport {
    public projectReport?: ProjectReportDto;
}

export class ProjectReportDto{
    public activeProjects?: number;
    public closedProjects?: number;
}

export class ReportLoadedDTO{
    public countInPeriod?: number;
    public countTotal?: number;
}

export class ReportUploadedDto {
    public filtered?: ReportUploadedSingleDto;
    public sum?: ReportUploadedSingleDto;
    public max?: ReportUploadedSingleDto;
    public total?: ReportUploadedSingleDto;
}

export class ProjectReportUploadedDTO {
    public clientName: string;
    public projectName: string;
    public reportUploaded: ReportUploadedDto;
}
