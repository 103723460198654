import {Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {JhiAlertService, JhiEventManager, JhiParseLinks} from '@upside-cloud/ng-jhipster';
import {MatDialog} from '@angular/material/dialog';
import {Question, QuestionListElement, QuestionRequest} from './question.model';
import {QuestionService} from './question.service';
import {Group, GroupService, Principal} from '../../shared';
import {Answer, AnswerService} from '../answer';
import {QuestionSearch} from './question-search.model';
import {Recipient} from './recipient.model';
import {RecipientService} from './recipient.service';
import {ConfirmationDialogService} from '../confirmation-dialog/confirmation-dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {DownloadObjectDto} from '../directory-document/dto/download-object-dto.model';
import {
    getFileNameFromResponseContentDispositionBase64,
    saveFile,
    UploadDownloadService
} from '../directory-document/upload-download.service';
import {PdfDocumentViewerComponent} from '../../account';
import {PdfPasswordProtectionDialog} from '../directory-document/pdf-password-protection-dialog/pdf-password-protection-dialog';
import {NgxSpinnerService} from 'ngx-spinner';
import {animate, query, stagger, style, transition, trigger} from '@angular/animations';
import {Category} from './category/category.model';
import {CategoryDialogComponent} from './category/category-dialog.component';
import {CategoryService} from './category.service';
import {getFileNameFromResponseContentDisposition, ReportService} from '../../report';
import {ReportQuestionsCriteriaDto} from '../../report/report-question/dto/report-questions-criteria.dto.model';
import * as moment from 'moment';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {DomSanitizer} from '@angular/platform-browser';
import {QuestionDialogComponent} from './question-dialog.component';

@Component({
    selector: 'jhi-question',
    templateUrl: './question.component.html',
    styleUrls: ['question.scss'],
    animations: [
        trigger('question', [
            transition('* => void', [
                animate('1s', style({background: 'red', transform: 'translateY(-100px)'}))
            ]),
            transition('* <=> *', [
                query('.panel', [
                    style({opacity: 0, transform: 'translateY(-100px)'}),  // initial
                    stagger(-30, [
                        animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({opacity: 1, transform: 'none'}))])
                ], {optional: true})
            ]),

        ])
    ]
})
export class QuestionComponent implements OnInit, OnDestroy {
    tabs = [null, 'WAITING_FOR_ANSWER', 'PENDING', 'RESOLVED'];
    tabStatus = null;

    currentAccount: any;

    questions: QuestionListElement[];
    question: Question;

    isShowAddButton = true;
    isShowSearchBox = false;
    currentSearch: string;
    loading = false;

    answers: Answer[] = [];
    isNewAnswerFormVisible = false;
    forwardMode = false;
    scrolled = false;

    links: any;
    totalItems: any;
    queryCount: any;
    itemsPerPage: any;
    page = 0;
    predicate: any;
    reverse = false;
    recipients: Recipient[];
    groups: Group[] = [];
    categories: Category[] = [];
    selectedCategorieIDs: number[] = [];
    questionLimitInfo = '';

    questionSearch = new QuestionSearch();
    questionSearchForm = new QuestionSearch();

    private eventSubscriber: Subscription;
    private subscription: Subscription;
    private newAnswer: Answer;

    currentGroup: Group;

    @ViewChild('newAnswerForm') newAnswerFormContainer: ElementRef;

    constructor(
        private questionService: QuestionService,
        private answerService: AnswerService,
        private parseLinks: JhiParseLinks,
        private jhiAlertService: JhiAlertService,
        private recipientService: RecipientService,
        private groupService: GroupService,
        private categoryService: CategoryService,
        private reportService: ReportService,
        private principal: Principal,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private eventManager: JhiEventManager,
        private route: ActivatedRoute,
        private confirmationDialogService: ConfirmationDialogService,
        private translateService: TranslateService,
        private spinner: NgxSpinnerService,
        private uploadDownloadService: UploadDownloadService,
        public dialog: MatDialog,
        public sanitizer: DomSanitizer
    ) {
        this.questions = [];
        this.itemsPerPage = 15;
        this.predicate = 'createdDate';
        this.currentSearch = this.activatedRoute.snapshot && this.activatedRoute.snapshot.params['search'] ?
            this.activatedRoute.snapshot.params['search'] : '';
    }

    isSelected(question: Question) {
        return this.question && question.id === this.question.id;
    }

    clearItem(autocomplete: any) {
        autocomplete.value = '';
        autocomplete.handleDropdownClick();
    }

    private loadAll() {
        this.initQuestionSearch();
        this.questionService.search(this.questionSearch).subscribe(
            (res: HttpResponse<QuestionListElement[]>) => this.onSuccess(res.body, res.headers),
            (res: HttpErrorResponse) => this.onError(res.message)
        );
    }

    private load(id) {
        this.loadQuestion(id);
    }

    private loadQuestion(id) {
        const questionResponseObservable = this.questionService.find(id);

        questionResponseObservable
            .subscribe((questionResponse: HttpResponse<Question>) => {
                this.question = questionResponse.body;
                this.answers = questionResponse.body.answers;
                this.selectedCategorieIDs = [];
                this.question.categories.forEach((cat) => this.selectedCategorieIDs.push(cat.id));
            });

        return questionResponseObservable;
    }

    loadPage(page: number) {
        this.page = page;
        this.loadAll();
    }

    loadGroups(): void {
        this.groupService.getProjectGroups(true, true)
            .subscribe((resp: HttpResponse<Group[]>) => {
                this.groups = resp.body;
        });
    }

    transition() {
        this.reset();
        this.loadAll();
    }

    isCurrentUserAuthor(question: Question): boolean {
        return this.principal.getUserIdentity() &&
            question.authorId === this.principal.getUserIdentity().id;
    }

    questionCanBeResolved(question: Question): boolean {
        return !question.isResolved() &&
            (this.principal.hasAnyAuthorityDirect(['QA_DELETE']) ||
                (this.isCurrentUserAuthor(question) && this.principal.hasAnyAuthorityDirect(['QA_WRITE'])));
    }

    private clear() {
        this.page = 0;
        this.currentSearch = '';
        this.router.navigate(['/question', {
            page: this.page,
            // sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc')
        }]);
        this.loadAll();
        this.isShowSearchBox = false;
    }

    clearFilters() {
        this.reset();
        this.questionSearch.clear();
        this.questionSearchForm.clear();
        this.loadAll();
    }

    search() {
        // prepare query.
        this.questionSearch = this.questionSearchForm;
        this.reset();
        this.questionSearch.isCriteria = true;
        this.loadAll();
        this.isShowSearchBox = false;
    }

    openNewQuestion(): void {
        this.dialog.open(QuestionDialogComponent, {
            width: '800px',
            data: new QuestionRequest()
        });
    }

    markAsResolved() {
        this.questionService.markAsResolved(this.question.id).subscribe((res: HttpResponse<Question>) => {
            this.question = res.body;
            this.isNewAnswerFormVisible = false;
            this.refreshQuestionOnList(this.question);
        }, (res: HttpErrorResponse) => {
            this.onError(res.message);
        });
    }

    changePriority(event: any) {
        this.questionService.changeQuestionPriority(this.question.id, event.value).subscribe((res: HttpResponse<Question>) => {
            this.question = res.body;
            this.isNewAnswerFormVisible = false;
            this.refreshQuestionOnList(this.question);
        }, (res: HttpErrorResponse) => {
            this.onError(res.message);
        });
    }

    canChangePriority() {
        return this.question.active && ((this.principal.hasAnyAuthorityDirect(['QA_DELETE']) ||
            (this.isCurrentUserAuthor(this.question) && this.principal.hasAnyAuthorityDirect(['QA_WRITE']))));
    }

    restoreQuestion(): void {
        this.questionService.restore(this.question.id).subscribe((res: HttpResponse<any>) => {
            this.question.active = true;
            this.questions[this.questions.findIndex((question) => question.id === this.question.id)].active = true;
        }, (res: HttpErrorResponse) => {
            this.onError(res.message);
        });
    }

    deleteQuestion() {
        this.confirmationDialogService.confirm({
            'title': this.translateService.instant('entity.delete.title'),
            'text': this.translateService.instant('vdrApp.question.delete.question', {id: this.question.title}),
            'closeLabel': this.translateService.instant('entity.action.cancel'),
            'confirmLabel': this.translateService.instant('entity.action.delete')
        }).subscribe((ans) => {
            if (ans) {
                this.questionService.delete(this.question.id).subscribe((res: HttpResponse<any>) => {
                    this.isNewAnswerFormVisible = false;
                    this.question.active = false;
                    this.questions[this.questions.findIndex((question) => question.id === this.question.id)].active = false;
                }, (res: HttpErrorResponse) => {
                    this.onError(res.message);
                });
            }
        });
    }

    deleteAnswer(answer: Answer) {
        this.answerService.delete(answer.id).subscribe((res: HttpResponse<any>) => {
            this.isNewAnswerFormVisible = false;
            this.answers.splice(this.answers.indexOf(answer), 1);
            this.refreshQuestion();
        }, (res: HttpErrorResponse) => {
            this.onError(res.message);
        });
    }

    reset() {
        this.questions = [];
        this.links = {
            last: 0
        };
        this.page = 0;
    }

    suggestRecipients(event: any) {
        let query2 = event.query;

        if (!query2) {
            query2 = '';
        }
        this.recipientService.suggestRecipients({query2, recipientType: 'BCC'})
            .subscribe((res: HttpResponse<Recipient[]>) => {
                this.recipients = res.body;
            }, (res: HttpErrorResponse) => this.onError(res.message));
    }

    ngOnInit() {
        this.loadAll();
        this.subscription = this.route.params.subscribe((params) => {
            if (params['id']) {
                this.load(params['id']);
            }
        });

        this.principal.identity().then((account) => {
            this.currentAccount = account;
        });
        this.principal.hasAnyAuthority(['QA_DELETE', 'QA_SHARE', 'QA_WRITE']).then((qa) => {
           this.isShowAddButton = qa;
        });
        this.principal.hasAnyAuthority(['ADMIN', 'ADMIN_LIMITED']).then((admin) => {
            if (admin) {
                this.tabs.push('CANCELED');
                this.loadGroups();
            }
        });
        this.registerChangeInQuestions();
        this.loadCategories();
        this.getQuestionLimit();
    }

    ngOnDestroy() {
        this.eventManager.destroy(this.eventSubscriber);
    }

    trackId(index: number, item: Question) {
        return item.id;
    }

    registerChangeInQuestions() {
        this.eventSubscriber = this.eventManager.subscribe('questionListModification', (event) => {
           this.clearFilters();
            /* this.questions.unshift(event.content.toListElement());
            return this.chooseQuestion(event.content);*/
        });
    }

    infiniteDisabled() {
        // const disabled = (this.page) + 1 * this.itemsPerPage >= this.queryCount;
        // testowo na false
        return false;
    }

    sort() {
        return [this.predicate];
    }

    private initQuestionSearch(): void {
        this.questionSearch.page = this.page;
        this.questionSearch.size = this.itemsPerPage;
        this.questionSearch.sort = this.sort();
        this.questionSearch.direction = this.reverse ? 'ASC' : 'DESC';
    }

    toggleSearchBox() {
        this.isShowSearchBox = !this.isShowSearchBox;
    }

    showNewAnswerForm(replyTo: Answer|Question, forward = false) {
        this.isNewAnswerFormVisible = true;
        this.scrolled = false;
        this.newAnswer = new Answer();

        if (!forward) {
            this.createRecipients(replyTo);
            this.forwardMode = false;
        }

        this.newAnswer.questionId = this.question.id;
    }

    showNewForwardForm(replyTo: Question | Answer) {
        this.forwardMode = true;
        this.showNewAnswerForm(replyTo, true);
        this.newAnswer.content = replyTo.content;
    }

    createRecipients(replyTo: Answer|Question) {
        this.newAnswer.recipients.push(Recipient.fromUser(replyTo.authorId, replyTo.authorName));

        let previousRecipients: Recipient[] = [];

        if (this.answers.length > 0) {
            previousRecipients = this.answers
                .slice(-1)[0]
                .recipients;
        } else {
            previousRecipients = this.question.recipients;
        }

        const filteredRecipients: Recipient[] = previousRecipients
            .filter((recipient) => recipient.userId !== this.principal.getUserIdentity().id);
        const newRecipients = this.newAnswer.recipients.concat(filteredRecipients);
        this.newAnswer.recipients = newRecipients.filter((recipient, index, self) =>
            index === self.findIndex((t) => (
                (recipient.userId !== null && t.userId === recipient.userId) || (recipient.groupId !== null && t.groupId === recipient.groupId)
            )));
    }

    hideAnswer() {
        this.isNewAnswerFormVisible = false;
    }

    sendAnswer() {
        this.newAnswer.forward = this.forwardMode;
        this.answerService.create(this.newAnswer).subscribe((res: HttpResponse<Answer>) => {
            this.answers.push(res.body);
            this.isNewAnswerFormVisible = false;
            this.refreshQuestion();
        }, (res: HttpErrorResponse) => {
            this.onError(res.message);
        });
    }

    isNewAnswerButtonVisible() {
        return this.question; // && !this.isNewAnswerFormVisible ;// && !this.question.isResolved();
    }

    private onSuccess(data, headers) {
        this.links = this.parseLinks.parse(headers.get('link'));
        this.totalItems = headers.get('X-Total-Count');
        this.queryCount = this.totalItems;
        for (let i = 0; i < data.length; i++) {
            this.questions.push(data[i]);
        }
        if (this.questions.length > 0) {
            this.chooseQuestion(this.questions[0]);
        } else {
            this.question = null;
        }
    }

    chooseQuestion(question: QuestionListElement) {
        this.isNewAnswerFormVisible = false;
        this.load(question.id);
    }

    private onError(error) {
        this.jhiAlertService.error(error.message, null, null);
    }

    private refreshQuestion() {
        this.loadQuestion(this.question.id)
            .subscribe((questionResponse: HttpResponse<Question>) => {
                const updatedQuestion = questionResponse.body;
                this.refreshQuestionOnList(updatedQuestion);
            });
    }

    private refreshQuestionOnList(updatedQuestion: Question) {
        const index = this.questions.findIndex((question) => question.id === updatedQuestion.id);
        this.questions[index] = updatedQuestion.toListElement();
    }

    downloadFile(id) {
        this.spinner.show();
        const downloadObjectFromSelection: DownloadObjectDto[] = [];
        const obj = new DownloadObjectDto(id, 'document', false);
        downloadObjectFromSelection.push(obj);

        this.uploadDownloadService.downloadZip(downloadObjectFromSelection, 'ORIGINAL').subscribe((res) => {
            const fileName = getFileNameFromResponseContentDispositionBase64(res);
            saveFile(res.body, fileName);
            this.spinner.hide();
            this.handlePdfPassword(res, downloadObjectFromSelection);
        }, (error1) => {
            this.spinner.hide();
            this.onError(error1);
        });
    }

    pdfViewer(id, documents) {
        this.spinner.show();
        const dialogRef = this.dialog.open(PdfDocumentViewerComponent, {
            width: '100%',
            height: '100%',
            minHeight: '100%',
            maxWidth: '100vw',
            panelClass: 'pdf-viewer-dialog',
            autoFocus: false,
            data: {documentId: id, documents}
        });
        this.spinner.hide();
    }

    private handlePdfPassword(res, downloadObjectFromSelection) {
        if (res.headers.keys().includes('x-pdf-protection')) {
            this.dialog.open(PdfPasswordProtectionDialog, {
                width: '350px',
                data: {
                    pdfPassword: res.headers.get('x-pdf-protection'),
                    archiveMode: downloadObjectFromSelection.length > 1
                }
            });
        }
    }

    changeTab(matTabChangeEvent: MatTabChangeEvent) {
        this.tabStatus = this.tabs[matTabChangeEvent.index];

        this.questionSearchForm = new QuestionSearch();
        if (this.tabStatus) {
            this.questionSearchForm.status = this.tabStatus === 'CANCELED' ? null : this.tabStatus;
            this.questionSearchForm.canceled = this.tabStatus === 'CANCELED';
        } else {
            this.questionSearchForm.status = null;
        }

        this.search();

    }

    cannotShareQuestion() {
        return !this.principal.hasAnyAuthorityDirect(['QA_SHARE', 'QA_DELETE']);
    }

    toString(items: any[]): string {
        let result = '';
        items.forEach((item: any) => result += item.name + ', ');
        return result.substring(0, result.length - 2);
    }

    toStringPriorities(): string {
        if (this.questionSearch.priorities) {
            let result = '';
            this.questionSearch.priorities.forEach((item: string) =>
                result += this.translateService.instant('vdrApp.QuestionPriority.' + item) + ', ');
            return result.substring(0, result.length - 2);
        }
        return '';
    }

    clearField(field: string): void {
        switch (field) {
            case 'groups':
                this.questionSearch.groups = null;
                break;
            case 'fromDay':
                this.questionSearch.fromDay = null;
                break;
            case 'untilDay':
                this.questionSearch.untilDay = null;
                break;
            case 'categories':
                this.questionSearch.categories = null;
                break;
            case 'priorities':
                this.questionSearch.priorities = null;
                break;
            case 'author':
                this.questionSearch.author = null;
                break;
        }
        this.search();
    }

    openCategories(): void {
        this.dialog.open(CategoryDialogComponent, {
          'width': '600px',
          'height':  '600px'
        }).afterClosed().subscribe(() => this.loadCategories());
    }

    loadCategories(): void {
        this.categoryService.getCategories(true).subscribe((resp) => {
            this.categories = [];
            resp.body.forEach( (item: Category) => this.categories.push({id: item.id, name: item.name, active: true}));
        });
    }

    getQuestionLimit(): void {
        this.questionService.getQuestionLimit().subscribe((response: any) => {
            if (response.body.second === 0) {
                // this.questionLimitInfo = 'Brak określonego limitu pytań w ramach grupy';
            } else if (response.body.second > response.body.first) {
                this.questionLimitInfo = this.translateService.instant('vdrApp.question.limit.less',
                    { number: response.body.first, limit: response.body.second, allowed: (response.body.second - response.body.first) });
            } else if (response.body.second <= response.body.first) {
                this.isShowAddButton = false;
                this.questionLimitInfo = this.translateService.instant('vdrApp.question.limit.equals');
            }
        });
    }

    closeCategories(): void {
        this.questionService.changeQuestionCategories(this.question.id, this.selectedCategorieIDs).subscribe((res: HttpResponse<Question>) => {
            this.question = res.body;
            this.isNewAnswerFormVisible = false;
            this.refreshQuestionOnList(this.question);
        }, (res: HttpErrorResponse) => {
            this.onError(res.message);
        });
    }

    getNameOfCategories(): string {
        let result = '';
        if (this.question) {
            this.question.categories.forEach((item) => result += item.name + ' ');
        }
        return result;
    }

    export() {
        this.loading = true;
        this.reportService.exportQuestions(this.generateReportQuestionCriteria())
            .subscribe((response: HttpResponse<Blob>) => {
                const fileName = getFileNameFromResponseContentDisposition(response);
                saveFile(response.body, fileName);
                this.loading = false;
            });
    }

    private generateReportQuestionCriteria(): ReportQuestionsCriteriaDto {
        const reportQuestionsCriteriaDto = new ReportQuestionsCriteriaDto();
        reportQuestionsCriteriaDto.author = this.questionSearch.author;
        reportQuestionsCriteriaDto.phrase = this.questionSearch.phrase;
        if (this.questionSearch.fromDay) {
            reportQuestionsCriteriaDto.createdFrom = moment(this.questionSearch.fromDay);
        }
        if (this.questionSearch.untilDay) {
            reportQuestionsCriteriaDto.createdTo = moment(this.questionSearch.untilDay);
        }
        if (this.questionSearch.groups) {
            reportQuestionsCriteriaDto.groupIds = this.questionSearch.groups.map((item: Group) => item.id);
        }
        if (this.questionSearch.categories) {
            reportQuestionsCriteriaDto.categories = this.questionSearch.categories.map((item: Category) => item.id);
        }
        if (this.questionSearch.priorities) {
            reportQuestionsCriteriaDto.priorities = this.questionSearch.priorities;
        }

        return reportQuestionsCriteriaDto;
    }

}

