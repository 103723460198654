export class Dashboard {
    public id: number;
    public name = 'name';
    public content: string;
    public lead: string;
    public moreInfo: string;
    public active = false;
    public date: Date;
    public isShowMore: boolean;
}
