<div style="overflow-y:scroll;">
    <div class="modal-header">
        <h4 class="modal-title"><span >Wybierz lub dodaj klienta</span></h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                (click)="close()">&times;
        </button>
    </div>

    <div class="container">

        <div class="row">
            <div class="col-12 col-sm-12">
                <div class="form-group">
                    <label class="form-control-label"
                           for="field_client">Wybierz klienta</label>
                    <select class="form-control" id="field_client" [(ngModel)]="selectedClient">
                        <!--<option value="null">wybierz ...</option>-->
                        <option *ngFor="let client of clients" [ngValue]="client">
                            {{client.name}}
                        </option>
                    </select>

                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-12 col-sm-12">
                <div class="form-group">
                    <label class="form-control-label"
                           for="field_client_name">lub dodaj nowego klienta</label>
                    <input class="form-control" type="text" id="field_client_name" [(ngModel)]="clientName"/>
                </div>
            </div>
        </div>

        <div>
            <div class="col-12 col-sm-12 align-items-end">
                <div fxFlex></div>
                <button mat-raised-button
                        class="btn-primary"
                        (click)="save()"
                        >
                    Zapisz
                </button>
            </div>
        </div>

    </div>

</div>
