
<div *ngIf="file">
    <h2><span jhiTranslate="vdrApp.file.detail.title">File</span> {{file.id}}</h2>
    <hr>
    <jhi-alert-error></jhi-alert-error>
    <dl class="row-md jh-entity-details">
        <dt><span jhiTranslate="vdrApp.file.name">Name</span></dt>
        <dd>
            <span>{{file.name}}</span>
        </dd>
        <dt><span jhiTranslate="vdrApp.file.size">Size</span></dt>
        <dd>
            <span>{{file.size}}</span>
        </dd>
        <dt><span jhiTranslate="vdrApp.file.bytes">Bytes</span></dt>
        <dd>
            <div *ngIf="file.bytes">
                <a (click)="openFile(file.bytesContentType, file.bytes)">
                    <img [src]="'data:' + file.bytesContentType + ';base64,' + file.bytes" style="max-width: 100%;" alt="file image"/>
                </a>
                {{file.bytesContentType}}, {{byteSize(file.bytes)}}
            </div>
        </dd>
    </dl>

    <button type="submit"
            (click)="previousState()"
            class="btn btn-info">
        <span class="fa fa-arrow-left"></span>&nbsp;<span jhiTranslate="entity.action.back"> Back</span>
    </button>

    <button type="button"
            [routerLink]="['/', { outlets: { popup: 'file/'+ file.id + '/edit'} }]"
            replaceUrl="true"
            queryParamsHandling="merge"
            class="btn btn-primary">
        <span class="fa fa-pencil"></span>&nbsp;<span jhiTranslate="entity.action.edit"> Edit</span>
    </button>
</div>
