import {AfterViewChecked, AfterViewInit, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FocusMonitor} from '@angular/cdk/a11y';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent implements AfterViewInit, AfterViewChecked{
    confirmText: string;
    confirmHint: string;
    cancelText: string;
    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
        private _focusMonitor: FocusMonitor,
        private translateService: TranslateService
    ) {
    }

    ngAfterViewInit(): void {
        this._focusMonitor.stopMonitoring(document.getElementById('clear'));
    }

    ngAfterViewChecked(): void {
        this.confirmText = this.translateService.instant('deleteConfirmation.confirm');
        this.confirmHint =  this.translateService.instant('deleteConfirmation.hint');
        this.cancelText = this.translateService.instant('deleteConfirmation.cancel');
    }

    close(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

    getColor(): string {
        if (this.data && this.data.buttonColor) {
            return this.data.buttonColor;
        }
        return 'var(--prime-color)';
    }
}

export interface ConfirmationDialogData {
    title: string;
    text: string;
    param?: string;
    confirmLabel?: string;
    closeLabel?: string;
    buttonColor?: string;
}
