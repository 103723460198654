<div style="margin-top:-5px">
    <mat-tab-group style="background-color: #efeff2" (selectedIndexChange)="chooseType($event)">
        <mat-tab label="{{'report.projects.title' | translate }}" class="custom-mat-tab">
             <jhi-project-vdr *ngIf="type==0"></jhi-project-vdr>
        </mat-tab>
        <mat-tab label="{{'global.leftMenu.monitoring' | translate }}" class="custom-mat-tab">
            <div fxLayout="column" class="monitoring-page">
                <div fxFlex="row" class="mb-4">
                    <div class="file-report">
                        <jhi-report-uploaded [global]="true" style="min-height: 100vh;"></jhi-report-uploaded>
                    </div>
                    <div class="project-report">
                        <h4 class="d-flex justify-content-center" jhiTranslate="report.projects.title"></h4>
                        <p-chart [options]="options"   #projectChart #documentTypeChart type="pie" [data]="fileTypeChartData" height="400" class="content-font"></p-chart>
                    </div>
                </div>
            </div>

        </mat-tab>
        <mat-tab label="{{'dashboard.title' | translate }}" class="custom-mat-tab" >
            <jhi-dashboard *ngIf="type==2"></jhi-dashboard>
        </mat-tab>
        <mat-tab label="{{'userManagement.home.title' | translate }}" class="custom-mat-tab" >
           <div>
               <jhi-user2 *ngIf="type==3"></jhi-user2>
           </div>

        </mat-tab>
    </mat-tab-group>
</div>
