import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from '../../app.constants';
import {default as SecurityQuestion} from '../security/question-reset/question-reset.model';
import {map} from 'rxjs/operators';
import {of} from 'rxjs';


export type RegisterAccountRequest = {
    firstName: string
    lastName: string
    company: string
    companyTitle: string
    phoneNumber: string
    password: string
    securityQuestion: string
    securityAnswer: string
};

@Injectable()
export class RegistrationService {

    constructor(private http: HttpClient) {
    }

    save(account: RegisterAccountRequest): Observable<any> {
        return this.http.post(SERVER_API_URL + 'api/register', account);
    }


    getSecurityQuestions(): Observable<SecurityQuestion[]> {
        return this.http.get<SecurityQuestion[]>(SERVER_API_URL + 'api/security-questions', {observe: 'body'});
    }

    verifyRegistrationKey(key: string): Observable<boolean> {
        return this.http.get<boolean>(SERVER_API_URL + 'api/registration-key/' + key,  {observe: 'body'});
    }
}
