export const PROBLEM_BASE_URL = 'http://www.jhipster.tech/problem';
export const EMAIL_ALREADY_USED_TYPE = PROBLEM_BASE_URL + '/email-already-used';
export const INVALID_SECURITY_ANSWER = PROBLEM_BASE_URL + '/invalid-security-answer';
export const LOGIN_ALREADY_USED_TYPE = PROBLEM_BASE_URL + '/login-already-used';
export const EMAIL_NOT_FOUND_TYPE = PROBLEM_BASE_URL + '/email-not-found';
export const USER_NOT_ACTIVATED = PROBLEM_BASE_URL + '/user-not-activated';
export const INVALID_PASSWORD_TYPE = PROBLEM_BASE_URL + '/invalid-password';
export const INVALID_VERIFICATION_CODE_TYPE = PROBLEM_BASE_URL + '/invalid-verification-code';
export const UNIQUE_PASSWORD_TYPE = PROBLEM_BASE_URL + '/unique-password';
export const PHONE_ALREADY_USED_TYPE = PROBLEM_BASE_URL + '/phone-already-used';
