<div class="help">
    <div class="panel d-flex col-md-8 flex-column">
        <!--czekamy na stronę pomocy -->

        <h1 jhiTranslate="help.title1">Jak możemy Ci pomoć?</h1>
        <div class="manualGroup">
            <div class="manualBox">
                <div>
                    <span class="fa fa-file"></span>
                </div>
                <h3 jhiTranslate="help.adminManual"></h3>
                <div>
                    <a class="btn btn-primary" download="{{'help.adminManual.filename' | translate}}" href="{{'../../../content/documents/manualAdmin_'+('help.langKey' | translate) +'.pdf'}}">{{'help.download' | translate}}</a>
                </div>
            </div>
            <div class="manualBox">
                <div>
                    <span class="fa fa-file"></span>
                </div>
                <h3 jhiTranslate="help.userManual"></h3>
                <div>
                    <a class="btn btn-primary" download="{{'help.userManual.filename' | translate}}" href="{{'../../../content/documents/manualUser_'+('help.langKey' | translate)+'.pdf'}}">{{'help.download' | translate}}</a>
                </div>
            </div>
        </div>
        <h1 class="mt-4" jhiTranslate="help.title2">Nie znalazłeś czego szukałeś?</h1>
        <h2 jhiTranslate="help.title3"></h2>
        <div>
            <button class="btn btn-primary" (click)="openContactEmail()"><span jhiTranslate="help.contactButton"></span></button>

        </div>
    </div>
</div>
