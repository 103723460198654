import {AfterViewChecked, AfterViewInit, Component, EventEmitter, forwardRef, Input, OnDestroy, Output, QueryList, ViewChildren} from '@angular/core';
import {SingleFileUploadComponent} from './single-file-upload.component';

@Component({
    selector: 'directory-structure',
    templateUrl: 'directory-structure.component.html'
})
export class DirectoryStructureComponent implements AfterViewInit, OnDestroy {

    @Input() structure: DirectoryStructure;
    @Input() uploadUrl: string;
    @ViewChildren(forwardRef(() => SingleFileUploadComponent)) fileUploads: QueryList<SingleFileUploadComponent>;
    @ViewChildren(DirectoryStructureComponent) children: QueryList<DirectoryStructureComponent>;

    @Output() fileUploadComponents = new  EventEmitter<QueryList<SingleFileUploadComponent>>();

    subscription: any;

    getMargin(): string {
        if (this.structure) {
            return (this.structure.level * 10) + 10 + 'px';
        } else {
            return '0px';
        }
    }

    getMarginFile(): string {
        if (this.structure) {
            return (this.structure.level * 10) + 20 + 'px';
        } else {
            return '0px';
        }
    }

    ngAfterViewInit(): void {
        this.subscription = this.children.changes.subscribe(() => {
            this.processChanges();
        });
        this.processChanges();
    }

    processChanges() {
        const tmp: SingleFileUploadComponent[] = [];
        if (this.children) {
            this.children.forEach((item) => {
               tmp.push(...item.fileUploads.toArray());
            });
            this.fileUploads.reset([...this.fileUploads.toArray(), ...tmp]);
            this.emit();
        }

    }

    ngOnDestroy(): void {
    }


    emit() {
        this.fileUploadComponents.emit(this.fileUploads);
    }
}

export class DirectoryStructure {
    id?: number;
    name?: string;
    active?: boolean;
    batchId?: string;
    children?: { [key: string]: DirectoryStructure };
    files?: FileAndDir[];
    level?: number;

    childrenDisabled(): boolean {
       for (const key in this.children) {
            if (this.children[key].active) {
                return false;

            }
        }
        return true;
    }
}

export class FileAndDir {
    file?: File;
    directoryId?: number;
    batchId?: string;
}
