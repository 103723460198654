import {Route, Routes} from '@angular/router';

import {UserRouteAccessService} from '../../../shared/index';
import {PasswordResetComponent} from './password-reset.component';

export const passwordResetRoute: Routes = [{
    path: 'password-reset',
    component: PasswordResetComponent,
    data: {
        authorities: [],
        pageTitle: 'global.menu.account.password',
        isPhone: false
    },
},
    {
        path: 'password-reset',
        component: PasswordResetComponent,
        data: {
            authorities: [],
            pageTitle: 'global.menu.account.password',
            isPhone: true
        },
    },
];
