<div class="sticky-top" style="background-color: white">
    <button mat-raised-button color="warn" [disabled]="uploadInProgress" *ngIf="files.length > 0" (click)="startUpload()" class="mr-2 ml-2">
        {{'document.upload-actions.upload-all' | translate}}
    </button>
    <button mat-raised-button color="primary" style="background-color: #8c8c8c"
            *ngIf="files.length > 0 && !structureCreated" (click)="removeAll()" [disabled]="uploadInProgress">
        {{'document.upload-actions.remove-all' | translate}}
    </button>
    <div style="float:right" *ngIf="getUploadedSize()>0" fxLayout="column">
        <div style="margin-left:auto">{{uploadedFilesSet.size}} / {{files.length}}</div>

        <div>{{getUploadedSize()}} / {{getTotalSize()}}MB</div>

    </div>
    <mat-divider *ngIf="files.length > 0"></mat-divider>
</div>


<div *ngIf="!documentId" fxLayout="column" fxLayoutAlign="center center">
    <file-drop (onFileDrop)="dropped($event)"
               *ngIf="!files || files.length === 0" style="width: 100%;">
        <div fxLayout="column" fxLayoutAlign="center center">
            <span>{{'document.upload-actions.drag-drop' | translate}}


                <i class="fa fa-info-circle" matTooltip="{{'document.file-upload-tooltip' | translate}}"
                   matTooltipPosition="below"></i>

            </span>
        </div>
    </file-drop>
    <div style="padding-top: 1rem" *ngIf="!files || files.length === 0">
        <input hidden id="input-file-id" type="file" multiple (change)="filesOpen($event)"/>
        <label for="input-file-id" class="mat-raised-button cent">{{'document.upload-choose' | translate}}</label>
    </div>
</div>
<div *ngIf="documentId">
    <div *ngIf="!files || files.length === 0">
        <input hidden id="input-single-file-id" type="file" (change)="filesOpen($event)"/>
        <label for="input-single-file-id"
               class="mat-raised-button cent">{{'document.upload-choose' | translate}}</label>
    </div>
</div>

<div *ngIf="!directories" style="margin-top: 30px">
    <single-file-upload [file]="file" [id]="i" [httpUrl]="uploadUrl"
                        *ngFor="let file of files; let i = index" [documentId]="documentId"></single-file-upload>
</div>

<div *ngIf="directories" style="margin-top: 30px">
    <directory-structure [structure]="structure" [uploadUrl]="uploadUrl"
                         (fileUploadComponents)="onChildrenChange($event)"></directory-structure>
</div>

