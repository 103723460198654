export const FILE_FORMAT_COLOR_DEFAULT = '#a1a1a1';

// MS OFFICE
export const FILE_FORMAT_COLOR_DOC = '#2a3e9a';
export const FILE_FORMAT_COLOR_DOCX = '#2a579a';
export const FILE_FORMAT_COLOR_XLS = '#217345';
export const FILE_FORMAT_COLOR_XLSX = '#21732d';
export const FILE_FORMAT_COLOR_PPT = '#d16f26';
export const FILE_FORMAT_COLOR_PPTX = '#d15a26';

// IMG
export const FILE_FORMAT_COLOR_PNG = '#8cbf46';
export const FILE_FORMAT_COLOR_JPG = '#099acf';
export const FILE_FORMAT_COLOR_JPEG = '#09abcf';
export const FILE_FORMAT_COLOR_GIF = '#818ac5';
export const FILE_FORMAT_COLOR_TIFF = '#f57d40';
export const FILE_FORMAT_COLOR_BMP = '#642a9a';

// OTHERS
export const FILE_FORMAT_COLOR_RTF = '#986699';
export const FILE_FORMAT_COLOR_PDF = '#c30b15';
export const FILE_FORMAT_COLOR_TXT = '#6f7b8b';
