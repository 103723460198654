import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from '../../app.constants';
import {HttpClient} from '@angular/common/http';
import {PdfDocumentViewerResponse, Annotation} from './pdf-document-viewer.model';

@Injectable()
export class PdfDocumentViewerService {

    private pdfViewerDownloadFileUrl = SERVER_API_URL + 'api/pdfViewerDownloadFileMetadata';
    private pdfViewerEventsUrl = SERVER_API_URL + 'api/pdf-viewer/events';

    constructor(private http: HttpClient) {
    }

    getPDFResponse(id: number): Observable<PdfDocumentViewerResponse> {
        return this.http.get<PdfDocumentViewerResponse>(this.pdfViewerDownloadFileUrl + '/' + id);
    }

    getAnnotations(documentId: number) {
        return this.http.get<Annotation[]>(`/api/documents/annotations/${documentId}`);
    }

    saveAnnotations(documentId: number, annotations: Annotation[]) {
        return this.http.post(`/api/documents/annotations/${documentId}`, {
            'annotations': annotations
        });
    }

    downloadPDF(id: number): Observable<Blob> {
        return this.http.get(this.getPDFLocation(id), {responseType: 'blob'});
    }

    getPDFLocation(id: number): string {
        return 'api/pdfViewerDownloadFile/' + id;
    }

    printEvent(id: number): void {
        this.publish('/print/' + id);
    }

    fenceOnEvent(id: number): void {
        this.publish('/fence/on/' + id);
    }

    fenceOffEvent(id: number): void {
        this.publish('/fence/off/' + id);
    }

    zoomInEvent(id: number): void {
        this.publish('/zoom/in/' + id);
    }

    zoomOutEvent(id: number): void {
        this.publish('/zoom/out/' + id);
    }

    rotateLeftEvent(id: number): void {
        this.publish('/rotate/left/' + id);
    }

    rotateRightEvent(id: number): void {
        this.publish('/rotate/right/' + id);
    }

    searchEvent(id: number, phrase: string): void{
        this.http.post(this.pdfViewerEventsUrl + '/search/' + id, phrase).subscribe((value) => value);
    }

    passwordCopyEvent(id: number): void{
        this.publish('/password-copy/' + id);
    }

    closeEvent(id: number, initTime: number, currentPage: number, timeEnteringPage: number): void{
        this.publish('/close/' + id + '/' + initTime + '/' + currentPage + '/' + timeEnteringPage);
    }

    pageViewEvent(documentId: number, pageNumber: number, timeEnteringPage: number) {
        this.publish('/page-view/' + documentId + '/' + pageNumber + '/' + timeEnteringPage);
    }

    private publish(path) {
        this.http.post(this.pdfViewerEventsUrl + path, null).subscribe((value) => value);
    }
}
