import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'listDocumentName'})
export class ListDocumentNamePipe implements PipeTransform {


    transform(documentName: string, toolTip: boolean, length?: number): string {
        let result = documentName;
        const resultLength = (length) ? length : 30;
        if (!toolTip && result && result.length > resultLength) {
            result = result.substr(0, (resultLength - '...'.length));
            result = result + '...';
        }

        return result;
    }
}
