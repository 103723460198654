import {Component, OnInit} from '@angular/core';

import {
    INVALID_PASSWORD_TYPE,
    INVALID_SECURITY_ANSWER,
    INVALID_VERIFICATION_CODE_TYPE, LoginService,
    Principal, UNIQUE_PASSWORD_TYPE
} from '../../../shared/index';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PasswordReset} from './password-reset.model';
import {PasswordResetService} from './password-reset.service';


@Component({
    selector: 'jhi-password',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.scss'],
})
export class PasswordResetComponent implements OnInit {
    doNotMatch: string;
    uniquePasswordError: string;
    invalidPasswordError: string;
    invalidVerificationCodedError: string;
    invalidSecurityAnswer: string;
    isCodeSended: string;
    error: string;
    success: string;
    account: any;
    isPhone: boolean;
    passwordPhoneReset: PasswordReset;

    passwordCurrentShow = false;
    password1Show = false;
    password2Show = false;


    constructor(private passwordService: PasswordResetService,
                private principal: Principal,
                private router: Router,
                private activeModal: NgbActiveModal,
                private loginService: LoginService,
                private route: ActivatedRoute,
    ) {
        this.principal.identity().then((account) => {
            this.account = account;
        });

    }

    ngOnInit() {

        //  this.sendVerificationCode();
        //  kod powinien być wysyłany przy wczytaniu strony
        // this.passwordService.sendVerificationCode('ala ma kota');
        this.error = null;
        this.success = null;
        this.doNotMatch = null;
        this.invalidPasswordError = null;
        this.invalidVerificationCodedError = null;
        this.invalidSecurityAnswer = null;
        this.uniquePasswordError = null;
        this.passwordPhoneReset = new PasswordReset();
        this.isCodeSended = null;
        // tylko weryfikacja nr telefonu jest możliwa
        this.isPhone = true; // this.route.snapshot.data['isPhone'];

    }

    cancel() {
        if (this.isPhone) {
            return '../security';
        } else {
            return '../password-phone';
        }

    }

    changePassword() {
        if (this.passwordPhoneReset.newPassword !== this.passwordPhoneReset.confirmPassword) {
            this.doNotMatch = 'ERROR';
        } else {
            this.doNotMatch = null;
            this.passwordService.save(this.passwordPhoneReset, this.isPhone).subscribe(() => {
                this.error = null;
                this.uniquePasswordError = null;
                this.invalidVerificationCodedError = null;
                this.invalidSecurityAnswer = null;
                this.invalidPasswordError = null;
                this.success = 'OK';
                setTimeout(() => {
                    this.activeModal.dismiss('password changed');
                    this.router.navigate(['']);
                    this.loginService.logout();
                }, 2000);
            }, (response) => this.processError(response));
        }
    }

    processError(response) {
        this.success = null;
        if (response.status === 400 && response.error.type === INVALID_VERIFICATION_CODE_TYPE) {
            this.invalidVerificationCodedError = 'ERROR';
        } else if (response.status === 400 && response.error.type === INVALID_SECURITY_ANSWER) {
            this.invalidSecurityAnswer = 'ERROR';
        } else if (response.status === 400 && response.error.type === INVALID_SECURITY_ANSWER) {
            this.invalidSecurityAnswer = 'ERROR';
        } else if (response.status === 400 && response.error.type === UNIQUE_PASSWORD_TYPE) {
            this.uniquePasswordError = 'ERROR';
        } else if (response.status === 400 && response.error.type === INVALID_PASSWORD_TYPE) {
            this.invalidPasswordError = 'ERROR';
        } else {
            this.error = 'ERROR';
        }
    }

    sendVerificationCode() {
        this.passwordService.sendVerificationCode(this.passwordPhoneReset).subscribe(() => {
            this.error = null;
            this.isCodeSended = 'OK';
        }, (response) => this.processError(response));
    }
}
