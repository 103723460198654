import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Routes} from '@angular/router';
import {JhiPaginationUtil}from '@upside-cloud/ng-jhipster';

import {UserRouteAccessService} from '../../shared';
import {ProjectVdrComponent} from './project-vdr.component';
import {ProjectVdrPopupComponent} from './project-vdr-dialog.component';

@Injectable()
export class ProjectVdrResolvePagingParams implements Resolve<any> {

    constructor(private paginationUtil: JhiPaginationUtil) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const page = route.queryParams['page'] ? route.queryParams['page'] : '1';
        const sort = route.queryParams['sort'] ? route.queryParams['sort'] : 'id,asc';
        return {
            page: this.paginationUtil.parsePage(page),
            predicate: this.paginationUtil.parsePredicate(sort),
            ascending: this.paginationUtil.parseAscending(sort)
        };
    }
}

export const projectRoute: Routes = [
    {
        path: 'project-vdr',
        component: ProjectVdrComponent,
        resolve: {
            'pagingParams': ProjectVdrResolvePagingParams
        },
        data: {
            pageTitle: 'vdrApp.project.home.title',
            authorities: ['AUTHENTICATED']
        },
        canActivate: [UserRouteAccessService],
        children: [{
            path: 'new',
            component: ProjectVdrPopupComponent,
            data: {
                pageTitle: 'vdrApp.project.home.title'
            },
            outlet: 'popup'
        }]
    },
    {
        path: 'project-vdr-new',
        component: ProjectVdrPopupComponent,
        data: {
            pageTitle: 'vdrApp.project.home.title',
            authorities: ['PROJECT_MANAGEMENT']
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
];


