import {Component, Input, OnInit} from '@angular/core';
import {Group, GroupService, Role, User} from '../../../shared';

@Component({
    selector: 'jhi-qa-permission-info',
    templateUrl: './qa-permission-info.component.html',
    styleUrls: ['./../user.scss']
})
export class QaPermissionInfoComponent implements OnInit {


    private _groupId: any;

    @Input() permissionFunction: Function;

    @Input() permission: string;

    private group: Group;

    constructor(private groupService: GroupService) {
    }


    @Input() set groupId(groupId: number) {
        this._groupId = groupId;
        this.groupService.find(groupId.toString()).subscribe((res) => {
            this.group = res.body;
        });

    }


    ngOnInit() {
    }

    hasPermission(permission: string) {
        return this.permissionFunction(permission);
    }

    isModeratorOrAdministrator() {
        if (this.group) {
            return this.group.role.toString().indexOf('ADMIN') > -1;
        }
        return false;
    }

    private isUser() {
        if (this.group) {
            return this.group.role.toString() === 'USER';
        }
        return false;
    }

    private isLimitedUser() {
        return this.group.role === Role.USER_INDIVIDUAL;
    }

    getMesageSuffix() {
        if (this.isModeratorOrAdministrator()) {
            return 'admin';
        } else if (this.isUser()) {
            return 'user';
        } else {
            return 'limited';
        }

    }

}
