import {Component, ElementRef, OnInit} from '@angular/core';
import {JhiAlertService, JhiDataUtils, JhiDateUtils, JhiEventManager, JhiLanguageService}from '@upside-cloud/ng-jhipster';

import {
    Principal,
    AccountService,
    JhiLanguageHelper,
    UNIQUE_PASSWORD_TYPE,
    PHONE_ALREADY_USED_TYPE
} from '../../../shared/index';

import {CountryISO, SearchCountryField} from 'ngx-intl-tel-input-gg';


@Component({
    selector: 'jhi-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls : ['./../settings.scss']
})
export class UserSettingsComponent implements OnInit {
    readonly SearchCountryField = SearchCountryField;
    readonly CountryISO = CountryISO;

    error: string;
    success: string;
    settingsAccount: any;
    languages: any[];
  //  timezonesjson = require('angular-timezone/timezones.json');
    timeZones: any[];

    constructor(
        private account: AccountService,
        private principal: Principal,
        private languageService: JhiLanguageService,
        private languageHelper: JhiLanguageHelper,
        private eventManager: JhiEventManager,
        private dataUtils: JhiDataUtils,
        private elementRef: ElementRef
    ) {
    }

    ngOnInit() {
        this.principal.identity().then((account) => {
            this.settingsAccount = this.copyAccount(account);
        });
        this.languageHelper.getAll().then((languages) => {
            this.languages = languages;
        });


     //   this.timeZones = this.timezonesjson;
    }

    save() {
        this.settingsAccount.phoneNumber = this.settingsAccount.phoneNumber['e164Number'];
        this.account.save(this.settingsAccount).subscribe(() => {
            this.error = null;
            this.success = 'OK';
            this.principal.identity(true).then((account) => {
                this.settingsAccount = this.copyAccount(account);
            });
            this.languageService.getCurrent().then((current) => {
                if (this.settingsAccount.langKey !== current) {
                    this.languageService.changeLanguage(this.settingsAccount.langKey);
                }
            });
        }, (response) => {
            this.success = null;
            this.error = 'ERROR';
        });
    }


    byteSize(field) {
        return this.dataUtils.byteSize(field);
    }

    openFile(contentType, field) {
        return this.dataUtils.openFile(contentType, field);
    }

    setFileData(event, entity, field, isImage) {
        this.dataUtils.setFileData(event, entity, field, isImage);
    }

    clearInputImage(field: string, fieldContentType: string, idInput: string) {
        this.dataUtils.clearInputImage(this.settingsAccount, this.elementRef, field, fieldContentType, idInput);
    }



    copyAccount(account) {
        return {
            activated: account.activated,
            email: account.email,
            firstName: account.firstName,
            langKey: account.langKey,
            lastName: account.lastName,
            login: account.login,
            imageUrl: account.imageUrl,
            phoneNumber: account.phoneNumber,
            company: account.company,
            companyTitle: account.companyTitle,
            bytes: account.bytes,
            bytesContentType: account.bytesContentType,

        };
    }
}
