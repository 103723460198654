<div>
    <div class="modal-header flex-column" style="max-height: 80vh;max-height: 700px; overflow-y: scroll;">
        <div style="width:100%" class="logo2 justify-content-between d-flex flex-row">
            <img src="../../../content/images/secudo_watermark2.png"/>
            <a (click)="cancel()"><span class="fa fa-remove"></span></a>
        </div>

        <div class="p-3" style="width:100%">
            <div class="form-group">
                <label class="form-control-label" for="code" jhiTranslate="login.form.verificationCode">Kod</label>
                <input class="form-control" id="code" name="code"
                       #passwordInput="ngModel"
                       [(ngModel)]="verificationCode"
                       required autocomplete="foo">
            </div>
            <div class="text-right">
                <button class="btn btn-link" style="color:black" (click)="clearMessageTypeAndSendVerificationCode()" [disabled]="secondsLeft > 1">
                    {{ sendCodeTitle + (secondsLeft > 1 ? forTitle + secondsLeft : '') }}
                </button>
                <button class="btn btn-primary" (click)="submitForm()"
                        jhiTranslate="login.form.sendCode">Zatwierdź kod</button>
            </div>
        </div>
    </div>
</div>
