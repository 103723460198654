<div>
    <div class="row justify-content-center">
        <div class="col-md-8">
            <h1 jhiTranslate="activate.title" class="mb-4">Activation</h1>

            <div *ngIf="success">
                <div class="alert alert-success">
                    <span
                        jhiTranslate="activate.messages.success"><strong>Your user account has been activated.</strong> Please </span>
                    <a class="alert-link" (click)="login()" jhiTranslate="global.messages.info.authenticated.link">sign
                        in</a>.
                </div>

                <div class="login-panel">
                    <jhi-login-modal></jhi-login-modal>
                </div>

            </div>
            <div class="alert alert-danger" *ngIf="error" jhiTranslate="activate.messages.error">
                <strong>Your user could not be activated.</strong> Please use the registration form to sign up.
            </div>

        </div>
    </div>
</div>
