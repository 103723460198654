<div *ngIf="hasSearched" class="m-2">
    <div *ngIf="dataSource.data.length == 0">
        <mat-card class="document-search-row">
            <mat-card-header fxFlex fxLayoutAlign="center">
                <mat-card-title>
                    <h2 style="margin: 0;"><span jhiTranslate="document.filter.no-results">No search results</span></h2>
                </mat-card-title>
            </mat-card-header>
        </mat-card>
    </div>
    <div fxLayout="row" class="d-flex flex-row ml-2 mr-2 Aligner" *ngIf="dataSource.data.length > 0">
        <div fxLayout="column" fxFlex="100%">
            <div fxLayout="row" class="d-flex flex-row">
                <mat-table [dataSource]="dataSource" fxFlex id="document-search-table">
                    <ng-container matColumnDef="icon">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element" style="min-width: 75px; max-width: 75px;">
                            <i class="{{getFileIcon(element.type)}}" style="font-size: 32px"></i>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="fileData">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element">
                                <div class="block">
                                    <a
                                        (click)="pdfViewer(element.documentId)"
                                        style="cursor: pointer"
                                        matTooltip="{{element.fileName | listDocumentName: true}}"
                                        matTooltipPosition="below">
                                        <b style="color: black;">{{element.fileName}}</b></a>
                                    <br/>
                                    <span>{{element.directoryPath}}</span>
                                </div>
                        </mat-cell>

                    </ng-container>
                    <ng-container matColumnDef="creationDate">
                        <mat-header-cell *matHeaderCellDef class="header-align-right"></mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div>{{element.createdDate | date: 'yyyy-MM-dd'}}</div>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns" style="display: none;"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
            </div>
        </div>
    </div>
</div>
