<div>
    <div class="row justify-content-center">
        <div class="col-md-8">

            <h2 jhiTranslate="settings.phoneReset.title" *ngIf="settingsAccount">Zmień numeru telefonu</h2>

           <jhi-alert-error></jhi-alert-error>
            <jhi-alert></jhi-alert>




            <form name="form" role="form" (ngSubmit)="save()" #settingsForm="ngForm" *ngIf="settingsAccount" novalidate autocomplete="off">

                <div class="form-group phoneNumber-form-group">
                     <label class="form-control-label" for="phoneNumber" jhiTranslate="global.form.phoneNumber">Numer telefonu</label>
<!--                   <jhi-phone-input type="email" class="form-control jhiPhoneNumber no-border no-padding" id="phoneNumber" name="phoneNumber"-->
<!--                                    [(ngModel)]="phoneReset.phoneNumber"></jhi-phone-input>-->

                    <ngx-intl-tel-input #phone
                                        id="phoneNumber"
                                        [cssClass]="'custom'"
                                        [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true"
                                        customPlaceholder="XXXXXXXXX"
                                        [searchCountryFlag]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                        [selectFirstCountry]="true"
                                        [selectedCountryISO]="CountryISO.Poland"
                                        [maxLength]="15"
                                        [phoneValidation]="true"
                                        inputId="phone2"
                                        name="phoneNumber"
                                        style="display: block;"
                                        [(ngModel)]="phoneReset.phoneNumber"
                    ></ngx-intl-tel-input>

                    <!-- <int-phone-prefix class="form-control" [(ngModel)]="phoneReset.phoneNumber"></int-phone-prefix>
-->
                </div>

               <div class="question">
               {{phoneReset.securityQuestion | translate}}
               </div>
                <div class="form-group">
                    <input type="text" class="form-control" id="securityAnswer" name="securityAnswer"
                           placeholder="{{'password.securityAnswerPlaceholder' | translate}}"
                           [(ngModel)]="phoneReset.securityAnswer" minlength=1 maxlength=50 #lastNameInput="ngModel"
                           required>
                </div>


                <div style="text-align:right">
                    <button type="button" class="btn btn-no-border" routerLink="../security"
                            jhiTranslate="entity.action.cancel">Cancel
                    </button>
                    <button class="ml-10" type="submit" [disabled]="settingsForm.form.invalid" class="btn btn-primary" jhiTranslate="settings.form.button">Save</button>
                </div>
            </form>
        </div>
    </div>

</div>
