import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {JhiAlertService, JhiEventManager, JhiParseLinks} from '@upside-cloud/ng-jhipster';

import {
    AccountService,
    enumKeyValueFilter,
    enumToKeyValue,
    getFormattedTimezone,
    Group,
    ITEMS_PER_PAGE,
    Pageable,
    PageableSort,
    Principal,
    ProjectUser,
    SortDirection,
    UserService,
} from '../../shared';
import {ReportService} from '../report.service';
import {BlockchainConfirmationStatus, ReportEventDto} from './dto/report-event-dto.model';
import {ReportEventCriteriaDto} from './dto/report-event-criteria-dto.model';
import {moment} from 'fullcalendar';
import {MatExpansionPanel} from '@angular/material/expansion';
import {getGroupTypes, ReportEventType} from './report-event-type.enum';
import {ReportEventTypeGroup} from './report-event-type-group.enum';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {FormControl} from '@angular/forms';
import {ProjectVdr} from '@admin/project-vdr';
import {map, startWith} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {ClipboardService} from 'ngx-clipboard';
import {ReportBlockchain} from './report-blockchain.enum';

@Component({
    selector: 'jhi-report-event',
    templateUrl: './report-event.component.html',
    styleUrls: ['../report.scss']
})
export class ReportEventComponent implements OnInit, OnDestroy {
    reportEvents: ReportEventDto[];
    reportEventCriteriaDto: ReportEventCriteriaDto;
    reportEventCriteriaDtoTmp: ReportEventCriteriaDto;
    groups: Group[];
    selectedGroups: Group[];
    projectUsers: ProjectUser[];
    selectedProjectUser: ProjectUser;
    reportTypeItems: ReportTypeItem[];
    selectedReportTypeItems: Map<string, ReportTypeItem>;

    pageable: Pageable;
    totalItems: number;
    reverse: boolean;
    links: any;
    predicate: any;
    eventSubscriber: Subscription;

    public ViewType = ViewType;
    viewType: ViewType;

    dataSource;
    displayedColumns: string[];
    pageSizeOptions: number[] = [5, 10, 20, 50];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('groupsExpansionPanel') groupsExpansionPanel: MatExpansionPanel;

    visit = true;

    isAdmin: boolean;
    projects = new BehaviorSubject<ProjectListItem[]>([]);
    projectSelect = new FormControl();
    filteredProjects: Observable<ProjectListItem[]>;
    projectChangeAvailable = false;
    selectedBlockchainItem: ReportEventDto;

    constructor(
        private reportService: ReportService,
        private jhiAlertService: JhiAlertService,
        private eventManager: JhiEventManager,
        private parseLinks: JhiParseLinks,
        private translateService: TranslateService,
        private principal: Principal,
        private accountService: AccountService,
        private userService: UserService,
        public clipboardService: ClipboardService,
    ) {
        this.dataSource = new MatTableDataSource<ReportEventDto>();
        this.dataSource.paginator = this.paginator;
        this.displayedColumns = ['id', 'author', 'action', 'description', 'details', 'date', 'blockchain'];
    }

    ngOnInit() {
        this.reportEvents = [];
        this.reportEventCriteriaDto = new ReportEventCriteriaDto();
        this.reportEventCriteriaDto.dateFrom = moment().utc();
        this.reportEventCriteriaDto.dateFrom = this.reportEventCriteriaDto.dateFrom.year(this.reportEventCriteriaDto.dateFrom.get('year') - 1);
        this.reportEventCriteriaDto.dateTo = moment().utc();
        this.reportTypeItems = [];


        this.accountService.getUserProjects().subscribe((res) => {
            const projectList = res.map((r: ProjectVdr) => {
                return new ProjectListItem(r);
            });

            this.projects.next(projectList);
        });

        this.selectedReportTypeItems = new Map();
        enumToKeyValue(ReportEventTypeGroup).forEach((reportEventTypeGroup: any) => {
            const reportTypeItem = new ReportTypeItem();
            reportTypeItem.key = reportEventTypeGroup.key;
            reportTypeItem.value = reportEventTypeGroup.value;
            reportTypeItem.childs = [];

            enumKeyValueFilter(enumToKeyValue(ReportEventType), getGroupTypes(ReportEventTypeGroup[reportEventTypeGroup.key]))
                .forEach((value) => {
                    const reportTypeItemChild = new ReportTypeItem();
                    reportTypeItemChild.key = value.key;
                    reportTypeItemChild.value = value.value;
                    reportTypeItemChild.parent = reportTypeItem;

                    reportTypeItem.childs.push(reportTypeItemChild);
                    this.selectedReportTypeItems[reportTypeItemChild.key] = reportTypeItemChild;
                });

            this.reportTypeItems.push(reportTypeItem);
            this.selectedReportTypeItems[reportTypeItem.key] = reportTypeItem;
        });

        /*  this.principal.hasAnyAuthority(['REPORT']).then((isAdmin) => {
             if (isAdmin) {
                 // this.userService.findProjectUsers().subscribe((res) => this.projectUsers = res.body);
             } else {
                 this.reset();
             }
         });
  */
        this.principal.identity().then((u) => {
            if (u.admin) {
                this.isAdmin = true;
                this.projectChangeAvailable = true;
                this.userService.findProjectUsers().subscribe((res) => this.projectUsers = res.body);
            }
        });

        this.selectedProjectUser = null;

        this.pageable = new Pageable(0, ITEMS_PER_PAGE);
        this.reverse = false;
        this.links = {
            last: 0
        };
        this.predicate = 'id';
        this.viewType = ViewType.TABLE;
        this.registerChangeInReportEvents();
        this.filteredProjects = combineLatest([this.projects, this.projectSelect.valueChanges.pipe(startWith(''))]).pipe(
            map(([projects, value]) => value ? this._filter(projects, value) : projects)
        );
    }

    private _filter(projects: ProjectListItem[], value: string): ProjectListItem[] {
        const filterValue = value.toLowerCase();
        return projects.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    ngOnDestroy() {
        this.eventManager.destroy(this.eventSubscriber);
    }

    registerChangeInReportEvents() {
        this.eventSubscriber = this.eventManager.subscribe('reportEventListModification', (response) => this.reset());
    }

    sort() {
        const result = [new PageableSort(this.predicate, this.reverse ? SortDirection.ASC : SortDirection.DESC)];
        if (this.predicate !== 'id') {
            result.push(new PageableSort('id', SortDirection.ASC));
        }
        return result;
    }

    loadAll() {
        this.reportEventCriteriaDto.groupIds = [];
        this.reportEventCriteriaDto.reportEventTypes = [];
        Object.keys(this.selectedReportTypeItems).forEach((key: any) => {
            const selected: ReportTypeItem = this.selectedReportTypeItems[key];
            if (selected != null && selected.childs == null) {
                this.reportEventCriteriaDto.reportEventTypes.push(key);
            }
        });
        if (this.selectedProjectUser != null) {
            this.reportEventCriteriaDto.userId = this.selectedProjectUser != null ? this.selectedProjectUser.userId : null;
        } else if (this.selectedGroups != null) {
            this.selectedGroups.forEach((group) => this.reportEventCriteriaDto.groupIds.push(group.id));
        }
        this.pageable.sorts = this.sort();
        this.reportService.findEvents(this.reportEventCriteriaDto, this.pageable, this.visit).subscribe(
            (res: HttpResponse<ReportEventDto[]>) => this.onSuccess(res.body, res.headers),
            (res: HttpErrorResponse) => this.onError(res.message)
        );
    }

    reset() {
        this.reportEvents = [];
        this.pageable = new Pageable(0, ITEMS_PER_PAGE);
        this.paginator.pageIndex = 0;
        this.loadAll();
    }

    pageSwitch(event) {
        this.pageable.page = event.pageIndex;
        this.pageable.size = event.pageSize;
        this.loadAll();
    }

    search() {
        this.reportEvents = [];
        this.reportEventCriteriaDto = Object.assign(new ReportEventCriteriaDto(), this.reportEventCriteriaDtoTmp);
        this.reportEventCriteriaDtoTmp = null;
        this.loadAll();
    }

    changeGroup(event) {
        this.visit = true;
        this.selectedGroups = event;
        this.reset();
    }

    changeReportType(reportTypeItem: ReportTypeItem) {
        if (reportTypeItem.childs != null) {
            this.parentCheck(reportTypeItem);
        } else {
            this.childCheck(reportTypeItem);
        }

        this.visit = true;
        this.reset();
    }

    parentCheck(reportTypeItem: ReportTypeItem) {
        if (this.selectedReportTypeItems[reportTypeItem.key] != null) {
            reportTypeItem.childs.forEach((value: ReportTypeItem) => {
                this.selectedReportTypeItems[value.key] = null;
            });
            this.selectedReportTypeItems[reportTypeItem.key] = null;
        } else {
            reportTypeItem.childs.forEach((value: ReportTypeItem) => {
                this.selectedReportTypeItems[value.key] = value;
            });
            this.selectedReportTypeItems[reportTypeItem.key] = reportTypeItem;
        }
    }

    childCheck(reportTypeItem: ReportTypeItem) {
        if (this.selectedReportTypeItems[reportTypeItem.key] != null) {
            this.selectedReportTypeItems[reportTypeItem.key] = null;
            this.selectedReportTypeItems[reportTypeItem.parent.key] = null;
        } else {
            this.selectedReportTypeItems[reportTypeItem.key] = reportTypeItem;
            let allSelected = true;
            reportTypeItem.parent.childs.forEach((value) => {
                if (this.selectedReportTypeItems[value.key] == null) {
                    allSelected = false;
                }
            });

            if (allSelected) {
                this.selectedReportTypeItems[reportTypeItem.parent.key] = reportTypeItem.parent;
            }
        }
    }

    changeProjectUser() {
        this.visit = true;
        if (this.selectedProjectUser != null) {
            this.groupsExpansionPanel.close();
        }
        this.reset();
    }

    viewTypeChanged(viewType: ViewType) {
        this.viewType = viewType;
    }

    filterOpen() {
        this.reportEventCriteriaDtoTmp = Object.assign(new ReportEventCriteriaDto(), this.reportEventCriteriaDto);
    }

    export() {
        this.reportService.exportEvents(this.reportEventCriteriaDto);
    }

    chipsRemoveDateFrom() {
        this.reportEventCriteriaDto.dateFrom = null;
        this.reset();
    }

    chipsRemoveDateTo() {
        this.reportEventCriteriaDto.dateTo = null;
        this.reset();
    }

    chipsRemoveAll() {
        this.reportEventCriteriaDto = new ReportEventCriteriaDto();
        this.reset();
    }

    hasFilters() {
        return this.reportEventCriteriaDto.dateFrom != null
            || this.reportEventCriteriaDto.dateTo != null;
    }

    lastIsDifferentDay(idx: number): boolean {
        const currentDate = new Date(this.reportEvents[idx].date);
        const lastDate = new Date(this.reportEvents[idx - 1].date);

        return currentDate.getFullYear() !== lastDate.getFullYear()
            || currentDate.getMonth() !== lastDate.getMonth()
            || currentDate.getDay() !== lastDate.getDay();
    }

    private onSuccess(data, headers) {
        this.links = this.parseLinks.parse(headers.get('link'));
        this.totalItems = headers.get('X-Total-Count');
        this.reportEvents = data;
        this.dataSource.data = this.reportEvents;
        this.visit = false;
    }

    private onError(error) {
        this.jhiAlertService.error(error.message, null, null);
    }

    getReportEventTypeGroupValue(reportEventTypeGroup) {
        return this.translateService.instant(ReportEventTypeGroup[reportEventTypeGroup]);
    }

    getReportEventTypeValue(reportEventType) {
        return this.translateService.instant(ReportEventType[reportEventType]);
    }

    isReportTypeSelected(key: string): boolean {
        return this.selectedReportTypeItems[key] != null;
    }

    getFormattedTimezone() {
        return getFormattedTimezone();
    }

    getBlockchainIconColor(element: ReportEventDto): string {
        switch (element.blockchainStatus) {
            case BlockchainConfirmationStatus.PENDING:
            case BlockchainConfirmationStatus.CONFIRMED:
                return '#009E47';
            case BlockchainConfirmationStatus.ERROR:
                return '#F00';
            // case BlockchainConfirmationStatus.PENDING:
            default:
                return '#ffae00';
        }
    }

    getBlockchainDetailsTooltip(element: ReportEventDto): string {
        const details: string[] = [];
        details.push(this.translateService.instant(ReportBlockchain.CHECKSUM) + element.checksum);

        switch (element.blockchainStatus) {
            case BlockchainConfirmationStatus.CONFIRMED:
                details.push(this.translateService.instant(ReportBlockchain.TRANSACTION_ID) + element.blockchainTransactionId);
                details.push(this.translateService.instant(ReportBlockchain.CONFIRMED_AT) + element.blockchainCommittedAt);
                details.push(this.translateService.instant(ReportBlockchain.CONFIRMED));
                break;

            case BlockchainConfirmationStatus.PENDING:
                details.push(this.translateService.instant(ReportBlockchain.WAITING_FOR_CONFIRMATION));
                break;
        }

        return details.join('\n'); // title tag friendly new line
    }

    onCopyBlockchainDataClick(element: ReportEventDto) {
        if (element.blockchainStatus !== BlockchainConfirmationStatus.CONFIRMED) {
            return;
        }
        this.clipboardService.copy(element.blockchainTransactionId);
        this.jhiAlertService.info('report.blockchainDataCopied');
    }

    onProjectSelectionChange($value: any) {
        this.projects.subscribe((projects) => {
            this.reportEventCriteriaDto.projectId = $value.id;
            this.loadAll();
        });
    }


    getProjectName(projectId: number) {
        const projectListItems = this.projects.getValue().filter((p) => p.id === projectId);

        if (!projectListItems.length) {
            return ``;
        }

        return projectListItems[0].name;
    }

    setSelectedBlockchainItem(element) {
        this.selectedBlockchainItem = element;
    }

    onOpenBlockchainExplorer(selectedBlockchainItem: ReportEventDto) {
        const url = `http://node1.logpass.info/transactions/${selectedBlockchainItem.blockchainTransactionId}`;
        window.open(url, '_blank');
    }

    protected readonly BlockchainConfirmationStatus = BlockchainConfirmationStatus;
}

export enum ViewType {
    FEED, TABLE
}

export class ReportTypeItem {
    public key: string;
    public value: string;
    public childs: ReportTypeItem[];
    public parent: ReportTypeItem;
}


class ProjectListItem {
    id: number;
    name: string;

    constructor(project: ProjectVdr) {
        this.id = project.id;
        this.name = project.name;
    }
}
