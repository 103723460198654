import { Routes } from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {MonitoringComponent} from './monitoring/monitoring.component';
import {
    auditsRoute,
    configurationRoute,
    docsRoute,
    healthRoute,
    logsRoute,
    metricsRoute,
    userMgmtRoute,
    userDialogRoute
} from './';

import { UserRouteAccessService } from '../shared';

const ADMIN_ROUTES = [
    auditsRoute,
    configurationRoute,
    docsRoute,
    healthRoute,
    logsRoute,
    ...userMgmtRoute,
    metricsRoute
];

export const adminState: Routes = [{
    path: '',
    data: {
        authorities: ['USER_MANAGEMENT']
    },
    canActivate: [UserRouteAccessService],
    children: ADMIN_ROUTES
},
{
    path: 'dashboard',
    data: {
        authorities: ['USER_MANAGEMENT']
    },
    component: DashboardComponent,
    canActivate: [UserRouteAccessService],
},
{
    path: 'monitoring',
    data: {
        authorities: ['USER_MANAGEMENT']
    },
    component: MonitoringComponent,
    canActivate: [UserRouteAccessService],
},
    ...userDialogRoute
];
