import {
    FILE_FORMAT_COLOR_BMP,
    FILE_FORMAT_COLOR_DEFAULT,
    FILE_FORMAT_COLOR_DOC,
    FILE_FORMAT_COLOR_DOCX,
    FILE_FORMAT_COLOR_GIF,
    FILE_FORMAT_COLOR_JPEG,
    FILE_FORMAT_COLOR_JPG,
    FILE_FORMAT_COLOR_PDF,
    FILE_FORMAT_COLOR_PNG,
    FILE_FORMAT_COLOR_PPT,
    FILE_FORMAT_COLOR_PPTX,
    FILE_FORMAT_COLOR_RTF,
    FILE_FORMAT_COLOR_TIFF,
    FILE_FORMAT_COLOR_TXT,
    FILE_FORMAT_COLOR_XLS,
    FILE_FORMAT_COLOR_XLSX
} from '..';

export function formatColor(fileFormat: string) {
    const fileFormatLower = fileFormat.toLowerCase();
    switch (fileFormatLower) {
        // MS OFFICE
        case 'doc':
            return FILE_FORMAT_COLOR_DOC;
        case 'docx':
            return FILE_FORMAT_COLOR_DOCX;
        case 'xls':
            return FILE_FORMAT_COLOR_XLS;
        case 'xlsx':
            return FILE_FORMAT_COLOR_XLSX;
        case 'ppt':
            return FILE_FORMAT_COLOR_PPT;
        case 'pptx':
            return FILE_FORMAT_COLOR_PPTX;

        // IMG
        case 'png':
            return FILE_FORMAT_COLOR_PNG;
        case 'jpg':
            return FILE_FORMAT_COLOR_JPG;
        case 'jpeg':
            return FILE_FORMAT_COLOR_JPEG;
        case 'gif':
            return FILE_FORMAT_COLOR_GIF;
        case 'tiff':
            return FILE_FORMAT_COLOR_TIFF;
        case 'bmp':
            return FILE_FORMAT_COLOR_BMP;

        // OTHERS
        case 'rtf':
            return FILE_FORMAT_COLOR_RTF;
        case 'pdf':
            return FILE_FORMAT_COLOR_PDF;
        case 'txt':
            return FILE_FORMAT_COLOR_TXT;
    }

    return FILE_FORMAT_COLOR_DEFAULT;
}
