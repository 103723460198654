import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ReportService} from '../../report.service';
import {ReportUploadedCriteriaDto} from '../dto/report-uploaded-criteria-dto.model';
import {ProjectReportUploadedDTO, ReportUploadedDto} from '../dto/report-uploaded-dto.model';
import {MatTableDataSource} from '@angular/material/table';
import {NgxSpinnerService} from 'ngx-spinner';
import {moment} from 'fullcalendar';
import {ReportUploadedSingleDto} from '../dto/report-uploaded-single-dto.model';

@Component({
    templateUrl: 'report-uploaded-dialog.component.html'
})
export class ReportUploadedDialogComponent implements OnInit {

    dataSource = new MatTableDataSource<ProjectReportUploadedDTO>();
    displayedColumns = ['client', 'project', 'range_days', 'active', 'invoice', 'total'];
    reportUploadedCriteriaDto: ReportUploadedCriteriaDto;
    searchBy: string;
    data: ProjectReportUploadedDTO[];
    dataByClient: ProjectReportUploadedDTO[];

    @ViewChild('searchText') searchText: ElementRef;

    constructor(public dialogRef: MatDialogRef<ReportUploadedDialogComponent>,
                private reportService: ReportService,
                private spinner: NgxSpinnerService) {

    }

    ngOnInit() {
        this.reportUploadedCriteriaDto = new ReportUploadedCriteriaDto();
        this.reportUploadedCriteriaDto.dateTo = moment().utc();
        this.reportUploadedCriteriaDto.dateFrom = moment().subtract(1, 'months').utc();


        this.spinner.show();
        this.reportService.findProjectUploaded(this.reportUploadedCriteriaDto).subscribe((response) => {
            this.data = response;
            console.log(response);
            this.dataSource.data = this.dataSource.data.concat(response);
            this.initDataByClient();
            this.spinner.hide();
        } );
    }

    search(): void {

    }

    filterOpen(): void {
        this.reportUploadedCriteriaDto = Object.assign(new ReportUploadedCriteriaDto(), this.reportUploadedCriteriaDto);
    }

    chipsRemoveDateFrom() {
        this.reportUploadedCriteriaDto.dateFrom = null;
        // this.loadAll();
    }

    chipsRemoveDateTo() {
        this.reportUploadedCriteriaDto.dateTo = null;
        // this.loadAll();
    }

    chipsRemoveAll() {
        this.reportUploadedCriteriaDto = new ReportUploadedCriteriaDto();
        // this.loadAll();
    }

    hasFilters() {
        return this.reportUploadedCriteriaDto.dateFrom != null
            || this.reportUploadedCriteriaDto.dateTo != null;
    }

    searchByText(event: any): void {
        this.searchByString(event.target.value);
    }

    searchByString(text: string): void {
        if (this.displayedColumns.length === 5) {
            this.dataSource.data = this.dataByClient.filter((item) =>
                item.clientName.toLowerCase().indexOf(text.toLowerCase()) >= 0);
        } else {
            this.dataSource.data = this.data.filter((item) =>
                item.projectName.toLowerCase().indexOf(text.toLowerCase()) >= 0
                || item.clientName.toLowerCase().indexOf(text.toLowerCase()) >= 0);
        }
    }

    initDataByClient(): void {
        this.dataByClient = [];
        const group = this.data.reduce((tab: any, report) => {
            const key = report.clientName;
            if (!tab[key]) {
                tab[key] = [];
            }
            tab[key].push(report);
            return tab;
        }, {});

        const byClients = Object.keys(group).map((key) => ({
            clientName: key,
            reports: group[key]
        }));

        byClients.forEach((byClient) => {

            if (byClient.reports.length === 1) {
                this.dataByClient.push(byClient.reports[0]);
            } else {
                const projectReportUploaded = this.initProjectReportUploaded(byClient);
                byClient.reports.forEach((report: ProjectReportUploadedDTO) => {
                    this.addReportUploadedSingle(projectReportUploaded.reportUploaded.filtered, report.reportUploaded.filtered);
                    this.addReportUploadedSingle(projectReportUploaded.reportUploaded.sum, report.reportUploaded.sum);
                    this.addReportUploadedSingle(projectReportUploaded.reportUploaded.max, report.reportUploaded.max);
                    this.addReportUploadedSingle(projectReportUploaded.reportUploaded.total, report.reportUploaded.total);
                });

                this.dataByClient.push(projectReportUploaded);
            }
        });

    }

    groupByClient(event: any): void {

        if (event.checked) {
            this.displayedColumns = ['client', 'range_days', 'active', 'invoice', 'total'];
            this.dataSource.data = this.dataByClient;
            this.searchByString(this.searchText.nativeElement.value);
        } else {
            this.displayedColumns = ['client', 'project', 'range_days', 'active', 'invoice', 'total'];
            this.dataSource.data = this.data;
            this.searchByString(this.searchText.nativeElement.value);
        }
    }

    private initProjectReportUploaded(byClient: any): ProjectReportUploadedDTO {
        const projectReportUploaded = new ProjectReportUploadedDTO();
        projectReportUploaded.clientName = byClient.clientName;
        projectReportUploaded.reportUploaded = new ReportUploadedDto();
        projectReportUploaded.reportUploaded.filtered = this.initReportUploadedSingleDto();
        projectReportUploaded.reportUploaded.sum = this.initReportUploadedSingleDto();
        projectReportUploaded.reportUploaded.max = this.initReportUploadedSingleDto();
        projectReportUploaded.reportUploaded.total = this.initReportUploadedSingleDto();

        return projectReportUploaded;
    }

    private initReportUploadedSingleDto(): ReportUploadedSingleDto {
        const report = new ReportUploadedSingleDto();
        report.uploadedBytes = 0;
        report.documentsCount = 0;
        report.pagesCount = 0;
        return report;
    }

    private addReportUploadedSingle(report1: ReportUploadedSingleDto, report2: ReportUploadedSingleDto): void {
        report1.pagesCount += report2.pagesCount;
        report1.documentsCount += report2.documentsCount;
        report1.uploadedBytes += report2.uploadedBytes;
    }

    close(): void {
        this.dialogRef.close();
    }
}
