import {Component, OnInit} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from '@angular/router';

import {ActivateService} from './activate.service';
import {LoginModalService} from '../../shared';

@Component({
    selector: 'jhi-activate',
    templateUrl: './activate.component.html',
    styleUrls: ['activate.scss']
})
export class ActivateComponent implements OnInit {
    error: string;
    success: string;
    modalRef: NgbModalRef;

    constructor(
        private activateService: ActivateService,
        private loginModalService: LoginModalService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.activateService.get(params['key']).subscribe(() => {
                this.error = null;
                this.success = 'OK';
                this.login();
            }, () => {
                this.success = null;
                this.error = 'ERROR';
                this.errorLogin();
            });
        });
    }

    login() {
        this.modalRef = this.loginModalService.open('activate.messages.success');
    }
    errorLogin() {
        this.modalRef = this.loginModalService.open('');
    }
    errorLogin2() {
        this.modalRef = this.loginModalService.open('activate.messages.error2');
    }

}
