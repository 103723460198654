import {Injectable, Input} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from '../../app.constants';
import {UploadDownloadService} from '../../entities/directory-document/upload-download.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class Invitation {
    private commonUrl = SERVER_API_URL + 'api/';
    private downloadCSV = 'download-csv';
    private downloadType = 'CSV';

    @Input()
    httpRequestHeaders: HttpHeaders | {
        [header: string]: string | string[];
    } = new HttpHeaders().set('Content-Type', 'multipart/form-data');

    constructor(private http: HttpClient) {
    }

    save(emails: any, file: File, groupId: number, langKey: any): Observable<any> {
        const formdata: FormData = new FormData();
        formdata.append('file', file);
        formdata.append('langKey', langKey);
        formdata.append('groupId', groupId.toString());

        if (emails) {
            formdata.append('emails', emails);
        }
        const req = new HttpRequest('POST', '/api/invitation', formdata, {
            reportProgress: true,
            responseType: 'text'
        });
        return this.http.request(req).pipe(catchError(UploadDownloadService.parseErrorBlob));

    }

    downloadTemplate(fileName: any): Observable<HttpResponse<Blob>> {
        const params = new HttpParams().set('fileName', fileName).set('downloadType', this.downloadType);
        return this.http.post<Blob>(this.commonUrl + this.downloadCSV, 'CSV', {
            //params,
            responseType: 'blob' as 'json',
            observe: 'response'
        });
    }
}
