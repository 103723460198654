import {Route} from '@angular/router';

import {InvitationDialogComponent} from './invitation-dialog.component';

export const invitationRoute: Route = {
    path: 'invitation',
    component: InvitationDialogComponent,
    data: {
        authorities: ['USER_MANAGEMENT'],
        pageTitle: 'register.title'
    }
};
