import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {JhiAlertService, JhiEventManager, JhiParseLinks} from '@upside-cloud/ng-jhipster';

import {ITEMS_PER_PAGE, Principal, ProjectUser, User, UserService, NameDto} from '../../shared';
import {GroupService} from '../../shared/group/group.service';
import {Group} from '../../shared/group/group.model';
import {UserCriteriaDto} from './user-criteria-dto.model';
import {ConfirmationDialogService} from '../../entities/confirmation-dialog/confirmation-dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {ReportService} from '../../report';
import {ProjectVdr} from '@admin/project-vdr';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ClipboardService} from 'ngx-clipboard';
import {InvitationDialogComponent} from '../../account';
import {GroupDialogComponent} from './group/group-dialog.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

declare var navigator: any;

@Component({
    selector: 'jhi-user2',
    templateUrl: './user2.component.html',
    styleUrls: ['./user.scss']

})
export class User2Component{
    projects: NameDto[] = [];
    currentAccount: any;
    usersAll: ProjectUser[];
    error: any;
    success: any;
    routeData: any;
    links: any;
    totalItems: any;
    queryCount: any;
    itemsPerPage: any;
    page: any;
    predicate: any;
    previousPage: any;
    reverse: any;
    group: Group;
    testId: any;
    currentSearch: string;
    groups: Group[];
    filteredGroup: Group[];
    selectedUser: ProjectUser;
    isSaving: boolean;
    userCriteriaDto: UserCriteriaDto;
    questionPermissionArray = [null, 'QA', 'QA_WRITE', 'QA_SHARE', 'QA_DELETE'];
    currentProject: ProjectVdr;
    selectedTab: number;
    allUsersCount: number;


    constructor(
        public clipboardService: ClipboardService,
        private userService: UserService,
        private groupService: GroupService,
        private alertService: JhiAlertService,
        private principal: Principal,
        private parseLinks: JhiParseLinks,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private eventManager: JhiEventManager,
        private confirmationDialogService: ConfirmationDialogService,
        private translateService: TranslateService,
        private reportService: ReportService,
        private snackBar: MatSnackBar,
        private dialog: NgbModal
    ) {
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.routeData = this.activatedRoute.data.subscribe((data) => {
            if(data['pagingParams']){
            this.page = data['pagingParams'].page;
            this.previousPage = data['pagingParams'].page;
            this.reverse = data['pagingParams'].ascending;
            this.predicate = data['pagingParams'].predicate;
            }
        });
    }
ngOnInit() {
        this.currentProject = this.principal.getUserIdentity().project;
        this.selectedUser = null;
        this.principal.identity(true).then((account) => {
            this.userCriteriaDto = new UserCriteriaDto();
            this.userCriteriaDto.status = 'a';
            this.currentAccount = account;
            this.loadAll();
            this.registerChangeInUsers();
            this.registerGroupSaveModification();
        });
    }

    findFilterdGroups(isadmin: boolean) {
        if (isadmin) {
            return this.groups.filter((group) => (group.role.toString() === 'ADMIN' || group.role.toString() === 'ADMIN_LIMITED'));
        } else {
            return this.groups.filter((group) => (group.role.toString() !== 'ADMIN' && group.role.toString() !== 'ADMIN_LIMITED'));
        }
    }

    /* save() {
         this.isSaving = true;
         if (this.selectedUser.group && this.selectedUser.group.users) {
             this.selectedUser.group.users = null;
         }
         if (this.selectedUser.id !== null) {
             this.userService.update(this.selectedUser).subscribe((response) => this.onSaveSuccess(response), () => this.onSaveError());
         } else {
             this.userService.create(this.selectedUser).subscribe((response) => this.onSaveSuccess(response), () => this.onSaveError());
         }
     }*/

    isUserRowSelected(user: ProjectUser) {
        if (this.selectedUser) {
            return user.id === this.selectedUser.id;
        }
        return false;
    }

    selectProject(projectId: number) {
        this.userCriteriaDto.selectedGroupId = projectId;
        this.criteriaChanged();
    }

    changeGroupTypeAdmin(projectUser: ProjectUser, admin: boolean) {
        projectUser.groupAdmin = admin;
    }

    selectUser(user: ProjectUser) {
        this.selectedUser = user;
        this.selectedTab = 0;
    }

    loadProjectGroups() {

        this.groupService.getProjectGroups().subscribe(
            (res: HttpResponse<Group[]>) => {
                this.groups = res.body.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });

                this.groups.forEach((u, index) => u.name = 'Project '+(index+1));


                this.currentSearch = '';
            },
            (res: HttpErrorResponse) => this.onError(res.message)
        );
    }

    clear() {
        this.currentSearch = '';
        this.loadAll();
    }

    search(name) {
        this.userCriteriaDto.name = name;
        this.loadAll();
    }

    ngOnDestroy() {
        this.routeData.unsubscribe();
    }

    registerChangeInUsers() {
        this.eventManager.subscribe('userListModification', (response) => this.loadAll());
    }

    registerGroupSaveModification() {
        this.eventManager.subscribe('groupListModification', (response) => this.loadProjectGroups());

    }

    setActive(projectUser: ProjectUser, isActivated, skipInfo = false) {

        this.userService.switchActive(projectUser, skipInfo).subscribe(
            (response) => {
                if (response.status === 200) {
                    this.error = null;
                    this.success = 'OK';
                    projectUser.active = !projectUser.active;
                    // this.loadAll();
                } else {
                    this.success = null;
                    this.error = 'ERROR';
                }
            });
    }


    loadAll() {
        this.loadProjects();
        this.loadProjectUsers();
    }

    loadProjects() {
         this.userService.findAdminProjects().subscribe(projects => this.projects = projects.sort((a, b) => (a.name.toLowerCase().localeCompare(b.name.toLowerCase()))));
    }

    loadProjectUsers(): void {
        this.userService.findAdminProjectUsers(this.userCriteriaDto.name, this.userCriteriaDto.selectedGroupId).subscribe(
            (res: HttpResponse<User[]>) => this.onSuccess(res.body, res.headers),
            (res: HttpResponse<any>) => this.onError(res.body)
        );
    }

    trackIdentity(index, item: User) {
        return item.id;
    }

    getGroupCount(group: Group) {
        if (!this.usersAll || !this.groups) {
            return null;
        }
        return this.usersAll.filter((user) => {
            return user.groupId === group.id;
        }).length;
    }

    sort() {
        const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }

    loadPage(page: number) {
        if (page !== this.previousPage) {
            this.previousPage = page;
            this.transition();
        }
    }

    transition() {
        const moveSmaller = this.reverse ? 1 : -1;
        const moveLarger = this.reverse ? -1 : 1;
        if (this.usersAll) {
            this.usersAll.sort((a, b) => {
                if (a[this.predicate] <= b[this.predicate]) {
                    return moveSmaller;
                } else {
                    return moveLarger;
                }
            });
        }
    }

    chooseAccountStatus(accountStatus) {
        this.userCriteriaDto.status = accountStatus;
        this.criteriaChanged();
    }

    private onSuccess(data, headers) {

        this.usersAll = data.map((projectUser) => new ProjectUser(projectUser));

        this.allUsersCount = this.usersAll.length;
    }

    private delete(data, headers) {
        this.groupService.delete(this.testId).subscribe((response) => {
            this.success = 'OK';
        }, () => this.success = null);
    }

    private onError(error) {
        this.success = null;
    }


    criteriaChanged() {
        this.loadAll();
    }

    criteriaNameRemove() {
        this.userCriteriaDto.name = null;
        this.criteriaChanged();
    }

    criteriaNameChanged() {
        if (!this.userCriteriaDto.name) {
            this.criteriaChanged();
        }
    }

    deleteGroup(group: Group) {
        this.confirmationDialogService.confirm({
            'title': this.translateService.instant('entity.delete.title'),
            'text': this.translateService.instant('vdrApp.group.delete.question', {name: group.name}),
            'closeLabel': this.translateService.instant('entity.action.cancel'),
            'confirmLabel': this.translateService.instant('entity.action.delete')
        }).subscribe((res) => {
            if (res) {
                this.groupService.delete(group.id.toString()).subscribe((response) => {
                    this.eventManager.broadcast({
                        name: 'userListModification',
                        content: 'Deleted a group'
                    });
                });
            }
        });
    }



    findProjectName(projectId: number){
        return this.projects.find(p=>p.id===projectId)?.name;
    }

    activateAll(active: boolean) {
        let counter = 0;
        this.usersAll.forEach(user=> {
            if(user.active !== active) {
                this.setActive(user, active, true);
                counter++;
            }
        });
        this.alertService.success('global.messages.info.success-update');
          /*this.alertService.info('Użytkowników aktywowanych:'
          + active ? 'aktywowanych' : 'dezaktywowanych'
          + counter);*/
    }
}




