import {Moment} from 'moment';

export class ReportOverviewCriteriaDto {
    constructor(
        public dateFrom?: Moment,
        public dateTo?: Moment,
        public groupIds?: number[],
        public userIds?: number[]
    ) { }
}
