import {Pipe, PipeTransform} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {DirectoryDocumentRowDto} from '../dto/directory-document-row-dto.model';
@Pipe({name: 'deleteFilesAvailable',
        pure: false})
export class DeleteFilesAvailablePipe implements PipeTransform {

    transform(canModifyCurrentDirectory: boolean, nodeType: string, selection: SelectionModel<DirectoryDocumentRowDto>): boolean {
        if (!canModifyCurrentDirectory) {
            return false;
        }

        if (nodeType === 'QA') {
            return false;
        }
        const directoryDocumentRowDtos = selection.selected;
        const ret = directoryDocumentRowDtos.length > 0 && directoryDocumentRowDtos
            .every((row) => !(row.type === 'directory' && row.parentDirectoryId === null));

        return ret;
    }

}
