<ng-container [ngTemplateOutlet]="customFindbar ? customFindbar : defaultFindbar"> </ng-container>

<ng-template #defaultFindbar>
  <div
    class="findbar hidden doorHanger"
    id="findbar"
    [style.transform]="'scale(' + mobileFriendlyZoomScale + ')'"
    [style.transformOrigin]="'left top'"
    [style.left]="findbarLeft"
    [style.top]="findbarTop"
  >
    <ng-container [ngTemplateOutlet]="customFindbarButtons ? customFindbarButtons : defaultFindbarButtons"> </ng-container>
  </div>
</ng-template>

<ng-template #defaultFindbarButtons>
  <pdf-find-input-area [customFindbarInputArea]="customFindbarInputArea"></pdf-find-input-area>
  <pdf-find-highlight-all [class.hidden]="!showFindHighlightAll"></pdf-find-highlight-all>
  <pdf-find-match-case [class.hidden]="!showFindMatchCase"></pdf-find-match-case>
  <pdf-match-diacritics [class.hidden]="!showFindMatchDiacritics"></pdf-match-diacritics>
  <pdf-find-entire-word [class.hidden]="!showFindEntireWord"></pdf-find-entire-word>
  <pdf-find-results-count [class.hidden]="!showFindResultsCount"></pdf-find-results-count>
  <pdf-findbar-message-container [class.hidden]="!showFindMessages"></pdf-findbar-message-container>
</ng-template>
