import {faro} from '@grafana/faro-web-sdk';
import {ErrorHandler, Injectable} from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    handleError(error: any) {
        if (error instanceof Error) {
            faro.api.pushError(error);
        }
        console.error(error);
    }
}
