import {Route} from '@angular/router';

import {UserRouteAccessService} from '../../shared';
import {InfoComponent} from './info.component';

export const infoRoute: Route = {
    path: 'account-info',
    component: InfoComponent,
    data: {
        authorities: ['ROLE_USER', 'ROLE_ADMIN'],
        pageTitle: 'info.title'
    },
    canActivate: [UserRouteAccessService]
};
