import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {VdrSharedModule} from '../../shared';
import {
    projectRoute,
    ProjectVdrComponent,
    ProjectVdrDeleteDialogComponent,
    ProjectVdrDialogComponent,
    ProjectVdrPopupComponent,
    ProjectVdrPopupService,
    ProjectVdrResolvePagingParams,
    ProjectVdrService,
} from './';
import {RadioButtonModule} from 'primeng/radiobutton';
import {VdrAccountModule} from '@admin/../account/account.module';
import {TranslateModule} from '@ngx-translate/core';
import {SafePipeModule} from 'safe-pipe';
import {ClientModule} from '@entities/client/client.module';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input-gg';

@NgModule({
    imports: [
        VdrSharedModule,
        RouterModule.forChild(projectRoute),
        RadioButtonModule,
        VdrAccountModule,
        TranslateModule,
        SafePipeModule,
        ClientModule,
        NgxIntlTelInputModule,
    ],
    declarations: [
        ProjectVdrComponent,
        ProjectVdrDialogComponent,
        ProjectVdrDeleteDialogComponent,
        ProjectVdrPopupComponent
    ],
    entryComponents: [
        ProjectVdrComponent,
        ProjectVdrDialogComponent,
        ProjectVdrPopupComponent,
        ProjectVdrDeleteDialogComponent
    ],
    providers: [
        ProjectVdrService,
        ProjectVdrPopupService,
        ProjectVdrResolvePagingParams,
    ],
    exports: [
            ProjectVdrComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VdrProjectVdrModule {}




