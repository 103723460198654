import {DocumentType} from './document-type.enum';
import {SearchIn} from './search-in.enum';
import {Moment} from 'moment';

export class DocumentCriteriaDto {
    public directoryId?: number;
    public favourite?: boolean;
    public read?: boolean;
    public readFrom?: Moment;
    public readTo?: Moment;
    public createdFrom?: Moment;
    public createdTo?: Moment;
    public documentTypes?: DocumentType[];


    constructor(
    ) {
    }

    public hasFilters() {
        return this.favourite === true
            || this.read != null
            || (this.documentTypes != null && this.documentTypes.length > 0);
    }
}
