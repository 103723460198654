import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {SelectedItemDto} from './directory-document-tree.component';
import {GroupObject} from './group-list.component';
import {DirectoryDocumentPermissionService} from './directory-document-permission.service';
import {DocumentPermissionType} from './document-permission-type.enum';
import {NgxSpinnerService} from 'ngx-spinner';
import {ChangePermissionRequest} from './change-permission-request.model';
import {Observable} from 'rxjs';
import {PermissionDto} from './permission-dto.model';
import {JhiAlertService}from '@upside-cloud/ng-jhipster';
import {group} from '@angular/animations';

@Component({
    selector: 'permission-detail',
    templateUrl: 'permission-detail.component.html',
    styleUrls: ['../directory-document.scss']
})
export class PermissionDetailComponent implements OnChanges {

    @Input() selectedObject: SelectedItemDto;
    @Input() selectedGroup: GroupObject;
    currentPermission: DocumentPermissionType;
    topLevel = false;

    constructor(
        private permissionService: DirectoryDocumentPermissionService,
        private spinner: NgxSpinnerService,
        private alertService: JhiAlertService
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!(this.selectedObject && this.selectedGroup)) {
            return;
        }

        this.spinner.show();
        this.permissionService.permission(this.selectedGroup.groupId, this.selectedObject.itemId, this.selectedObject.directory)
            .subscribe((permission) => {
                this.currentPermission = DocumentPermissionType[permission.permissionType.toString()];
                this.topLevel = this.topPermissionAllowed();
                this.spinner.hide();
            }, () => {
                this.currentPermission = null;
                this.spinner.hide();
            });
    }

    isPermissionActive(level: number): boolean {
        if (this.topPermissionAllowed()) {
            return true;
        } else if (this.currentPermission || this.currentPermission === 0) {
            return this.currentPermission.valueOf() >= level;
        }
        return false;
    }

    disabled() {
        return !this.selectedGroup || !this.selectedObject || this.topPermissionAllowed();
    }

    onToggle(event, level: number) {
        if (event.checked) {
            this.currentPermission = DocumentPermissionType[DocumentPermissionType[level]];
            this.changePermission();
        } else {
            if (level > 0) {
                this.currentPermission = DocumentPermissionType[DocumentPermissionType[level - 100]];
            } else {
                this.currentPermission = DocumentPermissionType.NONE;
            }

            this.changePermission();
        }
    }

    changePermission() {
        const dir = this.selectedObject.directory;
        const request = new ChangePermissionRequest(this.selectedObject.itemId, DocumentPermissionType[this.currentPermission.toString()], this.selectedGroup.groupId);
        if (dir) {
            this.subscribeToSaveResponse(this.permissionService.setDirectoryPermission(request));
        } else {
            this.subscribeToSaveResponse(this.permissionService.setDocumentPermission(request));
        }
    }

    private subscribeToSaveResponse(result: Observable<PermissionDto>) {
        this.spinner.show();
        result.subscribe((res: PermissionDto) => {
            this.currentPermission = DocumentPermissionType[res.permissionType.toString()];
            this.alertService.info('permission.success');
            this.spinner.hide();

        }, () => {
            this.alertService.error('permission.failure');
            this.spinner.hide();
        });
    }

    topPermissionAllowed(): boolean {
        if (!this.selectedGroup) {
            return false;
        }
        const role = this.selectedGroup.role.toLocaleString();
        return role === 'ADMIN' || role === 'ADMIN_LIMITED';
    }

}
