export const enum RecipientType {
    'TO',
    'CC',
    'BCC'
}

export class Recipient {

    static fromUser(userId: number, userName: string): Recipient {
        return new Recipient(
            userId, null, 'TO', userName
        );
    }

    constructor(
        public userId: number,
        public groupId: number,
        public recipientType = 'TO',
        public recipientName?: string,
        public groupName?: string
    ) {
    }
}
