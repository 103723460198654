<form name="deleteForm" (ngSubmit)="confirmDelete()">
    <div class="modal-header">
        <h4 class="modal-title" jhiTranslate="entity.delete.title">Confirm delete operation</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                (click)="close()">&times;</button>
    </div>
    <div class="modal-body">
        <jhi-alert-error></jhi-alert-error>
        <p>{{info}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">
            <span class="fa fa-ban"></span>&nbsp;<span jhiTranslate="entity.action.cancel">Cancel</span>
        </button>
        <button type="submit" class="btn btn-danger">
            <span class="fa fa-remove"></span>&nbsp;<span jhiTranslate="entity.action.delete">Delete</span>
        </button>
    </div>
</form>
