import {Route} from '@angular/router';

import {PdfDocumentViewerComponent} from './pdf-document-viewer.component';

export const pdfDocumentViewerRoute: Route = {
    path: 'pdf-viewer/:id',
    component: PdfDocumentViewerComponent,
    data: {
        authorities: [],
    }
};
