import {Routes} from '@angular/router';

import {
    activateRoute,
    passwordResetFinishRoute,
    passwordResetInitRoute,
    registerRoute,
    sessionsRoute,
    settingsRoute,
    userSettingsRoute,
    invitationRoute,
    pdfDocumentViewerRoute
} from './';
import {infoRoute} from './info/info.route';
import {questionResetRoute} from './security/question-reset/question-reset.route';
import {phoneResetRoute} from './security/phone-reset/phone-reset.route';
import {securitySettingsRoute} from './security-settings/security-settings.route';
import {passwordResetRoute} from './security/password-reset/password-reset.route';
import {project2Route} from './project2/project2.route';
import {UserRouteAccessService} from '../shared';

const ACCOUNT_ROUTES = [
    activateRoute,
    passwordResetFinishRoute,
    passwordResetInitRoute,
    registerRoute,
    sessionsRoute,
    ...settingsRoute,
    infoRoute,
    invitationRoute,
    questionResetRoute,
    phoneResetRoute,
    securitySettingsRoute,
    ...passwordResetRoute,
    pdfDocumentViewerRoute,
    project2Route
];

export const accountState: Routes = [{
    path: '',
    children: ACCOUNT_ROUTES,
    data: {
        authorities: [],
        pageTitle: 'security.title'
    },
    canActivate: [UserRouteAccessService]

}];
