<div class="pdf-password-protection-dialog">
    <div fxLayout="row">
        <span mat-dialog-title>
            <span><i class="fa fa-lock ng-star-inserted"></i></span>
            <span *ngIf="data.archiveMode">{{ 'vdrApp.document.pdfPasswordProtectionDialog.titleForArchive' | translate}}</span>
            <span *ngIf="!data.archiveMode">{{ 'vdrApp.document.pdfPasswordProtectionDialog.title' | translate}}</span>
        </span>
    </div>
    <div class="input-group mb-3">
        <input id="password" type="text" class="form-control" [(ngModel)]="data.pdfPassword" >
        <div class="input-group-append">
            <button
                ngxClipboard [cbContent]="data.pdfPassword"
                matTooltip="{{ 'vdrApp.document.pdfPasswordProtectionDialog.copyToClipboard' | translate }}"
                class="btn btn-outline-secondary" type="button" (click)="copyPasswordToClipboard()">
                <i class="fa fa-clipboard"></i>
            </button>
        </div>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">
        <span class="message-container">
            <span *ngIf="copiedToClipboard" jhiTranslate="vdrApp.document.pdfPasswordProtectionDialog.copiedToClipboard">Copied</span>
        </span>
        <button mat-raised-button class="btn-primary" (click)="close()">OK</button>
    </div>
</div>
