import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ReportArchive, ReportArchiveOption} from "./report-archive.model";

@Component({
    templateUrl: 'report-archive.dialog.html'
})

export class ReportArchiveDialog {

    entitlements = false;
    documents = false;
    users = false;
    qa = false;
    history = false;
    uploadedData = false;
    overview = false;

    constructor(public dialogRef: MatDialogRef<ReportArchiveDialog>,
                @Inject(MAT_DIALOG_DATA) public data: ReportArchive) {
    }

    close(): void {
        this.dialogRef.close();
    }

    generate(): void {
        let selectedOptions = [];
        if(this.entitlements) {
            selectedOptions.push(ReportArchiveOption.ENTITLEMENTS);
        }
        if(this.documents) {
            selectedOptions.push(ReportArchiveOption.DOCUMENTS);
        }
        if(this.users) {
            selectedOptions.push(ReportArchiveOption.USERS);
        }
        if(this.qa) {
            selectedOptions.push(ReportArchiveOption.QA);
        }
        if(this.history) {
            selectedOptions.push(ReportArchiveOption.HISTORY);
        }
        if(this.uploadedData) {
            selectedOptions.push(ReportArchiveOption.UPLOADED_DATA);
        }
        if(this.overview) {
            selectedOptions.push(ReportArchiveOption.OVERVIEW);
        }

        this.data.selectedOptions = selectedOptions;
        this.dialogRef.close(this.data);
    }

}
