import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import { VdrSharedModule } from '../../shared';
import { VdrAdminModule } from '../../admin/admin.module';
import {
    AnswerService,
} from './';

@NgModule({
    imports: [
        VdrSharedModule,
        VdrAdminModule,
    ],
    providers: [
        AnswerService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VdrAnswerModule {}
