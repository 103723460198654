<div class="help">

    <div class="panel d-flex col-md-8 flex-column">
        <mat-tab-group  >
            <mat-tab label="News" class="custom-mat-tab">

        <mat-accordion class="example-headers-align" multi>
            <!-- #enddocregion multi -->
            <mat-expansion-panel *ngFor="let dashboard of dashboards">
                <mat-expansion-panel-header>
                    <mat-panel-title style="width:5%; margin-top: 6px;">
                        <span>
                        {{dashboard.name}}
                            </span>
                    </mat-panel-title>

                    <mat-panel-description>
                        <button type="button" *ngIf="dashboard.active"
                                class="btn btn-success btn-sm"
                                mat-raised-button
                                (click)="chooseAccountStatus('a')">
                            <span class="d-none d-md-inline"
                                  jhiTranslate="userManagement.filter.status.active">Aktywny</span>
                        </button>

                        <button type="button" *ngIf="!dashboard.active"
                                class="btn btn-danger btn-sm"
                                mat-raised-button
                                (click)="chooseAccountStatus('a')">
                            <span class="d-none d-md-inline"
                                  jhiTranslate="userManagement.filter.status.inactive">Nieaktywny</span>
                        </button>

                        <mat-icon style="line-height: 40px;" class="ml-auto">accessibility</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="d-flex justify-content-between" style="width:600px;">
                    <mat-form-field>
                        <mat-label><span jhiTranslate="vdrApp.project.name"></span></mat-label>
                        <input [(ngModel)]="dashboard.name" matInput>
                    </mat-form-field>

                    <mat-form-field>
                        <input matInput [matDatepicker]="picker" placeholder="{{'entity.common.date' | translate}}" [(ngModel)]="dashboard.date">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <mat-slide-toggle [(ngModel)]="dashboard.active" name="enableWifi"><span jhiTranslate="userManagement.filter.status.active"></span></mat-slide-toggle>

                </div>

                <p-editor class="new-answer-content" name="answer-content" id="answer-content"
                          placeholder="{{ 'vdrApp.question.content' | translate }}" maxlength="10000"
                          [(ngModel)]="dashboard.content" [style]="{'height':'250px'}"
                          required>
                    <p-header>
                                        <span class="ql-formats">
                                            <select class="ql-size">
                                              <option value="small">Petit</option>
                                              <option selected>Normalne</option>
                                              <option value="large">Sous-titre</option>
                                              <option value="huge">Titre</option>
                                            </select>
                                          </span>
                        <span class="ql-formats">
                                            <button class="ql-bold" aria-label="Bold"></button>
                                            <button class="ql-italic" aria-label="Italic"></button>
                                            <button class="ql-underline" aria-label="Underline"></button>
                                            <button class="ql-strike" aria-label="Strike"></button>
                                        </span>
                        <span class="ql-formats">
                                            <button class="ql-list" value="ordered"></button>
                                            <button class="ql-list" value="bullet"></button>
                                            <select class="ql-align" >
                                                <option selected></option>
                                                <option value="center" label="Center"></option>
                                                <option value="right" label="Right"></option>
                                                <option value="justify" label="Justify"></option>
                                            </select>
                                        </span>
                        <span class="ql-formats">
                                            <select class="ql-color"></select>
                                            <select class="ql-background"></select>
                                        </span>
                    </p-header>
                </p-editor>

                <div class="mt-2 d-flex justify-content-end">
                    <button class="btn mr-2" (click)="cancel(dashboard)" jhiTranslate="entity.action.cancel">Anuluj</button>
                    <button class="btn btn-primary" (click)="save(dashboard)" jhiTranslate="entity.action.save">Zapisz</button>
                </div>

            </mat-expansion-panel>
            <!-- #docregion disabled -->

        </mat-accordion>
                <button class="btn btn-primary float-right mt-2" (click)="createEmpty()" jhiTranslate="entity.action.add">Dodaj2</button>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
