import {NgModule, LOCALE_ID} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {registerLocaleData} from '@angular/common';
import locale from '@angular/common/locales/pl';

import {
    VdrSharedLibsModule,
    JhiLanguageHelper,
    FindLanguageFromKeyPipe,
    JhiAlertComponent,
    JhiAlertErrorComponent
} from './';
import {CustomMaterialModule} from '../material/material.module';

@NgModule({
    imports: [
        VdrSharedLibsModule,
        CustomMaterialModule
    ],
    declarations: [
        JhiAlertComponent,
        JhiAlertErrorComponent,

    ],
    providers: [

    ],
    exports: [
        VdrSharedLibsModule,
        JhiAlertComponent,
        JhiAlertErrorComponent,
        CustomMaterialModule
    ]
})
export class VdrSharedCommonModule {
    constructor() {
        registerLocaleData(locale);
    }
}
