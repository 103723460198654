import { Pipe, PipeTransform } from '@angular/core';
import {Principal} from "../../../shared";
import {Question} from "../question.model";

@Pipe({name: 'checkQuestionDeletePipe'})
export class CheckQuestionDeletePipePipe implements PipeTransform {

    constructor(private principal: Principal){

    }

    transform(question: Question): boolean {
        const ret =  this.principal.hasAnyAuthorityDirect(['QA_DELETE']) && (this.principal.hasAnyAuthorityDirect(['ADMIN','ADMIN_LIMITED']) ||
            (this.isCurrentUserAuthor(question)));
        console.log('ret:'+ret+' delete2:'+this.principal.hasAnyAuthorityDirect(['QA_DELETE'])+' admin:'+(this.principal.hasAnyAuthorityDirect(['ADMIN','ADMIN_LIMITED']))+' author:'+this.isCurrentUserAuthor(question) );

        return ret;
    }

    isCurrentUserAuthor(question: Question): boolean {
        return this.principal.getUserIdentity() &&
            question.authorId === this.principal.getUserIdentity().id;
    }
}
