import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from '../../../app.constants';
import {map} from 'rxjs/operators';
import Questions, {default as SecurityQuestion} from './question-reset.model';

@Injectable()
export class QuestionResetService {

    questions: any;
    constructor(private http: HttpClient) {
    }

    save(data: any): Observable<HttpResponse<any>> {
        return this.http.post(SERVER_API_URL + 'api/question-reset', data, {observe: 'response'});
    }

    getQuestions(force?: boolean): Promise<any> {
        if (force === true) {
            this.questions = undefined;
        }

        // check and see if we have retrieved the questions data from the server.
        // if we have, reuse it by immediately resolving
        if (this.questions) {
            return Promise.resolve(this.questions);
        }

        // retrieve the questions data from the server, update the questions object, and then resolve.
        return this.get().toPromise().then((response) => {
            const questions = response.body;
            if (questions) {
                this.questions = questions;
            } else {
                this.questions = null;
            }
            return this.questions;
        }).catch((err) => {
            this.questions = null;
            return null;
        });
    }

    get(): Observable<HttpResponse<SecurityQuestion[]>> {
        return this.http.get<SecurityQuestion[]>(SERVER_API_URL + 'api/security-questions', {observe: 'response'});
    }
}
