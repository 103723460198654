import {Moment} from 'moment';
import {moment} from 'fullcalendar';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

export function httpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/i18n/');
}


export const enumValues = (enumerator) => {
    const values = [];
    const valuesTmp = Object.keys(enumerator).filter((value, index) => {
        return (index % 2);
    });

    for (const k of valuesTmp) {
        values.push(enumerator[k]);
    }

    return values;
};

export const enumKeys = (enumerator) => {
    const keys = [];
    const keysTmp = Object.keys(enumerator).filter((value, index) => {
        return !(index % 2);
    });

    for (const k of keysTmp) {
        keys.push(k);
    }

    return keys;
};

export const enumToKeyValue = (enumerator) => {
    const keyValue = [];
    const keys = Object.keys(enumerator).filter((value, index) => {
        return !(index % 2);
    });

    for (const k of keys) {
        keyValue.push({key: k, value: enumerator[k]});
    }

    return keyValue;
};

export const enumKeyValueFilter = (keyValue, keys) => {
    const keyValueFiltered = [];

    for (let i = 0; i < keyValue.length; i++) {
        const kV = keyValue[i];
        if (keys.indexOf(kV.key) !== -1) {
            keyValueFiltered.push(kV);
        }
    }

    return keyValueFiltered;
};

export const momentStartOfDay = (m: Moment) => {
    const copy = m.clone();
    return copy.startOf('day');
};

export const momentEndOfDay = (m: Moment) => {
    const copy = m.clone();
    return copy.endOf('day').add(-3, 'milliseconds');
};

export const getFormattedTimezone = () => {
    const timezoneOffset = moment().utcOffset();
    const mod = timezoneOffset % 60;
    return 'UTC'
        + (timezoneOffset >= 0 ? '+' : '-')
        + (timezoneOffset >= 600 || timezoneOffset <= -600 ? '' : '0')
        + Math.floor(timezoneOffset / 60 * (timezoneOffset >= 0 ? 1 : -1))
        + ':' + (mod !== 0 ? mod * (timezoneOffset >= 0 ? 1 : -1) : '00');
};
