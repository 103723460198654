import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from '../../../app.constants';

@Injectable()
export class PasswordResetService {

    constructor(private http: HttpClient) {
    }

    save(data, isPhone: boolean): Observable<HttpResponse<any>> {
        if (isPhone) {
            return this.changePasswordWithPhone(data);
        } else {
            return this.changePasswordWithQuestion(data);
        }
    }

    changePasswordWithPhone(data: any): Observable<HttpResponse<any>> {
        return this.http.post(SERVER_API_URL + 'api/account/change-password-phone', data, {observe: 'response'});
    }

    changePasswordWithQuestion(data: any): Observable<HttpResponse<any>> {
        return this.http.post(SERVER_API_URL + 'api/account/change-password-question', data, {observe: 'response'});
    }

    sendVerificationCode(data: any): Observable<HttpResponse<any>> {
        return this.http.post(SERVER_API_URL + 'api/sendVerificationCode/-1', data, {observe: 'response'});
    }
}
