import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {RouterModule} from '@angular/router';

import {VdrSharedModule} from '../../shared';
import {CheckboxModule} from 'primeng/checkbox';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {VdrAdminModule} from '../../admin/admin.module';
import {FileUploadModule} from 'primeng/fileupload';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';

import {
    QuestionService,
    QuestionPopupService,
    QuestionComponent,
    QuestionDialogComponent,
    QuestionPopupComponent,
    QuestionDeletePopupComponent,
    QuestionDeleteDialogComponent,
    questionRoute,
    questionPopupRoute,
    QuestionResolvePagingParams

} from './';
import {AnswerService} from '../answer';
import {RecipientService} from './recipient.service';
import {CategoryDialogComponent} from './category/category-dialog.component';
import {CategoryService} from './category.service';
import {TranslateModule} from '@ngx-translate/core';
import {EditorModule} from "primeng/editor";
import {CheckAnswerDeletePipe} from "./pipe/check-answer-delete.pipe";
import {CheckQuestionDeletePipePipe} from "./pipe/check-question-delete.pipe";

const ENTITY_STATES = [
    ...questionRoute,
    ...questionPopupRoute,
];

@NgModule({
    imports: [
        VdrSharedModule,
        VdrAdminModule,
        CheckboxModule,
        AutoCompleteModule,
        FileUploadModule,
        NgMultiSelectDropDownModule.forRoot(),
        RouterModule.forChild(ENTITY_STATES),
        TranslateModule,
        [EditorModule]
    ],
    declarations: [
        QuestionComponent,
        QuestionDialogComponent,
        QuestionDeleteDialogComponent,
        QuestionPopupComponent,
        QuestionDeletePopupComponent,
        CategoryDialogComponent,
        CheckAnswerDeletePipe,
        CheckQuestionDeletePipePipe
    ],
    entryComponents: [
        QuestionComponent,
        QuestionDialogComponent,
        QuestionPopupComponent,
        QuestionDeleteDialogComponent,
        QuestionDeletePopupComponent,
        CategoryDialogComponent
    ],
    providers: [
        QuestionService,
        RecipientService,
        QuestionPopupService,
        QuestionResolvePagingParams,
        AnswerService,
        CategoryService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VdrQuestionModule {
}
