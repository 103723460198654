<div>
    <h2>
        <span jhiTranslate="vdrApp.file.home.title">Files</span>
        <button class="btn btn-primary float-right jh-create-entity create-file" [routerLink]="['/', { outlets: { popup: ['file-new'] } }]">
            <span class="fa fa-plus"></span>
            <span class="hidden-sm-down"  jhiTranslate="vdrApp.file.home.createLabel">
            Create new File
            </span>
        </button>
    </h2>
    <jhi-alert></jhi-alert>
    <div class="row">
        <div class="col-sm-12">
            <form name="searchForm" class="form-inline">
                <div class="input-group w-100 mt-3">
                    <input type="text" class="form-control" [(ngModel)]="currentSearch" id="currentSearch" name="currentSearch" placeholder="{{ 'vdrApp.file.home.search' | translate }}">
                    <button class="input-group-append btn btn-info" (click)="search(currentSearch)">
                        <span class="fa fa-search"></span>
                    </button>
                    <button class="input-group-append btn btn-danger" (click)="clear()" *ngIf="currentSearch">
                        <span class="fa fa-trash-o"></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
    <br/>
    <div class="table-responsive" *ngIf="files">
        <table class="table table-striped">
            <thead>
            <tr jhiSort [(predicate)]="predicate" [(ascending)]="reverse" [callback]="reset.bind(this)">
            <th jhiSortBy="id"><span jhiTranslate="global.field.id">ID</span> <span class="fa fa-sort"></span></th>
            <th jhiSortBy="name"><span jhiTranslate="vdrApp.file.name">Name</span> <span class="fa fa-sort"></span></th>
            <th jhiSortBy="size"><span jhiTranslate="vdrApp.file.size">Size</span> <span class="fa fa-sort"></span></th>
            <th jhiSortBy="bytes"><span jhiTranslate="vdrApp.file.bytes">Bytes</span> <span class="fa fa-sort"></span></th>
            <th></th>
            </tr>
            </thead>
            <tbody infinite-scroll (scrolled)="loadPage(page + 1)" [infiniteScrollDisabled]="page >= links['last']" [infiniteScrollDistance]="0">
            <tr *ngFor="let file of files ;trackBy: trackId">
                <td><a [routerLink]="['../file', file.id ]">{{file.id}}</a></td>
                <td>{{file.name}}</td>
                <td>{{file.size}}</td>
                <td>
                    <a *ngIf="file.bytes" (click)="openFile(file.bytesContentType, file.bytes)">
                        <img [src]="'data:' + file.bytesContentType + ';base64,' + file.bytes" style="max-height: 30px;" alt="file image"/>
                    </a>
                    <span *ngIf="file.bytes">{{file.bytesContentType}}, {{byteSize(file.bytes)}}</span>
                </td>
                <td class="text-right">
                    <div class="btn-group flex-btn-group-container">
                        <button type="submit"
                                [routerLink]="['../file', file.id ]"
                                class="btn btn-info btn-sm">
                            <span class="fa fa-eye"></span>
                            <span class="d-none d-md-inline" jhiTranslate="entity.action.view">View</span>
                        </button>
                        <button type="submit"
                                [routerLink]="['/', { outlets: { popup: 'file/'+ file.id + '/edit'} }]"
                                replaceUrl="true"
                                queryParamsHandling="merge"
                                class="btn btn-primary btn-sm">
                            <span class="fa fa-pencil"></span>
                            <span class="d-none d-md-inline" jhiTranslate="entity.action.edit">Edit</span>
                        </button>
                        <button type="submit"
                                [routerLink]="['/', { outlets: { popup: 'file/'+ file.id + '/delete'} }]"
                                replaceUrl="true"
                                queryParamsHandling="merge"
                                class="btn btn-danger btn-sm">
                            <span class="fa fa-remove"></span>
                            <span class="d-none d-md-inline" jhiTranslate="entity.action.delete">Delete</span>
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
