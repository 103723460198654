import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {moment} from 'fullcalendar';

@Component({
    selector: 'git-version',
    templateUrl: './git-version.component.html',
    styleUrls: ['git-version.component.scss']

})

export class GitVersionComponent implements OnInit {
    version: GitVersion;

    constructor(private httpClient: HttpClient ) {
        this.httpClient.get<GitVersion>('content/primeng/assets/data/json/git-version/git-version.json', {responseType: 'json'})
            .subscribe((data) => {
                this.version = data;
            });
    }

    ngOnInit(): void {

    }

    getTagOrBranch(): string {
        return this.isTagSet() ? this.version.tag : (this.version ? 'Branch: ' + this.version.branch : '');
    }

    isTagSet(): boolean {
        return this.version && this.version.tag !== '';
    }

    formatDate(date: any): string {
        return moment(date).format('LLL');
    }
}

interface GitVersion {
    branch: string;
    tag: string;
    lastCommitTime: string;
    lastCommitHash: string;
}


