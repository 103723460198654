import {
    AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild
} from '@angular/core';
import 'intl-tel-input';
import * as jQuery from 'jquery';
import {ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';

/**
 * @deprecated use ngx-phone-number-gg instead
 */
@Component({
    selector: 'jhi-phone-input',
    styles: [`
        :host.ng-touched.ng-invalid input {
            background: rgba(255, 0, 0, 0.1);
        }
    `],
    template: `
        <input #intlInput
               [jhiAppIntlTelInput]="intlOptions"
               [disabled]="disabled"
               [id]="id"
               [(ngModel)]="value" class="telephone"
               maxlength="12"
               placeholder="XXXXXXXXX"
               (ngModelChange)="onInputChange($event)"
        >
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TelephoneInputComponent),
        multi: true
    }]
})
export class TelephoneInputComponent implements AfterViewInit {
    @Input() fullValue: string;
    @Output() fullValueChange = new EventEmitter<string>();
    @Input() id: string;
    @Input() disabled: boolean;
    @Input() intlOptions = {initialCountry: 'pl', formatOnDisplay: false, separateDialCode: true};
    @Input() value: string;
    @Output() valueChange = new EventEmitter<string>();
    @Input()
    name: string;
    @Input()
    form: FormGroup;
    @ViewChild('intlInput') intlInput: ElementRef;

    constructor() {
    }

    ngAfterViewInit() {
        if (this.fullValue && this.id && !this.value) {
            setTimeout(() => {
                const phoneInput = jQuery(`input#${this.id}`);
                phoneInput.intlTelInput('setNumber', this.fullValue);
            }, 100);
        }
        console.log('Init', this.name, this.form);
    }

    onInputChange(value: string) {
        this.value = value;
        this.valueChange.emit(value);
        this.fullValue = jQuery(this.intlInput.nativeElement).intlTelInput('getNumber');
        this.fullValueChange.emit(this.fullValue);
        this.onChange(this.fullValue);
        this.form.controls[this.name].setValue(this.fullValue);
    }


    onChange(number: string) {
        console.log('input', this.intlInput, this.intlInput.nativeElement);
        if (number.length >= 4) {
            this.intlInput.nativeElement.classList.remove('ng-invalid');
            return;
        }

        this.intlInput.nativeElement.classList.add('ng-invalid');
    }


    updateChanges() {
        this.onChange(this.value);
    }

    writeValue(value: string): void {
        this.fullValue = value;
        this.ngAfterViewInit();
    }

}
