import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Client} from './client.model';
import {ClientService} from './client.service';
import {HttpResponse} from '@angular/common/http';
import {ProjectVdr, ProjectVdrService} from '@admin/project-vdr';

@Component({
    templateUrl: 'client-choice-dialog.html'
})
export class ClientChoiceDialog {

    clients: Client[];
    clientName = '';
    selectedClient: Client;

    constructor(private clientService: ClientService,
                private projectService: ProjectVdrService,
                public dialogRef: MatDialogRef<ClientChoiceDialog>,
                @Inject(MAT_DIALOG_DATA) public data: ProjectVdr) {

        this.getClients();
    }

    close(): void {
        this.dialogRef.close();
    }

    save(): void {
        const client = this.getOrCreateClient();

        if (client !== null) {

            this.data.client = client;
            this.projectService.updateProjectByClient(this.data).subscribe((response: HttpResponse<ProjectVdr>) => {
                this.data = response.body;
                this.close();
            });

        } else {
            this.close();
        }
    }

    getOrCreateClient(): Client {
        if (this.selectedClient != null) {
            return this.selectedClient;
        }

        if (this.clientName !== '' && this.clientName !== undefined) {

            const client = new Client();
            client.name = this.clientName;
            return client;
        }

        return null;
    }

    private getClients(): void {
        this.clientService.findAll().subscribe((response) => {
            this.clients = response.body.filter(client => client.active == true);
        });
    }
}
