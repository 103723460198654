<div id="container">
    <div class="box" *ngIf="!isReportArchive() || !error">
        <p class="report-event-feed-date" *ngIf="!isReportArchive()" jhiTranslate="report.archive.download.in.progress">
            Archive information is being retrieved
        </p>
    </div>
    <div class="box" *ngIf="isReportArchive()">
        <p class="text"> {{ title }} </p>
        <p class="text" jhiTranslate="report.archive.download.containing">containing:</p>
        <p class="text" jhiTranslate="report.archive.entitlements" *ngIf="hasSelectedOptions(reportArchiveOption.ENTITLEMENTS)">Documents</p>
        <p class="text" jhiTranslate="report.archive.documents" *ngIf="hasSelectedOptions(reportArchiveOption.DOCUMENTS)">Documents</p>
        <p class="text" jhiTranslate="report.archive.users" *ngIf="hasSelectedOptions(reportArchiveOption.USERS)">Users</p>
        <p class="text" jhiTranslate="report.archive.qa" *ngIf="hasSelectedOptions(reportArchiveOption.QA)">Q&A</p>
        <p class="text" jhiTranslate="report.archive.history" *ngIf="hasSelectedOptions(reportArchiveOption.HISTORY)">History</p>
        <p class="text" jhiTranslate="report.archive.uploaded.data" *ngIf="hasSelectedOptions(reportArchiveOption.UPLOADED_DATA)">Uploaded data</p>
        <p class="text" jhiTranslate="report.archive.overview" *ngIf="hasSelectedOptions(reportArchiveOption.OVERVIEW)">Overview</p>
    </div>
    <div class="box" *ngIf="error">
        <p class="error-text">{{message}}</p>
    </div>
</div>
