import {AfterViewInit, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';

import {PasswordResetFinishService} from './password-reset-finish.service';
import {LoginModalService} from '../../../shared';
import {JhiLanguageService} from '@upside-cloud/ng-jhipster';

@Component({
    selector: 'jhi-password-reset-finish',
    templateUrl: './password-reset-finish.component.html',
    styleUrls: ['./password-reset.scss']
})
export class PasswordResetFinishComponent implements OnInit, AfterViewInit {
    confirmPassword: string;
    doNotMatch: string;
    error: string;
    keyMissing: boolean;
    resetAccount: any;
    success: string;
    modalRef: NgbModalRef;
    key: string;
    password1Show = false;
    password2Show = false;
    constructor(
        private passwordResetFinishService: PasswordResetFinishService,
        private loginModalService: LoginModalService,
        private route: ActivatedRoute,
        private elementRef: ElementRef, private renderer: Renderer2,
        private router: Router,
        private languageService: JhiLanguageService,
    ) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.key = params['key'];
            const lang = params['lang'];
            this.changeLanguage(lang);
        });
        this.resetAccount = {};
        this.keyMissing = !this.key;
    }

    ngAfterViewInit() {
        if (this.elementRef.nativeElement.querySelector('#password') != null) {
            // this.renderer.invokeElementMethod(this.elementRef.nativeElement.querySelector('#password'), 'focus', []);
            this.renderer.selectRootElement(this.elementRef.nativeElement.querySelector('#password')).focus();
        }
    }

    finishReset() {
        this.doNotMatch = null;
        this.error = null;
        if (this.resetAccount.password !== this.confirmPassword) {
            this.doNotMatch = 'ERROR';
        } else {
            this.passwordResetFinishService.save({
                key: this.key,
                newPassword: this.resetAccount.password
            }).subscribe(() => {
                this.success = 'OK';
                this.login();
                //        this.router.navigate(['/login']);
            }, () => {
                this.success = null;
                this.error = 'ERROR';
            });
        }
    }

    login() {
        this.modalRef = this.loginModalService.open('reset.finish.messages.success');
    }


    changeLanguage(lang: string) {
        this.languageService.changeLanguage(lang);
    }


}
