import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'findLanguageFromKey'})
export class FindLanguageFromKeyPipe implements PipeTransform {
    private languages: any = {
        'en': { name: 'English' },
        'fr': { name: 'Français' },
        'de': { name: 'Deutsch' },
        'pl': { name: 'Polski' },
        'cs': {name: 'Český'},
        'es': {name: 'Español'}
        // jhipster-needle-i18n-language-key-pipe - JHipster will add/remove languages in this object
    };
    transform(lang: string): string {
        if(!lang){
            lang = 'pl';
        }
        return this.languages[lang].name;
    }
}
