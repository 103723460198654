<div fxLayout="column">
    <div fxFlex="row">
        <mat-tab-group style="background-color: #efeff2" (selectedIndexChange)="chooseReportType($event)" *ngIf="isAdmin">
            <mat-tab label="{{ 'report.navbar.overview' | translate }}" class="custom-mat-tab">
                <jhi-report-overview style="min-height: 100vh;" *ngIf="selectedReportType === ReportType.OVEVIEW"></jhi-report-overview>
            </mat-tab>
            <mat-tab label="{{ 'report.overview.most-viewed-documents-chart.title' | translate }}" class="custom-mat-tab">
                <jhi-report-document-views style="min-height: 100vh;" *ngIf="selectedReportType === ReportType.DOCUMENTS"></jhi-report-document-views>
            </mat-tab>
            <mat-tab label="{{ 'report.navbar.questions' | translate }}" class="custom-mat-tab">
                <jhi-report-question *ngIf="selectedReportType === ReportType.QA"></jhi-report-question>
            </mat-tab>
            <mat-tab label="{{ 'report.navbar.history' | translate }}" class="custom-mat-tab">
                <jhi-report-event *ngIf="selectedReportType === ReportType.HISTORY"></jhi-report-event>
            </mat-tab>
            <mat-tab label="{{ 'report.navbar.uploaded-data' | translate }}" class="custom-mat-tab">
                <jhi-report-uploaded style="min-height: 100vh;" *ngIf="selectedReportType === ReportType.UPLOADED"></jhi-report-uploaded>
            </mat-tab>
            <mat-tab label="{{ 'report.navbar.processing' | translate }}" class="custom-mat-tab">
               <jhi-report-processing style="min-height: 100vh;" *ngIf="selectedReportType === ReportType.PROCESSING"></jhi-report-processing>
            </mat-tab>
            <mat-tab label="{{ 'report.navbar.archive' | translate }}" class="custom-mat-tab">
                <jhi-report-archive style="min-height: 100vh;" *ngIf="selectedReportType === ReportType.ARCHIVE"></jhi-report-archive>
            </mat-tab>
        </mat-tab-group>
        <mat-tab-group style="background-color: #efeff2" (selectedIndexChange)="chooseReportType($event)" *ngIf="!isAdmin && isQA">
            <mat-tab label="{{ 'report.navbar.questions' | translate }}" class="custom-mat-tab">
                <jhi-report-question style="min-height: 100vh;" *ngIf="selectedReportType === ReportType.QA"></jhi-report-question>
            </mat-tab>
            <mat-tab label="{{ 'report.navbar.history' | translate }}" class="custom-mat-tab">
                <jhi-report-event style="min-height: 100vh;" *ngIf="selectedReportType === ReportType.HISTORY"></jhi-report-event>
            </mat-tab>
        </mat-tab-group>
        <mat-tab-group style="background-color: #efeff2" (selectedIndexChange)="chooseReportType($event)" *ngIf="!isAdmin && !isQA">
            <mat-tab label="{{ 'report.navbar.history' | translate }}" class="custom-mat-tab">
                <jhi-report-event style="min-height: 100vh;"></jhi-report-event>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
