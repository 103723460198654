import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Socket} from 'ngx-socket-io';
import {TranslateService} from '@ngx-translate/core';
import {SERVER_API_URL} from '../../../app.constants';
import {interval, of, timer} from 'rxjs';
import {catchError, takeWhile} from 'rxjs/operators';
import {UploadDownloadService} from '../upload-download.service';

export interface ZipRequest {
    id: string;
    requestId: string; // alias for id :facepalm:
    requester: number;
    createdOn: Date;
    status: string;
    filesZipped: number;
    totalFileCount: number;
    lastFilePath: string;
}


@Component({
    selector: 'request-zip-dialog',
    templateUrl: 'request-zip-dialog.component.html',
    styleUrls: ['./../directory-document.scss']
})
export class RequestZipStatusDialog implements AfterViewInit {
    progressBarMode = 'indeterminate';
    percentageValue = 0;
    statusLine: string;
    error = false;
    finished = false;
    lastFilePath: string = null;

    @ViewChild('link') link: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<RequestZipStatusDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ZipRequest,
        private socket: Socket,
        private translateService: TranslateService,
        uploadDownloadService: UploadDownloadService
    ) {
        const pollTimer = interval(10_000).pipe(takeWhile(() => !this.finished));
        pollTimer.subscribe(() => {
            uploadDownloadService.getZipLastEvent(data.id)
                .pipe(catchError((err, value) => {
                    console.log('Error while polling for status', err);
                    return of(null);
                }))
                .subscribe((value) => {
                    if (!value) {
                        console.warn('Bogus response from server');
                        return;
                    }

                    console.log('Received zip-progress from poll', value);
                    this.data = value;
                    this.processEvent(value.requestId);
                });
        });

        console.log(this.socket);
        this.socket.on('zip-progress', (zipRequestId, zipRequest) => {
            // not this modal, do nothing
            if (zipRequest.requestId !== this.data.requestId) {
                return;
            }

            console.log('Received zip-progress from ws', zipRequestId, zipRequest);
            this.data = zipRequest;
            this.processEvent(zipRequest.requestId);
        });
    }

    private processEvent(zipRequestId) {
        if (this.data.totalFileCount > 0) {
            this.statusLine = this.translateService.instant('vdrApp.zipRequestAction.status.' + this.data.status,
                {filesZipped: this.data.filesZipped, totalFileCount: this.data.totalFileCount});
            this.progressBarMode = 'determinate';
        }

        this.lastFilePath = this.data.lastFilePath;
        this.percentageValue = this.getProgressPercentage();

        if (this.data.status === 'Ready') {
            this.finished = true;
            this.link.nativeElement.href = SERVER_API_URL + 'api/zip-download/' + this.data.requestId;
            this.link.nativeElement.click();
            console.log('Zipping finished. Starting to download zip '  + this.data.requestId);
            setTimeout(() => this.dialogRef.close(true), 2000);
        }
        if (this.data.status === 'Error') {
            this.error = true;
            this.finished = true;
            console.error('Got error while waiting for zip '  + this.data.requestId);
            setTimeout(() => this.dialogRef.close(false), 5000);
        }
    }

    private getProgressPercentage() {
        if (this.data.totalFileCount === 0) {
            return 0;
        }

        return Math.floor(this.data.filesZipped * 100 / this.data.totalFileCount);
    }

    ngAfterViewInit(): void {
        this.statusLine = 'trwa przygotowanie';
    }
}
