<div fxLayout="column" class="d-flex event-left-panel" style="background-color: #ffffff;" fxFlex="20%">
    <div fxLayout="row" class="d-flex flex-row" fxLayoutAlign="center">
        <mat-accordion style="width: 100%;" multi="false">
            <mat-expansion-panel style="width: 100%;" #usersExpansionPanel expanded="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <label class="form-control-label">
                            <span jhiTranslate="report.users-filter-title" style="font-size: 14px; color: #575c66;">Users</span>
                        </label>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <jhi-user-filter  (select)="changeUser($event)" [projectUsers]="projectUsers"

                                   fxFlex class="content-font"></jhi-user-filter>
            </mat-expansion-panel>
            <mat-expansion-panel style="width: 100%;" #groupsExpansionPanel expanded="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <label class="form-control-label">
                            <span jhiTranslate="report.group-filter-title" style="font-size: 14px; color: #575c66;">Groups</span>
                        </label>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <jhi-group-filter [userGroups]="groups" (select)="changeGroup($event)"
                                  fxFlex class="content-font"></jhi-group-filter>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<mat-divider [vertical]="true"></mat-divider>
<div fxLayout="column" class="d-flex" fxFlex="80%">
    <div class="d-flex flex-row button-row" fxLayout="row" style="background-color: #efeff2;">
        <div fxLayout="row" fxFlexAlign="start" fxFlex="30%">
            <button mat-raised-button style="min-width: 70px" [matMenuTriggerFor]="filterMenu" (click)="filterOpen()">
                <mat-icon>filter_list</mat-icon>
                <span jhiTranslate="document.filter.search">Filtr</span>
            </button>
            <!--            <button mat-raised-button style="min-width: 70px; margin-left: 0.5rem;" (click)="export()" color="warn"  matTooltip="Pobierz raport Excel">
                            <mat-icon>get_app</mat-icon>
                        </button>-->
            <mat-menu #filterMenu="matMenu" class="filter-menu">
                <div fxFlex
                     fxLayout="column"
                     fxLayoutAlign="center"
                     *ngIf="reportOverviewCriteriaDtoTmp"
                     (click)="$event.stopPropagation();">
                    <div class="filter-item" fxLayout="column">
                        <mat-form-field>
                            <input
                                name="searchDateFrom"
                                matInput
                                [matDatepicker]="searchDateFromPicker"
                                [(ngModel)]="reportOverviewCriteriaDtoTmp.dateFrom"
                                placeholder="{{'report.date-from' | translate}}"
                                (click)="$event.stopPropagation();">
                            <mat-datepicker-toggle matSuffix [for]="searchDateFromPicker"></mat-datepicker-toggle>
                            <mat-datepicker #searchDateFromPicker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field>
                            <input
                                name="searchDateTo"
                                matInput
                                [matDatepicker]="searchDateToPicker"
                                [(ngModel)]="reportOverviewCriteriaDtoTmp.dateTo"
                                placeholder="{{'report.date-to' | translate}}"
                                (click)="$event.stopPropagation();">
                            <mat-datepicker-toggle matSuffix [for]="searchDateToPicker"></mat-datepicker-toggle>
                            <mat-datepicker #searchDateToPicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="filter-item mt-2" fxLayout="column">
                        <div align="right">
                            <button
                                mat-raised-button
                                color="warn"
                                (click)="search()">
                                <span class="fa fa-search"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-menu>
        </div>
        <!--<div fxLayout="row" fxFlexAlign="start" fxFlex="70%">-->
        <!--<h5 fxFlexAlign="center" class="ml-3 mt-2">-->
        <!--<span jhiTranslate="report.questions.title">Activity in questions and answers</span>-->
        <!--</h5>-->
        <!--</div>-->
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" class="d-flex flex-row button-row" *ngIf="hasFilters()" style="background-color: #efeff2;">
        <mat-form-field #chipList fxFlex="98%">
            <mat-chip-list>
                <mat-chip *ngIf="reportOverviewCriteriaDto.dateFrom" (removed)="chipsRemoveDateFrom()">
                    <span jhiTranslate="report.date-from">Date from</span>
                    <span>&nbsp;{{reportOverviewCriteriaDto.dateFrom | date: 'yyyy-MM-dd'}}</span>
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip *ngIf="reportOverviewCriteriaDto.dateTo" (removed)="chipsRemoveDateTo()">
                    <span jhiTranslate="report.date-to">Date to</span>
                    <span>&nbsp;{{reportOverviewCriteriaDto.dateTo | date: 'yyyy-MM-dd'}}</span>
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip color="warn" (removed)="chipsRemoveAll()">
                    <span jhiTranslate="report.clear-filters">Clear filters</span>
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </mat-form-field>
    </div>
    <div class=" p-3 overview-docs">
        <div fxLayout="row" class="d-flex flex-row" style="background-color: #ffffff">
            <div fxLayout="column" fxFlex="98%">
                <p-chart #mostViewedDocumentsChart type="bar" [data]="mostViewedDocumentsData" [options]="mostViewedDocumentsOptions" ></p-chart>
            </div>
        </div>
    </div>

</div>
