import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DirectoryDocumentTreeDto} from '../dto/directory-tree-dto.model';
import {SERVER_API_URL} from '../../../app.constants';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PermissionDto} from './permission-dto.model';
import {ChangePermissionRequest} from './change-permission-request.model';

@Injectable()
export class DirectoryDocumentPermissionService {

    constructor(
        private http: HttpClient
    ) { }

    directoryDocumentTree(): Observable<DirectoryDocumentTreeDto[]> {
        return this.http.get<DirectoryDocumentTreeDto[]>(SERVER_API_URL + 'api/directory-document/tree');
    }

    permission(groupId: number, objectId: number, directory: boolean): Observable<PermissionDto> {
        const params = new HttpParams().set('groupId', groupId.toString()).set('objectId', objectId.toString()).set('directory', String(directory));
        return this.http.get<PermissionDto>(SERVER_API_URL + 'api/permissions', {params});
    }


    userDocumentPermission(objectId: number, directory: boolean): Observable<PermissionDto> {
        console.log('userDocumentPermission');
        const params = new HttpParams().set('objectId', objectId.toString()).set('directory', String(directory));
        return this.http.get<PermissionDto>(SERVER_API_URL + 'api/userDocumentPermissions', {params});
    }


    setDocumentPermission(request: ChangePermissionRequest): Observable<PermissionDto>{
        return this.http.post<PermissionDto>(SERVER_API_URL + 'api/permissions/document', request);
    }

    setDirectoryPermission(request: ChangePermissionRequest) {
        return this.http.post<PermissionDto>(SERVER_API_URL + 'api/permissions/directory', request);

    }
}
