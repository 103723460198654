<div *ngIf="structure && structure.active" fxLayout="column">
    <div
        [ngStyle]="{'margin-left': getMargin(),
                 'margin-right': '5px',
                 'min-height': '30px',
                 'display': 'flex',
                 'flex-direction': 'column',
                 'justify-content': 'space-between'
                }">
        <div>
            <i class="fa fa-folder-open  fa-lg"></i><span>{{structure.name}}</span>
        </div>
    </div>
    <div *ngIf="structure.files"  [ngStyle]="{'margin-left': getMarginFile(), 'margin-right': '5px'}" >
        <single-file-upload [file]="file" [id]="i" *ngFor="let file of structure.files; let i = index" [httpUrl]="uploadUrl">
        </single-file-upload>
    </div>
    <div *ngFor="let item of structure.children | keyvalue">
        <directory-structure [structure]="item.value"
                             [uploadUrl]="uploadUrl"
        ></directory-structure>
    </div>
</div>
