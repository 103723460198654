<dialog id="passwordDialog">
  <div class="row">
    <label for="password" id="passwordText" data-l10n-id="password_label">Enter the password to open this PDF
      file:</label>
  </div>
  <div class="row">
    <input type="hidden" id="password" class="toolbarField" />
  </div>
  <div class="buttonRow">
    <button id="passwordCancel" class="dialogButton"><span data-l10n-id="password_cancel">Cancel</span></button>
    <button id="passwordSubmit" class="dialogButton"><span data-l10n-id="password_ok">OK</span></button>
  </div>
</dialog>
