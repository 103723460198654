import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from '../../../app.constants';

@Injectable()
export class PhoneResetService {

    constructor(private http: HttpClient) {
    }

    save(data: any): Observable<HttpResponse<any>> {
        return this.http.post(SERVER_API_URL + 'api/phone-reset', data, {observe: 'response'});
    }
}
