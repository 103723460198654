import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {UserRouteAccessService, VdrSharedModule} from '../../shared';
import {RouterModule, Routes} from '@angular/router';
import {directoryDocumentRoute} from './directory-document.route';
import {DirectoryTreeComponent} from './directory-tree.component';
import {DocumentListComponent} from './document-list.component';
import {DirectoryDocumentNoteComponent} from './directory-document-note.component';
import {DirectoryVdrService} from '../directory-vdr';
import {DocumentVdrService} from '../document-vdr';
import {DocumentNoteComponent} from './document-note.component';
import {UploadDialogComponent} from './upload.component';
import {DirectoryCreateDialog} from './directory-utils/directory-create-dialog';
import {UploadDownloadService} from './upload-download.service';
import {TreeIndexPipe} from './pipe/tree-index-pipe';
import {ListIndexPipe} from './pipe/list-index-pipe';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DirectoryDocumentService} from './directory-document.service';
import {FileDropModule} from '../ngx-file-drop/ngx-drop';
import {FilesUploadComponent} from '../file-upload/files-upload.component';
import {SingleFileUploadComponent} from '../file-upload/single-file-upload.component';
import {DirectoryStructureComponent} from '../file-upload/directory-structure.component';
import {DocumentSearchComponent} from './document-search.component';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {DirectoryDocumentTreeComponent} from './directory-document-permission/directory-document-tree.component';
import {GroupListComponent} from './directory-document-permission/group-list.component';
import {PermissionViewComponent} from './directory-document-permission/permission-view.component';
import {PermissionDetailComponent} from './directory-document-permission/permission-detail.component';
import {DirectoryDocumentPermissionService} from './directory-document-permission/directory-document-permission.service';
import {PdfPasswordProtectionDialog} from './pdf-password-protection-dialog/pdf-password-protection-dialog';
import {ChangeNameDialog} from './utils/change-name-dialog';
import {SortingDirective} from './utils/sort-cache.directive';

import {ManagementDocumentListComponent} from './admin-management/management-document-list.component';
import { ClipboardModule } from 'ngx-clipboard';
import {PermanentDeleteDialog} from './admin-management/permanent-delete-dialog';
import {VdrAccountModule} from '../../account/account.module';
import {ProgressBarModule} from 'primeng/progressbar';
import {RequestZipStatusDialog} from './zip-request/request-zip-status-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {DeleteFilesAvailablePipe} from './pipe/delete-files-available-pipe';
import {CheckDownloadTypePipe} from './pipe/check-download-type-pipe';

const ENTITY_STATES = [
    ...directoryDocumentRoute,
];

export const directoryState: Routes = [{
    path: '',
    data: {
        authorities: ['DOCUMENT']
    },
    canActivate: [UserRouteAccessService],
    children: ENTITY_STATES

}];


@NgModule({
    imports: [
        VdrSharedModule,
        ClipboardModule,
        BrowserAnimationsModule,
        RouterModule.forChild(directoryState),
        FileDropModule,
        VdrAccountModule,
        ProgressBarModule,
        TranslateModule,
    ],
    declarations: [
        DirectoryTreeComponent,
        DocumentListComponent,
        DirectoryDocumentNoteComponent,
        DocumentNoteComponent,
        UploadDialogComponent,
        DirectoryCreateDialog,
        PdfPasswordProtectionDialog,
        TreeIndexPipe,
        ListIndexPipe,
        FilesUploadComponent,
        SingleFileUploadComponent,
        DirectoryStructureComponent,
        DocumentSearchComponent,
        DirectoryDocumentTreeComponent,
        GroupListComponent,
        PermissionViewComponent,
        PermissionDetailComponent,
        ChangeNameDialog,
        ManagementDocumentListComponent,
        PermanentDeleteDialog,
        RequestZipStatusDialog,
        DeleteFilesAvailablePipe,
        CheckDownloadTypePipe,
        SortingDirective,
    ],
    entryComponents: [
        DirectoryTreeComponent,
        DocumentListComponent,
        DirectoryDocumentNoteComponent,
        DocumentNoteComponent,
        UploadDialogComponent,
        DirectoryCreateDialog,
        PdfPasswordProtectionDialog,
        FilesUploadComponent,
        SingleFileUploadComponent,
        DirectoryStructureComponent,
        DocumentSearchComponent,
        DirectoryDocumentTreeComponent,
        GroupListComponent,
        PermissionViewComponent,
        PermissionDetailComponent,
        ChangeNameDialog,
        ManagementDocumentListComponent,
        PermanentDeleteDialog,
        RequestZipStatusDialog
    ],
    providers: [
        DirectoryVdrService,
        DocumentVdrService,
        UploadDownloadService,
        DirectoryDocumentService,
        DirectoryDocumentPermissionService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VdrDirectoryDocumentModule {
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ){
        this.matIconRegistry.addSvgIcon('vdr_print_document',
            this.domSanitizer.bypassSecurityTrustResourceUrl('content/images/public/icons/print_document.svg'));
        this.matIconRegistry.addSvgIcon('vdr_view_document',
            this.domSanitizer.bypassSecurityTrustResourceUrl('content/images/public/icons/view_document.svg'));
        this.matIconRegistry.addSvgIcon('vdr_fence_view',
            this.domSanitizer.bypassSecurityTrustResourceUrl('content/images/public/icons/fence_view.svg'));
        this.matIconRegistry.addSvgIcon('vdr_download_pdf',
            this.domSanitizer.bypassSecurityTrustResourceUrl('content/images/public/icons/download_pdf.svg'));
        this.matIconRegistry.addSvgIcon('vdr_download_orginal',
            this.domSanitizer.bypassSecurityTrustResourceUrl('content/images/public/icons/download_orginal.svg'));
        this.matIconRegistry.addSvgIcon('vdr_download_encrypted',
            this.domSanitizer.bypassSecurityTrustResourceUrl('content/images/public/icons/download_encrypted.svg'));
        this.matIconRegistry.addSvgIcon('vdr_upload',
            this.domSanitizer.bypassSecurityTrustResourceUrl('content/images/public/icons/upload.svg'));
        this.matIconRegistry.addSvgIcon('vdr_chat',
            this.domSanitizer.bypassSecurityTrustResourceUrl('content/images/public/icons/chat.svg'));
    }
}
