<div>
    <router-outlet name="navbar"></router-outlet>
</div>
<div class="content-wrapper">
    <div style="height:4px" class="btn-primary"></div>
    <div class="card jh-card">
        <jhi-alert></jhi-alert>
        <jhi-alert-error></jhi-alert-error>
        <router-outlet></router-outlet>
        <router-outlet name="popup"></router-outlet>
    </div>
    <!--<jhi-footer></jhi-footer>
    <footer class="sticky-footer">
        <div class="container">
            <div class="text-center">
                <small>Stopka</small>
            </div>
        </div>
    </footer>
    -->
    <!-- Scroll to Top Button-->
    <a class="scroll-to-top rounded" href="#page-top">
        <i class="fa fa-angle-up"></i>
    </a>
</div>

<p-blockUI [blocked]="connectionLost" xmlns="http://www.w3.org/1999/html">
    <div>
        <div class="wifi-symbol">
            <div class="wifi-circle first"></div>
            <div class="wifi-circle second"></div>
            <div class="wifi-circle third"></div>
            <div class="wifi-circle fourth"></div>
        </div>
    </div>

    <div class="message">
        <h2>Utracono połączenie z VDR</h2>
        <p>Sprawdź, czy Twój internet działa. Po przywróceniu połączenia komunikat sam zniknie</p>
    </div>
</p-blockUI>

