<div fxLayout="row">
    <span mat-dialog-title fxFlex="90%" jhiTranslate="vdrApp.zipRequestAction.title">Zipping...</span>
</div>
<div mat-dialog-content>
    <jhi-alert-error></jhi-alert-error>
    <div fxLayout="column" fxLayoutAlign="center">
        <p-progressBar [mode]="progressBarMode" [value]="percentageValue" showValue="true"></p-progressBar>
        <span>Status: {{ statusLine }}</span>
    </div>
    <a #link></a>
</div>
