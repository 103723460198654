import {Pipe, PipeTransform} from '@angular/core';
import {DirectoryDocumentRowDto} from '../dto/directory-document-row-dto.model';
@Pipe({name: 'checkDownloadType'})
export class CheckDownloadTypePipe implements PipeTransform {

    transform(object: DirectoryDocumentRowDto, downloadType: string): boolean {
        if (object.type === 'directory') {
            return true;
        }
        return object.effectivePermissions.includes(downloadType);
    }

}
