<pdf-shy-button
  [cssClass]="showOpenFileButton | responsiveCSSClass : 'hiddenMediumView'"
  primaryToolbarId="openFile"
  title="Open File"
  l10nId="open_file"
  l10nLabel="open_file_label"
  image="<svg style='width: 24px; height: 20px' viewBox='0 0 24 24'><path fill='currentColor' d='M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M12,12L16,16H13.5V19H10.5V16H8L12,12Z' /></svg>"
  [order]="200"
>
</pdf-shy-button>
