import {Route} from '@angular/router';

import {UserRouteAccessService} from '../../../shared/index';
import {PhoneResetComponent} from './phone-reset.component';

export const phoneResetRoute: Route = {
    path: 'phone-reset',
    component: PhoneResetComponent,
    data: {
        pageTitle: 'phone.title'
    },
};
