<div *ngIf="audits">
    <h2 jhiTranslate="audits.title">Audits</h2>

    <div class="row">
        <div class="col-md-5">
            <h4 jhiTranslate="audits.filter.title">Filter by date</h4>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" jhiTranslate="audits.filter.from">from</span>
                </div>
                <input type="date" class="form-control" name="start" [(ngModel)]="fromDate" (ngModelChange)="onChangeDate($event)" required/>

                <div class="input-group-append">
                        <span class="input-group-text" jhiTranslate="audits.filter.to">To</span>
                    </div>
                    <input type="date" class="form-control" name="end" [(ngModel)]="toDate" (ngModelChange)="onChangeDate($event)" required/>
            </div>
        </div>
    </div>

    <div class="table-responsive">
        <table class="table table-sm table-striped table-bordered">
            <thead>
            <tr>
                <th (click)="orderProp = 'timestamp'; reverse=!reverse"><span jhiTranslate="audits.table.header.date">Date</span></th>
                <th (click)="orderProp = 'principal'; reverse=!reverse"><span jhiTranslate="audits.table.header.principal">User</span></th>
                <th (click)="orderProp = 'type'; reverse=!reverse"><span jhiTranslate="audits.table.header.status">State</span></th>
                <th (click)="orderProp = 'data.message'; reverse=!reverse"><span jhiTranslate="audits.table.header.data">Extra data</span></th>
            </tr>
            </thead>
            <tr *ngFor="let audit of getAudits()">
                <td><span>{{audit.timestamp| date:'medium'}}</span></td>
                <td><small>{{audit.principal}}</small></td>
                <td>{{audit.type}}</td>
                <td>
                    <span *ngIf="audit.data" ng-show="audit.data.message">{{audit.data.message}}</span>
                    <span *ngIf="audit.data" ng-show="audit.data.remoteAddress"><span jhiTranslate="audits.table.data.remoteAddress">Remote Address</span> {{audit.data.remoteAddress}}</span>
                </td>
            </tr>
        </table>
    </div>
    <div *ngIf="audits">
        <div class="row justify-content-center">
            <jhi-item-count [page]="page" [total]="totalItems" [itemsPerPage]="itemsPerPage"></jhi-item-count>
        </div>
        <div class="row justify-content-center">
            <ngb-pagination [collectionSize]="totalItems" [(page)]="page" [pageSize]="itemsPerPage" [maxSize]="5" [rotate]="true" [boundaryLinks]="true" (pageChange)="loadPage(page)"></ngb-pagination>
        </div>
    </div>
</div>
