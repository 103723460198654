import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'highlight'
})

export class HighlightSearch implements PipeTransform {

    transform(value: any, args: any): any {
        if (!args || !value) {return value;}
        var re = new RegExp(args, 'gi');
        return value.replace(re, "<mark>$&</mark>");
    }
}
