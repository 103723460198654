
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from '../../app.constants';

import { DocumentNoteVdr } from './document-note-vdr.model';
import { createRequestOption } from '../../shared';

export type EntityResponseType = HttpResponse<DocumentNoteVdr>;

@Injectable()
export class DocumentNoteVdrService {

    private resourceUrl =  SERVER_API_URL + 'api/document-notes';

    constructor(private http: HttpClient) { }

    create(documentNote: DocumentNoteVdr): Observable<EntityResponseType> {
        const copy = this.convert(documentNote);
        return this.http.post<DocumentNoteVdr>(this.resourceUrl, copy, { observe: 'response' }).pipe(
            map((res: EntityResponseType) => this.convertResponse(res)));
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response'});
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: DocumentNoteVdr = this.convertItemFromServer(res.body);
        return res.clone({body});
    }

    private convertArrayResponse(res: HttpResponse<DocumentNoteVdr[]>): HttpResponse<DocumentNoteVdr[]> {
        const jsonResponse: DocumentNoteVdr[] = res.body;
        const body: DocumentNoteVdr[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

    /**
     * Convert a returned JSON object to DocumentNoteVdr.
     */
    private convertItemFromServer(documentNote: DocumentNoteVdr): DocumentNoteVdr {
        const copy: DocumentNoteVdr = Object.assign({}, documentNote);
        return copy;
    }

    /**
     * Convert a DocumentNoteVdr to a JSON which can be sent to the server.
     */
    private convert(documentNote: DocumentNoteVdr): DocumentNoteVdr {
        const copy: DocumentNoteVdr = Object.assign({}, documentNote);
        return copy;
    }
}
