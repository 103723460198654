<div class="d-flex flex-row">
    <div style="width:400px;" class="group-list" *ngIf="projects">
        <div class="group-item d-flex flex-row"
             [ngClass]="(!userCriteriaDto || !userCriteriaDto.selectedGroupId) ? 'user-selected-button' : ''">
            <button style="width:70%;margin: 0 5px;text-align: left"
                    class="btn btn-link"
                    (click)="selectProject(null)">
                <i class="fa fa-remove"></i>&nbsp;
                <span jhiTranslate2="userManagement.allGroups">Wszystkie projekty</span><i class="ml-1"></i>
            </button>
        </div>

        <div *ngFor="let project of projects" class="group-item d-flex flex-rowx"
            [ngClass]="(userCriteriaDto && project.id==userCriteriaDto.selectedGroupId) ? 'user-selected-button' : ''">
            <button style="width:70%;margin: 0 5px;text-align: left"
                    class="btn btn-link"
                    (click)="selectProject(project.id)">
                <i class="fa fa-users"></i>&nbsp;
                <span><i class="ml-1">{{project.name}}</i></span>
            </button>
            <div class="group-buttons">
                <button type="submit" *ngIf="false"
                        (click)="showGroupDialog(userGroup)"
                        replaceUrl="true"
                        queryParamsHandling="merge"
                        class="btn btn-sm btn-link">
                    <span class="fa fa-pencil"></span>
                </button>
                <button type="button" *ngIf="false"
                        (click)="deleteGroup(userGroup)"
                        replaceUrl="true"
                        queryParamsHandling="merge"
                        class="btn btn-sm btn-link">
                    <span class="fa fa-trash"></span>
                </button>
            </div>
        </div>

    </div>
    <div class="user-card pl-2 pr-2 pt-3" style="width:100%">
        <form (ngSubmit)="criteriaChanged()" name="searchForm" novalidate #searchForm="ngForm">
            <div *ngIf="userCriteriaDto" fxLayout="row" class="mb-2">
                <div fxLayout="column" class="mr-2">
                    <div class="search-input" fxLayout="row">
                        <div fxLayout="column">
                            <input name="search-name"
                                   type="text"
                                   placeholder="{{'userManagement.filter.user-name' | translate}}"
                                   [(ngModel)]="userCriteriaDto.name"
                                   (change)="criteriaNameChanged()"/>
                        </div>
                        <mat-divider [vertical]="true"></mat-divider>
                        <div fxLayout="column">
                            <button
                                mat-icon-button
                                type="submit"
                                style="margin: 0;"
                                [disabled]="!userCriteriaDto.name">
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>
                        <mat-divider [vertical]="true"></mat-divider>
                        <div fxLayout="column">
                            <button
                                mat-icon-button
                                type="button"
                                (click)="criteriaNameRemove()"
                                style="margin: 0"
                                [disabled]="!userCriteriaDto.name">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <div class="btn-group flex-btn-group-container">
                        <button type="button"
                                class="btn btn-light btn-user-status"
                                mat-raised-button
                                (click)="activateAll(true)"
                                [ngClass]="{'btn-primary': userCriteriaDto.status=='a'}">
                            <span class="d-none d-md-inline"
                                  jhiTranslate="entity.common.activateAll">Aktywuj wszystkich</span>
                        </button>
                        <button type="button"
                                class="btn btn-light btn-user-status"
                                mat-raised-button
                                (click)="activateAll(false)"
                                [ngClass]="{'btn-primary': userCriteriaDto.status=='i'}">
                            <span class="d-none d-md-inline"
                                  jhiTranslate="entity.common.deactivateAll">Dezaktywuj wszystkich</span>
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <mat-divider></mat-divider>
        <div class="table-responsive user-table" *ngIf="usersAll">
            <!--panel zapytań -->

            <table class="table">
                <thead>
                <tr jhiSort [(predicate)]="predicate" [(ascending)]="reverse" [callback]="transition.bind(this)">
                    <th jhiSortBy="userEmail">
                        <span jhiTranslate="userManagement.table.email">Login</span>
                        <span class="fa fa-sort"></span>
                    </th>
                    <th></th>
                    <th jhiSortBy="groupName">
                        <span jhiTranslate="userManagement.table.group">Grupa</span>
                        <span class="fa fa-sort"></span>
                    </th>
                    <!--                   <th jhiSortBy="userCreatedDate">
                                           <span jhiTranslate="userManagement.createdDate">Zalogowany</span>
                                           <span class="fa fa-sort"></span>
                                       </th>-->
                    <th jhiSortBy="userCompany">
                        <span >Projekt</span>
                        <span class="fa fa-sort"></span>
                    </th>

                </tr>
                </thead>
                <tbody *ngIf="usersAll">
                <tr *ngFor="let user of usersAll; trackBy: trackIdentity" class="group-item"
                    [class.selectedrow]="isUserRowSelected(user)">
                    <td class="d-flex">
                        <div class="login-status"><span class="logged-out"
                                                        [ngClass]="{'logged-in': user| userLogged}">●</span></div>
                        <div>
                            <div *ngIf="user.userFullName!='null null'">
                                <span> {{user.userFullName}}</span>
                            </div>
                            <div *ngIf="user.userFullName=='null null'"><span style="text-transform: uppercase"
                                                                              jhiTranslate="userManagement.table.nonRegisterUser"></span>
                            </div>
                            <div class="hint">{{user.userEmail}}</div>

                        </div>
                    </td>


                    <td>
                        <button class="btn btn-danger btn-sm" (click)="setActive(user, true)" *ngIf="!user.active"
                                [disabled]="currentAccount.id === user.userId"
                                jhiTranslate="userManagement.deactivated">Deactivated
                        </button>
                        <button class="btn btn-success btn-sm" (click)="setActive(user, false)" *ngIf="user.active"
                                [disabled]="currentAccount.id === user.userId"
                                jhiTranslate="userManagement.activated">Activated</button>
                    </td>
                    <td>
                        <div>
                            <span *ngIf="user.groupName">{{ user.groupName }}</span>
                            <div class="hint">{{ user.userPhoneNumber }}</div>
                        </div>
                    </td>
                    <!--  <td attr.title="{{user.userCreatedDate | date:'HH:mm dd.MM.yyyy':'+0300'}}">{{user.userCreatedDate |
                          date:'HH:mm dd.MM.yyyy'}}
                      </td>-->

                    <td>
                        <div>{{findProjectName(user.projectId)}}
                           <!-- <div>{{user.loginDate | date:'yyyy-MM-dd HH:mm'}}</div>-->
                        </div>
                    </td>

                </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="false">
            <div class="row justify-content-center">
                <jhi-item-count [page]="page" [total]="queryCount" [itemsPerPage]="itemsPerPage"></jhi-item-count>
            </div>
            <div class="row justify-content-center">
                <ngb-pagination [collectionSize]="totalItems" [(page)]="page" [pageSize]="itemsPerPage"
                                [maxSize]="5"
                                [rotate]="true" [boundaryLinks]="true"
                                (pageChange)="loadPage(page)"></ngb-pagination>
            </div>
        </div>
    </div>

</div>

