import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {JhiLoginModalComponent} from '../login/login.component';
import {JhiLanguageHelper} from '../index';
import {ThermsModalComponent} from './therms.component';
import {ProjectVdr} from '@admin/project-vdr';

@Injectable()
export class ThermsModalService {
    private isOpen = false;

    constructor(
        private modalService: NgbModal,
    ) {
    }

    open(): NgbModalRef {
        if (this.isOpen) {
            return;
        }
        this.isOpen = true;
        const modalRef = this.modalService.open(ThermsModalComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            backdropClass: 'login-backdrop',
            windowClass: 'login-modal',
            centered: true
        });



        modalRef.result.then((result) => {
            this.isOpen = false;
        }, (reason) => {
            this.isOpen = false;
        });
        return modalRef;
    }
}
