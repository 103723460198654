<div class="area" fxLayout="row" fxFlexAlign="start center">
</div>
<mat-divider></mat-divider>
<mat-tab-group [selectedIndex]="selectedTab">
    <mat-tab class="custom-mat-tab">
        <ng-template mat-tab-label><span jhiTranslate="details.title">Details</span></ng-template>
        <div>
            <div fxLayout="column">
                <div fxLayout="column" *ngIf="_documentsIds && directiryDetails">
                    <span class="directory-tree-title" jhiTranslate="details.folder.title">FOLDER INFO</span>
                    <div class="tab-content-font" *ngIf="directiryDetails">
                        <div fxLayout="row">
                            <span fxFlex="50%" jhiTranslate="details.folder.name">Folder name: </span>
                            <span fxFlex="50%">{{directiryDetails.directoryName}}</span>
                        </div>
                        <div>
                            <span fxFlex="50%" jhiTranslate="details.folder.created">Date created: </span>
                            <span fxFlex="50%">{{directiryDetails.createdDate | date:'dd.MM.yyyy'}}</span>
                        </div>
                        <div>
                            <span fxFlex="50%" jhiTranslate="details.folder.files">Files: </span>
                            <span fxFlex="50%">{{directiryDetails.files}}</span>
                        </div>
                        <div>
                            <span fxFlex="50%" jhiTranslate="details.folder.subfolders">Subfolders: </span>
                            <span fxFlex="50%">{{directiryDetails.subfolders}}</span>
                        </div>
                        <div>
                            <span fxFlex="50%" jhiTranslate="details.folder.size">Size: </span>
                            <span fxFlex="50%">{{directiryDetails.size | fileSize}}</span>
                        </div>
                        <div>
                            <span fxFlex="50%" jhiTranslate="details.folder.createdBy">Created by: </span>
                            <span fxFlex="50%">{{directiryDetails.createdBy}}</span>
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div *ngIf="_documentsIds && fileDetails" fxLayout="column">
                    <span class="directory-tree-title" jhiTranslate="details.file.title">FILE INFO</span>
                    <div class="tab-content-font">
                        <div>
                            <span fxFlex="50%" jhiTranslate="details.file.name">Name: </span>
                            <span fxFlex="50%">{{fileDetails.fileName}}</span>
                        </div>
                        <div *ngIf="fileDetails.pages">
                            <span fxFlex="50%" jhiTranslate="details.file.pages">Pages: </span>
                            <span fxFlex="50%">{{fileDetails.pages}}</span>
                        </div>
                        <div>
                            <span fxFlex="50%" jhiTranslate="details.file.size">Size: </span>
                            <span fxFlex="50%">{{fileDetails.fileSize | fileSize}}</span>
                        </div>
                        <div>
                            <span fxFlex="50%" jhiTranslate="details.file.createdBy">Created by: </span>
                            <span fxFlex="50%">{{fileDetails.createdBy}}</span>
                        </div>
                        <div>
                            <span fxFlex="50%" jhiTranslate="details.file.version">Version: </span>
                            <span fxFlex="50%">{{fileDetails.documentVersion}}</span>
                        </div>
                        <div>
                            <span fxFlex="50%">ID: </span>
                            <span fxFlex="50%">{{fileDetails.documentId}}</span>
                        </div>

                        <div>
                            <span fxFlex="50%">BatchID: </span>
                            <span fxFlex="50%">{{fileDetails.batchId}}</span>
                        </div>

                        <div>
                            <span fxFlex="50%" jhiTranslate="details.file.original.name">Original name: </span>
                            <span fxFlex="50%" [matTooltip]="fileDetails.originalName !== undefined ? fileDetails.originalName : fileDetails.fileName">
                                {{ fileDetails.originalName !== undefined ? fileDetails.originalName : fileDetails.fileName }}</span>
                        </div>

                        <div>
                            <span fxFlex="50%" jhiTranslate="details.file.permissions">Permissions: </span>
                            <span fxFlex="50%"></span>
                        </div>
                        <div *ngIf="fileDetails.effectivePermissions.includes('UPLOAD')">
                            <span fxFlex="20%"><mat-icon svgIcon="vdr_upload"></mat-icon></span>
                            <span jhiTranslate="permissions.upload">Upload</span>
                        </div>
                        <div *ngIf="fileDetails.effectivePermissions.includes('DOWNLOAD_ORIGINAL')">
                            <span fxFlex="20%"><mat-icon svgIcon="vdr_download_orginal"></mat-icon></span>
                            <span jhiTranslate="permissions.download_orginal">download_orginal</span>
                        </div>
                        <div *ngIf="fileDetails.effectivePermissions.includes('DOWNLOAD_PDF')">
                            <span fxFlex="20%"><mat-icon svgIcon="vdr_download_pdf"></mat-icon></span>
                            <span jhiTranslate="permissions.download_pdf">download_pdf</span>
                        </div>
                        <div *ngIf="fileDetails.effectivePermissions.includes('PRINT')">
                            <span fxFlex="20%"><mat-icon svgIcon="vdr_print_document"></mat-icon></span>
                            <span jhiTranslate="permissions.print_document">print_document</span>
                        </div>
                        <div *ngIf="fileDetails.effectivePermissions.includes('DOWNLOAD_ENCRYPTED')">
                            <span fxFlex="20%"><mat-icon svgIcon="vdr_download_encrypted"></mat-icon></span>
                            <span jhiTranslate="permissions.download_encrypted">download_encrypted</span>
                        </div>
                        <div *ngIf="fileDetails.effectivePermissions.includes('VIEW')">
                            <span fxFlex="20%"><mat-icon svgIcon="vdr_view_document"></mat-icon></span>
                            <span jhiTranslate="permissions.view_document">view_document</span>
                        </div>
                        <div *ngIf="fileDetails.effectivePermissions.includes('FENCE_VIEW')">
                            <span fxFlex="20%"><mat-icon svgIcon="vdr_fence_view"></mat-icon></span>
                            <span jhiTranslate="permissions.fence_view">fence_view</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="_documentsIds && filesDetails" fxLayout="column">
                    <span class="directory-tree-title" jhiTranslate="details.files.title">FILES INFO</span>
                    <div class="tab-content-font" *ngIf="filesDetails">
                        <div>
                            <span fxFlex="50%" jhiTranslate="details.files.files">Files: </span>
                            <span fxFlex="50%">{{filesDetails.files}}</span>
                        </div>
                        <div>
                            <span fxFlex="50%" jhiTranslate="details.files.size">Size: </span>
                            <span fxFlex="50%">{{filesDetails.size | fileSize}}</span>
                        </div>
                        <div>
                            <span fxFlex="50%" jhiTranslate="details.files.createdBy">Created by: </span>
                            <span fxFlex="50%">{{filesDetails.createdBy}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab [disabled]="!showNoteTab" class="custom-mat-tab">
        <ng-template mat-tab-label *ngIf="showNoteTab"><span jhiTranslate="notes.title">Details</span></ng-template>
        <ng-template mat-tab-label *ngIf="!showNoteTab">
            <span class="note-tab-hide" jhiTranslate="notes.title">Details</span>
        </ng-template>
        <div *ngIf="showNoteTab">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header  style="padding-left: 8px !important;">
                    <span jhiTranslate="notes.create"></span>
                </mat-expansion-panel-header>
                <div fxLayout="column" fxLayoutAlign="center center"  style="min-width: 100%">
                    <mat-form-field>
                            <textarea matInput [(ngModel)]="newDocumentNote.note" width="100%"
                                      placeholder="{{'notes.text' | translate}}"
                                      style="text-transform: none !important;">
                            </textarea>
                    </mat-form-field>
                    <br>
                    <mat-button-toggle-group name="noteType" [(value)]="newDocumentNote.noteType">
                        <mat-button-toggle value="PRIVATE">
                            <span jhiTranslate="notes.private" style="text-transform: none !important;"></span>
                        </mat-button-toggle>
                        <mat-button-toggle value="PUBLIC">
                            <span jhiTranslate="notes.public" style="text-transform: none !important;"></span>
                        </mat-button-toggle>
                    </mat-button-toggle-group>

                </div>
                <br>
                <mat-divider></mat-divider>
                <div width="100%" fxLayout="row" fxLayoutAlign="end center">
                    <button mat-icon-button (click)="newNoteCancel()">
                        <mat-icon>cancel</mat-icon>
                    </button>
                    <button mat-icon-button color="var(--prime-color)" (click)="newNoteSave()" [disabled]="!newNoteSaveEnabled()">
                        <mat-icon>check_circle</mat-icon>
                    </button>
                </div>
            </mat-expansion-panel>
        </div>
        <br>
        <mat-accordion *ngIf="fileDetails?.noteList && fileDetails.noteList.length > 0 && document?.type === 'document' && single" >
            <mat-expansion-panel *ngFor="let documentNote of fileDetails.noteList ;trackBy: trackId">
                <mat-expansion-panel-header  style="padding-left: 8px">
                    <mat-panel-title>
                        <span>{{documentNote.createdBy}}</span>
                    </mat-panel-title>
                    <mat-panel-description>
                        <span>{{documentNote.createdDate | date:'dd.MM.yyyy HH:mm'}}</span>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <span>{{documentNote.note}}</span>
                <div width="100%" align="right">
                    <button mat-icon-button (click)="deleteNote(documentNote.id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-tab>
    <!--
    <mat-tab *jhiHasAnyAuthority="['QA']" [disabled]="!single || document?.type === 'directory'" class="custom-mat-tab">
        <ng-template mat-tab-label><span jhiTranslate="questions.title">Questions</span></ng-template>
        <div fxLayout="column" *ngIf="documentQuestions && documentQuestions.length > 0">
            <div *ngFor="let question of documentQuestions">
                <a routerLink="{{ '/question/' + question.id }}" href="#">
                    <div fxLayout="column" style="margin-left: 10px">
                        <div fxLayout="row" class="fontspring-13px-grey">
                            <span fxFlex="10%">{{question.answersCount}}</span>
                            <span fxFlex>{{question.authorName}}</span>
                        </div>
                        <div fxLayout="row" fxFlexAlign="start center">
                            <span fxFlex="10%">
                                <div *ngIf="question.priority">
                                    <i style="color:red;" class="fa fa-exclamation"
                                       *ngIf="question.priority.toString() === 'HIGH'"></i>
                                    <i style="color:orange;" class="fa fa-exclamation"
                                       *ngIf="question.priority.toString() === 'MEDIUM'"></i>
                                    <i style="color:yellow;" class="fa fa-exclamation"
                                       *ngIf="question.priority.toString() === 'LOW'"></i>
                                </div>
                            </span>
                            <span fxFlex class="fontspring-13px-black">{{question.title}}</span>
                            <span fxFlex>{{question.createdDate | date: 'yyyy-MM-dd'}}</span>
                        </div>
                    </div>
                </a>
                <mat-divider></mat-divider>
            </div>
        </div>
    </mat-tab>
    -->
</mat-tab-group>
