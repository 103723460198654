import {Component, HostListener, Input, OnInit} from '@angular/core';

import {
    AccountService
} from '../index';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {take, timer} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import { ProjectVdr } from '@admin/project-vdr';

@Component({
    selector: 'jhi-verification-code',
    templateUrl: './verification-code.component.html',
    styleUrls: ['../../layouts/navbar/navbar.scss'],
})
export class VerificationCodeComponent implements OnInit {
    doNotMatch: string;
    error: string;
    verificationCode: string;
    secondsLeft: number;
    sendCodeTitle: string;
    forTitle: string;

    messageType: string;
    project: ProjectVdr;

    constructor(private activeModal: NgbActiveModal,
                private accountService: AccountService,
                private translateService: TranslateService
    ) {
    }

    ngOnInit() {
        this.sendVerificationCode();
        this.error = null;

        this.forTitle = this.translateService.instant('login.form.for');
    }

    sendVerificationCode() {
        this.startCountDown();

        if (this.messageType && this.messageType === 'INVALID_CODE') {
            this.sendCodeTitle = this.translateService.instant('login.form.wrongCodeException');
        } else {
            this.sendCodeTitle = this.translateService.instant('login.form.resendCode');
            this.accountService.sendVerificationCode(this.project ? this.project.id : null).subscribe(() => {
            }, () => {
            });
        }
    }

    clearMessageTypeAndSendVerificationCode() {
        this.messageType = null;
        this.sendVerificationCode();
    }

    @HostListener('window:keyup.Enter')
    submitForm() {
        this.activeModal.close({verificationCode: this.verificationCode});

    }

    cancel() {
        this.activeModal.dismiss('cancel');
    }

    startCountDown(): void {
        const countdown$ = timer(0, 1000).pipe(
            take(60),
            map((secondsElapsed) => 60 - secondsElapsed)
        );

        countdown$.subscribe((secondsLeft) => {
            this.secondsLeft = secondsLeft;
        });
    }
}
