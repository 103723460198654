import {AfterViewInit, Component, OnInit} from '@angular/core';
import {PhoneResetService} from './phone-reset.service';
import {AccountService, Principal} from '../../../shared/index';
import {PhoneReset} from './phone-reset.model';
import {INVALID_SECURITY_ANSWER} from '../../../shared';
import {CountryISO, SearchCountryField} from 'ngx-intl-tel-input-gg';

@Component({
    selector: 'jhi-phone',
    templateUrl: './phone-reset.component.html',
    styleUrls: ['phone-reset.scss']
})
export class PhoneResetComponent implements OnInit, AfterViewInit {
    readonly SearchCountryField = SearchCountryField;
    readonly CountryISO = CountryISO;

    error: string;
    invalidSecurityAnswer: string;
    success: string;
    settingsAccount: any;
    phoneReset: PhoneReset;

    constructor(private account: AccountService,
                private phoneResetService: PhoneResetService,
                private principal: Principal,
    ) {
    }

    ngOnInit() {
        this.principal.identity().then((account) => {
            this.settingsAccount = account;
            this.phoneReset = new PhoneReset();
            this.phoneReset.phoneNumber = this.settingsAccount.phoneNumber;
            this.phoneReset.securityQuestion = this.settingsAccount.securityQuestion;
        });
    }

    ngAfterViewInit() {
        // this.renderer.selectRootElement(this.elementRef.nativeElement.querySelector('#firstNameInput')).focus();
    }

    save() {
        this.phoneReset.phoneNumber = this.phoneReset.phoneNumber['e164Number'];

        this.phoneResetService.save(this.phoneReset).subscribe(() => {
            this.error = null;
            this.invalidSecurityAnswer = null;
            this.success = 'OK';

        }, (response) => {
            this.success = null;
            this.processError(response);
        });
    }

    private processError(response) {
        this.success = null;
        if (response.status === 400 && response.error.type === INVALID_SECURITY_ANSWER) {
            this.invalidSecurityAnswer = 'ERROR';
        } else {
            this.error = 'ERROR';
        }
    }
}
