import {ReportEventTypeGroup} from '../report-event-type-group.enum';
import {ReportEventType} from '../report-event-type.enum';

export enum BlockchainConfirmationStatus {
    SEND_TO_QUEUE = 'SEND_TO_QUEUE', //  use only for migration of existing events to RMQ
    PENDING = 'PENDING', CONFIRMED = 'CONFIRMED', ERROR = 'ERROR'
}

export class ReportEventDto {
    public id: number;
    public type: ReportEventType;
    public typeGroup: ReportEventTypeGroup;
    public path: string;
    public description: string;
    public details: string;
    public date: any;
    public userId: number;
    public userLogin: string;
    public userFirstName: string;
    public userLastName: string;
    public userEmail: string;
    public userCompany: string;
    public groupId: number;
    public groupName: string;
    public checksum: string;
    public projectId: number;

    // blockchain stuff
    blockchainCommittedAt: any;
    blockchainTransactionId: string;
    blockchainStatus: BlockchainConfirmationStatus;

}
