import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from '../../app.constants';

@Injectable()
export class InfoService {

    constructor(private http: HttpClient) {
    }

    save(account: any): Observable<any> {
        return this.http.post(SERVER_API_URL + 'api/info', account);
    }
}
