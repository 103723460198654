import * as moment from 'moment';
import {Moment} from 'moment';

export class ProjectUser {
    public id: number;
    public userId: number;
    public groupId: number;
    public projectId: number;
    public groupName: string;
    public userLogin: string;
    public activated: boolean;
    public userCreatedDate?: Date;
    public userEmail?: string;
    public userFullName?: string;
    public userCompany?: string;
    public userPhoneNumber?: string;
    public active?: boolean;
    public userCompanyPosition?: string;
    public groupAdmin?: boolean;
    public qaPermission?: string;
    public ipRestriction?: boolean;
    public ip4?: string;
    public dateRestriction?: Moment;
    public notificationQa?: string;
    public notificationDocumentFrequency?: string;
    public ip6?: string;
    public ip6Type?: boolean;
    public loginDate?: Date;
    public logoutDate?: Date;
    public hasActiveSession: boolean;


    constructor(data: Object | ProjectUser
    ) {
        Object.assign(this, data);
    }

    get enableType(): string {
        if (this.dateRestriction) {
            return 'todate';
        } else if (!this.active) {
            return 'disabled';
        } else {
            return 'enabled';
        }
    }

    set enableType(restriction: string) {
        if (restriction === 'enabled') {
            this.active = true;
            this.dateRestriction = null;
        } else if (restriction === 'disabled') {
            this.active = false;
            this.dateRestriction = null;
        } else {
            this.active = true;
            this.dateRestriction = moment();
        }
    }




}


