export enum ReportBlockchain {
    WAITING_FOR_CONFIRMATION = 'report.blockchain.waiting-for-confirmation',
    CONFIRMED = 'report.blockchain.confirmed',
    CONFIRMED_AT = 'report.blockchain.confirmed-at',
    TRANSACTION_ID = 'report.blockchain.transaction-id',
    CHECKSUM = 'report.blockchain.checksum',



}
