import {Component, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';

import {JhiLanguageHelper} from '../../shared';
import {JhiAlertService, JhiEventManager}from '@upside-cloud/ng-jhipster';
import {BackendUnavailableInterceptor} from '../../blocks/interceptor/backend-unavailable.interceptor';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'jhi-main',
    styleUrls: ['main.component.scss'],
    templateUrl: './main.component.html'
})
export class JhiMainComponent implements OnInit {
    connectionLost = false;

    constructor(
        private jhiLanguageHelper: JhiLanguageHelper,
        private router: Router,
        private eventManager: JhiEventManager,
        private alertService: JhiAlertService,
        private translateService: TranslateService
    ) {
    }

    private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
        let title: string = (routeSnapshot.data && routeSnapshot.data['pageTitle']) ? routeSnapshot.data['pageTitle'] : 'vdrApp';
        if (routeSnapshot.firstChild) {
            title = this.getPageTitle(routeSnapshot.firstChild) || title;
        }
        return title;
    }

    ngOnInit() {
        // initializeApp({
        //     apiKey: 'AIzaSyA6ux_qUOahtZxuOLqqJLIUzFNeF_K47Gw',
        //     authDomain: 'vdr-test.firebaseapp.com',
        //     projectId: 'vdr-test',
        //     storageBucket: 'vdr-test.appspot.com',
        //     messagingSenderId: '1028860431351',
        //     appId: '1:1028860431351:web:a4a52177b3041df5b17cf2'
        //
        // });

        this.translateService.setDefaultLang('pl');

        this.eventManager.subscribe(BackendUnavailableInterceptor.eventNameBackendUnavailable, () => this.connectionLost = true);
        this.eventManager.subscribe(BackendUnavailableInterceptor.eventNameBackendAvailable, () => {
            this.alertService.success('Połączono z VDR');
            return this.connectionLost = false;
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.jhiLanguageHelper.updateTitle(this.getPageTitle(this.router.routerState.snapshot.root));
            }
        });
    }

}
