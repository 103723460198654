import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from '../app.constants';

import {QuestionReport} from './report-question/report-question.model';
import {momentEndOfDay, momentStartOfDay, Pageable} from '../shared';
import {ReportQuestionsCriteriaDto} from './report-question/dto/report-questions-criteria.dto.model';
import {saveAs} from 'file-saver/FileSaver';
import {ProjectReportDto, ProjectReportUploadedDTO, ReportUploadedDto} from './report-uploaded/dto/report-uploaded-dto.model';
import {ReportOverviewCriteriaDto} from './report-overview/report-overview-criteria-dto.model';
import {ReportUploadedCriteriaDto} from './report-uploaded/dto/report-uploaded-criteria-dto.model';
import {OverviewReportProjectDto} from './report-overview/dto/overview-report-project-dto.model';
import {ReportOverviewVM} from './report-overview/report-overview-vm.model';
import {ReportEventDto} from './report-event/dto/report-event-dto.model';
import {ReportEventCriteriaDto} from './report-event/dto/report-event-criteria-dto.model';
import {ReportSmsCriteriaDto} from './report-sms/dto/report-sms-criteria-dto.model';
import {ReportSmsDto} from './report-sms/dto/report-sms.dto.model';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';
import {ReportProcessingDTO} from './report-processing/report-processing.component';
import {ReportArchive} from './report-archvie/report-archive.model';
import {catchError, map} from 'rxjs/operators';
import {UploadDownloadService} from '../entities/directory-document/upload-download.service';
import {UserCriteriaDto} from '../admin';

@Injectable()
export class ReportService {
    dateFormat = 'yyyy-MM-dd';

    constructor(
        private http: HttpClient,
        private translateService: TranslateService,
        private     datePipe: DatePipe,

    ) {
    }

    findOverview(reportOverviewCriteriaDto: ReportOverviewCriteriaDto, visit: boolean): Observable<ReportOverviewVM> {
        const params = new HttpParams().set('visit', String(visit));

        return this.http.post<ReportOverviewVM>(
            SERVER_API_URL + 'api/_search/report/overview/all',
            this.convertOverviewSearchToServer(reportOverviewCriteriaDto),
            {params, observe: 'response'}
        ).pipe(map((res: HttpResponse<ReportOverviewVM>) => res.body));
    }

    findOverviewDocumentType(reportOverviewCriteriaDto: ReportOverviewCriteriaDto): Observable<HttpResponse<QuestionReport[]>> {
        return this.http.post<Object[]>(
            SERVER_API_URL + 'api/_search/report/overview/document-types',
            this.convertOverviewSearchToServer(reportOverviewCriteriaDto),
            {observe: 'response'}
        );
    }

    findProcessingProgress(): Observable<ReportProcessingDTO[]> {
        return this.http.get<ReportProcessingDTO[]>(
            SERVER_API_URL + 'api/_search/report/loading-progress',
            {observe: 'body'}
        );
    }

    findOverviewDocumentViews(reportOverviewCriteriaDto: ReportOverviewCriteriaDto, visit: boolean): Observable<HttpResponse<Object[]>> {
        const params = new HttpParams().set('visit', String(visit));
        return this.http.post<Object[]>(
            SERVER_API_URL + 'api/_search/report/overview/document-views',
            this.convertOverviewSearchToServer(reportOverviewCriteriaDto),
            {params, observe: 'response'}
        );
    }

    findOverviewLoggedUsers(reportOverviewCriteriaDto: ReportOverviewCriteriaDto): Observable<HttpResponse<Object[]>> {
        return this.http.post<Object[]>(
            SERVER_API_URL + 'api/_search/report/overview/users',
            this.convertOverviewSearchToServer(reportOverviewCriteriaDto),
            {observe: 'response'}
        );
    }

    findMostViewedQuestions(reportOverviewCriteriaDto: ReportOverviewCriteriaDto): Observable<HttpResponse<Object[]>> {
        return this.http.post<Object[]>(
            SERVER_API_URL + 'api/_search/report/overview/questions-most-viewed',
            this.convertOverviewSearchToServer(reportOverviewCriteriaDto),
            {observe: 'response'}
        );
    }

    findMostViewedDocuments(reportOverviewCriteriaDto: ReportOverviewCriteriaDto): Observable<HttpResponse<Object[]>> {
        return this.http.post<Object[]>(
            SERVER_API_URL + 'api/_search/report/overview/documents-most-viewed',
            this.convertOverviewSearchToServer(reportOverviewCriteriaDto),
            {observe: 'response'}
        );
    }

     findQAReport(): Observable<{groupName: string,threads:number, posts: number}[]> {
            return this.http.get<{groupName: string,threads:number, posts: number}[]>(SERVER_API_URL + 'api/home/qa');
        }

    findMostSearchedDocuments(reportOverviewCriteriaDto: ReportOverviewCriteriaDto): Observable<HttpResponse<Object[]>> {
        return this.http.post<Object[]>(
            SERVER_API_URL + 'api/_search/report/overview/documents-most-searched',
            this.convertOverviewSearchToServer(reportOverviewCriteriaDto),
            {observe: 'response'}
        );
    }

    findProject(): Observable<OverviewReportProjectDto> {
        return this.http.get<OverviewReportProjectDto>(SERVER_API_URL + 'api/_search/report/overview/project', {observe: 'response'})
            .pipe(map((res: HttpResponse<OverviewReportProjectDto>) => res.body));
    }

    findQuestionReport(reportQuestionsCriteriaDto: ReportQuestionsCriteriaDto, visit: boolean): Observable<HttpResponse<QuestionReport[]>> {
        const params = new HttpParams().set('visit', String(visit));

        return this.http.post<QuestionReport[]>(
            SERVER_API_URL + 'api/_search/report/questions',
            this.convertQuestionSearchToServer(reportQuestionsCriteriaDto),
            {params, observe: 'response'}
        );
    }

    findUploaded(reportUploadedCriteriaDto: ReportUploadedCriteriaDto, visit: boolean): Observable<ReportUploadedDto> {
        const params = new HttpParams().set('visit', String(visit));

        return this.http.post<ReportUploadedDto>(
            SERVER_API_URL + 'api/_search/report/uploaded',
            this.convertUploadedSearchToServer(reportUploadedCriteriaDto),
            {params, observe: 'response'}
        ).pipe(map((res: HttpResponse<ReportUploadedDto>) => res.body));
    }

    findProjectUploaded(reportUploadedCriteriaDto: ReportUploadedCriteriaDto): Observable<ProjectReportUploadedDTO[]> {
        return this.http.post<ProjectReportUploadedDTO[]>(
            SERVER_API_URL + 'api/_search/report/project/uploaded',
            this.convertUploadedSearchToServer(reportUploadedCriteriaDto),
            {observe: 'response'}
        ).pipe(map((res: HttpResponse<ProjectReportUploadedDTO[]>) => res.body));
    }


    findProjects(): Observable<ProjectReportDto> {
        return this.http.get<ProjectReportDto>(
            SERVER_API_URL + 'api/_search/report/project'
        );
    }

    findEvents(reportEventCriteriaDto: ReportEventCriteriaDto, pageable: Pageable, visit: boolean): Observable<HttpResponse<ReportEventDto[]>> {
        const params = new HttpParams().set('visit', String(visit));

        const criteriaPagination: any = {
            reportEventCriteriaDTO: this.convertEventsSearchToServer(reportEventCriteriaDto),
            pageable
        };

        return this.http.post<ReportEventDto[]>(
            SERVER_API_URL + 'api/_search/report/events',
            criteriaPagination,
            {params, observe: 'response'}
        );
    }

    exportOverview(reportOverviewCriteriaDto: ReportOverviewCriteriaDto)  {
        this.http.post<Blob>(
            SERVER_API_URL + 'api/_export/report/overview',
            this.convertOverviewSearchToServer(reportOverviewCriteriaDto),
            {responseType: 'blob' as 'json',  observe: 'response'}
        ).subscribe( (res) => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
        });
    }

    exportUser(userCriteriaDto: UserCriteriaDto): void {
        this.http.post<Blob>(
            SERVER_API_URL + 'api/_export/report/users',
            userCriteriaDto,
            {responseType: 'blob' as 'json',  observe: 'response'}
        ).subscribe( (res) => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
        });
    }

    exportQuestions(reportQuestionsCriteriaDto: ReportQuestionsCriteriaDto): Observable<HttpResponse<Blob>>  {
        return this.http.post<Blob>(
            SERVER_API_URL + 'api/_export/report/questions',
            this.convertQuestionSearchToServer(reportQuestionsCriteriaDto),
            {responseType: 'blob' as 'json',  observe: 'response'}
        );
    }

    exportEvents(reportEventCriteriaDto: ReportEventCriteriaDto)  {
        this.http.post<Blob>(
            SERVER_API_URL + 'api/_export/report/events',
            this.convertEventsSearchToServer(reportEventCriteriaDto),
            {responseType: 'blob' as 'json',  observe: 'response'}
        ).subscribe( (res) => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
        });
    }

    exportUploaded(reportUploadedCriteriaDto: ReportUploadedCriteriaDto)  {
        this.http.post<Blob>(
            SERVER_API_URL + 'api/_export/report/uploaded',
            this.convertUploadedSearchToServer(reportUploadedCriteriaDto),
            {responseType: 'blob' as 'json',  observe: 'response'}
        ).subscribe( (res) => {
            let fileName;
            console.log('deleted:' + reportUploadedCriteriaDto.deleted );
            if (!reportUploadedCriteriaDto.deleted) {
                console.log('vdrApp.document.export.upload.report');
                fileName = this.translateService.instant('vdrApp.document.export.upload.report') + '-'
                    + this.datePipe.transform(new Date(), this.dateFormat) + '.xls';
            } else {
                console.log('vdrApp.document.export.upload.report.all');
                fileName = this.translateService.instant('vdrApp.document.export.upload.report.all').split(' ').join('')
                    + this.datePipe.transform(reportUploadedCriteriaDto.dateFrom.toDate()!, this.dateFormat) + '-'
                    + this.datePipe.transform(reportUploadedCriteriaDto.dateTo.toDate()!, this.dateFormat) + '.xls';
                // getFileNameFromResponseContentDisposition(res);
            }

            saveFile(res.body, fileName);
        });
    }

    findSmsOperations(reportSmsCriteriaDto: ReportSmsCriteriaDto): Observable<ReportSmsDto[]> {
        return this.http.post<ReportSmsDto[]>(
            SERVER_API_URL + 'api/_search/report/sms-operations',
            this.convertUploadedSearchToServer(reportSmsCriteriaDto),
            {observe: 'response'}
        ).pipe(map((res: HttpResponse<ReportSmsDto[]>) => res.body));
    }

    downloadEntitlementReport(): Observable<HttpResponse<Blob>> {
        return this.http.post<Blob>(SERVER_API_URL + 'api/_search/report/entitlement', null,
            {responseType: 'blob' as 'json',  observe: 'response'}).pipe();
    }

    createAndWaitForReportArchive(reportArchive: ReportArchive): Observable<HttpResponse<Blob>> {
        return this.http.post<Blob>(SERVER_API_URL + 'api/archive/create?waitForCompletion=true', reportArchive,
            {responseType: 'blob' as 'json',  observe: 'response'}).pipe(catchError(UploadDownloadService.parseErrorBlob));
    }

    addReportArchiveWithDownload(reportArchive: ReportArchive): Observable<HttpResponse<void>> {
        return this.http.post<void>(SERVER_API_URL + 'api/archive/create', reportArchive,
            {observe: 'response'}).pipe();
    }

    addReportArchive(reportArchive: ReportArchive): Observable<HttpResponse<void>> {
        return this.http.post<void>(SERVER_API_URL + 'api/archive/add', reportArchive,
            {observe: 'response'}).pipe();
    }

    downloadURI(uri: string, filename: string) {
        const link = document.createElement('a');
        link.download = filename;
        link.href = SERVER_API_URL + uri;
        link.setAttribute('target', '_blank');
        link.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(SERVER_API_URL + uri);
            link.remove();
        }, 500);

    }

    downloadFileByReportArchive(reportArchive: ReportArchive): void {
        this.http.get(SERVER_API_URL + 'api/archive/download/' + reportArchive.id,
            {reportProgress: true, observe: 'response', responseType: 'arraybuffer'})
            .subscribe((response) => {
                if (response.type === HttpEventType.Response) {
                    reportArchive.downloading = false;
                }
            });
    }

    downloadFileByReportArchiveWithProgress(reportArchive: ReportArchive): Observable<HttpEvent<any>> {
        return this.http.post(SERVER_API_URL + 'api/archive/download', reportArchive,
            {responseType: 'blob', reportProgress: true, observe: 'events',
                headers: new HttpHeaders(
                    { 'Content-Type': 'application/json' }
                )
            }
        );
    }

    findReportArchives(page: number, size: number): Observable<HttpResponse<ReportArchive[]>> {
        return this.http.post<ReportArchive[]>(SERVER_API_URL + 'api/archive/find', { page, size},
            {observe: 'response'}).pipe();
    }

    findReportArchiveByToken(token: string): Observable<HttpResponse<ReportArchive>> {
        return this.http.post<ReportArchive>(SERVER_API_URL + 'api/archive/token/find', token,
            {observe: 'response'}).pipe();
    }

    private convertOverviewSearchToServer(reportOverviewCriteriaDto: ReportOverviewCriteriaDto): ReportOverviewCriteriaDto {
        const copy = Object.assign(new ReportOverviewCriteriaDto(), reportOverviewCriteriaDto);

        if (copy.dateFrom) {
            copy.dateFrom = momentStartOfDay(copy.dateFrom);
        }

        if (copy.dateTo) {
            copy.dateTo = momentEndOfDay(copy.dateTo);
        }

        return copy;
    }

    private convertQuestionSearchToServer(reportQuestionsCriteriaDto: ReportQuestionsCriteriaDto): ReportQuestionsCriteriaDto {
        const copy = Object.assign(new ReportQuestionsCriteriaDto(), reportQuestionsCriteriaDto);

        if (copy.createdFrom) {
            copy.createdFrom = momentStartOfDay(copy.createdFrom);
        }

        if (copy.createdTo) {
            copy.createdTo = momentEndOfDay(copy.createdTo);
        }

        return copy;
    }

    private convertUploadedSearchToServer(reportUploadedCriteriaDto: ReportSmsCriteriaDto): ReportUploadedCriteriaDto {
        const copy = Object.assign(new ReportUploadedCriteriaDto(), reportUploadedCriteriaDto);

        if (copy.dateFrom) {
            copy.dateFrom = momentStartOfDay(copy.dateFrom);
        }

        if (copy.dateTo) {
            copy.dateTo = momentEndOfDay(copy.dateTo);
        }

        return copy;
    }

    private convertEventsSearchToServer(reportEventCriteriaDto: ReportEventCriteriaDto): any {
        const copy = Object.assign(new ReportEventCriteriaDto(), reportEventCriteriaDto);

        if (copy.dateFrom) {
            copy.dateFrom = momentStartOfDay(copy.dateFrom);
        }

        if (copy.dateTo) {
            copy.dateTo = momentEndOfDay(copy.dateTo);
        }

        return copy;
    }

    today() {
        const dateFormat = 'yyyy-MM-dd';
        // Today + 1 day - needed if the current day must be included
        const today: Date = new Date();
        today.setDate(today.getDate() + 1);
        const date = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        return  this.datePipe.transform(date, dateFormat);
    }
}

export const getFileNameFromResponseContentDisposition = (res: HttpResponse<any>) => {
    const contentDisposition = res.headers.get('content-disposition') || '';
    const matches = /filename=([^;]+)/ig.exec(contentDisposition);
    const fileName = (matches[1] || 'untitled').trim();
    return fileName.replace(/['"]+/g, '');
};

export const saveFile = (blobContent: Blob, fileName: string) => {
    const blob = new Blob([blobContent], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
};
