import {Moment} from 'moment';
import {ReportEventTypeGroup} from '../report-event-type-group.enum';
import {ReportEventType} from '../report-event-type.enum';
import {ProjectVdr} from "@admin/project-vdr";

export class ReportEventCriteriaDto {
    public projectId: number;
    public groupIds: number[];
    public reportEventTypes: ReportEventType[];
    public dateFrom: Moment;
    public dateTo: Moment;
    public userId: number;
}
