import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Group, ProjectUser, UserService} from '../shared';
import {GroupService} from '../shared';

@Component({
    selector: 'jhi-user-filter',
    templateUrl: './user-filter.html',
    styleUrls: ['./report.scss']
})
export class UserFilter implements OnInit {

    @Input()
    projectUsers: ProjectUser[];

    selectedUsers: any[];

    @Output() select = new EventEmitter();

    constructor(
        private userService: UserService,
    ) {
    }

    loadAllUsers() {
        this.userService.findProjectUsers().subscribe((res) => {
                this.projectUsers = res.body;
                this.selectedUsers = Object.assign([], this.projectUsers);
                this.changeUser();
            },
            (error: HttpErrorResponse) => this.onError(error.message)
        );
    }

    selectUser(user) {
        if (this.selectedUsers.includes(user)) {
            this.selectedUsers.splice(this.selectedUsers.indexOf(user), 1);
        } else {
            this.selectedUsers.push(user);
        }
        this.changeUser();
    }

    changeUser() {
        this.select.emit(this.selectedUsers);
    }

    isElementSelected(user) {
        return this.selectedUsers && this.selectedUsers.includes(user);
    }

    private onError(error) {
    }

    ngOnInit() {

        this.loadAllUsers();

    }

}
