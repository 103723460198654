import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import { VdrSharedModule } from '../../shared';
import {
    DocumentNoteVdrService,
} from './';

@NgModule({
    imports: [
        VdrSharedModule,
    ],
    providers: [
        DocumentNoteVdrService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VdrDocumentNoteVdrModule {}
